<template>
  <div class="quicktips-page-wrapper">
    <div class="quicktips-page-header">{{ $t('help.quickTips') }}</div>
    <v-text-field
      solo
      v-model="quicktipsSearch"
      single-line
      hide-details
      class="item-search-input"
      autocomplete="off"
      :placeholder="
        !!routeQuery ? $t('help.displayingResult') : $t('help.searchQuickTips')
      "
      :disabled="!!routeQuery"
      ><template v-slot:append>
        <v-icon
          color="primary"
          @click="resetSearch"
          v-text="!!routeQuery || quicktipsSearch ? `mdi-close` : `mdi-magnify`"
        />
      </template>
    </v-text-field>

    <div class="qtips-content-wrapper">
      <v-btn
        v-if="previousRoute === 'search' && isSearchPage"
        class="btn-back"
        @click="goToSearchLandingPage"
      >
        <v-icon class="icon" color="primary">mdi-chevron-left</v-icon>
        <span>{{ $t('help.backToSearch') }}</span>
      </v-btn>
      <div v-if="isLoading" class="d-flex justify-center pt-4">
        <LoadSpinner />
      </div>
      <v-expansion-panels
        v-else-if="quicktips && quicktips.length"
        multiple
        :mandatory="!!(quicktipsSearch || $route.query.id)"
        class="categories-wrapper"
        v-model="activePanel"
        ref="categories-wrapper"
      >
        <Category
          :quicktips="quicktips"
          :key="category.name"
          :itemData="category"
          v-for="category in categories"
          :cat-index="category.index"
          :ref="`quicktips-category-${category.index}`"
        />
      </v-expansion-panels>
      <v-row v-else>
        <EmptyState
          img-url="/assets/img/help/no-results-found-send-support-request.svg"
          :is-btn-visible="true"
          :buttonName="'help.supportRequest'"
          :handleCTA="handleOpenSendRequest"
        >
          <template v-slot:description>
            <div class="no-results__wrapper">
              <p class="no-results-found">
                {{ $t('build.step1.storylineFieldNoResult') }}
              </p>
              <p class="no-results__description">
                {{ $t('help.noResultDescription') }}
              </p>
            </div>
          </template>
        </EmptyState>
      </v-row>
    </div>
  </div>
</template>

<script>
import {
  mdiAccountCircleOutline,
  mdiMagnify,
  mdiHelpCircleOutline,
  mdiPlaylistCheck,
} from '@mdi/js';
import { mapState } from 'vuex';
import debounce from 'lodash/debounce';
import EmptyState from '../../../common/EmptyState.vue';
import Category from './Category';
import LoadSpinner from '@/components/common/LoadSpinner';
import LocalSearch from '@/services/localSearch';
import helpApi from '@/API/help-api';
import { transformId } from '@/utils/common';
import EventBus from '../../../common/event-bus';
import { logSearchQuery } from '../../../../utils/api-helper';
import {
  MatomoAnalyticsHandler,
  QUICKTIPS,
} from '../../../common/Analytics/MatomoAnalyticsHandler';
import { trackHelpEvents } from '../../../common/Analytics/HelpEvents';

export default {
  name: 'Quick-tips',
  components: { LoadSpinner, EmptyState, Category },
  data() {
    return {
      previousRoute: '',
      activePanel: [],
      isLoading: true,
      searchEngine: null,
      quicktipsSearch: '',
      categories: [
        { name: 'Slide Library', icon: 'slide-lib-icon', index: 0 },
        {
          name: 'Prezentation Library',
          icon: 'prez-ex-icon',
          index: 1,
        },
        {
          name: 'Profile',
          icon: mdiAccountCircleOutline,
          isMdi: true,
          index: 2,
        },
        { name: 'Search', icon: mdiMagnify, isMdi: true, index: 3 },
        { name: 'Fingerprint', icon: 'fp-icon', index: 4 },
        { name: 'Help', icon: mdiHelpCircleOutline, isMdi: true, index: 5 },
        { name: 'General', icon: mdiPlaylistCheck, isMdi: true, index: 6 },
      ],
      rawList: [],
    };
  },
  methods: {
    // eslint-disable-next-line func-names
    handleSearch: debounce(function () {
      trackHelpEvents.hybridSearch(
        this.currentUser,
        this.quicktipsSearch,
        'quicktips',
        this.quicktips?.length,
      );
    }, 500),
    handleOpenSendRequest() {
      this.$root.$emit('openHelpMenuRequest');
    },
    goToSearchLandingPage() {
      this.$router.back();
    },
    logSearch() {
      const logPayload = {
        type: 'Help',
        searchTerm: '',
        searchTermResult: false,
      };
      logPayload.searchTerm =
        this.$route.query.display_name?.toLowerCase() || '';
      const foundItem = this.rawList.find(
        (e) => e.uuid.toLowerCase() === transformId(this.$route.query.id),
      );
      logPayload.searchTermResult = !!foundItem;
      logSearchQuery(logPayload)
        .then()
        .catch((err) => console.log(err));
    },
    resetSearch() {
      this.quicktipsSearch = '';
      const query = { ...this.$route.query };
      // removing log_search and display_name from query
      if (query.log_search) {
        delete query.log_search;
      }
      if (query.display_name) {
        delete query.display_name;
      }
      if (this.$route.query.id) {
        this.$router.replace({ query: null });
      }
      this.$forceUpdate();
      this.loadData();
    },
    async loadData() {
      this.isLoading = true;
      this.rawList = [];
      const { data } = await helpApi.methods.getHelpData('quicktips');
      this.rawList = data;
      if (this.$route.query.log_search) {
        this.logSearch();
      }
      this.searchEngine = new LocalSearch(data, [
        'question',
        'step_1',
        'step_2',
        'step_3',
        'step_4',
        'step_5',
        'step_6',
        'step_7',
        'step_8',
        'step_9',
        'step_10',
      ]);
      this.isLoading = false;
    },
  },
  mounted() {
    this.previousRoute = this.$router.app.previousRoute.name;
    this.loadData();
    MatomoAnalyticsHandler.trackHelp(
      {
        type: QUICKTIPS.toLowerCase(),
      },
      this.currentUser,
    );
    EventBus.$on('quicktips_click', () => {
      this.resetSearch();
    });
    EventBus.$on('quicktip_select', ({ id }) => {
      const catWrapperEl = this.$refs['categories-wrapper'].$el;
      setTimeout(() => {
        catWrapperEl.scrollTo({
          top: id * (56 * 1.75),
          behavior: 'smooth',
        });
      }, 500);
      this.activePanel = [id];
    });
  },
  beforeDestroy() {
    EventBus.$off('quicktips_click');
    EventBus.$off('quicktip_select');
  },
  watch: {
    quicktipsSearch(ne) {
      if (!ne) {
        this.activePanel = [];
      } else {
        this.activePanel = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
        this.handleSearch();
      }
    },
    routeQuery(ne, ol) {
      if (!!ol && !ne) {
        this.activePanel = [];
      }
    },
    '$route.query': {
      async handler(newVal) {
        if (newVal.log_search) {
          this.logSearch();
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapState('users', ['currentUser']),
    ...mapState('search', ['isSearchPage']),
    routeQuery() {
      return this.$route.query.id;
    },
    quicktips() {
      if (this.rawList.length) {
        if (this.$route.query.id) {
          // we transform the ID because Calyrex returns Ids with _ rather than -
          const foundItem = this.rawList.find(
            (e) => e.uuid.toLowerCase() === transformId(this.$route.query.id),
          );
          if (foundItem) {
            return [foundItem];
          }
          return null;
        }
      }
      if (this.quicktipsSearch) {
        return this.searchEngine.search(this.quicktipsSearch);
      }
      return this.rawList;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/scss/app.scss', '@/scss/variables.scss';
.quicktips-page-wrapper {
  padding: 1.5rem 2rem 0 2rem;

  ::v-deep .v-chip {
    border-radius: 40px;
    font-size: 20px;
    height: 40px;
    margin-right: 18px !important;
    padding: 0 25px !important;

    &.v-chip--active.v-chip--active {
      background: #21a7e0 !important;
      color: white !important;
    }
  }

  ::v-deep {
    .item-search-input.item-search-input {
      align-items: center;
      background-color: #fff;
      border: 1px solid transparent;
      border-radius: 24px;
      box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
      display: flex;
      justify-content: flex-start;
      margin-left: 2px;
      padding: 0 !important;
      position: relative;
      max-width: 425px;
      z-index: 3;

      > .v-input__control {
        min-height: 40px;
        > .v-input__slot {
          box-shadow: none;
        }
      }

      .v-input__control > .v-input__slot:before {
        border-style: none;
      }

      input {
        background-clip: padding-box;
        border: none;
        color: $gray-search-input;
        display: block;
        font-size: 14px;
        line-height: 1.25;
        margin: 0 auto;
        outline: none;
        padding: 0.5rem 0.7rem;
        transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
        width: 100%;
      }

      input::placeholder {
        color: $gray-dark-login-text !important;
        font-size: 14px;
        opacity: 1;
      }

      .v-input__append-inner {
        padding-right: 5px;
      }
    }
  }
  .btn-back {
    border: none;
    text-transform: none;
    background-color: #ffffff !important;
    box-shadow: none;
    letter-spacing: normal;
    color: #21a7e0;
    padding: 0;
    margin: 0px 0px 0px -6px;
    font-size: 16px;
  }

  .categories-wrapper {
    margin-top: 2rem;
    overflow-y: auto;
    height: calc(100vh - 15rem);
    padding: 0.2rem;
  }

  .quicktips-page-header {
    font-size: 24px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 600;
    letter-spacing: normal;
    line-height: normal;
    margin-bottom: 32px;
    text-align: left;
  }

  .no-results__wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    .no-results-found {
      font-size: 24px;
      font-weight: 600;
      letter-spacing: -0.01em;
      line-height: 29px;
      text-align: center;
    }
    .no-results__description {
      height: 52px;
      line-height: 130%;
      margin-bottom: -10px !important;
      text-align: center;
      width: 344px;
    }
  }
  ::v-deep {
    .v-timeline-item:last-of-type:last-child {
      .v-timeline-item__divider {
        &:after {
          background-color: white;
          content: '';
          height: 100%;
          left: 0;
          position: absolute;
          top: 24px;
          width: 25px;
        }
      }
    }

    .v-expansion-panel--active
      > .v-expansion-panel-header--active
      .v-expansion-panel-header__icon:not(.v-expansion-panel-header__icon--disable-rotate)
      .v-icon {
      color: $zen-blue-default;
    }
    .v-expansion-panels {
      display: block;
    }
    .v-input--is-disabled:not(.v-input--is-readonly) {
      pointer-events: all;
    }
  }
}
</style>
