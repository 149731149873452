<template>
  <div class="prompt-example-detailed-view-container">
    <div class="closeIconContainer">
      <v-icon medium @click="handleCloseModal">{{ 'mdi-close' }}</v-icon>
    </div>
    <div class="tip-container">
      <img src="/assets/img/generate/Examples-icon.svg" />
      <div class="content">
        <div class="tip-header">{{ $t('generate.example') }}</div>
        <div class="tip-info" v-html="data.example"></div>
      </div>
    </div>
    <img v-if="type === 'slides'" :src="data.image" class="tip-img" />
    <div v-else class="prez-container">
      <div class="list">
        <img
          v-for="(slide, ind) in data.images"
          :src="slide"
          class="small-img"
          @click="prezInd = ind"
          :key="ind"
          :class="{ selected: ind === prezInd }"
        />
      </div>
      <img :src="data.images[prezInd]" class="main-img" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'PromptExamplesDetailedView',
  components: {},
  props: {
    type: {
      type: String,
      default: 'slides',
    },
    data: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      prezInd: 0,
    };
  },
  watch: {},
  computed: {},
  methods: {
    handleCloseModal() {
      this.$modal.hide('PromptExamplesDetailedView');
    },
  },
  mounted() {},
};
</script>
<style scoped lang="scss">
.prompt-example-detailed-view-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  .closeIconContainer {
    position: absolute;
    right: 15px;
    top: 15px;
    .v-icon {
      cursor: pointer;
    }
  }

  .tip-container {
    display: flex;
    gap: 16px;
    padding: 10px;
    align-items: flex-start;

    .content {
      position: relative;
      top: 8px;

      .tip-header {
        font-family: Lato;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        color: #075689;
        margin-bottom: 10px;
      }

      .tip-info {
        font-family: Lato;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.14px;
        color: #212121;
      }
    }
  }

  .tip-img {
    border-radius: 6px;
    width: 100%;
    border: 0.5px solid #c1c7cd;
  }

  .prez-container {
    display: flex;
    gap: 24px;

    .list {
      width: 120px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      img {
        width: 100%;
        aspect-ratio: 16/9;
        border-radius: 2px;
        border: 0.5px solid #c1c7cd;
        cursor: pointer;
      }
      .selected {
        border: 1.5px solid #21a7e0;
      }
    }

    .main-img {
      width: calc(100% - 144px);
      border-radius: 8px;
      box-shadow: -1px -1px 4px 0px rgba(0, 0, 0, 0.1),
        1px 1px 4px 0px rgba(0, 0, 0, 0.1);
    }
  }
}
</style>
