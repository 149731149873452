<template>
  <div>
    <v-row align="center" justify="center" v-if="loading" class="mt-9">
      <LoadSpinner
        :id="'spinner'"
        :text="'Loading...'"
        :size="'60'"
        :bgcolor="'lightgray'"
        :speed="'0'"
        :color="'gray'"
        class="loadspinner"
      />
    </v-row>
    <div v-else class="finger-prints-wrapper">
      <template v-if="!notFound">
        <FingerPrint
          v-if="!limitedAccess"
          :fingerprint="audienceType"
          :audience="audience"
          :personalPrefs="personalPrefs"
          :creator="creatorName"
          :isPrivateAudience="isPrivateAudience"
          :prefEditable="isPrefEditable"
          :allPrefs="selections"
          :shareable="
            creatorName === 'me' || audience.id === currentUser.user.id
          "
          :isAlreadyShared="isAlreadyShared"
          :isDifferentCompany="formattedAudience.isDifferentCompany"
          :isGroup="formattedAudience.isGroup"
          :currentCompany="company"
          :isCurrentUser="
            creatorName === 'me' || audience.id === currentUser.user.id
          "
          @handleClickShare="handleShareFingerPrint"
          @handleQuestion="onHandleQuestion"
          @handleSavePreference="handleSavePreference"
          :origin="fp_origin"
        />
        <template v-else>
          <FingerPrint
            :audience="audience"
            :locked="true"
            :fingerprint="'locked'"
            :currentCompany="company"
            :origin="fp_origin"
          ></FingerPrint>
          <div class="d-flex justify-center">
            <v-btn
              class="send-assessment"
              rounded
              color="#20a7e0"
              width="262"
              height="50"
              @click="handleRequestAccess"
              :loading="requestAccessLoading"
              :disabled="requestAccessDisabled || requestAccessLoading"
            >
              {{
                requestAccessDisabled
                  ? $t('fingerprint.requestSent')
                  : $t('build.step3.requestAccess')
              }}
            </v-btn>
          </div>
        </template>
      </template>
      <template v-else-if="notSharedWithUser">
        <div class="fp-not-shared">
          <div>
            <img
              class="fp-not-shared-img"
              src="/assets/img/not-shared-fingerprint.svg"
              alt
            />
          </div>
          <div class="fp-not-shared-descriptions">
            <div>
              {{
                $t('fingerprint.fingerprintHasNotBeenShared', {
                  name: notSharedRsp.fpFullName,
                })
              }}
            </div>
          </div>
          <div class="request-fingerprint-container">
            <v-btn
              class="send-assessment"
              rounded
              color="#20a7e0"
              width="262px !important"
              height="50px !important"
              @click="handleRequestFingerprint"
            >
              {{ $t('fingerprint.requestFingerprint') }}
            </v-btn>
          </div>
        </div>
      </template>
      <template v-else-if="!noFingerprint">
        <div class="not-found">
          <div style="text-align: center">
            <img src="/assets/img/fingerprints-not-found.png" alt />
          </div>
          <div class="not-found-descriptions">
            <div>
              {{ $t('fingerprint.weCouldntFindyoursearch') }}
            </div>
            <div class="not-found-border">
              {{ $t('fingerprint.workingHardToAddMorecontent') }}
            </div>
            <div class="not-found-descriptions">
              <div class="final-not-found-description">
                {{ $t('fingerprint.couldntFindTheColleague') }}
              </div>
              <v-btn
                class="create-new ma-2 py-2"
                rounded
                color="#21a7e0"
                width="292"
                height="50"
                @click="handleAddNew"
              >
                {{ $t('fingerprint.addNewColleague') }}
              </v-btn>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="fingerprintNotCompleted">
        <div class="fp-not-found">
          <div>
            <img src="/assets/img/fingerprints-not-found.svg" alt />
          </div>
          <div class="fp-not-found-descriptions">
            <div>
              {{ $t('overnightPresentations.colleagueNotYetTakenAssessment') }}
            </div>
          </div>
          <div class="send-reminder-container">
            <v-btn
              class="send-assessment"
              rounded
              color="#20a7e0"
              width="262"
              height="50"
              @click="handleSendAssessment"
              :loading="getReminderSending"
              :disabled="isSent"
            >
              {{
                isSent
                  ? $t('overnightPresentations.reminderSent')
                  : $t('overnightPresentations.remind')
              }}
            </v-btn>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { findIndex, filter } from 'lodash';
import { getFileURL } from '@/utils/calyrex';
import AddCollegueDetail from '../../Main/NewPrezentation/AddCollegueDetail';
import LoadSpinner from '../../common/LoadSpinner.vue';
import FingerPrint from './FingerPrint.vue';
import ShareFingerPrintDetail from '../Profile/ShareFingerPrintDetail.vue';
import FingerprintShareRequestSent from './FingerprintShareRequestSent.vue';

import { AnalyticsHandler } from '../../common/Analytics/AnalyticsHandler';
import {
  sendFPReminder,
  getFingerprintShareDetails,
  logSearchQuery,
  updatePrivateAudience,
} from '../../../utils/api-helper';
import AudienceApi from '../../../API/audience-api';
import {
  getCommonPrivateAudienceData,
  capitalizeFirstLetter,
} from '../../../utils/common';
import { AddNewAudienceModalProps } from '../AddNewAudience/AddNewAudienceModalProps';
import { TD_FP_OTHER } from '../../common/Analytics/MatomoTrackingDataHelper';
import { fpTypes, fpSelections } from '@/mock/fingerprint';

export default {
  name: 'FingerPrints',
  components: {
    LoadSpinner,
    FingerPrint,
  },
  data() {
    return {
      loading: false,
      reminderLoading: false,
      isSent: false,
      audience: null,
      notFound: true,
      noFingerprint: false,
      notSharedWithUser: false,
      notSharedRsp: {},
      audienceType: null,
      personalPreferences: null,
      noResultsRouteLoaded: false,
      isAlreadyShared: false,
      fingerprintNotCompleted: false,
      limitedAccess: false,
      requestAccessDisabled: false,
      requestAccessLoading: true,
      fp_origin: TD_FP_OTHER,
      creatorName: '',
      selections: [],
      personal: [],
      isPrefEditable: false,
    };
  },
  watch: {
    '$route.query': {
      async handler(newVal, oldVal) {
        const logPayload = {
          type: 'People',
          searchTerm: '',
          searchTermResult: false,
        };
        this.loading = true;
        this.reminderLoading = false;
        this.isSent = false;
        this.audience = null;
        this.notFound = true;
        this.limitedAccess = false;
        this.requestAccessDisabled = false;
        this.requestAccessLoading = true;
        this.noFingerprint = false;
        this.notSharedRsp = {};
        this.notSharedWithUser = false;
        this.audienceType = null;
        this.personalPreferences = null;
        this.noResultsRouteLoaded = false;
        if (this.$route.query.noResultsFound) {
          this.loading = false;
          this.notFound = true;
          this.noFingerprint = false;
          this.noResultsRouteLoaded = true;
          logPayload.searchTermResult = false;
          logPayload.searchTerm =
            this.$route.query.display_name?.toLowerCase() || '';
          if (this.$route.query.log_search) {
            logSearchQuery(logPayload)
              .then()
              .catch((err) => console.log(err));
          }
        } else if (this.$route.query.username) {
          if (
            (oldVal !== undefined && oldVal !== newVal) ||
            this.noResultsRouteLoaded === true
          ) {
            // to prevent this duplicate call on mount
            if (this.$route.query.noResultsFound) {
              this.loading = false;
              this.notFound = true;
              this.noFingerprint = false;
              this.noResultsRouteLoaded = true;
              logPayload.searchTermResult = false;
              logPayload.searchTerm =
                this.$route.query.display_name?.toLowerCase() || '';
            } else if (this.$route.query.username) {
              await this.setCurrSearchedPerson({
                id: this.$route.query.username,
                type: this.$route.query.type,
              });
              this.audience = this.currSearchedPerson; // from store
              if (
                this.audience &&
                (this.audience.fingerprintType || this.audience.fingerPrint)
              ) {
                this.audience.firstName = (
                  this.audience.firstName
                    ? this.audience.firstName
                    : this.audience.fullName
                )
                  .split(' ')
                  .map((s) => s.charAt(0).toUpperCase() + s.substr(1))
                  .join(' ');
                this.loading = false;
                this.notFound = false;
                this.noFingerprint = true;
                this.audienceType = this.audience.fingerPrint.toLowerCase();
                this.personalPreferences = this.audience.personalPreference;
                logPayload.searchTermResult = true;
                logPayload.searchTerm =
                  this.audience.fullName?.toLowerCase() ||
                  this.$route.query.username;
              } else if (this.audience && this.audience.isPublicLimited) {
                this.loading = false;
                this.notFound = false;
                this.limitedAccess = true;
                AudienceApi.methods
                  .getAccessRequestIsDisabled(
                    this.sharedFingerprintType,
                    this.isSharedFingerprintTypeUser
                      ? this.audience.id
                      : this.audience.uuid,
                  )
                  .then((resp) => {
                    this.requestAccessDisabled = resp;
                    this.requestAccessLoading = false;
                  });
              } else if (this.audience && !this.audience.fingerPrint) {
                this.loading = false;
                this.notFound = true;
                this.noFingerprint = true;
                this.fingerprintNotCompleted = true;
                logPayload.searchTermResult = false;
                logPayload.searchTerm =
                  this.audience.fullName?.toLowerCase() ||
                  this.$route.query.username;
              } else {
                this.loading = false;
                this.notFound = true;
                this.noFingerprint = false;
                logPayload.searchTermResult = false;
                logPayload.searchTerm =
                  this.$route.query.display_name?.toLowerCase() ||
                  this.$route.query.username;
              }
            } else {
              this.loading = false;
              this.notFound = true;
              this.noFingerprint = false;
              this.noResultsRouteLoaded = true;
            }
            if (this.$route.query.log_search) {
              logSearchQuery(logPayload)
                .then()
                .catch((err) => console.log(err));
            }
            this.loadAudienceProfileImg();
            this.checkSharedStatus();
            this.initialize();
          }
        } else {
          this.loadSharedFingerPrint();
        }
      },
      deep: true,
      immediate: true,
    },
    audience: {
      immediate: true,
      async handler(audience) {
        if (!audience?.userID) {
          return;
        }

        if (this.audience.userID === this.currentUser.user.id) {
          this.creatorName = 'me';
          return;
        }

        const user = await AudienceApi.methods.getTypesenseAudience(
          this.audience.userID,
          'user',
        );

        this.creatorName = user?.document.fullName || '';
      },
    },
  },
  computed: {
    ...mapState('users', ['currentUser', 'currSearchedPerson']),
    getReminderSending() {
      return this.reminderLoading;
    },
    isPrivateAudience() {
      if (
        this.audience.userID !== this.currentUser.user.id &&
        !this.audience.shared
      ) {
        return false;
      }
      return (
        this.$route.query.type === 'private_audience' ||
        (this.audience.userID === this.currentUser.user.id &&
          !!this.$route.query.uuid)
      );
    },
    formattedAudience() {
      if (this.audience) {
        const { companyName, displayName } = this.audience;
        const audienceObj = {
          ...this.audience,
          companyName: companyName || displayName,
        };
        return getCommonPrivateAudienceData(audienceObj, this.currentUser);
      }
      return {};
    },
    company() {
      if (this.currentUser.company?.displayName) {
        return this.currentUser.company.displayName;
      }
      return capitalizeFirstLetter(this.currentUser.company.name);
    },
    sharedFingerprintType() {
      return this.$route.query.type || 'private_audience';
    },
    isSharedFingerprintTypeUser() {
      return this.$route.query.type === 'user';
    },
    personalPrefs() {
      return this.personal.map((item) => item.title);
    },
  },
  beforeMount() {
    this.loading = true;
  },
  async mounted() {
    const logPayload = {
      type: 'People',
      searchTerm: '',
      searchTermResult: false,
    };
    if (this.$route.query.username) {
      await this.setCurrSearchedPerson({
        id: this.$route.query.username,
        type: this.$route.query.type,
      });
      this.audience = this.currSearchedPerson; // from store
      if (
        this.audience &&
        (this.audience.fingerprintType || this.audience.fingerPrint)
      ) {
        this.audience.firstName = (
          this.audience.firstName
            ? this.audience.firstName
            : this.audience.fullName
        )
          .split(' ')
          .map((s) => s.charAt(0).toUpperCase() + s.substr(1))
          .join(' ');
        this.loading = false;
        this.notFound = false;
        this.noFingerprint = true;
        this.audienceType = this.audience.fingerPrint.toLowerCase();
        this.personalPreferences = this.audience.personalPreference;
        logPayload.searchTermResult = true;
        logPayload.searchTerm =
          this.audience.fullName?.toLowerCase() || this.$route.query.username;
      } else if (this.audience && this.audience.isPublicLimited) {
        this.loading = false;
        this.notFound = false;
        this.limitedAccess = true;
        AudienceApi.methods
          .getAccessRequestIsDisabled(
            this.sharedFingerprintType,
            this.isSharedFingerprintTypeUser
              ? this.audience.id
              : this.audience.uuid,
          )
          .then((resp) => {
            this.requestAccessDisabled = resp;
            this.requestAccessLoading = false;
          });
      } else if (
        this.audience &&
        !(this.audience.fingerprintType || this.audience.fingerPrint)
      ) {
        this.loading = false;
        this.notFound = true;
        this.noFingerprint = true;
        this.fingerprintNotCompleted = true;
        logPayload.searchTermResult = false;
        logPayload.searchTerm =
          this.audience.fullName?.toLowerCase() || this.$route.query.username;
      } else {
        this.loading = false;
        this.notFound = true;
        this.noFingerprint = false;
        logPayload.searchTermResult = false;
        logPayload.searchTerm =
          this.$route.query.display_name?.toLowerCase() ||
          this.$route.query.username;
      }
      if (this.$route.query.log_search) {
        logSearchQuery(logPayload)
          .then()
          .catch((err) => console.log(err));
      }
      this.loadAudienceProfileImg();
      this.checkSharedStatus();
      this.initialize();
      // } else if (this.$route.query.uuid) {
      // commented out duplicate call (same call from watcher)
      //   this.loadSharedFingerPrint();
    } else if (!this.$route.query.uuid) {
      this.loading = false;
      this.notFound = true;
      this.noFingerprint = false;
      this.noResultsRouteLoaded = true;
    }
  },
  methods: {
    ...mapActions('users', [
      'resetCurrSearchedPerson',
      'setCurrSearchedPerson',
      'setCurrentUserPersonalPreferences',
    ]),
    handleShareFingerPrint() {
      this.$modal.show(
        ShareFingerPrintDetail,
        {
          entityID: this.audience.uuid,
          share: this.checkSharedStatus,
        },
        {
          name: 'ShareFingerPrintDetail',
          width: 700,
          height: 'auto',
          minHeight: 300,
          styles: { borderRadius: '20px' },
        },
      );
    },
    async checkSharedStatus() {
      try {
        if (
          this.audience.uuid &&
          this.$route.query.type === 'private_audience'
        ) {
          const { data } = await getFingerprintShareDetails(this.audience.uuid);
          this.isAlreadyShared = data.length > 0;
        }
      } catch (e) {
        this.isAlreadyShared = this.audience?.shared;
      }
    },
    loadAudienceProfileImg() {
      this.loading = true;
      if (!this.audience) {
        this.loading = false;
        return;
      }
      if (
        this.audience.profileImage &&
        !this.audience.profileImageUrl &&
        !this.audience.isPublicLimited
      ) {
        getFileURL(
          null,
          `public/${this.audience.profileImage}`,
          null,
          'storage',
        ).then((s3url) => {
          this.audience = { ...this.audience, profileImageUrl: s3url };
          this.loading = false;
        });
      } else {
        this.loading = false;
      }
    },
    handleAddNew() {
      this.audience = 'Add New';
      this.handleAudienceSelect();
    },
    handleAudienceSelect() {
      if (this.audience === 'Add New') {
        this.$modal.show(
          AddCollegueDetail,
          {
            addColleague: this.addColleague,
          },
          AddNewAudienceModalProps,
        );
      } else {
        this.audienceType = this.audience.fingerPrint.toLowerCase();
        this.personalPreferences = this.audience.personalPreference;
      }
    },
    async handleSendAssessment() {
      const { type } = this.$route.query;
      const currentAudience = this.audience;
      if (currentAudience) {
        this.isSent = true;
        this.reminderLoading = true;
        sendFPReminder(this.currentUser, currentAudience, type === 'user')
          .then((response) =>
            console.log('Email Fingerprint Success : ', response),
          )
          .catch((err) => console.log(err))
          .finally(() => {
            this.reminderLoading = false;
          });
        AnalyticsHandler.fingerprintEmailedLink(
          this.currentUser,
          this.currSearchedPerson.email,
          this.currSearchedPerson.firstName,
          this.currSearchedPerson.lastName,
        );
      }
    },

    async handleRequestAccess() {
      this.requestAccessLoading = true;
      AudienceApi.methods
        .requestFingerprintAccess({
          type: this.sharedFingerprintType,
          id: this.isSharedFingerprintTypeUser
            ? this.audience.id
            : this.audience.uuid,
        })
        .then((data) => {
          console.log('requestFingerprintAccess', data);
          this.requestAccessDisabled = true;
          this.requestAccessLoading = false;
        })
        .catch((err) => console.error('requestFingerprintAccess', err));
    },

    async handleRequestFingerprint() {
      AudienceApi.methods
        .requestFingerprintAccess({
          type: this.sharedFingerprintType,
          id: this.isSharedFingerprintTypeUser
            ? this.notSharedRsp.id
            : this.notSharedRsp.uuid,
        })
        .then((data) => console.log('requestFingerprintAccess', data))
        .catch((err) => console.error('requestFingerprintAccess', err));

      this.$modal.show(
        FingerprintShareRequestSent,
        {
          fullname: this.notSharedRsp.ownerFullName,
        },
        {
          name: 'FingerprintShareRequestSent',
          width: '400',
          height: '350px',
        },
      );
    },
    async initialize() {
      // reset fingerprints and selections
      this.fpQuestions = [...fpTypes];
      this.selections = [...fpSelections];

      if (this.audience) {
        this.personal = [];
        this.audience.personalPreference.forEach((pref) => {
          const p = {};
          p.key = pref;
          p.title = pref;
          this.personal.push(p);
          const found = this.selections.find(
            (element) => element.title === pref,
          );
          if (!found) this.selections.push(p);
        });
      }
      this.isPrefEditable =
        this.audience.userID === this.currentUser.user.id ||
        this.audience.id === this.currentUser.user.id ||
        this.audience.companyID === this.currentUser.company.id;
    },
    onHandleQuestion(item) {
      this.handleQuestion(item);
    },
    handleQuestion(item) {
      const initialValue = this.personal;
      if (findIndex(initialValue, { title: item.title }) > -1) {
        const updatedValue = filter(
          initialValue,
          (initial) => initial.title !== item.title,
        );
        this.personal = updatedValue;
      } else {
        initialValue.push(item);
        this.personal = initialValue;
      }
    },
    async handleSavePreference() {
      if (this.audience.id === this.currentUser.user.id) {
        this.setCurrentUserPersonalPreferences(this.personal);
      } else {
        const tempPrefs = [];
        this.personal.forEach((item) => {
          tempPrefs.push(item.title);
        });
        const payload = {
          id: this.audience.id,
          userID: this.audience.userID,
          personalPreference: tempPrefs,
        };
        await updatePrivateAudience(payload);
      }

      this.audience.personalPreference = [
        ...this.personal.map((item) => item.title),
      ];
    },
    loadSharedFingerPrint() {
      const logPayload = {
        type: 'People',
        searchTerm: '',
        searchTermResult: false,
      };
      this.loading = true;
      AudienceApi.methods
        .getSharedFingerprintDetails(
          this.$route.query.uuid,
          this.$route.query.type,
        )
        .then((data) => {
          this.audience = data;
          if (this.audience && this.audience.isPublicLimited) {
            this.loading = false;
            this.notFound = false;
            this.limitedAccess = true;
            AudienceApi.methods
              .getAccessRequestIsDisabled(
                this.sharedFingerprintType,
                this.isSharedFingerprintTypeUser
                  ? this.audience.id
                  : this.audience.uuid,
              )
              .then((resp) => {
                this.requestAccessDisabled = resp;
                this.requestAccessLoading = false;
              });
          } else if (
            this.audience.fingerprintType ||
            this.audience.fingerPrint
          ) {
            // show fingerprint content
            this.audienceType = this.audience.fingerPrint;
            this.personalPreferences = this.audience.personalPreference;
            this.loading = false;
            this.notFound = false;
            this.noFingerprint = true;
            this.isAlreadyShared = data.shared;
            logPayload.searchTermResult = true;
            logPayload.searchTerm =
              this.audience.fullName?.toLowerCase() || undefined;
          } else {
            // since only private audience is shared currently
            // so if fingerPrint does not exist show not found content
            this.loading = false;
            this.notFound = true;
            this.noFingerprint = true;
            this.fingerprintNotCompleted = true;
            logPayload.searchTermResult = false;
            logPayload.searchTerm =
              this.$route.query.display_name?.toLowerCase() ||
              this.$route.query.uuid;
          }
          if (this.$route.query.log_search) {
            logSearchQuery(logPayload)
              .then()
              .catch((err) => console.log(err));
          }
          this.loadAudienceProfileImg();
          this.checkSharedStatus();
          this.initialize();
        })
        .catch((e) => {
          const rsp = e.response;
          if (rsp && rsp.status === 403) {
            let data = rsp.data ? rsp.data : {};
            data = data.data || {};
            this.notSharedRsp = {
              fpFirstName: data.firstName || '',
              fpLastName: data.lastName || '',
              fpFullName: data.fullName || '',
              ownerFirstName: data.ownerFirstName || '',
              ownerLastName: data.ownerLastName || '',
              ownerFullName: data.ownerFullName || '',
              ownerEmail: data.userID || '',
              id: data.id || '',
              uuid: data.uuid,
            };
            // show not shared content
            this.loading = false;
            this.notSharedWithUser = true;
          } else {
            // show not found content
            this.loading = false;
            this.notFound = true;
            this.noFingerprint = false;
          }
          logPayload.searchTermResult = false;
          logPayload.searchTerm =
            this.$route.query.display_name?.toLowerCase() ||
            this.$route.query.uuid;
          if (this.$route.query.log_search) {
            logSearchQuery(logPayload)
              .then()
              .catch((err) => console.log(err));
          }
        });
    },
  },
  beforeDestroy() {
    this.resetCurrSearchedPerson(null);
  },
};
</script>

<style lang="scss" scoped>
.loadspinner {
  top: 60vh;
}

.fp-not-found {
  padding-top: 150px;
  align-items: center;
  text-align: center;

  .fp-not-found-descriptions {
    margin-top: 20px;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
}

.not-found {
  padding-top: 150px;
}

.not-found-descriptions {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  width: fit-content;
  margin: auto;

  .not-found-border {
    padding-bottom: 35px;
    margin-bottom: 35px;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
  }

  .final-not-found-description {
    margin-bottom: 24px;
  }

  button {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: white;
    text-transform: none;
  }
}

.send-reminder-container {
  margin: 20px 0;
}
.send-assessment {
  border-radius: 25px;
  text-transform: none;
  color: white !important;
  background: $zen-blue-default;
  font-size: 1.3vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  min-width: 125px;
  width: auto !important;
}

.fp-not-shared {
  align-items: center;
  padding-top: 150px;
  text-align: center;

  .fp-not-shared-img {
    height: 282px;
  }

  .fp-not-shared-descriptions {
    font-size: 24px;
    font-stretch: normal;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    letter-spacing: normal;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .request-fingerprint-container {
    margin: 20px 0;
  }
}
</style>
