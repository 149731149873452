<template>
  <v-hover v-slot="{ hover }">
    <div class="slide-item">
      <p>{{ slideIndex + 1 }}</p>
      <div
        class="slide-wrapper"
        :class="{ 'slide-border-highlight': slideSelected }"
      >
        <v-checkbox
          v-model="slideSelected"
          class="deck-checkbox"
          :class="{ 'show-checkbox': hover }"
          :value="slideSelected"
          @change="(e) => handleSelectSlide(e, slide)"
        ></v-checkbox>
        <v-img
          @click="toggleSlideSelection"
          contain
          :src="
            slide.thumbnail_jpg
              ? slide.thumbnail_jpg.thumbnail
              : slide.thumbnail_png.thumbnail
          "
          :lazy-src="'/assets/img/slides/placeholder-slide.svg'"
          loading="lazy"
          alt
          :aspect-ratio="16 / 9"
        />
      </div>
    </div>
  </v-hover>
</template>
<script>
export default {
  name: 'SlideItem',
  props: {
    slide: {
      type: Object,
      default: () => ({}),
    },
    handleSelectSlide: {
      type: Function,
      default: () => {},
    },
    slidesSelected: {
      type: Array,
      default: () => [],
    },
    slideIndex: {
      type: Number,
      required: true,
    },
  },
  watch: {
    slidesSelected: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val.length) {
          this.slideSelected =
            this.slidesSelected.findIndex(
              (slideInList) => slideInList.key === this.slide.key,
            ) !== -1;
        } else {
          this.slideSelected = false;
        }
      },
    },
  },
  computed: {},
  data() {
    return {
      slideSelected: false,
    };
  },
  methods: {
    toggleSlideSelection() {
      this.slideSelected = this.slideSelected !== true;
      this.handleSelectSlide(this.slideSelected, this.slide);
    },
  },
};
</script>
<style scoped lang="scss">
@import '@/scss/variables.scss';
.slide-wrapper {
  cursor: pointer;
  position: relative;
  background: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
  flex: 1;

  &:hover {
    // box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.5);
    box-shadow: $item-box-shadow-hover;
  }

  .deck-checkbox {
    position: absolute;
    z-index: 2;
    margin: 0 10px;
    display: none;
  }

  .show-checkbox {
    display: block;
  }

  .conversion-id-div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .capitalize-text {
    display: flex;
    justify-content: space-between;
    padding: 8px 0px 0px 0px;
    .v-btn {
      padding: 0px 14px 0px 0px;
      width: 20px;
      height: 20px;
    }
    .v-progress-circular {
      padding: 0px 16px 0px 0px;
    }
  }
}
.slide-border-highlight {
  border: 1.5px solid var(--new-palette-secondary-secondary-50, #21a7e0);
}

.slide-item {
  display: flex;
  gap: 12px;
}
</style>
