import { render, staticRenderFns } from "./slideItem.vue?vue&type=template&id=85d2e6a6&scoped=true&"
import script from "./slideItem.vue?vue&type=script&lang=js&"
export * from "./slideItem.vue?vue&type=script&lang=js&"
import style0 from "./slideItem.vue?vue&type=style&index=0&id=85d2e6a6&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "85d2e6a6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VHover } from 'vuetify/lib/components/VHover';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VCheckbox,VHover,VImg})
