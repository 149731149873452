<template>
  <div class="generateActionModalContainer">
    <div class="closeIconContainer">
      <v-icon medium @click="handleCloseModal('cross')">{{
        'mdi-close'
      }}</v-icon>
    </div>
    <div class="headingContainer">
      {{ heading }}
    </div>
    <div class="content">
      <div v-if="modalType === 'unsaved'" class="subContent">
        {{
          type === 'slide'
            ? $t('premiumFeature.slideMessage')
            : $t('premiumFeature.prezentationMessage')
        }}
      </div>
      <div
        class="nameEditorAndLabel"
        v-if="
          modalType === 'download' ||
          modalType === 'slidesLibrary' ||
          (modalType === 'favorites' && !isAddedToLibrary) ||
          modalType === 'unsaved'
        "
      >
        <div :class="modalType === 'unsaved' ? 'label-unsaved' : 'label'">
          {{ $t('generate.typeLabel', { type: capitalize(type) }) }}
        </div>
        <div class="textField">
          <v-text-field
            v-model="slideName"
            :rules="maxLimit"
            :readonly="actionFinished"
          >
            <template #append>
              <v-icon
                v-if="slideName.length > 0 && !actionFinished"
                medium
                @click="clearSlideName"
                color="#21a7e0"
                :placeholder="$t('generate.enterSlideName')"
              >
                {{ 'mdi-close-circle' }}
              </v-icon>
            </template>
          </v-text-field>
        </div>
      </div>
      <div
        v-if="modalType === 'favorites' && isAddedToLibrary"
        class="favoritesText"
      >
        {{ $t('generate.actionLabel', { typeLabel: type }) }}
      </div>
      <div
        class="add-prez-lib"
        v-if="!isAddedToLibrary && modalType === 'download'"
      >
        <v-checkbox
          v-model="addToLibrary"
          :label="
            type === 'slide'
              ? $t('generate.addSlideToLibrary')
              : $t('generate.addPrezentationToLibrary')
          "
          color="#20a7e0"
          hide-details
        />
      </div>
    </div>
    <div
      class="actions"
      :style="
        modalType === 'download' &&
        ['redesign', 'generated'].indexOf(originPage) > -1
          ? { margin: '0px 0px 12px 0px' }
          : {}
      "
    >
      <div
        v-if="!actionFinished || operation.loading"
        class="buttons"
        :style="
          modalType === 'download' && isOriginPageFromPremiumFeatures
            ? { width: '38%' }
            : {}
        "
      >
        <v-btn class="cancelBtn" @click="handleCloseModal('button')">
          {{ secondaryBtnText || $t('common.cancel') }}
        </v-btn>
        <!-- !-->
        <!-- Update here if similar download options are required for other features where this component is used !-->
        <!-- Handling it for redesign specefically !-->
        <div v-if="modalType === 'download' && isOriginPageFromPremiumFeatures">
          <Download
            v-if="type === 'slide'"
            :file-name="getFilename"
            :item="{ ...downloadItem, name: slideName }"
            :downloading="isDownloadingFileFromSnackbar"
            :log-download="logDownload"
            :count-first-time="1"
            :on-download="() => handleSlideDownload()"
            origin="slide"
            limitsKey="slide"
            :isDisabled="
              isDownloadingFileFromSnackbar ||
              slideName.length === 0 ||
              slideName.length > 100
            "
            :speedDialDirection="'top'"
            :speedDialPosTop="'-80'"
            :speedDialPosRight="'0'"
            :closePreviousPopups="
              () => closePreviousPopups(slideName, addToLibrary)
            "
            :allowDownloadSettingsIcon="false"
          />
          <Download
            v-if="type === 'prezentation'"
            :downloading="isDownloadingFileFromSnackbar"
            :file-name="getFilename"
            :download-file-name="getDownloadFilename"
            :merged-url="getMergedUrlForSaveToGdrive"
            :item="{ ...downloadItem, name: slideName }"
            :log-download="logDownload"
            :on-download="() => handleSlideDownload()"
            origin="prezentation"
            limitsKey="prezentation"
            :isDisabled="
              isDownloadingFileFromSnackbar ||
              slideName.length === 0 ||
              slideName.length > 100
            "
            :speedDialDirection="'top'"
            :speedDialPosTop="'-80'"
            :speedDialPosRight="'0'"
            :closePreviousPopups="
              () => {
                closePreviousPopups(slideName, addToLibrary);
              }
            "
            :allowDownloadSettingsIcon="false"
          />
        </div>
        <!-- !-->
        <v-btn
          v-else
          class="primaryBtn"
          :disabled="
            (!isAddedToLibrary && slideName === '') || slideName.length > 100
          "
          @click="handlePrimaryButtonAction"
          :loading="operation.loading"
        >
          {{ primaryBtnText }}
        </v-btn>
      </div>
      <div
        v-if="actionFinished && operation.success && !operation.loading"
        class="success"
      >
        <v-icon color="#20a7e0">mdi-check</v-icon
        ><span>{{ successMessage }}</span>
      </div>
      <div
        v-if="actionFinished && !operation.success && !operation.loading"
        class="success"
      >
        <v-icon color="#20a7e0" size="18">mdi-alert-outline</v-icon
        ><span>{{
          modalType === 'favorites'
            ? $t('premiumFeature.addToFavoritesFailed')
            : $t('premiumFeature.addToLibraryfailed')
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
// import { v4 as uuidv4 } from 'uuid';
import { mapGetters, mapState, mapActions } from 'vuex';
import Download from '../../common/Download.vue';
// import { createSlide, createFavorite } from '../../../utils/api-helper';

export default {
  name: 'GenerateActionModal',
  data: () => ({
    slideName: '',
    actionFinished: false,
    successText: '',
    addToLibrary: true,
    // downloading: false,
  }),
  components: {
    Download,
  },
  props: {
    heading: {
      type: String,
      default: '',
    },
    primaryBtnText: {
      type: String,
      default: '',
    },
    secondaryBtnText: {
      type: String,
    },
    modalType: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'slide',
    },
    operation: {
      type: Object,
      default: () => null,
    },
    isAddedToLibrary: {
      type: Boolean,
      default: false,
    },
    primaryBtnAction: {
      type: Function,
      default: () => {},
    },
    secondaryBtnAction: {
      type: Function,
      default: () => {},
    },
    filename: {
      type: String,
      default: '',
    },
    originPage: {
      type: String,
      default: '',
    },
    downloadItem: {
      type: Object,
      default: () => {},
    },
    getMergedUrlForSaveToGdrive: {
      type: Function,
      default: () => {},
    },
    logDownload: {
      type: Function,
      default: () => {},
    },
    closePreviousPopups: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState('generateSlides', [
      'template',
      'slideGenerated',
      'audience',
      'nodeCount',
    ]),
    ...mapGetters('generateSlides', [
      'getFilePath',
      'generateThumbnail',
      'getAddToSlideLibraryResponse',
      'generateLandscape',
    ]),
    ...mapGetters('commonDownloads', ['getDownloadLoaders']),
    isDownloadingFileFromSnackbar() {
      const ldr = this.getDownloadLoaders.find(
        (ld) => ld.loaderId === this.downloadItem?.downloadLoaderId,
      );
      return ldr && ldr.downloading;
    },
    maxLimit() {
      return [
        (v) => (v || '').length <= 100 || this.$t('generate.cannotExceed100'),
      ];
    },
    successMessage() {
      if (this.modalType === 'favorites') {
        return this.$t('premiumFeature.addedToFavorites');
      }
      if (this.modalType === 'download') {
        return this.$t('premiumFeature.downloaded');
      }
      if (this.modalType === 'unsaved') {
        return this.$t('premiumFeature.saved');
      }
      return this.$t('premiumFeature.addedToLibrary');
    },
    isOriginPageFromPremiumFeatures() {
      return ['redesign', 'generated', 'comply'].indexOf(this.originPage) > -1;
    },
    getDownloadFilename() {
      return this.slideName || this.filename;
    },
    getFilename() {
      return this.slideName || this.filename;
    },
  },
  methods: {
    ...mapActions('generateSlides', ['setAddToSlideLibraryResponse']),

    clearSlideName() {
      this.slideName = '';
    },
    capitalize(s) {
      if (typeof s !== 'string') return '';
      return s.charAt(0).toUpperCase() + s.slice(1);
    },

    formatProperty(str) {
      return str.charAt(0) === '/' ? str : `/${str}`;
    },

    async handlePrimaryButtonAction() {
      // if (this.modalType === 'slidesLibrary') {
      //   const payload = {
      //     source: 'generated',
      //     isShare: false,
      //     sharedEntities: [],
      //     slidesData: [
      //       {
      //         tags: {
      //           node: this.nodeCount,
      //           source: 'generated',
      //           construct: this.slideGenerated?.constructs.join(', ') || '',
      //           has_image: this.slideGenerated?.prefs?.has_image,
      //           image_derived: this.slideGenerated?.prefs?.image_derived,
      //         },
      //         categories: this.slideGenerated?.categories || [],
      //         constructs: this.slideGenerated?.constructs.join(', ') || '',
      //         title: this.slideName,
      //         summary: this.slideGenerated?.summary || '',
      //         filename: this.getFilePath,
      //         landscape: this.formatProperty(this.generateThumbnail),
      //         thumbnail: this.formatProperty(this.generateThumbnail),
      //         type: 'generated',
      //         templateCode: this.template || '',
      //         audience: this.audience.id,
      //         audienceNumId: Number(this.audience.audienceNumID),
      //         audienceType: this.audience.audienceType,
      //       },
      //     ],
      //   };
      //   this.actionInProgress = true;
      //   await createSlide(payload)
      //     .then((resp) => {
      //       this.operationSuccess = true;
      //       setTimeout(() => {
      //         this.setAddToSlideLibraryResponse(resp.data[0]);
      //         this.successActionAdd(true);
      //         this.actionInProgress = false;
      //         this.handleCloseModal();
      //       }, 1000);
      //     })
      //     .catch((err) => {
      //       console.log(err);
      //       this.actionInProgress = false;
      //       this.handleCloseModal();
      //     });
      // } else if (this.modalType === 'favorites') {
      //   if (this.isAddedToLibrary) {
      //     const favoriteData = {
      //       id: uuidv4(),
      //       assetID: this.getAddToSlideLibraryResponse.unique_id,
      //       source: this.getAddToSlideLibraryResponse.tags.source,
      //     };
      //     this.actionInProgress = true;
      //     await createFavorite(favoriteData)
      //       .then(() => {
      //         this.operationSuccess = true;
      //         setTimeout(() => {
      //           // this.setAddToSlideLibraryResponse({});
      //           this.successActionFav(true);
      //           this.actionInProgress = false;
      //           this.handleCloseModal();
      //         }, 1000);
      //       })
      //       .catch((err) => console.log(err));
      //     this.actionInProgress = false;
      //   } else {
      //     const payload = {
      //       source: 'generated',
      //       isShare: false,
      //       sharedEntities: [],
      //       slidesData: [
      //         {
      //           tags: {
      //             node: this.nodeCount,
      //             source: 'generated',
      //             construct: this.slideGenerated?.constructs.join(', ') || '',
      //             has_image: this.slideGenerated?.prefs?.has_image,
      //             image_derived: this.slideGenerated?.prefs?.image_derived,
      //           },
      //           categories: this.slideGenerated?.categories || [],
      //           constructs: this.slideGenerated?.constructs.join(', ') || '',
      //           title: this.slideName,
      //           summary: this.slideGenerated?.summary || '',
      //           filename: this.getFilePath,
      //           landscape: this.formatProperty(this.generateThumbnail),
      //           thumbnail: this.formatProperty(this.generateThumbnail),
      //           type: 'generated',
      //           templateCode: this.template || '',
      //           audience: this.audience.id,
      //           audienceNumId: Number(this.audience.audienceNumID),
      //           audienceType: this.audience.audienceType,
      //         },
      //       ],
      //     };
      //     this.actionInProgress = true;
      //     await createSlide(payload)
      //       .then(async (response) => {
      //         this.setAddToSlideLibraryResponse(response.data[0]);
      //         const favoriteData = {
      //           id: uuidv4(),
      //           assetID: response.data[0].unique_id,
      //           source: response.data[0].tags.source,
      //         };
      //         await createFavorite(favoriteData)
      //           .then(() => {
      //             this.operationSuccess = true;
      //             setTimeout(() => {
      //               this.successActionFav(true);
      //               this.successActionAdd(true);
      //               this.actionInProgress = false;
      //               this.handleCloseModal();
      //             }, 1000);
      //           })
      //           .catch((err) => {
      //             this.actionInProgress = false;
      //             this.handleCloseModal();
      //             console.log(err);
      //           });
      //       })
      //       .catch((err) => {
      //         this.actionInProgress = false;
      //         this.handleCloseModal();
      //         console.log(err);
      //       });
      //   }
      // } else if (this.modalType === 'download') {
      await this.primaryBtnAction(
        this.slideName,
        this.modalType === 'favorites',
        this.addToLibrary,
      );
      if (
        this.modalType === 'slidesLibrary' ||
        this.modalType === 'favorites' ||
        this.modalType === 'download' ||
        this.modalType === 'unsaved'
      ) {
        // this.successText =
        //   this.modalType === 'favorites'
        //     ? 'Added to Favorites'
        //     : 'Added to Library';
        this.actionFinished = true;
      }
      // }
    },

    handleCloseModal(origin) {
      if (
        this.modalType === 'unsaved' &&
        (this.actionFinished || origin === 'button')
      ) {
        this.secondaryBtnAction();
      }
      this.$modal.hide('GenerateActionModal');
    },
    async handleSlideDownload() {
      // this.downloading = true;
      await this.primaryBtnAction(
        this.slideName,
        this.modalType === 'favorites',
        this.addToLibrary,
      );
      this.actionFinished = true;
      // this.downloading = false;
    },
  },
  mounted() {
    this.slideName = this.filename;
  },
};
</script>

<style lang="scss" scoped>
.generateActionModalContainer {
  padding: 20px;

  .closeIconContainer {
    position: absolute;
    right: 10px;
    top: 10px;

    .v-icon {
      cursor: pointer;
    }
  }

  .headingContainer {
    font-weight: bold;
    font-size: 20px;
    letter-spacing: normal;
    margin: 0px 0px 20px 0px;
  }

  .content {
    margin: 0px 0px 24px 0px;
    .subContent {
      font-size: 14px;
      margin: 0px 0px 20px 0px;
    }
    .add-prez-lib {
      ::v-deep .v-input {
        margin: 0 !important;
        padding: 0 !important;
        .v-input__control {
          .v-input__slot {
            .v-label {
              color: #000000;
              padding: 0px 0px 0px 10px;
            }
          }
        }
      }
    }
    .nameEditorAndLabel {
      .label {
        font-weight: 600;
      }
      .label-unsaved {
        font-weight: 600;
        margin-bottom: 10px;
      }

      .textField {
        margin: 0px 0px 0px 0px;

        .v-input {
          margin: 0;
          padding: 0;
        }

        ::v-deep .error--text {
          color: #f70000 !important;
          caret-color: #f70000 !important;
        }
      }
    }

    .favoritesText {
      font-size: 16px;
      margin: 0px 0px 16px 0px;
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;

    .buttons {
      // width: 34%;

      display: flex;
      justify-content: space-between;

      .cancelBtn,
      .primaryBtn {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 8px 18px;
        outline: none;
        letter-spacing: normal;
        text-transform: none;
        border-radius: 20px;
        margin-left: 15px;
      }

      .cancelBtn {
        background-color: #ffffff;
        border: 1px solid #21a7e0;
        font-weight: bold;
        color: #21a7e0;
      }

      .primaryBtn {
        background-color: #21a7e0;
        color: #ffffff;
        font-weight: bold;
      }
    }

    .successStatus {
      .v-btn {
        box-shadow: none;
        background-color: #ffffff !important;
        letter-spacing: normal;
        text-transform: none;
      }
    }

    .success {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      font-size: 16px;
      font-weight: bold;
    }
    ::v-deep .download-actions {
      .new-slide-detail-speed {
        .download {
          border-radius: 25px;
          color: white !important;
          background: #21a7e0;
          font-size: 14px;
          font-style: normal;
          font-stretch: normal;
          font-weight: bold;
          height: 38px;
          line-height: normal;
          letter-spacing: normal;
          text-transform: none;
          outline: none;
          border: none;
        }
      }
      .downloadPreferences {
        .downloadEleWithPrefs {
          .download {
            border-radius: 25px;
            color: white !important;
            background: #21a7e0;
            font-size: 14px;
            font-style: normal;
            font-stretch: normal;
            font-weight: bold;
            height: 38px;
            line-height: normal;
            letter-spacing: normal;
            text-transform: none;
            outline: none;
            border: none;
          }
        }
        .alwaysAskPref {
          .new-slide-detail-speed {
            .download {
              border-radius: 25px;
              color: white !important;
              background: #21a7e0;
              font-size: 14px;
              font-style: normal;
              font-stretch: normal;
              font-weight: bold;
              height: 38px;
              line-height: normal;
              letter-spacing: normal;
              text-transform: none;
              outline: none;
              border: none;
            }
          }
        }
      }
      .save-to-gdrive-disabled {
        .download {
          border-radius: 25px;
          color: white !important;
          background: #21a7e0;
          font-size: 14px;
          font-style: normal;
          font-stretch: normal;
          font-weight: bold;
          height: 38px;
          line-height: normal;
          letter-spacing: normal;
          text-transform: none;
          outline: none;
          border: none;
        }
      }
    }
  }
}
</style>
