var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"change-layout-container",attrs:{"data-pendo-id":_vm.isRedesign ? 'redesign-change-layout' : null}},[_c('div',{staticClass:"heading",style:({
      marginBottom:
        _vm.origin === 'Synthesis' || _vm.origin === 'Redesign' ? '8px' : '24px',
    })},[_vm._v(" "+_vm._s(_vm.$t('generate.smartLayout'))+" "),_c('div',{staticClass:"sub"},[_vm._v(_vm._s(_vm.$t('build.step3.poweredByZenSence')))])]),_c('div',{staticClass:"sub-heading"},[_vm._v(_vm._s(_vm.$t('generate.slideWithDifferentLayout')))]),_vm._t("default",function(){return [(_vm.relatedSlides.length)?_c('div',{staticClass:"icon-container"},[_c('div',{on:{"click":function($event){return _vm.handleView(false)}}},[_c('v-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.$t('prezentationGallery.gridView'),
            placement: 'top-center',
          }),expression:"{\n            content: $t('prezentationGallery.gridView'),\n            placement: 'top-center',\n          }"}],attrs:{"color":_vm.isColumnView ? '#000' : '#21A7E0'}},[_vm._v(_vm._s(_vm.isColumnView ? 'mdi-view-grid-outline' : 'mdi-view-grid'))])],1),_c('div',{on:{"click":function($event){return _vm.handleView(true)}}},[_c('v-icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.$t('prezentationGallery.columnView'),
            placement: 'top-center',
          }),expression:"{\n            content: $t('prezentationGallery.columnView'),\n            placement: 'top-center',\n          }"}],attrs:{"color":_vm.isColumnView ? '#21A7E0' : '#000'}},[_vm._v(_vm._s(_vm.isColumnView ? 'mdi-view-agenda' : 'mdi-view-agenda-outline'))])],1)]):_vm._e(),_c('div',{staticClass:"layout-content"},_vm._l((_vm.relatedSlides),function(slide,index){return _c('div',{key:index,class:[{ 'column-view': _vm.isColumnView }, 'slide']},[_c('v-img',{staticClass:"layout-image",class:{
            current:
              _vm.selectedSlideID &&
              (slide.unique_id === _vm.selectedSlideID ||
                slide.id === _vm.selectedSlideID),
            disabled: slide.disabled,
          },attrs:{"contain":"","lazy-src":"/assets/img/slides/placeholder-slide.svg","aspect-ratio":16 / 9,"src":_vm.getImgPath(slide),"loading":"lazy"},on:{"click":function($event){return _vm.handleChangeLayout(slide)}}})],1)}),0)]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }