var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"other-finger-prints"},[_c('div',{staticClass:"other-finger-prints-title"},[_vm._v(" "+_vm._s(_vm.$t('fingerprintvue.otherFingerprints.fingerprintsOnBehalfOfOthers'))+" ")]),_c('div',[(!_vm.loadingAudiences)?_c('v-data-table',{staticClass:"audiences",attrs:{"headers":_vm.tableHeaders,"footer-props":{
        itemsPerPageOptions: [10, 25, 50, 100],
      },"items":_vm.audiences,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('div',{staticClass:"other-fingerprints-toolbar"},[_c('Search',{attrs:{"searchKey":_vm.search,"placeholder":_vm.$t('fingerprintvue.otherFingerprints.searchByFingerprint'),"onSearch":_vm.onSearch}})],1),_c('div',{staticClass:"spacer"}),_c('v-btn',{staticClass:"primary-button add-audience mt-2 py-2",staticStyle:{"'font-size'":"'18px'"},attrs:{"rounded":"","color":"#21a7e0","height":"48","min-width":"134"},on:{"click":_vm.addNewAudience}},[_c('NewFeatureBadge',{attrs:{"module-name":"addAudience","feature-id":'addAudience_id',"offsetX":'30',"offsetY":'-15'}},[_vm._v(" "+_vm._s(_vm.$t('fingerprint.searchPage.addNewAudience'))+" ")])],1),_c('v-dialog',{attrs:{"width":"467px"},model:{value:(_vm.isDeleteAudienceInvoked),callback:function ($$v) {_vm.isDeleteAudienceInvoked=$$v},expression:"isDeleteAudienceInvoked"}},[_c('v-card',{staticClass:"delete-confirm-dialog"},[_c('v-card-title',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.deleteConfirmationText)+" ")]),_c('v-card-actions',[_c('v-btn',{staticClass:"delete-confirm-btn",attrs:{"rounded":"","outlined":"","text":"","width":"100","height":"32","loading":_vm.isDeleting},on:{"click":_vm.deleteItemConfirm}},[_vm._v(" "+_vm._s(_vm.$t('common.delete'))+" ")]),_c('v-btn',{staticClass:"delete-cancel-btn",attrs:{"rounded":"","color":"#21a7e0","width":"100","height":"32"},on:{"click":_vm.closeDelete}},[_vm._v(" "+_vm._s(_vm.$t('common.cancel'))+" ")])],1)],1)],1)],1)]},proxy:true},{key:"item.fullName",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"audience-title-wrapper",on:{"click":function($event){return _vm.onAudienceClick(item)}}},[_c('AudienceItem',{attrs:{"itemClass":'no-padding clickable',"firstName":item.firstName,"lastName":item.lastName,"profileImg":item.profileImageUrl || '',"isGroup":item.type.toLowerCase() === 'group',"showDot":false,"fingerprintType":item.fingerprintType}})],1)]}},{key:"item.shared",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"audience-status clickable",on:{"click":function($event){return _vm.shareAudience(item)}}},[(item.shared)?_c('span',{staticClass:"status-label"},[_c('v-icon',{staticClass:"status-icon"},[_vm._v(" mdi-account-multiple-check-outline ")]),_vm._v(" "+_vm._s(_vm.$t('build.step1.shared')))],1):_c('span',{staticClass:"status-label"},[_c('v-icon',{staticClass:"status-icon"},[_vm._v("mdi-account-check-outline")]),_vm._v(" "+_vm._s(_vm.$t('build.step1.private')))],1)])]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","max-width":"200","content-class":"tooltip-content","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"copy-fingerprint--auto",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.handleCopyLink(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-content-copy ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.isCopied && _vm.copiedFPId === item.id ? _vm.$t('navbarActions.shareFreeTrial.copied') : _vm.$t('navbarActions.shareFreeTrial.copyLink')))])]),(item.fingerprintType === 'Group_Insight')?_c('v-tooltip',{attrs:{"top":"","max-width":"200","content-class":"tooltip-content","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"retake-fingerprint--auto",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.editGroupInsight(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('fingerprintvue.otherFingerprints.editGroupMenbers')))])]):_c('v-tooltip',{attrs:{"top":"","max-width":"200","content-class":"tooltip-content","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"retake-fingerprint--auto",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.onRetakeAssessment(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-sync ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('fingerprintvue.otherFingerprints.retakeFingerprintOfOthers')))])]),_c('v-tooltip',{attrs:{"top":"","max-width":"200","content-class":"tooltip-content","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"delete-audience--auto",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.showDeleteConfirmation(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('common.delete')))])])]}}],null,true)}):_c('clip-loader',{staticClass:"cliploader",attrs:{"color":"#21a7e0","width":'60',"height":'60',"size":'50px'}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }