<template>
  <div class="menu-section">
    <v-tooltip
      left
      max-width="250"
      content-class="tooltip-content"
      fixed
      open-delay="500"
      close-delay="300"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-hover v-slot="{ hover }">
          <v-btn
            depressed
            small
            icon
            :ripple="false"
            @click="closeSmartTools"
            :color="hover ? '#21A7E0' : '#000'"
            :disabled="isActionLoading"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon name="close-icon"> mdi-close </v-icon>
          </v-btn>
        </v-hover>
      </template>
      <span>Close Smart Tools</span>
    </v-tooltip>
    <v-tooltip
      left
      max-width="250"
      content-class="tooltip-content"
      fixed
      open-delay="500"
      close-delay="300"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-hover v-slot="{ hover }">
          <v-btn
            depressed
            small
            icon
            :ripple="false"
            @click="onChangeTemplate"
            :color="
              rightPanelComponent === 'compare' || hover ? '#21A7E0' : '#000'
            "
            :disabled="isActionLoading"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon name="template-icon"> mdi-compare </v-icon>
          </v-btn>
        </v-hover>
      </template>
      <span>Change template</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  computed: {
    ...mapState('comply', [
      'rightPanelComponent',
      'fileDetails',
      'selectedTemplate',
    ]),
    ...mapState('users', ['currentUser']),
    ...mapGetters('comply', ['isActionLoading']),
  },
  methods: {
    ...mapActions('comply', ['setRightPanelComponent', 'setPlacement']),
    closeSmartTools() {
      this.$store.commit('comply/openSmartTools', false);
      this.setRightPanelComponent(null);
    },
    onChangeTemplate() {
      this.setRightPanelComponent('compare');
      this.setPlacement('Smart tool');
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-section {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: 4;
  background: white;
}
</style>
