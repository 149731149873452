<template>
  <div class="pro-tip-content">
    <img
      src="/assets/img/generate/bulb.svg"
      :class="{ pulse: animateProTip }"
    />
    <span>{{ $t(proTipTitle) }}</span>
    <span class="link" @click="handleProTip">
      {{ $t(proTipAction) }}
    </span>
  </div>
</template>
<script>
export default {
  name: 'ProTipContainer',
  components: {},
  props: {
    animateProTip: {
      type: Boolean,
    },
    proTip: {
      type: String,
      default: 'none_of_the_above',
    },
  },
  data() {
    return {};
  },
  watch: {},
  computed: {
    proTipTitle() {
      const proTip = this.proTip
        ? this.proTip.toLowerCase()
        : 'none_of_the_above';
      if (proTip === 'short_prompt') {
        return 'generate.proTipShort';
      }
      if (proTip === 'long_prompt') {
        return 'generate.proTipLong';
      }
      if (proTip === 'data_visualization') {
        return 'generate.proTipDataVisual';
      }
      if (proTip === 'slide_shell') {
        return 'generate.proTipSlideShell';
      }
      if (proTip === 'text_insertion') {
        return 'generate.proTipTextInsersion';
      }
      if (proTip === 'visual_design') {
        return 'generate.proTipVisualDesign';
      }
      if (proTip === 'unclear_intent') {
        return 'generate.proTipUnclearIntent';
      }
      return 'generate.proTip';
    },
    proTipAction() {
      if (
        ['none_of_the_above', 'long_prompt', 'unclear_intent'].includes(
          this.proTip.toLowerCase(),
        )
      ) {
        return 'generate.viewExamples';
      }
      return 'generate.browseSlides';
    },
  },
  methods: {
    handleProTip() {
      this.$emit('handleProTip', this.proTip);
    },
  },
  mounted() {},
};
</script>
<style scoped lang="scss">
.pro-tip-content {
  display: flex;
  align-items: center;
  img {
    margin-right: 5px;
    width: 32px;
  }

  .link {
    color: #21a7e0;
    cursor: pointer;
    margin-left: 5px;
  }
}

.pulse {
  animation-name: pulse;
  animation-timing-function: ease;
  animation-duration: 1.5s;
  animation-iteration-count: 2;
  transform-origin: center;
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.3);
  }
  50% {
    transform: scale(1.05);
  }
  75% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
</style>
