<template>
  <div class="node-count-container">
    <div
      class="heading"
      :style="{
        marginBottom: origin === 'synthesis' ? '8px' : '24px',
      }"
    >
      {{ $t('generate.nodeCount') }}
      <div class="sub">{{ $t('build.step3.poweredByZenSence') }}</div>
    </div>
    <div class="sub-heading">
      {{ $t('slideDetails.selectNodeCountInfo') }}
    </div>
    <div class="node-content">
      <div
        v-for="(slide, index) in filteredSibilings"
        class="slide"
        :key="index"
      >
        <v-img
          contain
          lazy-src="/assets/img/slides/placeholder-slide.svg"
          :aspect-ratio="16 / 9"
          :src="slide.poster || '/assets/img/slides/placeholder-slide.svg'"
          loading="lazy"
          class="node-image"
          :class="{ current: slide.asset.prefs.node == nodeCount }"
          @click="handleNodeChange(slide)"
        />
        <div>{{ $t('generate.nodeCount') }}: {{ slide.asset.prefs.node }}</div>
      </div>
    </div>
  </div>
</template>
<script>
// import { mapState } from 'vuex';

export default {
  name: 'ChangeNodeCount',
  components: {},
  props: {
    nodeCount: {
      type: String,
      default: null,
    },
    origin: {
      type: String,
      default: null,
    },
    siblings: {
      type: Array,
      default: () => null,
    },
    slideType: {
      type: String,
      default: 'normal',
    },
  },
  methods: {
    handleNodeChange(slide) {
      if (this.nodeCount === slide.asset.prefs.node) {
        return;
      }
      this.$emit('change', { type: 'node_change', slide: slide.asset });
    },
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {
    filteredSibilings() {
      if (['thank_you', 'discussion_topic'].includes(this.slideType)) {
        return this.siblings.filter(
          // eslint-disable-next-line eqeqeq
          (slide) => slide.asset.prefs.node == this.nodeCount,
        );
      }
      return this.siblings;
    },
    // ...mapState('generateSlides', ['siblings', 'nodeCount']),
  },
};
</script>
<style lang="scss" scoped>
.node-count-container {
  padding: 20px 20px;
  padding-right: 5px;
  height: 100%;

  .heading {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    //  margin-bottom: 24px;
    .sub {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.02em;
      color: #697077;
      margin-top: 4px;
    }
  }

  .sub-heading {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #000000;
    margin-bottom: 16px;
  }
  .node-content {
    height: calc(100% - 70px);
    overflow: auto;
    padding: 5px;
    .slide {
      width: calc(50% - 20px);
      height: 160px;
      display: inline-block;
      margin-right: 20px;
      margin-bottom: 20px;
      .node-image {
        filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.1))
          drop-shadow(-1px -1px 4px rgba(0, 0, 0, 0.1));
        border-radius: 6px;
        overflow: hidden;
        margin-bottom: 15px;
        cursor: pointer;

        &.current {
          border: 1.5px solid #21a7e0;
          cursor: default;
        }

        &:hover {
          box-shadow: -4px 4px 25px 1px rgba(0, 0, 0, 0.3);
        }
      }
    }
  }
}
</style>
