var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"replaceComplyImagesContainer"},[_c('div',{staticClass:"backBtn"},[_c('v-btn',{on:{"click":_vm.handleBack}},[_c('v-icon',{attrs:{"size":"22"}},[_vm._v(_vm._s('mdi-chevron-left'))]),_vm._v(" "+_vm._s('Back')+" ")],1)],1),_c('div',{staticClass:"heading"},[_vm._v(_vm._s('Image Suggestion'))]),_c('div',{staticClass:"suggestionNumberAndAction"},[_c('div',{staticClass:"suggestionsHeaderWrapper"},[_c('v-avatar',{staticClass:"mr-2",attrs:{"max-width":24,"max-height":24,"min-height":24,"min-width":24,"color":_vm.getReplaceImageSuggestionState.color}},[_c('span',{staticClass:"suggestions__item-id"},[_vm._v(" "+_vm._s(_vm.getReplaceImageSuggestionState.suggestionNumber)+" ")])]),_c('div',{staticClass:"suggText"},[_vm._v(_vm._s('Image - Brand image'))])],1),_c('div',{staticClass:"actions"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.handleAccept}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#00925a"}},[_vm._v("mdi-check")])],1)]}}])},[_c('span',[_vm._v("Accept")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.handleReject}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#d32f2f"}},[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Reject")])])],1)]),_c('div',{staticClass:"imageSuggestion"},[_c('div',{staticClass:"labelAndImg"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s('Current'))]),_c('div',{staticClass:"currentWrapper"},[_c('v-img',{attrs:{"contain":"","src":_vm.getReplaceImageSuggestionState.current,"lazy-src":'/assets/img/slides/placeholder-slide.svg',"loading":"lazy","alt":"","aspect-ratio":16 / 9}})],1)]),_c('div',{staticClass:"iconContainer"},[_c('v-icon',[_vm._v(_vm._s('mdi-arrow-right'))])],1),_c('div',{staticClass:"labelAndImg"},[_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(("" + (_vm.isRevertEnabled ? 'Selected' : 'Suggested')))+" ")]),_c('div',{staticClass:"suggestedWrapper"},[_c('v-img',{attrs:{"contain":"","src":_vm.getReplaceImageSuggestionState.suggested,"lazy-src":'/assets/img/slides/placeholder-slide.svg',"loading":"lazy","alt":"","aspect-ratio":16 / 9}})],1)]),_c('div',{staticClass:"revertWrappper"},[(_vm.isRevertEnabled)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":_vm.handleRevert}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s('mdi-restore')+" ")])]}}],null,false,572268941)},[_c('span',[_vm._v(_vm._s('Revert to suggested'))])]):_vm._e()],1)]),_c('div',{staticClass:"companyLibHeader"},[_vm._v(" "+_vm._s(((_vm.currentUser.company.displayName) + " Library"))+" ")]),_c('div',{staticClass:"brandImageList"},[_c('BrandImages',{staticClass:"image-library-item ml-2",attrs:{"selectedImageData":{ test: 'test-selected-img' },"isAdobeEnabled":false,"slideData":{ test: 'test-slide-data' },"isGenerate":false,"defaultImages":_vm.brandLibDefaultSuggestions,"origin":'comply'},on:{"imageSelected":function($event){return _vm.imageSelected($event)}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }