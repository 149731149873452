<template>
  <div class="faq-search-wrapper">
    <div class="actions-section">
      <span class="action-btn" @click="handleGoBack">
        <v-icon class="icon" color="primary">mdi-chevron-left</v-icon>
        {{
          $t(previousRoute === 'search' ? 'slides.backToSearch' : 'common.back')
        }}
      </span>
      <span class="action-btn" @click="handleToggleAll">
        {{ isExpandedAll ? $t('help.collapseAll') : $t('help.expandAll') }}
      </span>
    </div>
    <div class="faq-section">
      <FAQDetail
        v-for="(faqData, index) in searchData"
        :key="`faq-category-${index}`"
        :faq-data="faqData"
        :panel-data="panelModel[faqData.category]"
        :isExpandAll="isExpandedAll"
        @changeExpansion="handleChangePanel"
      />
    </div>
  </div>
</template>

<script>
import FAQDetail from './FAQDetail.vue';

export default {
  name: 'FAQSearchResult',
  components: {
    FAQDetail,
  },
  props: {
    searchData: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      previousRoute: '',
      isShowDetail: false,
      isExpandedAll: false,
      panelModel: [],
    };
  },
  computed: {
    routeQuery() {
      return this.$route.query.id;
    },
  },
  mounted() {
    this.previousRoute = this.$router.app.previousRoute.name;
    // initialize panelModel value
    this.collapseAll();

    // expand all if hybrid search
    if (this.routeQuery) {
      this.handleHybridSearch();
    }

    if (this.$route.query.category) {
      this.panelModel[this.$route.query.category] = [8];
      setTimeout(() => {
        const element = document.querySelector('.v-expansion-panel--active');
        if (element) {
          element.scrollIntoView(false);
        }
      }, 0);
    }
  },
  methods: {
    handleHybridSearch() {
      this.panelModel = [];
      this.isExpandedAll = true;
      this.expandAll();
    },
    handleGoBack() {
      this.$router.back();
    },
    handleToggleAll() {
      if (!this.isExpandedAll) {
        this.expandAll();
      } else {
        this.collapseAll();
      }
      this.isExpandedAll = !this.isExpandedAll;
    },
    handleChangePanel(payload) {
      this.panelModel[payload.category] = payload.panel;

      // check if all is expanded
      const curExpandedCount = Object.keys(this.panelModel).reduce(
        (acc, cur) => acc + this.panelModel[cur].length,
        0,
      );

      if (this.total === curExpandedCount) this.isExpandedAll = true;
      else this.isExpandedAll = false;
    },
    expandAll() {
      this.searchData.forEach((faqData) => {
        this.panelModel[faqData.category] = [
          ...Array(faqData.data.length).keys(),
        ].map((k, i) => i);
      });
    },
    collapseAll() {
      this.searchData.forEach((faqData) => {
        this.panelModel[faqData.category] = [];
      });
    },
  },
  watch: {
    routeQuery(val) {
      if (val) {
        // expand all if hybrid search
        this.handleHybridSearch();
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.faq-search-wrapper {
  font-family: 'Lato';
  font-style: normal;
  margin: 32px 60px 32px 0;

  .actions-section {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .action-btn {
      align-items: center;
      color: #21a7e0;
      cursor: pointer;
      display: flex;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
    }
  }

  .faq-section {
    height: 70vh;
    margin: 1rem -1rem;
    overflow-y: auto;
    padding: 1rem;
  }
}
</style>
