var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"quick-access-toolbar"},[_vm._t("default"),_c('v-tooltip',{attrs:{"bottom":"","max-width":"250","content-class":"tooltip-content","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',_vm._g(_vm._b({style:(!_vm.getComplianceScore
              ? {
                  pointerEvents: 'none',
                  cursor: 'not-allowed',
                }
              : {}),attrs:{"depressed":"","small":"","icon":"","ripple":false,"color":(hover && '#21A7E0') || (!_vm.getComplianceScore ? '#757575' : '#000'),"disabled":_vm.isActionLoading,"data-pendo-id":"compliance-checker-download"},on:{"click":_vm.handleDownload}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-download-outline")])],1)]}}],null,true)})]}}])},[_c('span',[_vm._v(_vm._s(_vm.mapUserDownloadPreferenceToTooltip))])]),_c('div',[_c('v-tooltip',{attrs:{"bottom":"","max-width":"250","content-class":"tooltip-content","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var hover = ref.hover;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","small":"","icon":"","ripple":false,"color":_vm.showTutorialMenu || hover ? '#21A7E0' : '#000',"disabled":_vm.isActionLoading,"data-pendo-id":"compliance-checker-tutorials"},on:{"click":_vm.onClickTutorialIcon}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"name":"tutorials-icon"}},[_vm._v("mdi-television-play")])],1)]}}],null,true)})]}}])},[_c('span',[_vm._v("Tutorials")])]),_c('v-menu',{attrs:{"attach":true,"absolute":"","offset-y":"","position-x":-425,"position-y":40,"nudge-width":320,"close-on-click":true,"close-on-content-click":false,"content-class":"tutorial-menu"},model:{value:(_vm.showTutorialMenu),callback:function ($$v) {_vm.showTutorialMenu=$$v},expression:"showTutorialMenu"}},[_c('tutorial-menu',{attrs:{"category":'Template Converter'}})],1)],1),_c('v-tooltip',{attrs:{"bottom":"","max-width":"250","content-class":"tooltip-content","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var hover = ref.hover;
return [_c('v-btn',_vm._g(_vm._b({style:(!_vm.getComplianceScore
              ? {
                  pointerEvents: 'none',
                  cursor: 'not-allowed',
                }
              : {}),attrs:{"depressed":"","small":"","icon":"","ripple":false,"color":((hover ||
              ['complianceScore', 'complySlideDetails'].includes(
                _vm.rightPanelComponent
              )) &&
              '#21A7E0') ||
            (!_vm.getComplianceScore ? '#757575' : '#000'),"disabled":_vm.isActionLoading},on:{"click":_vm.onComplianceSuggestionsClick}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-text-box-check-outline")])],1)]}}],null,true)})]}}])},[_c('span',[_vm._v("Brand compliance suggestions")])]),(!_vm.rightPanelComponent)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"250","content-class":"tooltip-content","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [_c('v-btn',_vm._g(_vm._b({style:(!_vm.getComplianceScore
              ? {
                  pointerEvents: 'none',
                  cursor: 'not-allowed',
                }
              : {}),attrs:{"depressed":"","small":"","icon":"","ripple":false,"color":(hover && '#21A7E0') || (!_vm.getComplianceScore ? '#757575' : '#000'),"disabled":_vm.isActionLoading},on:{"click":_vm.onClickSmartTools}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-square-edit-outline")])],1)]}}],null,true)})]}}],null,false,2120508361)},[_c('span',[_vm._v("Smart Tools")])]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }