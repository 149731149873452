var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"comply-content"},[_c('div',{staticClass:"comply-main-section",class:{ 'right-panel-open': _vm.rightPanelComponent }},[_c('div',{staticClass:"comply-top-row"},[_c('div',{staticClass:"page-heading"},[_c('div',{staticClass:"main"},[_vm._v("Upload the slides you would like to convert")]),_c('div',{staticClass:"sub"},[_vm._v(" "+_vm._s('Powered by ASTRID™ AI')+" ")])]),_c('QuickAccessToolbarComponent',{attrs:{"isAddedToLibrary":_vm.isAddedToLibrary,"addToLibrary":_vm.updateAddedToSlideLibraryStatus,"handleClosePopAndSaveToLib":_vm.handleClosePopAndSaveToLib}},[[_c('div',[(!_vm.removingFromLibrary)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"250","content-class":"tooltip-content","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',_vm._g(_vm._b({style:(!_vm.getComplianceScore
                          ? {
                              pointerEvents: 'none',
                              cursor: 'not-allowed',
                            }
                          : {}),attrs:{"depressed":"","small":"","icon":"","ripple":false,"color":hover || _vm.isAddedToLibrary
                          ? '#21A7E0'
                          : !_vm.getComplianceScore
                          ? '#757575'
                          : '#000',"disabled":_vm.isActionLoading,"data-pendo-id":"compliance-checker-add-to-library"},on:{"click":_vm.handleAddToSlideLibrary}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"name":"add-to-library-icon","data-pendo-id":"generate-add-to-library"}},[_vm._v(" "+_vm._s(_vm.isAddedToLibrary ? _vm.isMoreSlides ? 'mdi-folder' : 'mdi-folder-table' : _vm.isMoreSlides ? 'mdi-folder-outline' : 'mdi-folder-table-outline')+" ")])],1)]}}],null,true)})]}}],null,false,911270099)},[_c('span',[_vm._v(_vm._s(_vm.$t(_vm.addToLibraryTooltip)))])]):_vm._e(),(_vm.isAddedToLibrary && _vm.removingFromLibrary)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":'#21A7E0',"size":25}}):_vm._e()],1),_c('div',[(!_vm.removingAsFavorite)?_c('v-tooltip',{attrs:{"bottom":"","max-width":"250","content-class":"tooltip-content","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var hover = ref.hover;
return [_c('v-btn',_vm._g(_vm._b({style:(!_vm.getComplianceScore
                          ? {
                              pointerEvents: 'none',
                              cursor: 'not-allowed',
                            }
                          : {}),attrs:{"depressed":"","small":"","icon":"","ripple":false,"color":hover || _vm.isAddedAsFavorite
                          ? '#21A7E0'
                          : !_vm.getComplianceScore
                          ? '#757575'
                          : '#000',"disabled":_vm.isActionLoading,"data-pendo-id":"compliance-checker-favorite"},on:{"click":function($event){return _vm.handleAddToFavorites()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"name":"favorite-icon","data-pendo-id":"generate-add-to-favourite"}},[_vm._v(" "+_vm._s(_vm.isAddedAsFavorite ? 'mdi-heart' : 'mdi-heart-outline')+" ")])],1)]}}],null,true)})]}}],null,false,4071210345)},[_c('span',[_vm._v(_vm._s(_vm.$t(_vm.addToFavoriteTooltip)))])]):_vm._e(),(_vm.isAddedAsFavorite && _vm.removingAsFavorite)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":'#21A7E0',"size":25}}):_vm._e()],1)]],2)],1),_c('div',{staticClass:"comply-main-content"},[_c('manage-comply-upload',{attrs:{"handleOnFileUpload":_vm.handleOnFileUpload,"handleOnReCheckComply":_vm.handleOnCheckCompliance,"resetLocalFileInfo":_vm.resetLocalFileData,"inTemplateSelectionStep":_vm.templatesListResponse &&
            !_vm.showSlideContent &&
            !_vm.initialComplianceCheckFailed,"loading":_vm.checkingUsage.value},on:{"onCancelledCta":_vm.handleOnCancelledCta}}),_c('slides-placeholder',{directives:[{name:"show",rawName:"v-show",value:(
            (!_vm.fileDetails &&
              !_vm.rightPanelComponent &&
              !_vm.loading.fileUploadLoading) ||
            _vm.initialComplianceCheckFailed ||
            _vm.selectedSlidesComplianceCheckFailed
          ),expression:"\n            (!fileDetails &&\n              !rightPanelComponent &&\n              !loading.fileUploadLoading) ||\n            initialComplianceCheckFailed ||\n            selectedSlidesComplianceCheckFailed\n          "}]}),(_vm.loading.fileUploadLoading)?_c('progress-animation-loader'):_vm._e(),(
            _vm.showSlideContent &&
            !(
              (!_vm.fileDetails &&
                !_vm.rightPanelComponent &&
                !_vm.loading.fileUploadLoading) ||
              _vm.initialComplianceCheckFailed ||
              _vm.selectedSlidesComplianceCheckFailed
            )
          )?_c('ComplyDetails',{on:{"update-main-slide-height":_vm.handleMainSlideHeightUpdate}}):_vm._e(),(_vm.isFinalSlideDataAvailable && !_vm.showSlideContent)?_c('slides-listing',{attrs:{"slidesList":_vm.slidesListResponse.slide_images,"loading":_vm.loading.templateListLoading},on:{"handleOnNext":_vm.handleOnSlidesSelectionCta}}):_vm._e(),(
            _vm.templatesListResponse &&
            !_vm.showSlideContent &&
            !_vm.initialComplianceCheckFailed
          )?_c('templates-listing',{attrs:{"templatesData":_vm.templatesListResponse,"loading":_vm.loading.intialComplianceLoading,"templateRequestFrom":'Template Converter'},on:{"onPrimaryBtnClick":_vm.handleOnTemplateSelection}}):_vm._e()],1)]),(_vm.isSmartToolsOpen)?_c('SmartToolsMenu'):_vm._e(),_c('ComplySettingsPanel',{attrs:{"slide-height":_vm.mainSlideHeight,"clearSlideLibraryFlags":_vm.clearSlideLibraryFlags,"isAddedToLibrary":_vm.isAddedToLibrary,"addToLibrary":_vm.updateAddedToSlideLibraryStatus,"handleClosePopAndSaveToLib":_vm.handleClosePopAndSaveToLib},on:{"handleOnCheckCompliance":_vm.handleOnCheckCompliance}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }