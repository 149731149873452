var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu-section"},[_c('v-tooltip',{attrs:{"left":"","max-width":"250","content-class":"tooltip-content","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","small":"","icon":"","ripple":false,"color":hover ? '#21A7E0' : '#000',"disabled":_vm.isActionLoading},on:{"click":_vm.closeSmartTools}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"name":"close-icon"}},[_vm._v(" mdi-close ")])],1)]}}],null,true)})]}}])},[_c('span',[_vm._v("Close Smart Tools")])]),_c('v-tooltip',{attrs:{"left":"","max-width":"250","content-class":"tooltip-content","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","small":"","icon":"","ripple":false,"color":_vm.rightPanelComponent === 'compare' || hover ? '#21A7E0' : '#000',"disabled":_vm.isActionLoading},on:{"click":_vm.onChangeTemplate}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"name":"template-icon"}},[_vm._v(" mdi-compare ")])],1)]}}],null,true)})]}}])},[_c('span',[_vm._v("Change template")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }