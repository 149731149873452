<template>
  <div class="live-protip-container">
    <!-- <v-btn class="close-btn" icon @click="handleClose">
      <v-icon dark v-text="`mdi-close`" />
    </v-btn> -->
    <div class="header">
      <img src="/assets/img/generate/speaker_notes_header.svg" />
      <span>Prompt suggestions</span>
    </div>
    <div class="sub-heading">
      {{ subHeading }}
    </div>
    <div class="lp-body">
      <div
        class="lp-suggestion"
        :class="{
          selected: selectedSuggestionIndex === index,
        }"
        v-for="(suggestion, index) in suggestions"
        :key="index"
        @click="focusSuggestion(index)"
      >
        <v-lazy :key="`${hash}${index}`">
          <v-textarea
            v-model="suggestions[index]"
            class="edit-suggestion"
            :ref="`textarea-${index}`"
            hide-details
            no-resize
            auto-grow
            rows="1"
            @focus="selectedSuggestionIndex = index"
          />
        </v-lazy>
      </div>
    </div>
    <div class="lp-actions">
      <v-switch
        v-model="showPrompt"
        label="Show prompt suggestions"
        hide-details
        @change="toggleLivePrompt"
      ></v-switch>
      <div class="lp-buttons">
        <v-btn
          elevation="2"
          rounded
          class="ignore-btn"
          @click="ignoreSuggestions"
        >
          Ignore suggestion
        </v-btn>
        <v-btn
          elevation="2"
          :disabled="disableGenerateBtn"
          rounded
          color="primary"
          class="generate-btn"
          @click="generate()"
        >
          {{ $t('slides.generate') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';
import { mapActions, mapGetters, mapState } from 'vuex';
import { trackGenerateEvents } from '../../common/Analytics/GenerateEvents';
import {
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  TD_INSTRUCTIONS,
  TD_PLACEMENT,
  TD_PROMPT_SUGGESTIONS_POPUP,
} from '../../common/Analytics/MatomoTrackingDataHelper';

export default {
  name: 'LiveProTip',
  components: {},
  props: {
    proTip: {
      type: String,
      default: 'none_of_the_above',
    },
    suggestions: {
      type: Array,
      default: () => [],
    },
    isMenuOpen: Boolean,
  },
  data() {
    return {
      hash: uuidv4(),
      selectedSuggestionIndex: null,
      showPrompt: null,
    };
  },
  watch: {
    getAGLiveSuggestionEnabled(newVal) {
      this.showPrompt = newVal;
    },
    isMenuOpen() {
      this.hash = uuidv4();
      this.selectedSuggestionIndex = null;
    },
  },
  computed: {
    ...mapGetters('users', [
      'getAGLiveSuggestionEnabled',
      // 'doUserHavePremiumSubscription',
    ]),
    ...mapState('users', ['currentUser']),
    ...mapState('generateSlides', ['promptText']),
    subHeading() {
      if (this.proTip === 'data_visualization') {
        return 'ASTRID is still evolving in data visualization. Pick and edit any of the following prompt suggestions to produce better results';
      }
      return 'Nice start! Adding more details will help produce a better result. Pick and edit any of the following prompt suggestions';
    },
    disableGenerateBtn() {
      return !(
        this.suggestions.length &&
        this.selectedSuggestionIndex !== null &&
        this.suggestions[this.selectedSuggestionIndex].trim().length
      );
    },
  },
  methods: {
    ...mapActions('users', ['toggleUserAGPromptSuggestions']),
    handleClose() {
      console.log('close');
      this.$emit('close');
    },
    toggleLivePrompt() {
      this.toggleUserAGPromptSuggestions();
      const otherData = {
        [TD_PLACEMENT]: TD_PROMPT_SUGGESTIONS_POPUP,
        [TD_COMMON_COLUMN_NAME]: 'On or off',
        [TD_COMMON_COLUMN_VALUE]: this.getAGLiveSuggestionEnabled
          ? 'On'
          : 'Off',
        [TD_INSTRUCTIONS]: this.promptText,
      };
      trackGenerateEvents.generatePromptSuggestionsOnOff(
        this.currentUser,
        otherData,
      );
    },
    ignoreSuggestions() {
      console.log('ignoreSuggestions');
      const otherData = {
        [TD_INSTRUCTIONS]: this.promptText,
      };
      trackGenerateEvents.generatePromptSuggestionsIgnoreSuggestion(
        this.currentUser,
        otherData,
      );
      this.$emit('generate', { ignore: true });
    },
    generate() {
      console.log('generate');
      const otherData = {
        [TD_INSTRUCTIONS]: this.suggestions[this.selectedSuggestionIndex],
      };
      trackGenerateEvents.generatePromptSuggestionsSuggestionSelected(
        this.currentUser,
        otherData,
      );
      this.$emit('generate', {
        ignore: false,
        prompt: this.suggestions[this.selectedSuggestionIndex],
      });
    },
    focusSuggestion(index) {
      this.$refs[`textarea-${index}`][0].focus();
    },
  },
  mounted() {
    this.showPrompt = this.getAGLiveSuggestionEnabled;
  },
};
</script>
<style scoped lang="scss">
.live-protip-container {
  width: 620px;
  background: white;
  padding: 24px;
  position: relative;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  z-index: 2;

  .close-btn {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .header {
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 16px;
    gap: 9px;

    img {
      position: relative;
      width: 24px;
      height: 24px;
    }
  }

  .lp-body {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .lp-suggestion {
      padding: 8px 12px 8px 12px;
      border-radius: 4px;
      box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.1);
      font-size: 14px;

      &:not(.selected):hover {
        cursor: pointer;
        box-shadow: -4px 4px 25px 1px rgba(0, 0, 0, 0.3);
      }

      &.selected {
        border: 1.5px solid rgba(33, 167, 224, 1);
      }

      .edit-suggestion {
        padding-top: 0;
        font-size: 14px;
        margin-top: 0;
        cursor: pointer;

        ::v-deep textarea {
          line-height: 1.5;
          cursor: pointer;
        }
      }
    }
  }

  .lp-actions {
    display: flex;
    justify-content: space-between;

    .v-input {
      margin-top: 0;
    }

    .lp-buttons {
      display: flex;
      gap: 12px;
    }
    .ignore-btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 6px 16px;
      gap: 8px;
      height: 32px;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      text-transform: none;
      letter-spacing: normal;
      line-height: 19px;

      /* Color Styles (Old)/White */

      color: #21a7e0;

      background-color: #ffffff;
      border: 1px solid #21a7e0;
      border-radius: 25px;
    }

    .generate-btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 6px 16px;
      gap: 8px;

      width: auto;
      height: 32px;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      text-transform: none;
      letter-spacing: normal;
      line-height: 19px;

      /* Color Styles (Old)/White */

      color: #ffffff;

      background: #21a7e0;
      border-radius: 25px;
    }
  }
}
</style>
