<template>
  <div class="main-section">
    <div class="faq-category">
      <FAQCategoryIcon
        :icon="faqData.icon"
        :category="faqData.category"
        :iconUrl="faqData.data[0].iconUrl"
      />
      <span class="faq-category__label">{{ faqData.category }}</span>
    </div>
    <v-expansion-panels v-model="panel" multiple>
      <v-expansion-panel v-for="(faq, index) in faqData.data" :key="index">
        <v-expansion-panel-header @click="onFaqClick(faq)">
          {{ faq.question }}
        </v-expansion-panel-header>
        <v-expansion-panel-content v-html="returnMarked(faq.answer)">
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { marked } from 'marked';
import DOMPurify from 'dompurify';
import {
  MatomoAnalyticsHandler,
  _S_,
} from '../../../common/Analytics/MatomoAnalyticsHandler';
import FAQCategoryIcon from './FAQCategoryIcon.vue';
import { trackHelpEvents } from '../../../common/Analytics/HelpEvents';
import {
  TD_FAQCATEGORY,
  TD_FAQTITLE,
} from '../../../common/Analytics/MatomoTrackingDataHelper';

export default {
  name: 'FAQDetail',
  props: {
    faqData: {
      type: Object,
      default: () => {},
    },
    panelData: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    panel: [],
  }),
  components: {
    FAQCategoryIcon,
  },
  mounted() {
    this.panel = this.panelData;
  },
  methods: {
    returnMarked(content) {
      return DOMPurify.sanitize(
        `<div class="marked-container">${marked(content)}</div>`,
      );
    },
    onFaqClick(faq) {
      const { category, question } = faq;
      MatomoAnalyticsHandler.trackHelp(
        {
          type: 'faqs',
          actionValue: `${category}${_S_}${question}`,
        },
        this.currentUser,
      );
      trackHelpEvents.helpFAQClick(this.currentUser, {
        [TD_FAQCATEGORY]: category,
        [TD_FAQTITLE]: question,
      });
    },
  },
  watch: {
    panelData(val) {
      this.panel = val;
    },
    panel() {
      this.$emit('changeExpansion', {
        category: this.faqData.category,
        panel: this.panel,
      });
    },
  },
  computed: {
    ...mapState('users', ['currentUser']),
  },
};
</script>
<style lang="scss" scoped>
.main-section {
  margin-top: 32px;

  &:first-child {
    margin-top: 8px;
  }

  .faq-category {
    align-items: center;
    display: flex;

    .v-image {
      flex: none;
    }

    &__label {
      font-weight: 600;
      font-size: 24px;
      line-height: 24px;
      margin-left: 16px;
    }
  }

  ::v-deep .v-expansion-panel {
    box-shadow: $item-box-shadow;
    border-radius: 8px;
    margin-top: 16px;
    padding: 24px 16px;

    &::before {
      box-shadow: none !important;
    }
    &::after {
      border: 0;
    }

    .v-expansion-panel-header {
      font-weight: 400;
      font-size: 20px;
      line-height: 130%;
    }

    .v-expansion-panel-content {
      display: block;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      margin: 1em 24px;
      .marked-container {
        ol {
          margin-bottom: 1em;
        }
        ul {
          margin-bottom: 1em;
        }
        p {
          margin-bottom: 0px;
        }
      }
    }
  }
}
</style>
