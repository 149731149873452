<template>
  <v-navigation-drawer
    v-model="drawer"
    class="elevation-3"
    persistent
    app
    floating
    hide-overlay
    :mini-variant-width="80"
    :mini-variant="customMini"
    clipped
    permanent
    :width="318"
  >
    <v-list nav dense>
      <v-list-item-group
        :value="selectedItem"
        :mandatory="selectedItem !== null"
        color="primary"
        :class="customMini ? 'minimize' : 'expanded'"
      >
        <ListItem
          v-for="(item, i) in sidebarItems"
          :key="i"
          :item-key="i"
          :item="item"
          :listmini="customMini"
          menuId="left-sidebar"
          :itemActiveClass="
            selectedItem === i ? 'activeSideBarItem' : 'sideBarItem'
          "
        />
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// import {
//   mdiPlusCircleOutline,
//   mdiFolderOutline,
//   mdiFolderTableOutline,
//   mdiSchoolOutline,
//   mdiFingerprint,
//   mdiHistory,
//   mdiAutoFix,
//   mdiTrayArrowUp,
//   mdiTextBoxCheckOutline,
// } from '@mdi/js';
import { mapGetters, mapState, mapActions } from 'vuex';
import ListItem from './Sidebar/ListItem';
import EventBus from './common/event-bus';

export default {
  name: 'LeftSidebar',
  components: { ListItem },
  data() {
    return {
      router: null,
      search: '',
      drawer: true,
      // selectedItem: null,
      customMini: true,
      currentLink: '',
      sidebarItems: [],
      minimized: false,
      /* UPDATE: this array with the feature route name property when 
      its landing page or route requires navbar collpase */
      featuresRequireNavbarCollapse: [
        'build',
        'generate',
        'template-converter',
        'fingerprint',
        'synthesis',
        'redesign',
        'Prezentations',
        'overnightPrezentations',
        'best-practice-library',
        'slides',
        'learn',
        'accelerators',
        'know.detail',
      ],
    };
  },
  props: {
    mini: {
      type: Boolean,
      default: false,
    },
    isTrialExpired: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions('users', ['setLeftSideBarStatus']),
  },
  watch: {
    mini() {
      const miniStatus = this.mini;
      this.customMini = miniStatus;
      this.setLeftSideBarStatus(miniStatus);
    },
    customMini() {
      if (!this.customMini) {
        this.$emit('expanded');
        EventBus.$emit('LEFTNAV_EXPANDED');
      } else {
        EventBus.$emit('LEFTNAV_MINIMIZED');
      }
    },
    $route: {
      handler(val) {
        if (this.featuresRequireNavbarCollapse.indexOf(val.name) === -1) {
          this.customMini = !this.currentUser.user.navbarExpanded;
          this.setLeftSideBarStatus(!this.currentUser.user.navbarExpanded);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.customMini = this.mini;
    this.router = this.$router;
    const navItems = [
      {
        type: 'categoryHeader',
        expTitle: 'sideBarCategories.create',
        collTitle: 'sideBarCategories.create',
        automationText: 'create',
      },
      {
        icon: 'slide-lib-icon',
        title: 'sideBarFeatures.slide_library',
        tooltip: 'rightSideBar.slidesTooltip',
        collTooltip: 'sideBarFeatures.slide_library',
        link: 'slides',
        key: 'slides',
        automationText: 'slides',
        matomoTitle: 'Slides',
      },
      {
        icon: 'build-icon',
        title: 'sideBarFeatures.story_builder',
        tooltip: 'rightSideBar.buildTooltip',
        collTooltip: 'sideBarFeatures.story_builder',
        link: 'build',
        key: 'build',
        automationText: 'build',
        matomoTitle: 'Build',
      },
      this.isGenerateFullAccess
        ? {
            icon: 'auto-gen-icon',
            title: 'sideBarFeatures.auto_generator',
            flip: true,
            // beta: true,
            tooltip: 'rightSideBar.generateTooltip',
            collTooltip: 'sideBarFeatures.auto_generator',
            link: 'generate',
            key: 'generate',
            automationText: 'generate',
            matomoTitle: 'Generate',
          }
        : null,
      this.transformCategoryEnabled
        ? {
            type: 'categoryHeader',
            expTitle: 'sideBarCategories.transform',
            collTitle: 'sideBarCategories.transform',
            automationText: 'transform',
          }
        : null,
      this.isComplyFullAccess
        ? {
            icon: 'comply-icon',
            title: 'sideBarFeatures.compliance_checker',
            // beta: true,
            tooltip: 'rightSideBar.complyTooltip',
            collTooltip: 'sideBarFeatures.compliance_checker',
            link: 'template-converter',
            key: 'compliance',
            automationText: 'compliance',
            matomoTitle: 'Comply',
          }
        : null,
      this.isSynthesizeFullAccess
        ? {
            icon: 'synthesize-icon',
            title: 'rightSideBar.synthesize',
            // beta: true,
            tooltip: 'rightSideBar.synthesizeTooltip',
            collTooltip: 'sideBarFeatures.synthesize',
            link: 'synthesis',
            key: 'synthesis',
            automationText: 'synthesis',
            matomoTitle: 'Synthesis',
          }
        : null,
      this.isRedesignAccess
        ? {
            icon: 'redesign-icon',
            title: 'rightSideBar.redesign',
            beta: true,
            tooltip: 'rightSideBar.redesignTooltip',
            collTooltip: 'sideBarFeatures.redesign',
            link: 'redesign',
            key: 'redesign',
            automationText: 'redesign',
            matomoTitle: 'Redesign',
          }
        : null,
      {
        type: 'categoryHeader',
        expTitle: 'sideBarCategories.enable',
        collTitle: 'sideBarCategories.enable',
        automationText: 'enable',
      },
      {
        icon: 'prez-ex-icon',
        title: 'sideBarFeatures.prez_exchange',
        tooltip: 'rightSideBar.prezentationsTooltip',
        collTooltip: 'sideBarFeatures.prez_exchange',
        link: 'prezentations',
        key: 'prezentations',
        automationText: 'prezentations',
        matomoTitle: 'Prezentations',
      },
      this.isKnowEnabled
        ? {
            icon: 'bpe-icon',
            title: 'sideBarFeatures.bpe',
            tooltip: 'rightSideBar.bestPracticesTooltip',
            collTooltip: 'sideBarFeatures.bpe',
            link: 'best-practice-library',
            key: 'best-pratice-guides',
            automationText: 'best-practice-library',
            matomoTitle: 'Best Practice Library',
          }
        : null,
      {
        type: 'categoryHeader',
        expTitle: 'sideBarCategories.learn',
        collTitle: 'sideBarCategories.learn',
        automationText: 'learn_category',
      },
      {
        icon: 'courses-icon',
        title: 'sideBarFeatures.courses',
        tooltip: 'rightSideBar.learnTooltip',
        collTooltip: 'sideBarFeatures.courses',
        link: 'learn',
        key: 'learn',
        automationText: 'learn',
        matomoTitle: 'Learn',
      },
      {
        icon: 'fp-icon',
        title: 'sideBarFeatures.fingerprints',
        tooltip: 'rightSideBar.fingerprintTooltip',
        collTooltip: 'sideBarFeatures.fingerprints',
        link: 'fingerprint',
        key: 'fingerprint',
        automationText: 'fingerprint',
        matomoTitle: 'Fingerprints',
      },
      this.expertServicesCategoryEnabled
        ? {
            type: 'categoryHeader',
            expTitle: 'sideBarCategories.expert_services',
            collTitle: 'sideBarCategories.services',
            automationText: 'expert_services',
          }
        : null,
      this.getOPAccessLevel && this.getOPAccessLevel !== 'no_access'
        ? {
            icon: 'op-icon',
            title: 'sideBarFeatures.op',
            tooltip: 'rightSideBar.overnightPresentationTooltip',
            collTooltip: 'sideBarFeatures.op',
            link: 'overnight-prezentations',
            key: 'overnight-prezentations',
            automationText: 'overnight-prezentations',
            matomoTitle: 'Overnight Prezentations',
          }
        : null,
      this.isAcceleratorsEnabled
        ? {
            icon: 'accelerators-icon',
            title: 'sideBarFeatures.accelerators',
            tooltip: 'rightSideBar.acceleratorsTooltip',
            collTooltip: 'sideBarFeatures.accelerators',
            link: 'accelerators',
            key: 'accelerators',
            automationText: 'accelerators',
            matomoTitle: 'Accelerators',
          }
        : null,
      this.isUploadFullAccess
        ? {
            // icon: mdiTrayArrowUp,
            // title: 'common.upload',
            // beta: true,
            // tooltip: 'rightSideBar.uploadTooltip',
            // collTooltip: 'common.upload',
            // link: 'upload',
            key: 'upload',
            // automationText: 'upload',
            // matomoTitle: 'Upload',
            type: 'pendo-upload-item',
          }
        : null,
    ];
    this.sidebarItems = [...navItems].filter((item) => item !== null);
  },
  computed: {
    ...mapState('users', ['currentUser']),
    ...mapGetters('users', [
      'isKnowEnabled',
      'getOPAccessLevel',
      'isUploadFullAccess',
      'isGenerateFullAccess',
      'isComplyFullAccess',
      'isRedesignFullAccess',
      'isAcceleratorsEnabled',
    ]),

    selectedItem() {
      if (this.$route && this.$route.meta && this.$route.meta.routeKey) {
        const matchedRouteIndex = this.sidebarItems.findIndex(
          (item) => this.$route.meta.routeKey === item.key,
        );
        return matchedRouteIndex;
      }
      return null;
    },

    selectedFeature() {
      if (this.$route && this.$route.meta && this.$route.meta.routeKey) {
        const matchedRoute = this.sidebarItems.find(
          (item) => this.$route.meta.routeKey === item.key,
        );
        return matchedRoute;
      }
      return null;
    },

    expertServicesCategoryEnabled() {
      return (
        (this.getOPAccessLevel && this.getOPAccessLevel !== 'no_access') ||
        this.isAcceleratorsEnabled
      );
    },

    transformCategoryEnabled() {
      return (
        this.isComplyFullAccess ||
        this.isSynthesizeFullAccess ||
        this.isRedesignAccess
      );
    },

    isSynthesizeFullAccess(state) {
      const synFeature = state.currentUser?.features?.find(
        (ft) => ft.feature_name === 'synthesis',
      );
      return (
        (synFeature &&
          synFeature.enabled &&
          synFeature.access_level &&
          synFeature.access_level === 'full_access') ||
        (synFeature &&
          synFeature.access_level &&
          synFeature.access_level === 'restricted_access')
      );
    },
    isRedesignAccess(state) {
      const redesignFeature = state.currentUser?.features?.find(
        (ft) => ft.feature_name === 'redesign',
      );
      return (
        (redesignFeature &&
          redesignFeature.enabled &&
          redesignFeature.access_level &&
          redesignFeature.access_level === 'full_access') ||
        (redesignFeature &&
          redesignFeature.access_level &&
          redesignFeature.access_level === 'restricted_access')
      );
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/scss/app.scss';
@import '@/scss/variables.scss';

.elevation-3 {
  box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%) !important;
}

.toggle-wrapper {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  padding: 5px;
  background-color: #d8d8d8;
}
::v-deep .icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
::v-deep .top-list .v-list-item {
  margin-bottom: 0px !important;
}

::v-deep .v-list-item {
  // padding: 0px;
  // padding-top: 4px;
  // padding-bottom: 4px;
  // margin-bottom: 15px !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  .icon-wrapper {
    margin-left: 0px;

    .active {
      display: none;
    }
    .normal {
      display: block;
    }
  }

  &:hover {
    .icon-wrapper {
      .active {
        display: block;
      }
      .normal {
        display: none;
      }
    }
  }
  &.v-list-item--active {
    background-color: #ffffff;
    color: black;
    .icon-wrapper {
      margin-left: 0px;

      .active {
        display: block;
      }
      .normal {
        display: none;
      }
    }
  }
  &:hover {
    text-decoration: none;
  }
  &.divider {
    margin-bottom: -10px !important;
    margin-top: -15px !important;
    background-color: white;
    padding: 0 !important;
  }
  &:before {
    background: transparent;
  }
}

.activeSideBarItem {
  background-color: rgba(182, 182, 182, 0.21) !important;
  color: $zen-blue-default !important;
  border-radius: 50%;
  width: 55px;
  height: 55px;
  min-height: 46px;
  margin: auto;
}

::v-deep .v-item-group.expanded .icon-wrapper {
  margin-left: 12px;
  margin-top: 0px !important;
}
// ::v-deep .v-item-group .v-list-item--active {
//   border-radius: 50%;
//   width: 55px;
//   height: 55px;
//   min-height: 46px;
//   margin: auto;
// }

::v-deep .v-item-group.minimize .v-list-item {
  min-height: 32px !important;
}

::v-deep .v-item-group.expanded .v-list-item--active {
  border-radius: 0;
  width: auto;
  height: auto;
  margin: auto;
}
::v-deep .v-list-item__content {
  text-align: left;
  padding-left: 10px !important;
  flex: none;
  min-height: 2em;
  width: 100%;

  > .v-list-item__title {
    font-size: 20px;
    overflow: visible;
  }
}

.v-navigation-drawer--is-mouseover {
  .top-list {
    .v-list-item__avatar {
      margin-left: 30px;
    }
  }
  .icon-wrapper {
    margin-left: 30px;
  }
}
.line {
  background: rgba(0, 0, 0, 0.12);
  height: 2.5px;
  width: 100%;
}
.v-navigation-drawer--mini-variant .v-list-item .line {
  position: initial !important;
  height: 2.5px;
  width: 100%;
}
.v-list--nav {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.minimize,
.expanded {
  line-height: 1;
}
</style>
