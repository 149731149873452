<template>
  <div class="other-finger-prints">
    <div class="other-finger-prints-title">
      {{ $t('fingerprintvue.otherFingerprints.fingerprintsOnBehalfOfOthers') }}
    </div>
    <div>
      <v-data-table
        v-if="!loadingAudiences"
        :headers="tableHeaders"
        :footer-props="{
          itemsPerPageOptions: [10, 25, 50, 100],
        }"
        :items="audiences"
        :search="search"
        class="audiences"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <div class="other-fingerprints-toolbar">
              <Search
                :searchKey="search"
                :placeholder="
                  $t('fingerprintvue.otherFingerprints.searchByFingerprint')
                "
                :onSearch="onSearch"
              />
            </div>
            <div class="spacer"></div>
            <v-btn
              class="primary-button add-audience mt-2 py-2"
              rounded
              color="#21a7e0"
              height="48"
              min-width="134"
              style="'font-size': '18px'"
              @click="addNewAudience"
            >
              <NewFeatureBadge
                module-name="addAudience"
                :feature-id="'addAudience_id'"
                :offsetX="'30'"
                :offsetY="'-15'"
              >
                {{ $t('fingerprint.searchPage.addNewAudience') }}
              </NewFeatureBadge>
            </v-btn>
            <v-dialog v-model="isDeleteAudienceInvoked" width="467px">
              <v-card class="delete-confirm-dialog">
                <v-card-title class="headline">
                  {{ deleteConfirmationText }}
                </v-card-title>
                <v-card-actions>
                  <v-btn
                    rounded
                    outlined
                    text
                    width="100"
                    height="32"
                    class="delete-confirm-btn"
                    @click="deleteItemConfirm"
                    :loading="isDeleting"
                  >
                    {{ $t('common.delete') }}
                  </v-btn>
                  <v-btn
                    rounded
                    color="#21a7e0"
                    @click="closeDelete"
                    width="100"
                    height="32"
                    class="delete-cancel-btn"
                  >
                    {{ $t('common.cancel') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.fullName`]="{ item }">
          <div @click="onAudienceClick(item)" class="audience-title-wrapper">
            <AudienceItem
              :itemClass="'no-padding clickable'"
              :firstName="item.firstName"
              :lastName="item.lastName"
              :profileImg="item.profileImageUrl || ''"
              :isGroup="item.type.toLowerCase() === 'group'"
              :showDot="false"
              :fingerprintType="item.fingerprintType"
            />
          </div>
        </template>
        <template v-slot:[`item.shared`]="{ item }">
          <div class="audience-status clickable" @click="shareAudience(item)">
            <span class="status-label" v-if="item.shared">
              <v-icon class="status-icon">
                mdi-account-multiple-check-outline
              </v-icon>
              {{ $t('build.step1.shared') }}</span
            >
            <span class="status-label" v-else>
              <v-icon class="status-icon">mdi-account-check-outline</v-icon>
              {{ $t('build.step1.private') }}</span
            >
          </div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip
            top
            max-width="200"
            content-class="tooltip-content"
            fixed
            open-delay="500"
            close-delay="300"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                v-bind="attrs"
                v-on="on"
                class="copy-fingerprint--auto"
                color="primary"
                @click="handleCopyLink(item)"
              >
                mdi-content-copy
              </v-icon>
            </template>
            <span>{{
              isCopied && copiedFPId === item.id
                ? $t('navbarActions.shareFreeTrial.copied')
                : $t('navbarActions.shareFreeTrial.copyLink')
            }}</span>
          </v-tooltip>
          <v-tooltip
            v-if="item.fingerprintType === 'Group_Insight'"
            top
            max-width="200"
            content-class="tooltip-content"
            fixed
            open-delay="500"
            close-delay="300"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                v-bind="attrs"
                v-on="on"
                class="retake-fingerprint--auto"
                color="primary"
                @click="editGroupInsight(item)"
              >
                mdi-pencil-outline
              </v-icon>
            </template>
            <span>{{
              $t('fingerprintvue.otherFingerprints.editGroupMenbers')
            }}</span>
          </v-tooltip>
          <v-tooltip
            v-else
            top
            max-width="200"
            content-class="tooltip-content"
            fixed
            open-delay="500"
            close-delay="300"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                v-bind="attrs"
                v-on="on"
                class="retake-fingerprint--auto"
                color="primary"
                @click="onRetakeAssessment(item)"
              >
                mdi-sync
              </v-icon>
            </template>
            <span>{{
              $t('fingerprintvue.otherFingerprints.retakeFingerprintOfOthers')
            }}</span>
          </v-tooltip>
          <v-tooltip
            top
            max-width="200"
            content-class="tooltip-content"
            fixed
            open-delay="500"
            close-delay="300"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="delete-audience--auto"
                v-bind="attrs"
                v-on="on"
                small
                color="primary"
                @click="showDeleteConfirmation(item)"
              >
                mdi-delete-outline
              </v-icon>
            </template>
            <span>{{ $t('common.delete') }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <clip-loader
        v-else
        :color="`#21a7e0`"
        :width="'60'"
        :height="'60'"
        :size="'50px'"
        class="cliploader"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import AudienceApi from '../../../API/audience-api';
import Search from '../../common/Search.vue';
import AudienceItem from '../../common/AudienceItem.vue';
import ShareFingerPrintDetail from '../Profile/ShareFingerPrintDetail.vue';
import AddCollegueDetail from '../NewPrezentation/AddCollegueDetail.vue';
import NewFeatureBadge from '../../common/NewFeatureBadge.vue';
import { getCommonPrivateAudienceData } from '../../../utils/common';
import {
  FP_PROFILE_OTHER_DELETE,
  MatomoAnalyticsHandler,
} from '../../common/Analytics/MatomoAnalyticsHandler';
import { AddNewAudienceModalProps } from '../AddNewAudience/AddNewAudienceModalProps';
import {
  fpCopyVsPlacementMap,
  trackProfileEvents,
} from '../../common/Analytics/ProfileEvents';
import {
  shareFingerPrintVsPlacement,
  TD_FINGERPRINT_SHARING_SETTINGS,
  TD_PLACEMENT,
  TD_PROFILE_CREATED_FINGERPRINTS,
  TD_SHARE_FINGERPRINT_CREATED,
} from '../../common/Analytics/MatomoTrackingDataHelper';

export default {
  name: 'OtherFingerPrints',
  components: {
    AudienceItem,
    Search,
    NewFeatureBadge,
    ClipLoader,
  },
  data() {
    return {
      tableHeaders: [
        {
          text: 'Name',
          align: 'start',
          value: 'fullName',
        },
        { text: 'Fingerprint', value: 'fingerPrint' },
        { text: 'Company', value: 'companyName' },
        { text: 'Email', value: 'emailId' },
        { text: 'Type', value: 'type' },
        { text: 'Access', value: 'shared', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      loadingAudiences: false,
      items: [],
      search: '',
      origin: 'fingerprint',
      isDeleteAudienceInvoked: false,
      deleteConfirmationText: '',
      isDeleting: false,
      copiedFPId: '',
      isCopied: false,
    };
  },
  computed: {
    ...mapState('users', ['currentUser']),
    audiences() {
      const aud = [];
      this.items.forEach((item) => {
        if (item.fingerPrint) {
          const formattedAudience = getCommonPrivateAudienceData(
            item,
            this.currentUser,
          );
          const { isGeneratedEmail, isGroup, lastName } = formattedAudience;
          const type = isGroup ? 'Group' : 'Individual';
          aud.push({
            ...item,
            type,
            emailId: isGeneratedEmail ? '-' : item.id,
            lastName,
            fingerprintType: item.fingerprintType || 'default',
          });
        }
      });
      return aud;
    },
  },
  methods: {
    onAudienceClick(item) {
      this.$router.push({
        name: 'fingerprints',
        query: {
          uuid: item.uuid,
          type: 'private_audience',
        },
      });
    },
    addNewAudience() {
      this.$modal.show(
        AddCollegueDetail,
        {
          origin: TD_PROFILE_CREATED_FINGERPRINTS,
        },
        AddNewAudienceModalProps,
      );
    },
    onSearch(val) {
      this.search = val;
    },
    handleCopyLink(item) {
      this.isCopied = true;
      navigator.clipboard.writeText(
        `${window.location.origin}/home/fingerprints?uuid=${item.uuid}&type=private_audience`,
      );
      this.copiedFPId = item.id;
      setTimeout(() => {
        this.isCopied = false;
      }, 1000);
      trackProfileEvents.profileFingerprintCopylink(this.currentUser, {
        [TD_PLACEMENT]: fpCopyVsPlacementMap[TD_PROFILE_CREATED_FINGERPRINTS],
      });
    },
    editGroupInsight(item) {
      this.$router.push(
        `/home/fingerprint?editGroup=${item.uuid}&userID=${item.id}`,
      );
    },
    onRetakeAssessment(item) {
      console.log('retake', item);
      const { firstName, id } = item;
      this.$router.push(
        `/welcome?&name=${encodeURIComponent(
          firstName,
        )}&email=${id}&initialIndex=1&requester=${
          this.currentUser.user.id
        }&origin=${this.origin}&zensense=${
          item.fingerprintType === 'Zensense'
        }`,
        () => {},
        () => {},
      );
    },
    showDeleteConfirmation(item) {
      const { id, fullName } = item;
      const indexOfitemToBeDeleted = this.audiences.indexOf(item);
      this.itemToBeDeletedData = {
        index: indexOfitemToBeDeleted,
        id,
      };
      this.isDeleteAudienceInvoked = true;
      this.deleteConfirmationText = this.$t(
        'storyline.youWantToDeleteFingerprint',
        { Name: fullName },
      );
    },
    closeDelete() {
      this.isDeleteAudienceInvoked = false;
      this.deleteConfirmationText = '';
    },
    deleteItemConfirm() {
      const { id: audienceEmail } = this.itemToBeDeletedData;
      this.isDeleting = true;
      AudienceApi.methods
        .deletePrivateAudience(audienceEmail)
        .then((data) => {
          this.isDeleting = false;
          if (data.message === 'success') {
            const newItems = this.audiences.filter(
              (aud) => aud.id !== audienceEmail,
            );
            this.items = newItems;
            MatomoAnalyticsHandler.trackFingerprint({
              user: this.currentUser,
              actionName: FP_PROFILE_OTHER_DELETE,
              audience: {
                id: audienceEmail,
              },
            });
          }
          this.closeDelete();
          trackProfileEvents.profileFingerprintCreatedfingerprintsDelete(
            this.currentUser,
          );
        })
        .catch(() => {
          this.isDeleting = false;
          this.closeDelete();
        });
    },
    shareAudience(item) {
      this.$modal.show(
        ShareFingerPrintDetail,
        {
          entityID: item.uuid,
          shareData: item,
          share: this.onShareSuccess,
        },
        {
          name: 'ShareFingerPrintDetail',
          width: 700,
          height: 'auto',
          minHeight: 300,
          styles: { borderRadius: '20px' },
        },
      );
    },
    onShareSuccess(sharedEntities, entityID) {
      const audienceShared = this.items.find((aud) => aud.uuid === entityID);
      audienceShared.shared = sharedEntities && sharedEntities.length > 0;
      try {
        const types = sharedEntities.map((entity) => entity.type);
        trackProfileEvents.profileFingerprintShareUpdate(
          this.currentUser,
          TD_SHARE_FINGERPRINT_CREATED,
          {
            [TD_FINGERPRINT_SHARING_SETTINGS]: types
              ?.toString()
              .replaceAll(',', '_'),
            [TD_PLACEMENT]:
              shareFingerPrintVsPlacement[TD_SHARE_FINGERPRINT_CREATED],
          },
        );
      } catch (e) {
        console.log(e);
      }
    },
  },
  mounted() {
    this.loadingAudiences = true;
    AudienceApi.methods
      .getPrivateAudiences()
      .then((data) => {
        this.loadingAudiences = false;
        if (data.items) {
          this.items = data.items;
        }
      })
      .catch((e) => {
        console.log(e);
        this.loadingAudiences = false;
      });
  },
};
</script>

<style lang="scss" scoped>
.other-finger-prints {
  padding: 32px;
  padding-top: 10px;
  margin: 2px;

  .audiences {
    ::v-deep .audience-item {
      cursor: pointer;
      padding-left: 0px;

      .v-list-item__content {
        .v-list-item__title.text-name {
          color: $zen-blue-default;
        }
      }
    }
    .add-audience {
      color: white;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: normal;
    }
    .audience-status {
      color: $zen-blue-default;
      .status-icon {
        color: $zen-blue-default;
        font-size: 14px;
        margin-bottom: 2px;
      }
    }
    .audience-title-wrapper {
      min-width: 200px;
      ::v-deep .audience-title {
        max-width: calc(100% - 20px);
      }
    }

    .clickable {
      cursor: pointer;
    }

    ::v-deep header {
      .v-toolbar__content {
        padding-left: 0px;
      }
    }

    .retake-fingerprint--auto,
    .copy-fingerprint--auto {
      margin-right: 10px;
    }

    ::v-deep table {
      thead {
        tr {
          th {
            &:first-child {
              padding-left: 0px;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            &:first-child {
              padding-left: 0px;
            }
          }
        }
      }
    }
  }

  .other-finger-prints-title {
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 700;
    letter-spacing: normal;
    line-height: 24px;
    margin-bottom: 14px;
    text-align: start;
  }

  .other-fingerprints-toolbar {
    width: 450px;
    .other-fingerprints-search-input {
      background-color: #fff;
      border-radius: 24px;
      border: 1px solid transparent;
      box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
      padding: 8px 15px 8px 23px;
      z-index: 3;
      .v-input__slot::before {
        display: none;
      }
    }

    ::v-deep .item-search-input {
      margin-left: 0px;
    }
  }

  .v-data-table::v-deep th {
    color: $zen-blue-default !important;
    font-size: 16px !important;
    font-weight: normal;
    min-width: 150px;

    .v-data-table-header__icon {
      margin-top: -2px;
    }
  }
}

.delete-confirm-dialog {
  .headline {
    align-items: center;
    display: flex;
    font-size: 16px !important;
    font-stretch: normal !important;
    font-style: normal !important;
    font-weight: normal !important;
    justify-content: center;
    letter-spacing: normal !important;
    line-height: normal !important;
    padding: 32px 34px 17px 30px !important;
    word-break: break-word;
  }

  .v-card__actions {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding: 17px 30px 19px 30px;
  }

  .delete-cancel-btn {
    color: white;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    margin-right: 16px;
    text-align: center;
    text-transform: none;
  }
  .delete-confirm-btn {
    color: $zen-blue-default;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    text-align: center;
    text-transform: none;
  }
}
</style>
