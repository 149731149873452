<template>
  <div class="tutorial-item" @click="handleVideoClick(item)">
    <v-hover>
      <template v-slot:default="{ hover }">
        <div class="tutorial-item-img">
          <v-img
            contain
            loading="lazy"
            :src="item.imageUrl"
            alt
            height="266px"
          />
          <v-fade-transition>
            <v-overlay v-if="hover" absolute color="#616161">
              <v-icon class="play-btn" color="white">
                mdi-play-circle-outline</v-icon
              >
            </v-overlay>
          </v-fade-transition>
        </div>
      </template>
    </v-hover>
    <div class="item-info">
      <div class="item-info-title">
        {{ item.title }}
      </div>
      <v-tooltip
        top
        max-width="320"
        content-class="tooltip-content"
        fixed
        open-delay="500"
        close-delay="300"
      >
        <template v-slot:activator="{ on, attrs }">
          <div
            v-bind="attrs"
            v-on="on"
            class="item-info-desc"
            v-line-clamp:20="2"
          >
            {{ item.description }}
          </div>
        </template>
        <span>{{ item.description }}</span>
      </v-tooltip>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TutorialCard',
  props: {
    item: {
      type: Object,
    },
    tutorials: {
      type: Array,
    },
    handleVideoClick: {
      type: Function,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.tutorial-item {
  border-radius: 10px;
  box-shadow: $element-box-shadow-default;
  height: 422px;
  &:hover {
    box-shadow: $element-box-shadow-hover;
    cursor: pointer;
  }

  .tutorial-item-img {
    border-bottom: solid 1px #efefef;
    border-radius: 8px;
    cursor: pointer;
    position: relative;

    .play-btn {
      font-size: 54px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }

  .item-info {
    padding: 16px;

    .item-info-title {
      align-items: flex-start;
      color: #21a7e0;
      display: flex;
      font-size: 20px;
      font-weight: bold;
      line-height: 130%;
      margin-bottom: 16px;
      text-align: left;
    }

    .item-info-desc {
      align-items: center;
      font-size: 16px;
      line-height: 19px;
      word-break: break-word !important;
    }
  }
}
</style>
