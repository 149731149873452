var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wait-list-audience"},[_c('v-img',{staticClass:"not-registered",attrs:{"src":'/assets/img/audience/audience-not-registered.svg',"max-height":"400","max-width":"401","alt":""}}),_c('div',{staticClass:"not-registered-info"},[_c('div',{staticClass:"not-registered-info-detail"},[_vm._v(" "+_vm._s(_vm.$t('navbarActions.addColleagueDetail.thanksForIntroduction'))+" ")]),_c('div',{staticClass:"not-registered-info-detail"},[_vm._v(" "+_vm._s(_vm.$t('navbarActions.addColleagueDetail.addedToWaitlist'))+" ")])]),_c('div',{staticClass:"create-fingerprint"},[_c('div',{staticClass:"create-fingerprint-title"},[_vm._v(" "+_vm._s(_vm.$t('navbarActions.addColleagueDetail.onTheirBehalf'))+" ")]),_c('v-container',{staticClass:"px-0 py-0 pl-2",attrs:{"fluid":""}},[_c('v-radio-group',{staticClass:"pt-0",model:{value:(_vm.fingerprintType),callback:function ($$v) {_vm.fingerprintType=$$v},expression:"fingerprintType"}},[_c('v-radio',{attrs:{"value":"private","color":"#20a7e0"}},[_c('div',{attrs:{"slot":"label"},slot:"label"},[_c('span',{staticClass:"black--text option-description"},[_vm._v(_vm._s(_vm.$t( 'navbarActions.addColleagueDetail.completeaFingerprintAssessment' ))+" "),_c('v-tooltip',{attrs:{"top":"","max-width":"260","content-class":"tooltip-content","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"icon-title info-icon",domProps:{"textContent":_vm._s("mdi-information-outline")}},'v-icon',attrs,false),on))]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t( 'navbarActions.addColleagueDetail.completeaFingerprintAssessment' ))+" ")])])],1)])]),_c('v-radio',{attrs:{"value":"zenSense","color":"#20a7e0"}},[_c('span',{staticClass:"black--text option-description",attrs:{"slot":"label"},slot:"label"},[_vm._v(_vm._s(_vm.$t( 'navbarActions.addColleagueDetail.generateFingerprintUsingZensense' ))+" "),_c('v-tooltip',{attrs:{"top":"","max-width":"220","content-class":"tooltip-content","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"icon-title info-icon",domProps:{"textContent":_vm._s("mdi-information-outline")}},'v-icon',attrs,false),on))]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t( 'navbarActions.addColleagueDetail.generateFingerprintUsingZensense' ))+" ")])])],1)])],1)],1),_c('v-btn',{staticClass:"primary-button",attrs:{"rounded":"","color":"#21a7e0","height":"48","min-width":"134","loading":_vm.handleTakeAssessmentClicked},on:{"click":function () {
          _vm.handleTakeAssessmentClicked = true;
          _vm.handleTakeAssessment(_vm.fingerprintType === 'zenSense');
        }}},[_vm._v(" "+_vm._s(_vm.$t(_vm.takeAssessmentButtonText))+" ")])],1),_c('div',{staticClass:"add-new-audience"},[_c('div',{staticClass:"add-new-audience-title"},[_vm._v(" "+_vm._s(_vm.$t('navbarActions.addColleagueDetail.wouldYouLikeToAddAnother'))+" ")]),_c('v-btn',{staticClass:"secondary-button",attrs:{"rounded":"","outlined":"","color":"primary","height":"48","min-width":"134","loading":_vm.handleAddNewAudienceClicked},on:{"click":function () {
          _vm.handleAddNewAudienceClicked = true;
          _vm.handleAddNewAudience();
        }}},[_vm._v(" "+_vm._s(_vm.$t('fingerprint.searchPage.addNewAudience'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }