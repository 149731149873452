var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"slide-content",class:{ 'panel-open': _vm.rightPanelComponent }},[(_vm.getCurrentSuggestionView === 'deck')?_c('div',{staticClass:"marker-switch"},[_c('v-tooltip',{attrs:{"disabled":_vm.isActionLoading,"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-switch',{attrs:{"hide-details":"","dense":"","disabled":_vm.isActionLoading ||
              _vm.getCurrentSlideSuggestions.length === 0 ||
              _vm.getCurrentSuggestionView === 'deck'},on:{"change":_vm.onToggle},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',{class:{ 'is-disabled': _vm.isActionLoading }},[_vm._v("Show markers")])]},proxy:true}],null,true),model:{value:(_vm.showMarkers),callback:function ($$v) {_vm.showMarkers=$$v},expression:"showMarkers"}})],1)]}}],null,false,4277066497)},[_c('span',[_vm._v("Switch to the Current Slide tab in Suggestions pane to display markers")])])],1):_c('div',{staticClass:"marker-switch"},[_c('v-switch',{attrs:{"label":"Show markers","hide-details":"","dense":"","disabled":_vm.isActionLoading || _vm.getCurrentSlideSuggestions.length === 0},on:{"change":_vm.onToggle},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('label',{class:{ 'is-disabled': _vm.isActionLoading }},[_vm._v("Show markers")])]},proxy:true}]),model:{value:(_vm.showMarkers),callback:function ($$v) {_vm.showMarkers=$$v},expression:"showMarkers"}})],1),_c('div',{staticClass:"landscape-slide"},[(!_vm.tcLoader.isIdle())?_c('VideoLoader',{model:{value:(_vm.tcLoader),callback:function ($$v) {_vm.tcLoader=$$v},expression:"tcLoader"}}):_c('v-img',{ref:"selectedImageRef",staticClass:"landscape-image",attrs:{"aspect-ratio":16 / 9,"src":_vm.landscapeImage,"max-width":"100%","alt":"image","contain":"","loading":"lazy","lazy-src":'/assets/img/slides/placeholder-slide.svg'}},[(_vm.getMarkers.length && _vm.showMarkers)?_c('div',_vm._l((_vm.getMarkers),function(marker){return _c('div',{key:((marker.index) + "-" + (marker.avatarColor)),staticClass:"marker",class:{
            selected:
              _vm.selectedSuggestion &&
              _vm.selectedSuggestion.index === marker.index &&
              _vm.selectedSuggestion.categoryIndex === marker.categoryIndex,
          },style:(("left: " + (marker.x) + "%; top:" + (marker.y) + "%; position: absolute; background-color:" + (marker.avatarColor))),on:{"click":function($event){$event.stopPropagation();return _vm.handleMarkerClick(marker)}}},[(marker.status === 'accepted')?_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-check ")]):(marker.status === 'rejected')?_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-close ")]):[_vm._v(_vm._s(marker.index))]],2)}),0):_vm._e()])],1),_vm._m(0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"comply-privacy"},[_c('div',{staticClass:"comply-privacy__sub"},[_vm._v(" Prezent is powered by ASTRIDᵀᴹ AI, so mistakes are possible. Please double-check the results. ")]),_c('div',[_vm._v(" We take confidentiality very seriously and we’ll keep your information secure. "),_c('a',{attrs:{"href":"https://www.prezent.ai/security","target":"_blank"}},[_vm._v("Learn more")])])])}]

export { render, staticRenderFns }