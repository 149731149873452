var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"picker-color-wrapper"},[_c('div',{staticClass:"back-btn"},[_c('v-btn',{on:{"click":_vm.handleBack}},[_c('v-icon',{attrs:{"size":"22"}},[_vm._v(_vm._s('mdi-chevron-left'))]),_vm._v(" Back ")],1)],1),_c('div',{staticClass:"main-title"},[_vm._v("Color Suggestion")]),_c('div',{staticClass:"header-wrapper"},[_c('div',[_c('v-avatar',{staticClass:"mr-2",attrs:{"max-width":24,"max-height":24,"min-height":24,"min-width":24,"color":_vm.colorPickerState.suggestion.avatarColor}},[_c('span',{staticClass:"item-id"},[_vm._v(_vm._s(_vm.colorPickerState.suggestion.mappingIndex + 1))])]),_c('span',{staticClass:"item-name"},[_vm._v(_vm._s(_vm.getSuggestionDisplayName(_vm.colorPickerState.suggestion))+" ")])],1),_c('div',{staticClass:"suggestion-details__actions"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":!_vm.finalSuggestedColor,"icon":"","data-pendo-id":"comply-accept-color-change"},on:{"click":_vm.handleAccept}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#00925a"}},[_vm._v("mdi-check")])],1)]}}])},[_c('span',[_vm._v("Accept")])]),(!_vm.colorPickerState.pickingAfterReview)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":!_vm.finalSuggestedColor,"icon":""},on:{"click":_vm.handleReject}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#d32f2f"}},[_vm._v("mdi-close")])],1)]}}],null,false,732885605)},[_c('span',[_vm._v("Reject")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary","disabled":_vm.colorPickerState.pickingAfterReview &&
              !_vm.colorPickerState.selectedColor},on:{"click":_vm.resetColor}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-restore")])],1)]}}])},[_c('span',[_vm._v("Revert to suggested")])])],1)]),_c('div',{staticClass:"color-fill-suggestion"},[_c('div',{staticClass:"block"},[_c('div',{staticClass:"block__wrapper"},[_c('span',{staticClass:"block__text"},[_vm._v(_vm._s(_vm.suggestionTextLeft))]),_c('div',{staticClass:"color-and-details"},[_c('PreviewShape',{attrs:{"color":'#' + _vm.currentColor,"suggestionName":_vm.colorPickerState.suggestion.name,"colorDisplayName":_vm.colorPickerState.suggestion.display_name &&
              !_vm.colorPickerState.pickingAfterReview
                ? _vm.colorPickerState.suggestion.display_name
                : ''}}),(
              _vm.suggestion.display_name && !_vm.colorPickerState.pickingAfterReview
            )?_c('div',{staticClass:"d-flex",staticStyle:{"width":"180px"}},[_c('div',{staticClass:"color-details-with-display-name"},[_c('span',[_vm._v(_vm._s(_vm.getThemeColorDisplayName))])])]):_vm._e()],1)])]),_c('div',{staticClass:"arrow"},[_c('v-icon',[_vm._v("mdi-arrow-right")])],1),_c('div',{staticClass:"block",style:(_vm.finalSuggestedColor ? {} : { 'margin-bottom': '20px !important' })},[_c('div',{staticClass:"block__wrapper"},[_c('span',{staticClass:"block__text"},[_vm._v(_vm._s(_vm.suggestionTextRight))]),_c('div',{staticClass:"color-and-details"},[_c('PreviewShape',{attrs:{"color":_vm.finalSuggestedColor ? ("#" + _vm.finalSuggestedColor) : '',"suggestionName":_vm.colorPickerState.suggestion.name,"suggestion":_vm.suggestion,"colorDisplayName":_vm.colorPickerState.selectedColor
                ? ''
                : _vm.colorPickerState.suggestion.display_name}})],1)])]),(
        _vm.colorPickerState.selectedColor && !_vm.colorPickerState.pickingAfterReview
      )?_c('div',{staticClass:"block"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"reset-icon",attrs:{"icon":"","color":"primary"},on:{"click":_vm.resetColor}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"28"}},[_vm._v("mdi-restore")])],1)]}}],null,false,2882737591)},[_c('span',[_vm._v("Revert to suggested")])])],1):_vm._e()]),_c('div',{staticClass:"tints-wrapper"},[_c('p',{staticClass:"choose-color"},[_vm._v("Choose new color")]),_c('p',{staticClass:"select-color"},[_vm._v("Select a color from the brand palette")]),_vm._l((_vm.colorsWithTints),function(color,index){return _c('div',{key:_vm.origin === 'deck' ? index : color.name + index,staticClass:"color-row"},[(_vm.origin === 'deck')?_c('div',{staticClass:"color-description"},[_c('span',{staticClass:"color-name"},[_vm._v(" "+_vm._s(color.name ? _vm.getSchemeColorName(color.name) : '')+" ("+_vm._s(("#" + (color.hex.substring(3).toUpperCase())))+")")])]):_c('div',{staticClass:"color-description"},[_c('span',{staticClass:"color-name"},[_vm._v(" "+_vm._s(color.name ? _vm.getSchemeColorName(color.name) : '')+" ("+_vm._s(("#" + (color.hex.substring(3).toUpperCase())))+")")])]),_c('div',{staticClass:"color-boxes",attrs:{"data-pendo-id":index == 0 ? 'comply-choose-color' : ''}},[_c('div',{staticClass:"color-box-main",class:{
            'active-color':
              _vm.finalSuggestedColor === ("" + (color.hex.substring(3))),
          },style:({
            backgroundColor: ("#" + (color.hex.substring(3))),
            border: _vm.borderStyle(),
            boxShadow: _vm.boxShadowStyle,
          }),on:{"click":function($event){_vm.onColorSelected(("#" + (color.hex.substring(3))))}}}),_c('div',{staticClass:"tints-box-wrapper",style:({ border: '1.5px solid #c6c6c6' })},_vm._l((color.tints),function(tint,tintIndex){return _c('v-tooltip',{key:tint + tintIndex,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"tint-box",class:{
                  'active-color': _vm.finalSuggestedColor === tint.substring(1),
                },style:({
                  backgroundColor: tint,
                  boxShadow: _vm.boxShadowStyle,
                }),on:{"click":function($event){return _vm.onColorSelected(tint)}}},'div',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(tint.toUpperCase())+" ")])])}),1)])])})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }