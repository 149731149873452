var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      _vm.getReplaceImageSuggestionState &&
      _vm.getReplaceImageSuggestionState.panelOpen
    )?_c('div',{staticClass:"brandImagesLib"},[_c('ReplaceComplyImages',{attrs:{"handleAcceptFromBrandImageLib":_vm.handleAcceptFromBrandImageLib,"handleRejectFromBrandImageLib":_vm.handleRejectFromBrandImageLib,"origin":'comply'}})],1):_vm._e(),(
      _vm.getReplaceIconSuggestionState && _vm.getReplaceIconSuggestionState.panelOpen
    )?_c('div',{staticClass:"brandImagesLib"},[_c('ReplaceComplyIcons',{attrs:{"handleAcceptFromBrandImageLib":_vm.handleAcceptFromBrandIconLib,"handleRejectFromBrandImageLib":_vm.handleRejectFromBrandIconLib,"origin":'comply'}})],1):_vm._e(),(
      (!_vm.getDeckFullComplianceInfo &&
        !_vm.colorPickerState.panelOpen &&
        !_vm.getReplaceImageSuggestionState.panelOpen &&
        !_vm.getReplaceIconSuggestionState.panelOpen) ||
      (_vm.reviewClicked &&
        !_vm.colorPickerState.panelOpen &&
        !_vm.getReplaceImageSuggestionState.panelOpen &&
        !_vm.getReplaceIconSuggestionState.panelOpen)
    )?_c('div',{staticClass:"compliance-score-box"},[_c('v-row',{staticClass:"slide-compliance-score-box"},[_c('v-col',{staticClass:"score-text-col",attrs:{"cols":"8"}},[_c('span',{staticClass:"score-text",attrs:{"data-pendo-id":"comply-deck-compliance-score"}},[_vm._v("Brand Compliance Score")]),_c('span',{staticClass:"score-percentage"},[_vm._v(_vm._s(_vm.complianceScore)+"%")])]),_c('v-col',{staticClass:"progress-col",attrs:{"cols":"4"}},[_c('v-progress-linear',{attrs:{"value":_vm.complianceScore,"color":_vm.complianceColor,"height":"7"}})],1)],1)],1):_vm._e(),(_vm.colorPickerState && _vm.colorPickerState.panelOpen)?_c('div',[_c('ComplyColorPicker',{attrs:{"origin":'deck',"suggestion":_vm.colorPickerState.suggestion},on:{"close":_vm.closeColorPicker,"accept":_vm.onSuggestionAcceptFromPicker,"reject":_vm.onSuggestionRejectFromPicker}})],1):_vm._e(),_c('div',[(
        (!_vm.getDeckFullComplianceInfo &&
          !_vm.colorPickerState.panelOpen &&
          !_vm.getReplaceImageSuggestionState.panelOpen &&
          !_vm.getReplaceIconSuggestionState.panelOpen) ||
        (_vm.reviewClicked &&
          !_vm.colorPickerState.panelOpen &&
          !_vm.getReplaceImageSuggestionState.panelOpen &&
          !_vm.getReplaceIconSuggestionState.panelOpen)
      )?_c('v-row',{staticClass:"expansionPanelsSuggestions"},[_c('v-col',[_c('v-expansion-panels',{model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},_vm._l((_vm.getSuggestionsList),function(category,i){return _c('v-expansion-panel',{key:i,staticClass:"suggestions__panel",attrs:{"disabled":_vm.disableSuggestionsDropdown(category) ||
              (!_vm.allowReplaceImageOverall && category.key === 'images')},on:{"click":function($event){return _vm.selectCategory(category)}}},[_c('v-expansion-panel-header',[_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"suggestion-details"},[_c('div',[_c('v-avatar',{attrs:{"color":category.avatarColor,"size":16}}),_c('span',{staticClass:"suggestion-details__item-text"},[_vm._v(_vm._s(category.text))]),_c('v-icon',{attrs:{"color":'#D9D9D9'}},[_vm._v("mdi-circle-small")]),_c('span',{staticClass:"suggestion-details__total"},[_vm._v(" "+_vm._s(_vm.getSuggestionsCountText(category))+" ")])],1),(
                      category.key !== 'template' &&
                      !_vm.isSuggestionsResolved(category)
                    )?_c('div',{staticClass:"suggestion-details__actions",staticStyle:{"margin-left":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.onCategoryAccept(category)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#00925a"}},[_vm._v("mdi-check")])],1)]}}],null,true)},[_c('span',[_vm._v("Accept")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.onCategoryReject(category)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#d32f2f"}},[_vm._v("mdi-close")])],1)]}}],null,true)},[_c('span',[_vm._v("Reject")])])],1):_vm._e()]),(
                    !(
                      _vm.isReplaceBrandImageEnabled && _vm.allowReplaceImageOverall
                    ) && category.key === 'images'
                  )?_c('div',{staticClass:"suggestion-details__description"},[_vm._v(" No brand images available to check compliance. "),_c('a',{staticClass:"text-link",on:{"click":_vm.onGetInTouchclick}},[_vm._v(" Get in touch ")]),_vm._v(" to add brand images. ")]):(
                    _vm.isReplaceBrandIconsEnabled &&
                    _vm.brandIconsListInLibrary.length === 0 &&
                    category.key === 'icons' &&
                    !_vm.getDeckFullComplianceInfo
                  )?_c('div',{staticClass:"suggestion-details__description"},[_vm._v(" No brand icons available to check compliance. "),_c('a',{staticClass:"text-link",on:{"click":_vm.onGetInTouchclickIcons}},[_vm._v(" Get in touch ")]),_vm._v(" to add brand icons. ")]):_c('div',{staticClass:"suggestion-details__description"},[_vm._v(" "+_vm._s(_vm.getSuggestionDescription(category))+" ")])])]),_c('v-expansion-panel-content',{key:category.text,staticClass:"expansion-panel-content"},[_c('v-list',_vm._l((category.suggestions),function(suggestion,index){return _c('v-list-group',{key:suggestion.name + index,attrs:{"no-action":"","value":_vm.subPanel[category.key] == suggestion.index},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item',{staticClass:"suggestions-box",on:{"click":function($event){$event.stopPropagation();return _vm.selectSuggestion(suggestion)}}},[_c('v-list-item-content',{staticClass:"suggestions__item-header",attrs:{"append-icon":"mdi-check"}},[_c('div',{staticClass:"suggestions__header-wrapper"},[_c('v-avatar',{staticClass:"mr-2",attrs:{"max-width":24,"max-height":24,"min-height":24,"min-width":24,"color":category.avatarColor,"data-pendo-id":index === 0
                                ? 'comply-suggestion-item-bubble'
                                : null}},[(suggestion.category === 'template')?_c('span',[_c('v-icon',{attrs:{"color":'white'}},[_vm._v("mdi-check")])],1):_c('span',{staticClass:"suggestions__item-id"},[_vm._v(" "+_vm._s(index + 1)+" ")])]),_c('span',{staticClass:"suggestions__item-name"},[_vm._v(" "+_vm._s(_vm.getSuggestionDisplayName(suggestion))+" ")]),(suggestion.status)?_c('div',[_c('v-icon',{attrs:{"color":'#D9D9D9'}},[_vm._v(" mdi-circle-small ")]),_c('span',{staticClass:"suggestion-details__total"},[_vm._v(" "+_vm._s(suggestion.status === 'accepted' ? 'Accepted' : 'Rejected')+" ")])],1):_vm._e(),(suggestion.category === 'template')?_c('div',[_c('v-icon',{attrs:{"color":'#D9D9D9'}},[_vm._v(" mdi-circle-small ")]),_c('span',{staticClass:"suggestion-details__total"},[_vm._v(" "+_vm._s('Accepted')+" ")])],1):_vm._e()],1)]),(!suggestion.status)?_c('v-list-item-icon',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [(suggestion.category !== 'template')?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","data-pendo-id":"comply-suggestion-accept"},on:{"click":function($event){return _vm.onSuggestionAcceptClick(suggestion)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#00925a"}},[_vm._v("mdi-check")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Accept")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [(suggestion.category !== 'template')?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.onSuggestionRejectClick(suggestion)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"#d32f2f"}},[_vm._v("mdi-close")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Reject")])])],1):_vm._e(),(_vm.isEditEnableAfterAction(suggestion))?_c('v-list-item-icon',[_c('v-tooltip',{attrs:{"bottom":"","max-width":"250","content-class":"tooltip-content","fixed":"","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","data-pendo-id":!_vm.isActionLoading
                                  ? 'comply-edit-color-pencil'
                                  : ''},on:{"click":function($event){return _vm.toggleColorPicker(suggestion)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary","size":"24"}},[_vm._v("mdi-eyedropper-variant")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s('Choose new color'))])])],1):_vm._e()],1)]},proxy:true}],null,true)},[_c(_vm.getComponentName(suggestion.type || suggestion.category),{tag:"component",attrs:{"type":suggestion.type,"suggestion":suggestion,"category":category,"itemIndex":index}})],1)}),1)],1)],1)}),1)],1)],1):_vm._e(),(_vm.getDeckFullComplianceInfo && !_vm.reviewClicked)?_c('v-row',[_c('v-col',[_c('div',{staticClass:"complied-message"},[_c('v-img',{attrs:{"max-height":"45%","src":"/assets/img/comply/brand-compliance-achieved.svg"}}),_c('div',{staticClass:"complied-message__wrapper"},[_c('div',[_vm._v(" Congratulations! Your prezentation is "),_c('span',{staticClass:"score-percentage"},[_vm._v(" 100% ")]),_vm._v(" compliant with brand guidelines for your selected template. ")])]),_c('div',{staticClass:"complied-message__actions"},[_c('v-btn',{staticClass:"app-button mr-2 download-btn",attrs:{"rounded":"","color":"primary"},on:{"click":_vm.onDownload}},[_vm._v(" Download ")]),_c('div',{staticClass:"d-flex flex-row justify-space-between align-center"},[_c('span',[_vm._v("Need customization?")]),_c('v-btn',{staticClass:"app-button mr-2 pl-1",attrs:{"text":"","rounded":"","color":"primary","name--auto":"review_details_deck_details_tab","data-pendo-id":"comply-deck-review-details"},on:{"click":_vm.onReviewDetailsClick}},[_vm._v(" Review details ")])],1)],1)],1)])],1):_vm._e()],1),(
      !_vm.getDeckFullComplianceInfo &&
      !_vm.colorPickerState.panelOpen &&
      !_vm.getReplaceImageSuggestionState.panelOpen &&
      !_vm.getReplaceIconSuggestionState.panelOpen
    )?_c('v-row',[_c('v-col',{staticClass:"d-flex align-baseline"},[_c('div',{staticClass:"buttons-content mb-5"},[_c('v-btn',{staticClass:"app-button",attrs:{"rounded":"","color":"primary","data-pendo-id":"comply-make-hundred-compliant-button"},on:{"click":_vm.applyFullDeckCompliance}},[_vm._v("Accept all suggestions")])],1)])],1):_vm._e(),(_vm.showSuggestionAcceptRejectPopUp)?_c('ConfirmationModal',{attrs:{"dataPendoId":"popup-confirm-slide-changes","show":_vm.showSuggestionAcceptRejectPopUp,"title":_vm.acceptClick ? 'Accept Suggestion(s)' : 'Reject Suggestion(s)',"text":_vm.acceptClick
        ? 'Changes will be applied to all the slides. Do you want to proceed?'
        : 'Changes will be rejected on all the slides. Do you want to proceed?',"primaryBtnText":'Cancel',"secondaryBtnText":'Proceed',"onPrimaryClick":function () { return _vm.onPopUpClose(); },"onSecondaryClick":function () { return _vm.onProceedClick(); },"onClose":function () { return _vm.onPopUpClose(); }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }