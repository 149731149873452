<template>
  <div class="about-prezentation-container">
    <div class="heading">
      {{ $t('generate.aboutYourPrezentation') }}
      <div class="sub">{{ $t('build.step3.poweredByZenSence') }}</div>
    </div>
    <div class="textbox-label mb-4">
      {{ $t('generate.changeSlideCountToRegenerate') }}
    </div>

    <div class="position-container">
      <img :src="'/assets/img/generate/slide-count.svg'" alt="slide-pos-icon" />
      <div class="position-content">
        <div class="textbox-label">{{ $t('generate.slideCount') }}:</div>
        <v-autocomplete
          class="position-input"
          dense
          :items="[2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]"
          v-model="count"
          hide-details
          @input="handleChange('Slide count')"
        ></v-autocomplete>
      </div>
    </div>

    <v-btn
      elevation="2"
      rounded
      color="primary"
      class="save-btn"
      :disabled="!count"
      @click="regenerate('count')"
    >
      {{ $t('generate.regenerate') }}
    </v-btn>
    <div class="position-container">
      <img
        :src="'/assets/img/generate/prezentation-duration.svg'"
        alt="slide-pos-icon"
      />
      <div class="position-content">
        <div class="textbox-label">{{ $t('generate.meetingDuration') }}:</div>
        <v-autocomplete
          class="position-input"
          dense
          :items="durationItem"
          item-text="label"
          item-value="value"
          hide-details
          v-model="slideDuration"
          @input="handleChange('Meeting duration')"
        ></v-autocomplete>
      </div>
    </div>

    <v-btn
      elevation="2"
      rounded
      color="primary"
      class="save-btn"
      :disabled="!slideDuration"
      @click="regenerate('duration')"
    >
      {{ $t('generate.regenerate') }}
    </v-btn>

    <div class="description-header">
      <img
        :src="'/assets/img/generate/prez_description.svg'"
        alt="slide-pos-icon"
      />
      <div class="textbox-label">{{ $t('build.step3.description') }}</div>
    </div>

    <div class="textarea-prompt-container">
      <v-textarea
        v-model="desc"
        class="textarea-prompt"
        solo
        placeholder="Example: Describe CRM, add CRM key features"
        :rules="[...maxLimit]"
      >
      </v-textarea>
      <div class="clear-button-cont">
        <v-btn
          class="clear-button"
          text
          rounded
          v-if="desc"
          color="#20a7e0"
          width="100"
          height="30"
          @click="() => (this.desc = '')"
          >{{ $t('common.clear') }}</v-btn
        >
      </div>
    </div>
    <v-btn
      elevation="2"
      rounded
      color="primary"
      class="save-btn"
      @click="saveDescription"
      :disabled="
        desc.trim().length === 0 || desc.length > 500 || description === desc
      "
    >
      {{ $t('common.save') }}
    </v-btn>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { trackGenerateEvents } from '../../common/Analytics/GenerateEvents';
import {
  TD_PLACEMENT,
  TD_ABOVE_THE_GENERATED_SLIDE,
  TD_SMART_TOOLS,
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  TD_FEILD,
  TD_FEILD_CHANGED,
} from '../../common/Analytics/MatomoTrackingDataHelper';

export default {
  name: 'AboutPrezentation',
  components: {},
  props: {
    slideCount: {
      type: Number,
      default: 2,
    },
    description: {
      type: String,
      default: '',
    },
    isFromTopPanel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      count: 2,
      desc: '',
      slideDuration: 15,
      maxLimit: [
        (v) => (v || '').length <= 500 || this.$t('generate.cannotExceed500'),
      ],
      durationItem: [
        { label: '15 min', value: 15 },
        { label: '30 min', value: 30 },
        { label: '45 min', value: 45 },
        { label: '1 hour', value: 60 },
        { label: '1 hour 15 min', value: 75 },
        { label: '1 hour 30 min', value: 90 },
      ],
    };
  },
  watch: {},
  computed: {
    ...mapState('users', ['currentUser']),
  },
  methods: {
    saveDescription() {
      console.log('save');
      this.$emit('changeDescription', this.desc);
      const otherData = {
        [TD_PLACEMENT]: this.isFromTopPanel
          ? TD_ABOVE_THE_GENERATED_SLIDE
          : TD_SMART_TOOLS,
      };
      trackGenerateEvents.generateEditSlideCountDescriptionSave(
        this.currentUser,
        otherData,
      );
    },
    regenerate(type) {
      let count = 2;
      if (type === 'count') {
        count = this.count;
        const otherData = {
          [TD_PLACEMENT]: this.isFromTopPanel
            ? TD_ABOVE_THE_GENERATED_SLIDE
            : TD_SMART_TOOLS,
          [TD_COMMON_COLUMN_NAME]: TD_FEILD,
          [TD_COMMON_COLUMN_VALUE]: 'Slide count',
        };
        trackGenerateEvents.generateEditSlideCountRegenerate(
          this.currentUser,
          otherData,
        );
      } else {
        count = Math.min(this.slideDuration / 3, 15);
        const otherData = {
          [TD_PLACEMENT]: this.isFromTopPanel
            ? TD_ABOVE_THE_GENERATED_SLIDE
            : TD_SMART_TOOLS,
          [TD_COMMON_COLUMN_NAME]: TD_FEILD,
          [TD_COMMON_COLUMN_VALUE]: 'Meeting duration',
        };
        trackGenerateEvents.generateEditSlideCountRegenerate(
          this.currentUser,
          otherData,
        );
      }
      console.log('regenerate', count);
      this.$emit('change', {
        type: 'duration_change',
        noOfSlides: count,
      });
    },
    handleChange(commonColumnValue) {
      const otherData = {
        [TD_PLACEMENT]: this.isFromTopPanel
          ? TD_ABOVE_THE_GENERATED_SLIDE
          : TD_SMART_TOOLS,
        [TD_COMMON_COLUMN_NAME]: TD_FEILD_CHANGED,
        [TD_COMMON_COLUMN_VALUE]: commonColumnValue,
      };
      trackGenerateEvents.generateEditSlideCountFieldChanged(
        this.currentUser,
        otherData,
      );
    },
  },
  mounted() {
    this.count = this.slideCount <= 15 ? this.slideCount : null;
    this.desc = this.description;
    if (this.slideCount <= 5) {
      this.slideDuration = 15;
    } else if (this.slideCount > 15) {
      this.slideDuration = null;
    } else if (this.slideCount > 10) {
      this.slideDuration = 45;
    } else {
      this.slideDuration = 30;
    }
  },
};
</script>
<style scoped lang="scss">
.about-prezentation-container {
  padding: 20px;

  .heading {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 24px;

    .sub {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.02em;
      color: #697077;
      margin-top: 4px;
    }
  }

  .sub-heading {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 8px;
    color: #000000;
  }

  .prompt-text {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #212121;
    margin-bottom: 36px;
  }

  .textbox-label {
    font-family: 'Lato';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
  }

  .position-container {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    gap: 16px;
    margin-bottom: 16px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 2px;
    border-radius: 8px;

    .position-content {
      display: flex;
      align-items: baseline;
      gap: 16px;
    }

    .position-input {
      width: 100px;
    }

    img {
      width: 66px;
      height: 60px;
    }
  }
  .textarea-prompt-container {
    position: relative;
    // .clear-button {
    //   position: absolute;
    //   bottom: 10px;
    //   left: 0px;
    //   z-index: 1000;
    //   text-transform: none;
    // }

    .clear-button-cont {
      height: 33px;
      width: 98%;
      background-color: white;
      border-radius: 0px 0px 16px 16px;
      position: absolute;
      bottom: 25px;
      left: 0px;
      z-index: 3;
      .clear-button {
        text-transform: none;
      }
    }
    .textarea-prompt {
      width: 100%;
      border-radius: 16px;
      ::v-deep .v-input__slot {
        border-radius: 4px;
        box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.05),
          -2px -2px 4px 0px rgba(0, 0, 0, 0.05);

        textarea {
          padding-bottom: 40px;
          height: 210px;
          line-height: normal;
        }
      }
      ::v-deep .v-text-field__details {
        margin-top: 8px;
        margin-bottom: 0;
      }
    }
  }

  .description-header {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-left: 16px;
    margin-bottom: 16px;
  }

  .checkbox-container {
    display: flex;
    gap: 35px;
  }

  .save-btn {
    margin-top: 25px;
    margin-bottom: 25px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 16px;
    gap: 8px;
    height: 32px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    text-transform: capitalize;
    letter-spacing: normal;
    line-height: 19px;

    /* Color Styles (Old)/White */

    color: #ffffff;

    background: #21a7e0;
    border-radius: 25px;
  }
}
</style>
