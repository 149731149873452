var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"template-select-wrapper"},[_c('div',{staticClass:"heading-block"},[_c('div',{staticClass:"heading-block__main"},[_vm._v(_vm._s(_vm.title))]),(_vm.showAstridTitle)?_c('div',{staticClass:"heading-block__sub"},[_vm._v(" Powered by ASTRID™ AI ")]):_vm._e(),_c('p',{staticClass:"suggest-block__summary-text",style:({
        marginTop: _vm.templateRequestFrom === 'Comply' ? '10px' : '5px',
      })},[_vm._v(" Select the template for your "+_vm._s(this.origin !== 'slides' ? 'prezentation' : 'slide')+" ")])]),_c('div',{staticClass:"search-block"},[_c('v-text-field',{attrs:{"solo":"","rounded":"","label":"Search for templates"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-icon',{attrs:{"color":"primary"},domProps:{"textContent":_vm._s(_vm.templateSearch ? "mdi-close" : "mdi-magnify")},on:{"click":function($event){_vm.templateSearch = ''}}})]},proxy:true}]),model:{value:(_vm.templateSearch),callback:function ($$v) {_vm.templateSearch=$$v},expression:"templateSearch"}})],1),_c('div',{staticClass:"tmpLists"},[(_vm.sortedRecomendedTemplatesList().length > 0)?_c('h3',[_vm._v(" Recommended company templates ")]):_vm._e(),_c('v-row',_vm._l((_vm.sortedRecomendedTemplatesList()),function(template,index){return _c('v-col',{key:index,attrs:{"cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var hover = ref.hover;
return [_c('div',{class:{
              'template-block': !_vm.isTemplateInitial(template),
              'template-block current-select-bg': _vm.isTemplateInitial(template),
              'template-block template-hover': hover,
            },on:{"click":function($event){return _vm.onNewTemplateSelect(template)}}},[_c('div',{staticClass:"title-block"},[_c('p',{staticClass:"title-block__title"},[_vm._v(" "+_vm._s(template.name)+" ")]),(_vm.isTemplateInitial(template))?_c('p',{staticClass:"title-block__current-select"},[_vm._v(" Current Selection ")]):_vm._e()]),_c('v-row',[_c('v-col',{staticClass:"pa-5",attrs:{"cols":"6"}},[_c('v-img',{attrs:{"loading":"lazy","lazy-src":'/assets/img/comply/template1.png',"aspect-ratio":16 / 9,"src":template.thumbnailURL,"contain":"","alt":""}})],1),_c('v-col',{staticClass:"pa-5",attrs:{"cols":"6"}},[_c('v-img',{attrs:{"loading":"lazy","lazy-src":'/assets/img/comply/template1.png',"aspect-ratio":16 / 9,"src":template.sampleSlideURL
                      ? template.sampleSlideURL
                      : template.thumbnailURL,"alt":"","contain":""}})],1)],1)],1)]}}],null,true)})],1)}),1),(_vm.sortedOtherTemplatesList().length > 0)?_c('h3',[_vm._v(" Other company templates ")]):_vm._e(),_c('v-row',_vm._l((_vm.sortedOtherTemplatesList()),function(template,index){return _c('v-col',{key:index,staticStyle:{"position":"relative"},attrs:{"cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var hover = ref.hover;
return [_c('v-tooltip',{attrs:{"top":"","attach":"","absolute":"","max-width":"200","disabled":!template.disabled,"content-class":'center-tooltip'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
return [_c('div',_vm._g({class:{
                  'template-block': !_vm.isTemplateInitial(template),
                  'template-block current-select-bg':
                    _vm.isTemplateInitial(template),
                  'template-block template-hover': hover,
                  'template-block disabled-template': template.disabled,
                },on:{"click":function($event){return _vm.onNewTemplateSelect(template)}}},on),[_c('div',{staticClass:"title-block"},[_c('p',{staticClass:"title-block__title"},[_vm._v(" "+_vm._s(template.name)+" ")]),(_vm.isTemplateInitial(template))?_c('p',{staticClass:"title-block__current-select"},[_vm._v(" Current Selection ")]):_vm._e()]),_c('v-row',[_c('v-col',{staticClass:"pa-5",attrs:{"cols":"6"}},[_c('v-img',{attrs:{"loading":"lazy","lazy-src":'/assets/img/comply/template1.png',"aspect-ratio":16 / 9,"src":template.thumbnailURL,"contain":"","alt":""}})],1),_c('v-col',{staticClass:"pa-5",attrs:{"cols":"6"}},[_c('v-img',{attrs:{"loading":"lazy","lazy-src":'/assets/img/comply/template1.png',"aspect-ratio":16 / 9,"src":template.sampleSlide
                          ? template.sampleSlideURL
                          : template.thumbnailURL,"alt":"","contain":""}})],1)],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('common.comingSoon')))])])]}}],null,true)})],1)}),1),(_vm.noResults)?_c('div',{staticClass:"not-found-container"},[_c('img',{attrs:{"src":"/assets/img/no-results-found-templates.svg","height":"250","width":"250","alt":""}}),_c('div',{staticClass:"heading mb-1 mt-6"},[_vm._v(" "+_vm._s(_vm.$t('build.step3.noTemplatesFound'))+" ")]),_c('v-btn',{staticClass:"primary-button",attrs:{"center":"","height":"35","width":"160","rounded":"","filled":"","color":"primary"},on:{"click":_vm.handleAddMore}},[_vm._v(" "+_vm._s(_vm.$t('build.step3.addTemplates'))+" ")])],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }