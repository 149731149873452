<template>
  <div style="display: flex">
    <svg
      style="margin: 0 auto"
      width="264"
      height="214"
      viewBox="0 0 264 214"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M173.466 119.384C167.885 122.711 157.941 126.879 147.78 122.595L141.211 119.826L154.374 105.162C132.609 81.3124 132.647 44.9296 154.461 21.1242C176.275 -2.68129 212.721 -6.11224 238.644 13.1992C264.568 32.5107 271.478 68.2394 254.606 95.7264C237.734 123.213 202.606 133.456 173.466 119.384V119.384ZM148.245 63.0915C148.187 77.5726 154.217 91.4199 164.879 101.29L168.51 104.681L160.227 113.909C164.541 113.039 168.299 110.719 170.204 109.372L172.819 107.523L175.631 109.059C183.403 113.288 192.122 115.504 200.984 115.504C230.063 115.504 253.721 91.9917 253.721 63.0915C253.721 34.1915 230.063 10.6795 200.984 10.6795C171.904 10.6795 148.245 34.1915 148.245 63.0915V63.0915Z"
        fill="#21A7E0"
      />
      <path
        d="M143.475 61.6514C143.475 29.7002 169.376 3.79858 201.327 3.79858C233.279 3.79858 259.18 29.7002 259.18 61.6514C259.18 93.6026 233.279 119.504 201.327 119.504C191.603 119.518 182.034 117.07 173.511 112.387C169.512 115.233 159.756 120.908 150.119 116.82L161.72 103.815C150.063 92.893 143.456 77.6259 143.475 61.6514Z"
        fill="#21A7E0"
        fill-opacity="0.4"
      />
      <path
        opacity="0.1"
        d="M243.555 62.2463C243.555 31.9658 225.484 11.8574 195.929 9.26396C197.614 9.11609 197.321 9.26396 199.044 9.26396C230.995 9.26396 253.782 30.2393 253.782 62.2463C253.782 94.2533 232.964 111.05 201.013 111.05C199.292 111.05 210.271 114.247 208.588 114.1C238.14 111.502 243.555 92.5241 243.555 62.2463Z"
        fill="black"
      />
      <path
        d="M68.2987 199.727C38.5863 199.741 12.9216 178.822 6.76843 149.575C0.61531 120.329 15.6543 90.741 42.8239 78.6404C69.9935 66.5398 101.873 75.2311 119.254 99.4774C136.636 123.724 134.771 156.903 114.784 179.023L127.912 193.83L121.361 196.626C111.227 200.951 101.31 196.742 95.7432 193.384C87.1885 197.556 77.8056 199.725 68.2987 199.727ZM68.2987 83.6235C39.2964 83.6235 15.7011 107.364 15.7011 136.544C15.7011 165.725 39.2964 189.465 68.2987 189.465C77.1366 189.465 85.8324 187.228 93.5843 182.958L96.3888 181.407L98.9963 183.274C100.897 184.634 104.644 186.976 108.947 187.855L100.686 178.538L104.308 175.114C114.941 165.148 120.955 151.166 120.897 136.544C120.897 107.364 97.3011 83.6235 68.2987 83.6235Z"
        fill="#21A7E0"
      />
      <path
        d="M126.504 136.475C126.504 104.523 100.603 78.6218 68.6516 78.6218C36.7004 78.6218 10.7988 104.523 10.7988 136.475C10.7988 168.426 36.7004 194.327 68.6516 194.327C78.3758 194.342 87.9453 191.893 96.4678 187.211C100.467 190.056 110.223 195.732 119.861 191.643L108.259 178.639C119.916 167.716 126.523 152.449 126.504 136.475Z"
        fill="#21A7E0"
        fill-opacity="0.4"
      />
      <path
        opacity="0.1"
        d="M23.2613 140.659C23.2613 110.663 46.2665 86.0448 75.5943 83.4757C73.9222 83.3293 72.2307 83.25 70.5205 83.25C38.8156 83.25 13.1133 108.953 13.1133 140.659C13.1133 172.365 31.0406 180.443 62.7455 180.443C64.4531 180.443 61.0758 178.575 62.7455 178.428C33.4215 175.856 23.2613 170.652 23.2613 140.659Z"
        fill="black"
      />
      <path
        d="M214.557 43.8673C210.744 37.2987 203.198 36.9926 203.198 36.9926C203.198 36.9926 195.846 36.0342 191.13 46.0383C186.733 55.363 180.666 64.3662 190.153 66.549L191.866 61.1125L192.928 66.9538C194.278 67.0528 195.633 67.0764 196.987 67.0245C207.146 66.6901 216.821 67.1223 216.51 63.4062C216.095 58.4661 218.227 50.1877 214.557 43.8673Z"
        fill="#2F2E41"
      />
      <path
        d="M190.489 100.482C190.38 101.183 190.221 101.875 190.015 102.553C189.782 103.216 189.423 103.83 189.21 104.5C188.532 106.637 189.472 109.019 190.973 110.668C192.275 112.093 193.955 113.105 195.811 113.582C197.178 113.879 198.572 114.028 199.969 114.026C203.833 114.159 207.797 114.278 211.459 113.018C212.616 112.61 213.733 112.095 214.797 111.48C215.133 111.317 215.429 111.081 215.663 110.788C215.908 110.358 216.017 109.863 215.976 109.369C215.967 107.707 215.635 106.054 215.714 104.394C215.758 103.474 215.927 102.556 215.844 101.639C215.607 99.5065 214.075 97.7545 212.015 97.262C210.977 97.0358 209.899 97.1591 208.842 97.2622C206.097 97.5298 203.34 97.6548 200.582 97.6368C197.765 97.6186 194.964 97.1823 192.161 97.1367C191.332 97.1233 191.348 97.3825 191.084 98.1965C190.841 98.9456 190.642 99.7088 190.489 100.482Z"
        fill="#2F2E41"
      />
      <path
        d="M197.859 58.9421C197.719 59.6689 197.568 60.4223 197.129 61.0197C196.623 61.7069 195.799 62.0995 195.255 62.7572C194.436 63.7472 194.389 65.1402 194.386 66.4217C194.383 67.6022 194.407 68.8614 195.069 69.8413C195.541 70.45 196.126 70.9627 196.792 71.3513C199.276 73.0151 201.96 74.7087 204.955 74.7636C206.196 74.7864 207.526 74.4678 208.343 73.5388C208.695 73.1025 208.973 72.6109 209.163 72.0843C209.929 70.2081 210.393 68.2239 210.539 66.2044C210.649 65.255 210.56 64.2932 210.277 63.3799C209.913 62.3716 209.177 61.5421 208.691 60.5862C208.116 59.4495 207.906 58.165 208.089 56.9059C208.113 56.8173 208.106 56.723 208.068 56.6392C207.987 56.5483 207.868 56.5003 207.746 56.5095L201.808 56.0846C201.255 56.0573 200.704 55.9946 200.159 55.8971C199.839 55.8324 198.993 55.3805 198.688 55.5007C198.123 55.7231 197.975 58.3393 197.859 58.9421Z"
        fill="#A0616A"
      />
      <path
        opacity="0.1"
        d="M197.859 58.9421C197.719 59.6689 197.568 60.4223 197.129 61.0196C196.623 61.7069 195.799 62.0995 195.255 62.7572C194.436 63.7472 194.389 65.1402 194.386 66.4217C194.383 67.6022 194.407 68.8614 195.069 69.8413C195.541 70.45 196.126 70.9627 196.792 71.3513C199.276 73.0151 201.96 74.7087 204.955 74.7636C206.196 74.7864 207.526 74.4678 208.343 73.5388C208.695 73.1025 208.973 72.6109 209.163 72.0843C209.929 70.2081 210.393 68.2239 210.539 66.2044C210.649 65.255 210.56 64.2932 210.277 63.3799C209.913 62.3716 209.177 61.5421 208.691 60.5862C208.116 59.4495 207.906 58.165 208.089 56.9059C208.113 56.8173 208.106 56.723 208.068 56.6392C207.987 56.5483 207.868 56.5003 207.746 56.5095L201.808 56.0846C201.255 56.0573 200.704 55.9946 200.159 55.8971C199.839 55.8324 198.993 55.3805 198.688 55.5007C198.123 55.7231 197.975 58.3393 197.859 58.9421Z"
        fill="black"
      />
      <path
        d="M203.642 60.1088C208.328 60.1088 212.127 56.3099 212.127 51.6238C212.127 46.9376 208.328 43.1387 203.642 43.1387C198.956 43.1387 195.157 46.9376 195.157 51.6238C195.157 56.3099 198.956 60.1088 203.642 60.1088Z"
        fill="#A0616A"
      />
      <path
        d="M199.064 69.7389C199.493 70.2336 200.009 70.6451 200.588 70.9527C201.192 71.2012 201.839 71.3241 202.492 71.314C204.056 71.3578 205.752 71.0722 206.84 69.9514C208.243 68.5067 208.196 66.1765 209.296 64.4911C209.384 64.3382 209.505 64.2065 209.65 64.1049C209.834 64.0048 210.043 63.9571 210.253 63.9668C211.641 63.9539 212.851 64.8514 213.942 65.7068C214.456 66.0592 214.897 66.5068 215.241 67.0253C215.457 67.445 215.605 67.8962 215.679 68.362C216.221 70.9975 216.396 73.6923 216.57 76.3769C216.666 77.8598 216.762 79.3441 216.755 80.83C216.732 85.7255 215.59 90.5391 214.455 95.3022C214.309 95.9159 214.108 96.6019 213.548 96.8958C213.299 97.0077 213.031 97.0729 212.757 97.0882C209.506 97.4688 206.247 97.8499 202.973 97.9002C201.696 97.9198 200.419 97.889 199.142 97.8077C198.923 97.8048 198.705 97.767 198.497 97.6956C198.215 97.5606 197.973 97.3556 197.793 97.1001C196.023 94.8761 195.918 91.8013 195.929 88.9621C195.957 82.0205 196.206 75.0845 196.676 68.154C196.715 67.582 196.723 66.8872 196.31 66.4372C197.115 67.6267 198.038 68.7327 199.064 69.7389Z"
        fill="white"
      />
      <path
        d="M197.233 60.8715C196.951 61.3933 194.874 62.6892 194.473 63.1217C192.232 65.5383 188.143 65.4476 186.094 68.0359C185.373 68.9466 184.998 70.0908 184.723 71.2283C184.333 72.8372 184.125 74.5919 184.778 76.1089C185.406 77.5686 186.728 78.5778 187.626 79.8817C188.611 81.3134 189.054 83.0517 189.479 84.7482C189.793 86.0008 190.109 87.2844 189.987 88.5714C189.848 90.0346 189.156 91.3744 188.747 92.7839C188.339 94.1933 188.268 95.872 189.213 96.9797C189.907 97.7934 190.996 98.116 192.036 98.2975C193.369 98.5024 194.713 98.6221 196.06 98.6559C197.249 98.7097 198.561 98.7202 199.475 97.9399C200.197 97.3243 200.51 96.3252 200.551 95.3648C200.591 94.4044 200.396 93.4525 200.274 92.4994C200.147 91.5113 200.098 90.5147 200.057 89.519C199.874 85.0517 199.85 80.5814 199.983 76.1083C200.05 75.1786 200.005 74.244 199.85 73.3255C199.595 72.1289 198.963 71.0595 198.435 69.9601C197.381 67.8894 196.904 65.562 197.055 63.2325C197.215 60.8384 198.235 59.0145 197.233 60.8715Z"
        fill="white"
      />
      <path
        d="M209.646 60.3542C210.08 60.8084 211.556 60.9581 212.109 61.2506C212.629 61.4948 213.171 61.6858 213.728 61.8208C215.744 62.4123 217.661 63.3074 219.416 64.4763C219.781 64.6933 220.103 64.9766 220.367 65.312C220.645 65.7724 220.825 66.2875 220.893 66.8234C221.164 68.1868 221.436 69.5666 221.375 70.956C221.31 72.4309 220.874 73.8594 220.44 75.2684L218.821 80.5333C217.613 84.4632 216.397 88.4318 216.057 92.5349C215.869 94.8089 215.952 97.0977 216.306 99.3513C216.373 99.5731 216.343 99.8136 216.224 100.011C216.118 100.119 215.977 100.184 215.828 100.197C215.046 100.321 214.29 99.9161 213.582 99.5574C211.419 98.4621 209.109 97.6935 206.728 97.2759C207.658 95.9077 208.376 94.4018 208.857 92.8113C209.129 91.8014 209.344 90.7762 209.5 89.7409L210.253 85.3101C210.559 83.6906 210.775 82.0547 210.899 80.4104C210.956 79.7933 210.921 79.171 210.794 78.5646C210.621 77.9876 210.39 77.43 210.106 76.9001C208.865 74.2739 208.283 71.2769 208.771 68.4056C208.985 67.1491 209.4 65.8908 209.227 64.6278C209.061 63.4135 209.234 62.4698 208.927 61.268C207.67 56.3337 208.829 59.4999 209.646 60.3542Z"
        fill="white"
      />
      <path
        d="M185.823 71.3967C185.193 72.6146 184.14 73.6522 183.782 74.9622C183.642 75.5745 183.583 76.2002 183.604 76.8258C183.542 77.7686 183.642 78.7146 183.9 79.628C184.1 80.2057 184.426 80.7385 184.66 81.3048C184.987 82.0961 185.133 82.9423 185.088 83.7891C185.024 84.9623 184.597 86.1011 184.563 87.2753C184.511 89.1219 185.427 90.8578 186.321 92.5064C186.777 91.4549 187.707 90.6444 188.864 90.2916C190.02 89.9388 191.284 90.0796 192.319 90.6764C192.524 90.442 192.621 90.1417 192.589 89.8408C192.655 87.6985 192.719 85.5561 192.781 83.4137C192.877 80.1003 192.97 76.7426 192.152 73.5167C191.782 72.0603 191.208 70.6094 190.155 69.4798C189.6 68.8849 187.717 67.3181 186.807 67.9845C186.397 68.2841 186.493 69.0999 186.428 69.5129C186.33 70.1659 186.126 70.8011 185.823 71.3967Z"
        fill="white"
      />
      <path
        d="M221.09 68.6129C221.315 69.4878 221.412 70.3934 221.376 71.2986C221.387 73.1435 221.363 74.9881 221.306 76.8326C221.315 77.7786 221.199 78.7213 220.961 79.6343C220.78 80.2281 220.501 80.7848 220.314 81.3766C219.754 83.15 220.06 85.0734 220.108 86.94C220.142 87.5441 220.093 88.1501 219.962 88.7396C219.666 89.8967 218.872 90.8317 218.318 91.8802C217.791 92.876 217.474 93.9922 216.845 94.9211C216.216 95.8501 215.128 96.5861 214.073 96.3009C213.274 96.0849 212.706 95.3465 212.313 94.5886C211.455 92.9221 211.162 91.0025 211.481 89.1404C211.875 86.875 213.15 84.8653 213.61 82.6133C214.241 79.5208 213.282 76.3297 213.31 73.1679C213.297 72.6723 213.362 72.1778 213.501 71.7038C213.742 70.9816 214.26 70.4049 214.739 69.8257C215.337 69.1018 215.892 68.3409 216.402 67.5471C216.745 67.0138 217.21 65.6423 217.818 65.5283C219.174 65.2736 220.753 67.5195 221.09 68.6129Z"
        fill="white"
      />
      <path
        d="M178.415 98.1616C177.643 97.968 176.846 97.8837 176.05 97.911C174.358 98.0043 172.787 98.818 171.738 100.145C170.689 101.472 170.264 103.184 170.57 104.846C170.762 105.735 171.078 106.594 171.507 107.398C172.134 108.666 172.886 109.932 174.048 110.745C174.747 111.192 175.501 111.549 176.29 111.808L182.406 114.104C183.306 114.442 184.206 114.78 185.114 115.093C189.679 116.665 194.438 117.608 199.26 117.895C200.727 118.032 202.206 117.957 203.652 117.673C204.001 117.628 204.321 117.455 204.551 117.189C204.668 116.987 204.736 116.76 204.752 116.528L205.128 113.902C205.213 113.537 205.202 113.156 205.096 112.795C204.924 112.354 204.492 112.077 204.08 111.841C200.059 109.54 195.364 108.27 191.98 105.112C191.183 104.368 190.516 103.19 189.548 102.684C188.451 102.112 187.158 101.785 186.023 101.257C183.543 100.103 181.104 98.7774 178.415 98.1616Z"
        fill="#2F2E41"
      />
      <path
        opacity="0.1"
        d="M185.525 102.315C187.664 102.932 196.695 107.21 198.243 108.704C198.035 108.773 190.996 105.138 190.785 105.08C188.848 104.59 186.952 103.974 185.11 103.237C184.743 103.081 182.727 102.346 182.818 101.92C182.921 101.439 185.146 102.205 185.525 102.315Z"
        fill="black"
      />
      <path
        d="M210.905 119.5C211.027 119.509 211.148 119.474 211.245 119.403C211.332 119.295 211.37 119.158 211.352 119.023L211.342 115.559C210.316 115.085 209.169 114.933 208.044 114.787L202.791 114.105C202.912 114.12 201.97 116.302 202.115 116.593C202.369 117.107 204.315 117.562 204.864 117.804C206.772 118.647 208.755 119.573 210.905 119.5Z"
        fill="#A0616A"
      />
      <path
        d="M195.328 115.178C194.33 115.34 193.217 115.482 192.57 116.271C191.751 117.271 192.1 118.762 192.491 120.002C192.539 120.325 192.714 120.614 192.975 120.804C193.237 120.994 193.564 121.069 193.88 121.011C194.79 121.069 195.759 121.115 196.554 120.662C196.914 120.42 197.261 120.158 197.592 119.878C198.179 119.482 198.823 119.183 199.502 118.992C200.749 118.593 202.034 118.324 203.335 118.189C203.847 118.166 204.355 118.075 204.845 117.919C205.34 117.757 205.733 117.369 205.908 116.871C206.092 116.209 205.713 115.534 205.35 114.953C204.931 114.193 204.387 113.511 203.742 112.936C202.758 112.159 202.271 112.756 201.279 113.253C199.401 114.194 197.395 114.843 195.328 115.178Z"
        fill="#A0616A"
      />
      <path
        d="M192.609 116.413L186.247 114.559C185.484 114.297 184.686 114.145 183.879 114.107C183.061 114.074 182.269 114.39 181.705 114.974C181.211 115.545 181.07 116.325 180.947 117.065L180.684 118.648C180.509 119.447 180.459 120.267 180.537 121.08C180.622 121.904 181.089 122.643 181.801 123.083C182.681 123.556 183.76 123.325 184.743 123.127C186.727 122.727 188.746 122.513 190.772 122.489C191.534 122.48 192.332 122.49 193.005 122.138C193.798 121.675 194.308 120.855 194.366 119.948C194.491 118.595 194.034 116.829 192.609 116.413Z"
        fill="#2F2E41"
      />
      <path
        d="M213.96 115.378C214.756 115.235 215.459 114.77 216.223 114.496C217.297 114.11 218.461 114.11 219.6 114.114C220.018 114.075 220.439 114.161 220.81 114.363C221.312 114.706 221.44 115.392 221.525 116.004L222.04 119.698C222.177 120.387 222.192 121.096 222.082 121.79C221.931 122.475 221.476 123.135 220.815 123.311C220.011 123.526 219.224 122.999 218.465 122.653C216.569 121.787 214.365 122.009 212.388 121.362C212.005 121.283 211.674 121.04 211.478 120.694C211.41 120.489 211.386 120.271 211.407 120.056C211.441 118.868 211.197 117.247 211.545 116.112C211.858 115.092 213.071 115.378 213.96 115.378Z"
        fill="#2F2E41"
      />
      <path
        d="M227.986 97.455C229.264 97.1471 230.589 97.0601 231.898 97.1982C232.944 97.3089 234.022 97.5885 234.785 98.2841C235.715 99.1307 236.013 100.44 236.038 101.67C236.079 104.121 235.233 106.509 233.645 108.427C233.257 108.912 232.796 109.339 232.277 109.693C231.717 110.029 231.12 110.306 230.497 110.517L216.678 115.754C213.043 117.132 209.395 118.514 205.603 119.422C205.393 119.519 205.152 119.531 204.933 119.455C204.715 119.378 204.537 119.221 204.441 119.017C203.23 117.355 202.215 115.57 201.413 113.694C201.335 113.603 201.309 113.482 201.342 113.37C201.375 113.257 201.463 113.167 201.578 113.128L211.352 107.732C212.068 107.373 212.736 106.931 213.341 106.415C213.763 106.022 214.109 105.559 214.362 105.049C214.565 104.642 214.584 103.683 214.843 103.382C215.096 103.088 215.963 103.065 216.363 102.949C216.928 102.783 217.48 102.582 218.018 102.348C220.205 101.408 222.152 100.042 224.247 98.9419C225.423 98.2961 226.679 97.7965 227.986 97.455Z"
        fill="#2F2E41"
      />
      <path
        opacity="0.1"
        d="M221.565 101.246C219.894 102.455 217.731 103.567 216.075 104.807C215.51 105.231 206.089 110.334 205.956 111.012C207.501 111.263 217.341 104.63 218.749 103.968C220.157 103.306 221.405 102.372 222.642 101.446C222.957 101.21 224.293 100.5 223.388 100.236C222.878 100.088 221.941 101.004 221.565 101.246Z"
        fill="black"
      />
      <path
        d="M209.884 60.8801H195.244C194.006 60.8801 193.002 61.8532 193.002 63.0536V63.8494H192.843V65.6294H193.002V66.8753H192.843V67.9432H193.002V81.0765C193.002 82.2768 194.006 83.2499 195.244 83.2499H209.884C211.123 83.2499 212.127 82.2768 212.127 81.0765V63.0535C212.127 61.8532 211.123 60.8801 209.884 60.8801Z"
        fill="#D0CDE1"
      />
      <path
        d="M213.318 43.1138L205.929 39.2817L195.725 40.8494L193.614 50.0809L198.869 49.8806L200.337 46.4892V49.8249L202.762 49.7326L204.17 44.3328L205.049 50.0809L213.67 49.9066L213.318 43.1138Z"
        fill="#2F2E41"
      />
      <path
        d="M188.817 96.2861C189.13 96.8557 189.575 97.3294 190.109 97.6616C190.818 98.0085 191.638 97.985 192.33 97.5979C193.011 97.2069 193.569 96.6084 193.932 95.8782C194.685 94.4567 194.841 92.7558 194.747 91.1253C194.653 89.4948 194.327 87.8892 194.183 86.2629C194.079 85.0807 194.07 83.8914 194.062 82.704C194.036 82.4542 194.085 82.2022 194.202 81.9842C194.403 81.7702 194.663 81.6312 194.944 81.5877C196.462 81.105 197.47 79.5822 198.192 78.074C198.674 77.0669 199.094 75.966 199.001 74.8387C198.908 73.7114 198.151 72.5758 197.099 72.4608C196.231 72.3659 195.43 72.9555 194.805 73.6053C193.226 75.2547 192.212 77.424 191.925 79.7646C191.787 80.9108 191.827 82.0883 191.535 83.201C190.913 85.5769 188.967 87.0336 187.997 89.1715C186.928 91.5273 187.518 94.2017 188.817 96.2861Z"
        fill="#A0616A"
      />
      <path
        d="M217.98 87.5035C217.5 88.6245 216.59 89.5031 215.457 89.9398C214.76 90.2214 213.99 90.2687 213.264 90.0748C212.534 89.8712 211.94 89.3372 211.657 88.6306C211.546 88.2794 211.485 87.9141 211.476 87.5457C211.372 85.9324 211.412 84.2699 211.995 82.763C212.22 82.1819 212.524 81.6307 212.701 81.0332C212.875 80.3431 212.965 79.634 212.967 78.922L213.158 74.52C213.19 74.2004 213.168 73.8777 213.093 73.5655C212.902 72.9285 212.31 72.5146 211.766 72.1348C210.454 71.2174 209.157 70.147 208.521 68.6728C208.303 68.229 208.224 67.7286 208.297 67.2388C208.476 66.3647 209.354 65.7988 210.221 65.6082C211.368 65.3561 212.568 65.5858 213.542 66.2441C214.517 66.9024 215.181 67.9321 215.382 69.0948C215.468 69.5915 215.467 70.099 215.517 70.6007C215.624 71.6648 215.96 72.6907 216.17 73.7391C216.688 76.3217 216.42 78.9914 216.932 81.5735C217.36 83.7325 218.975 85.1412 217.98 87.5035Z"
        fill="#A0616A"
      />
      <path
        d="M203.257 70.1366C204.322 70.1366 205.185 69.2733 205.185 68.2082C205.185 67.1432 204.322 66.2798 203.257 66.2798C202.192 66.2798 201.328 67.1432 201.328 68.2082C201.328 69.2733 202.192 70.1366 203.257 70.1366Z"
        fill="#21A7E0"
      />
      <path
        d="M5.3996 134.161C8.38171 134.161 10.7992 131.743 10.7992 128.761C10.7992 125.779 8.38171 123.361 5.3996 123.361C2.41748 123.361 0 125.779 0 128.761C0 131.743 2.41748 134.161 5.3996 134.161Z"
        fill="#2F2E41"
      />
      <path
        d="M24.2528 144.671L21.5754 141.874L16.1992 143.669L16.7156 150.359L27.7698 149.807L24.3739 144.848L24.2528 144.671Z"
        fill="#FBBEBE"
      />
      <path
        d="M19.2839 144.188C23.9701 144.188 27.769 140.389 27.769 135.703C27.769 131.017 23.9701 127.218 19.2839 127.218C14.5977 127.218 10.7988 131.017 10.7988 135.703C10.7988 140.389 14.5977 144.188 19.2839 144.188Z"
        fill="#FBBEBE"
      />
      <path
        d="M73.3896 156.53C73.3896 156.53 83.9338 174.732 86.4432 177.465C87.6365 178.81 88.9079 180.082 90.2507 181.277C90.2507 181.277 89.0708 185.207 87.192 185.067C86.1211 185.002 85.0708 184.742 84.0926 184.3C84.0926 184.3 83.254 182.116 81.7912 181.159C80.3285 180.201 68.3778 168.391 68.6572 164.616C68.9365 160.841 73.3896 156.53 73.3896 156.53Z"
        fill="#2F2E41"
      />
      <path
        d="M35.4834 182.021C35.4834 182.021 46.8071 190.726 62.1963 182.546L63.5225 181.579C63.5225 181.579 61.2844 186.566 61.0767 187.919C60.836 189.486 55.009 198.67 54.0581 199.11C53.1072 199.55 52.8977 199.956 52.8977 199.956L56.9329 204.355L58.4249 203.268C58.4249 203.268 64.2426 195.103 65.9441 193.61C67.6457 192.116 75.7526 176.715 75.7526 176.715C75.7526 176.715 78.8522 170.911 71.1167 170.475C63.3812 170.038 59.8217 172.127 59.8217 172.127L46.0931 174.287L35.4834 182.021Z"
        fill="#2F2E41"
      />
      <path
        d="M90.5071 180.443L96.1046 184.461C96.1046 184.461 103.246 186.782 98.8469 188.327C94.448 189.872 82.8559 190.431 82.5929 188.299C82.3299 186.168 83.0502 182.813 83.6285 182.997C84.2068 183.18 88.2206 184.262 90.5071 180.443Z"
        fill="#2F2E41"
      />
      <path
        d="M57.9859 202.73L59.0196 208.976C59.0196 208.976 61.9588 215.161 58.4298 213.242C54.9008 211.323 47.6301 204.033 48.7038 202.505C49.7774 200.976 52.147 199.319 52.3877 199.817C52.6283 200.315 54.4094 203.65 57.9859 202.73Z"
        fill="#2F2E41"
      />
      <path
        d="M47.825 176.25C47.825 176.25 40.0016 185.403 35.2519 183.18C30.5022 180.957 11.0703 155.204 13.2891 150.444C15.5079 145.684 16.2518 146.697 16.2518 146.697L26.2851 147.627L23.3983 142.989C23.3983 142.989 24.7462 141.995 26.0306 143.39C27.315 144.785 32.4717 148.27 34.2682 153.192C36.0646 158.114 41.3387 157.869 41.3387 157.869L47.825 176.25Z"
        fill="white"
      />
      <path
        d="M78.6799 157.529L65.2528 171.958L53.2246 150.46L64.6593 136.475L78.6799 157.529Z"
        fill="#3F3D56"
      />
      <path
        d="M77.9087 157.261L65.7686 169.644L54.7676 150.562L64.8565 138.017L77.9087 157.261Z"
        fill="white"
      />
      <path
        d="M64.9117 171.186L65.5665 171.729L65.1466 172.552L46.4589 178.128L46.2822 177.487L47.1324 176.605L64.9117 171.186Z"
        fill="#B3B3B3"
      />
      <path
        d="M66.3381 172.093L46.6895 178.129L34.7119 155.894L54.0138 151.131L66.3381 172.093Z"
        fill="#D0CDE1"
      />
      <path
        d="M64.0239 168.27L63.195 168.872L53.9961 153.112L54.9468 152.673L64.0239 168.27Z"
        fill="#3F3D56"
      />
      <path
        d="M62.4808 168.822L54.1717 171.186L44.7393 155.25L52.9594 153.445L62.4808 168.822Z"
        fill="#3F3D56"
      />
      <path
        d="M38.8383 157.944L40.5353 157.482C40.5353 157.482 49.9994 156.451 50.1375 159.315C50.2757 162.179 39.265 160.851 39.265 160.851L37.7969 158.929L38.8383 157.944Z"
        fill="#FBBEBE"
      />
      <path
        d="M47.646 170.415L50.0314 169.439C50.0314 169.439 57.2317 166.22 55.1662 164.123C53.1006 162.026 46.7303 165.668 46.7303 165.668L45.5107 166.821L47.646 170.415Z"
        fill="#FBBEBE"
      />
      <path
        d="M45.417 165.655C45.417 165.655 49.361 169.582 48.4635 170.505C47.5661 171.427 32.8497 183.561 29.2016 179.679C25.5536 175.796 7.77972 156.304 14.6958 154.632C21.6119 152.96 23.4392 156.869 23.4392 156.869L32.5693 172.476L45.417 165.655Z"
        fill="white"
      />
      <path
        d="M33.5938 154.987L39.3399 156.959L39.2461 161.158L33.1689 160.495L33.5938 154.987Z"
        fill="white"
      />
      <path
        opacity="0.2"
        d="M40.1111 168.103L33.2706 171.958L25.4551 160.387L32.9993 169.918L40.1111 168.103Z"
        fill="black"
      />
      <path
        d="M15.0414 143.417C20.7927 143.417 25.4549 138.755 25.4549 133.003C25.4549 127.252 20.7927 122.59 15.0414 122.59C9.29022 122.59 4.62793 127.252 4.62793 133.003C4.62793 138.755 9.29022 143.417 15.0414 143.417Z"
        fill="#2F2E41"
      />
      <path
        d="M70.6335 158.073L63.252 147.286L66.381 143.417L74.0511 154.514L70.6335 158.073Z"
        fill="#21A7E0"
      />
      <path
        d="M70.4716 162.701L59.3955 147.14L59.9418 146.502L70.9661 162.1L70.4716 162.701Z"
        fill="#E6E6E6"
      />
      <path
        d="M69.7001 163.472L58.624 147.912L59.1703 147.274L70.1946 162.872L69.7001 163.472Z"
        fill="#E6E6E6"
      />
      <path
        d="M68.1571 165.015L57.0811 149.454L57.6273 148.816L68.6516 164.414L68.1571 165.015Z"
        fill="#E6E6E6"
      />
      <path
        d="M20.0557 126.447H23.2376C26.5925 126.447 29.3121 129.21 29.3121 132.618H20.0557V126.447Z"
        fill="#2F2E41"
      />
      <path
        d="M63.1855 123.406L62.6453 123.361C62.6244 123.675 62.6094 123.99 62.5916 124.304L60.167 124.214L62.7103 129.532L65.5666 124.416L63.1331 124.325C63.1505 124.019 63.1651 123.712 63.1855 123.406Z"
        fill="#3F3D56"
      />
      <path
        d="M79.4512 81.3523L79.9966 81.7073C80.8148 80.7938 81.6694 79.8819 82.5367 78.9968L82.01 78.6218C81.1363 79.5135 80.2754 80.4321 79.4512 81.3523Z"
        fill="#3F3D56"
      />
      <path
        d="M83.3086 75.1196L83.78 75.5362C84.6272 74.6387 85.5068 73.7475 86.3941 72.8868L85.942 72.4507C85.048 73.3175 84.162 74.2156 83.3086 75.1196Z"
        fill="#3F3D56"
      />
      <path
        d="M88.708 70.4219L89.1149 70.908C89.9878 70.0291 90.889 69.1609 91.7935 68.3276L91.4066 67.8225C90.4954 68.662 89.5874 69.5367 88.708 70.4219Z"
        fill="#3F3D56"
      />
      <path
        d="M74.8232 86.8079L75.4554 87.1067C76.2415 86.1765 77.067 85.246 77.9087 84.3408L77.2948 84.0212C76.4467 84.9332 75.6152 85.8707 74.8232 86.8079Z"
        fill="#3F3D56"
      />
      <path
        d="M94.1074 66.4851L94.4562 67.0511C95.3512 66.1947 96.272 65.3534 97.1929 64.5504L96.8645 63.9656C95.9366 64.7747 95.009 65.6223 94.1074 66.4851Z"
        fill="#3F3D56"
      />
      <path
        d="M72.5088 92.9692L72.8775 93.2778C73.2511 92.0951 73.6461 90.9102 74.0515 89.7561L73.6915 89.4209C73.283 90.5837 72.8851 91.7776 72.5088 92.9692Z"
        fill="#3F3D56"
      />
      <path
        d="M65.5662 109.636L64.9246 109.477C64.5971 110.705 64.2939 111.959 64.0234 113.201L64.6719 113.333C64.9404 112.1 65.2413 110.856 65.5662 109.636Z"
        fill="#3F3D56"
      />
      <path
        d="M99.5068 61.7611L99.8024 62.4229C100.715 61.5957 101.654 60.7863 102.592 60.0172L102.317 59.3374C101.372 60.1125 100.427 60.9278 99.5068 61.7611Z"
        fill="#3F3D56"
      />
      <path
        d="M64.7948 115.751L64.3777 115.647C64.242 116.896 64.1228 118.168 64.0234 119.429L64.4432 119.504C64.5418 118.253 64.6601 116.991 64.7948 115.751Z"
        fill="#3F3D56"
      />
      <path
        d="M68.6523 98.43L69.0875 98.6774C69.4359 97.4779 69.8085 96.2726 70.1951 95.0949L69.7682 94.8206C69.3787 96.0072 69.0032 97.2215 68.6523 98.43Z"
        fill="#3F3D56"
      />
      <path
        d="M67.1094 105.431L67.6328 105.62C67.9483 104.402 68.2913 103.177 68.6521 101.979L68.1364 101.763C67.7729 102.97 67.4273 104.204 67.1094 105.431Z"
        fill="#3F3D56"
      />
      <path
        d="M138.869 50.8524C140.134 50.804 141.423 50.7847 142.698 50.798L142.704 50.0851C141.42 50.0719 140.121 50.0912 138.847 50.1399L138.869 50.8524Z"
        fill="#3F3D56"
      />
      <path
        d="M105.678 58.1761L105.985 58.5659C107.152 58.1686 108.346 57.7836 109.535 57.4216L109.254 57.0232C108.056 57.3879 106.853 57.7758 105.678 58.1761Z"
        fill="#3F3D56"
      />
      <path
        d="M152.731 51.2438C153.978 51.3529 155.236 51.4807 156.471 51.6237L156.588 51.2351C155.344 51.0911 154.077 50.9622 152.821 50.8523L152.731 51.2438Z"
        fill="#3F3D56"
      />
      <path
        d="M159.674 52.2366C160.902 52.5182 162.142 52.831 163.357 53.1665L163.531 52.5604C162.306 52.2225 161.057 51.9075 159.82 51.6238L159.674 52.2366Z"
        fill="#3F3D56"
      />
      <path
        d="M165.845 54.4374C167.056 54.7615 168.276 55.1124 169.471 55.4805L169.702 54.9888C168.498 54.6179 167.269 54.2642 166.048 53.9377L165.845 54.4374Z"
        fill="#3F3D56"
      />
      <path
        d="M146.561 50.6162C147.824 50.6716 149.101 50.7511 150.355 50.8524L150.417 50.3191C149.153 50.2169 147.867 50.1368 146.594 50.0811L146.561 50.6162Z"
        fill="#3F3D56"
      />
      <path
        d="M131.904 50.3712L131.983 50.8524C133.231 50.7362 134.503 50.6393 135.761 50.5645L135.711 50.0811C134.443 50.1565 133.162 50.2541 131.904 50.3712Z"
        fill="#3F3D56"
      />
      <path
        d="M118.02 54.1413L118.21 54.7092C119.419 54.3633 120.652 54.0378 121.876 53.7417L121.714 53.1665C120.48 53.4646 119.237 53.7925 118.02 54.1413Z"
        fill="#3F3D56"
      />
      <path
        d="M111.849 55.7861L112.097 56.252C113.285 55.8763 114.5 55.5167 115.705 55.183L115.485 54.7092C114.269 55.0453 113.046 55.4077 111.849 55.7861Z"
        fill="#3F3D56"
      />
      <path
        d="M125.733 51.2644L125.867 51.6237C127.096 51.4724 128.348 51.3347 129.59 51.2146L129.484 50.8523C128.233 50.9734 126.971 51.112 125.733 51.2644Z"
        fill="#3F3D56"
      />
      <path
        d="M178.187 59.6805L175.153 54.7092L174.086 57.1439C173.828 57.0161 173.571 56.8855 173.313 56.7616L173.097 57.3142C173.349 57.4351 173.598 57.5618 173.849 57.6857L172.787 60.1088L178.187 59.6805Z"
        fill="#3F3D56"
      />
    </svg>
  </div>
</template>
<script>
export default {
  name: 'ConfirmationSVG',
};
</script>

<style lang="scss" scoped></style>
