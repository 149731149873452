<template>
  <v-timeline-item
    :class="isSeen ? `seen-item` : ``"
    small
    :color="isSeen ? 'primary' : 'white'"
    fill-dot
  >
    <div>{{ $t('help.step') }} {{ id }}</div>
    <div class="step-content" v-html="returnMarked(step.text)" />
    <v-img
      max-width="500"
      max-height="300"
      position="left center"
      contain
      :src="image"
      alt="image"
      class="step-image"
    />
    <template v-slot:icon>
      <v-icon color="white" size="17" v-text="icon" />
    </template>
  </v-timeline-item>
</template>

<script>
import { marked } from 'marked';
import { mdiCheck } from '@mdi/js';
import inViewport from 'vue-in-viewport-mixin';
import DOMPurify from 'dompurify';
import { mapState } from 'vuex';
import { getFileURL } from '@/utils/calyrex';

export default {
  name: 'Step',
  data: () => ({
    isSeen: false,
    image: null,
  }),
  mixins: [inViewport],
  computed: {
    ...mapState('users', ['currentUser', 'isTrialExpired']),
    icon() {
      return mdiCheck;
    },
  },
  watch: {
    'inViewport.now': async function checkViewPort(visible) {
      if (visible) {
        this.isSeen = true;
      }
    },
  },
  methods: {
    async loadImage() {
      this.image = await getFileURL(
        this.currentUser.username,
        this.step.image,
        this.currentUser.userIp,
      );
    },
    returnMarked(content) {
      return DOMPurify.sanitize(marked(content));
    },
  },
  mounted() {
    this.loadImage();
  },
  props: {
    id: {
      type: Number,
    },
    step: {
      type: Object,
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/scss/app.scss', '@/scss/variables.scss';

::v-deep .v-timeline-item__divider {
  justify-content: left;
  left: 5px;
}

::v-deep .v-timeline-item__dot {
  border: 1px solid $zen-blue-default;
}

.seen-item ::v-deep .v-timeline-item__dot {
  border: 0 !important;
}

::v-deep .v-timeline-item__inner-dot {
  height: 100%;
  width: 100%;
}

::v-deep .step-image {
  filter: drop-shadow($elevation-05) drop-shadow($elevation-05);
}
</style>
