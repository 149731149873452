<template>
  <div class="d-flex flex-column align-center">
    <div class="header">{{ $t(header) }}</div>
    <div class="font-weight-regular mb-8 new-line sub-header">
      {{ $t(subHeader) }}
    </div>
    <div class="cards-wrapper">
      <v-hover
        v-for="(item, index) in categories"
        :key="item.name"
        v-slot="{ hover }"
        open-delay="50"
      >
        <div
          class="cards"
          :class="{
            'elevation-4': hover,
            'selected-card': selectedCategory.includes(index),
          }"
          @click="selectItem(index)"
        >
          <div>{{ item.name }}</div>
          <img :src="item.icon" width="132px" height="93px" />
        </div>
      </v-hover>
    </div>
    <div class="mt-10">
      <v-btn
        class="next-button"
        rounded
        color="#21a7e0"
        @click="handleSelection"
        :disabled="!selectedCategory.length"
      >
        {{ $t('fingerprint.next') }}
      </v-btn>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ZenSenseFingerPrint',
  props: {
    categories: {
      type: Array,
      required: true,
    },
    header: {
      type: String,
      required: true,
    },
    subHeader: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    selectedCategory: {
      type: Array,
      required: true,
    },
  },
  emits: ['handleNext', 'handleSelection'],
  methods: {
    selectItem(index) {
      this.$emit('handleSelection', index);
    },
    handleSelection() {
      this.$emit('handleNext');
    },
  },
};
</script>
<style scoped lang="scss">
@import '@/scss/variables.scss', '@/scss/app.scss';

.header {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 52px;
  margin-bottom: 15px;
}
.sub-header {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
}
.next-button {
  width: 100px;
  height: 40px !important;
  background: #21a7e0;
  border-radius: 25px;
  text-transform: none;
  letter-spacing: normal;
  ::v-deep .v-btn__content {
    color: #ffffff;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 19px !important;
  }
}
.cards-wrapper {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  width: 840px;
  justify-content: center;
}
.cards {
  box-shadow: 1px 1px 8px 4px rgb(0 0 0 / 2%), -1px -1px 8px 4px rgb(0 0 0 / 2%);
  height: 180px;
  max-width: 260px;
  padding: 15px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex: calc(33.33% - 20px);

  > div {
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 18px;
  }

  > img {
    height: 100px;
    width: 120px;
  }

  &.selected-card {
    border: 3px solid #29b6f6;
  }
}

.new-line {
  white-space: pre-line;
}

.buttonAndHeading {
  display: flex;
  width: 40%;
  margin: 0 auto;
  .back-button-private {
    color: #29b6f6;
    cursor: pointer;
    width: 18%;
    text-align: center;
    margin-top: 9px;
  }
  .question-header-private {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 15px;
    width: 63%;
    text-align: center;
  }
}
.progress-wrapper {
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
}
</style>
