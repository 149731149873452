<template>
  <div class="main-wrapper" data-pendo-id="share-fingerprint-wrapper">
    <template v-if="type !== 'form'">
      <div class="share-fingerprint__title">
        {{ $t('navbarActions.shareFingerprint') }}
      </div>
      <v-btn class="close-btn" icon @click="handleClose">
        <v-icon dark>mdi-close</v-icon>
      </v-btn>
      <v-img
        v-if="type === 'menu' && !isUpdated"
        class="send-request__img"
        contain
        :src="'/assets/img/share-fingerprint-popup.svg'"
        alt
      ></v-img>
      <template v-else-if="type === 'menu' && isUpdated">
        <div class="align-center d-flex flex-column">
          <img
            :src="'/assets/img/fingerprint-shared-successfully.svg'"
            alt
            class="ma-8"
          />
          <div class="font-weight-bold success-message">
            {{
              $t(
                'navbarActions.shareFingerprintvue.shareFingerprintSuccessfully',
              )
            }}
          </div>
        </div>
        <!-- <div class="d-flex justify-end mt-6">
          <v-btn
            class="primary-button"
            rounded
            color="#20a7e0"
            width="120"
            height="32"
            @click="handleClose"
          >
            Continue
          </v-btn>
        </div> -->
      </template>
    </template>
    <template v-if="type !== 'menu' || !isUpdated">
      <div v-if="dataLoading">
        <ClipLoader :color="`#21a7e0`" />
      </div>
      <template v-else>
        <v-checkbox v-model="company" :disabled="true" color="#20a7e0">
          <template #label>
            <div class="label-wrapper">
              <div class="header">
                {{ $t('fingerprint.exploreFingerprint.company') }}
              </div>
              <div class="sub-header">
                {{
                  $t(
                    'navbarActions.shareFingerprintvue.companyColleaguesCanAccess',
                  )
                }}
              </div>
            </div>
          </template>
        </v-checkbox>
        <v-checkbox
          v-model="publicLimited"
          color="#20a7e0"
          data-pendo-id="share-fingerprint-access"
        >
          <template #label>
            <div class="label-wrapper">
              <div class="header">
                {{ $t('navbarActions.shareFingerprintvue.public1') }}
              </div>
              <div class="sub-header">
                {{ $t('navbarActions.shareFingerprintvue.public1Text') }}
              </div>
            </div>
          </template>
        </v-checkbox>
        <v-checkbox v-model="publicFull" color="#20a7e0">
          <template #label>
            <div class="label-wrapper">
              <div class="header">
                {{ $t('navbarActions.shareFingerprintvue.public2') }}
              </div>
              <div class="sub-header">
                {{ $t('navbarActions.shareFingerprintvue.public2Text') }}
              </div>
            </div>
          </template>
        </v-checkbox>
        <v-checkbox v-model="selectedMembers" color="#20a7e0">
          <template #label>
            <div class="label-wrapper">
              <div class="header">
                {{ $t('navbarActions.shareFingerprintvue.selectMembers') }}
              </div>
              <div class="sub-header">
                {{ $t('navbarActions.shareFingerprintvue.selectMembersText') }}
              </div>
            </div>
          </template>
        </v-checkbox>
        <template v-if="selectedMembers">
          <UserSelector
            class="select-users-autocomplete"
            v-model="selectedUsers"
            :canAddEmail="true"
          />
        </template>
      </template>
      <div class="share-footer" :class="{ 'align-middle': type === 'form' }">
        <v-btn
          class="primary-button"
          rounded
          color="#20a7e0"
          height="32"
          @click="handleUpdate"
          :disabled="dataLoading"
          :loading="loading || updateLoading"
        >
          {{
            isUpdated
              ? $t('navbarActions.shareFingerprintvue.updated')
              : $t(updateText)
          }}
        </v-btn>
        <div
          @click="openEmailModal"
          class="preview-email"
          v-if="selectedUsers.length"
        >
          {{ $t('fingerprint.previewMail') }}
          <v-tooltip
            bottom
            max-width="320"
            content-class="tooltip-content"
            fixed
            open-delay="500"
            close-delay="300"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="icon-title info-icon" v-bind="attrs" v-on="on">
                mdi-information-outline
              </v-icon>
            </template>
            <span>
              {{ $t('fingerprint.previewTheEmailThatIsSentToYourConnections') }}
            </span>
          </v-tooltip>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import EmailPreviewModal from '../../common/EmailPreviewModal.vue';
import UserSelector from '../../common/UserSelector/UserSelector.vue';
import { fpTypes } from '@/mock/fingerprint';
import {
  updateFingerprintShareDetails,
  getFingerPrintShareACL,
} from '../../../utils/api-helper';
import { trackProfileEvents } from '../../common/Analytics/ProfileEvents';
import {
  shareFingerPrintVsPlacement,
  TD_FINGERPRINT_SHARING_SETTINGS,
  TD_PLACEMENT,
  TD_SHARE_PUBLIC_FULL,
  TD_SHARE_PUBLIC_LIMITED,
  TD_SHARE_SELECT_MEMBERS,
  TD_AUD_EMAIL,
  TD_AUD_FIRST_NAME,
  TD_AUD_LAST_NAME,
  TD_AUD_COMPANY_NAME,
  TD_AUDIENCE_TYPE,
  TD_FLOW,
  TD_AUD_ADDITION_TYPE,
  TD_ADD_AUDIENCE_REQUESTED,
  TD_AUDIENCE_TYPE_INDIVIDUAL,
  TD_AUDIENCE_ADD_TYPE_REQUEST,
  TD_RETAKE_FINGERPRINT_TEST,
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  TD_FP_TEST,
  TD_FIRST_TIME_USER_LOGIN,
} from '../../common/Analytics/MatomoTrackingDataHelper';
import { trackGeneralEvents } from '../../common/Analytics/GeneralEvents';
import { isEmailValid } from '../../utils';

export default {
  name: 'ShareFingerPrint',
  components: { UserSelector, ClipLoader },
  props: {
    isAudience: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'modal',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    updateText: {
      type: String,
      default: 'common.update',
    },
    requester: {
      type: String,
    },
    origin: {
      type: String,
    },
  },
  computed: {
    ...mapState('users', ['currentUser', 'currentTestAudience']),
    fingerprintData() {
      return fpTypes.find(
        (fprint) =>
          fprint.title === this.currentUser.user.fingerPrint?.toLowerCase(),
      );
    },
  },
  watch: {
    publicFull(val) {
      if (val) {
        this.publicLimited = false;
      }
    },
    publicLimited(val) {
      if (val) {
        this.publicFull = false;
      }
    },
  },
  data() {
    return {
      company: true,
      dataOfUser: null,
      publicLimited: false,
      publicFull: false,
      selectedMembers: false,
      selectedUsers: [],
      isUpdated: false,
      updateLoading: false,
      dataLoading: false,
    };
  },
  emits: ['close', 'updated', 'subModalOpen'],
  methods: {
    handleClose() {
      this.$emit('close');
    },
    openEmailModal() {
      if (this.origin === 'fingerprint') {
        let matomoData = {
          [TD_COMMON_COLUMN_NAME]: TD_FLOW,
          [TD_COMMON_COLUMN_VALUE]: TD_RETAKE_FINGERPRINT_TEST,
          [TD_PLACEMENT]: TD_FP_TEST,
        };
        if (!this.currentUser?.user?.fingerPrint) {
          matomoData = {
            [TD_COMMON_COLUMN_NAME]: TD_FLOW,
            [TD_COMMON_COLUMN_VALUE]: TD_FIRST_TIME_USER_LOGIN,
            [TD_PLACEMENT]: TD_FP_TEST,
          };
        }
        if (this.isAudience) {
          matomoData = {
            [TD_AUD_EMAIL]: this.currentTestAudience?.id,
            [TD_AUD_FIRST_NAME]: this.currentTestAudience?.firstName,
            [TD_AUD_LAST_NAME]: this.currentTestAudience?.lastName,
            [TD_AUD_COMPANY_NAME]: this.currentTestAudience?.companyName,
            [TD_AUDIENCE_TYPE]: TD_AUDIENCE_TYPE_INDIVIDUAL,
            [TD_COMMON_COLUMN_NAME]: TD_FLOW,
            [TD_COMMON_COLUMN_VALUE]: TD_ADD_AUDIENCE_REQUESTED,
            [TD_PLACEMENT]: TD_FP_TEST,
            [TD_AUD_ADDITION_TYPE]: TD_AUDIENCE_ADD_TYPE_REQUEST,
          };
        }
        trackGeneralEvents.addnewaudienceContinuePreviewEmailV2(
          this.currentUser,
          matomoData,
        );
      }
      this.$emit('subModalOpen', false);
      if (this.type !== 'form') {
        this.$modal.show(
          EmailPreviewModal,
          {
            emailApiPayload: {
              // type: 'send-fingerprint-request-new',
              type: 'fingerprint_share_dml',
              meta: {
                firstName: this.currentUser.user.firstName,
                lastName: this.currentUser.user.lastName,
                fingerPrint: this.currentUser.user.fingerPrint,
                fingerPrintDescription: this.fingerprintData.description,
                visualPreferenceImgURL:
                  this.fingerprintData.preferences[0].logo,
                visualPreference:
                  this.fingerprintData.preferences[0].preference,
                visualPreferenceText:
                  this.fingerprintData.preferences[0].oneLine,
                storyPreferenceImgURL: this.fingerprintData.preferences[1].logo,
                storyPreference: this.fingerprintData.preferences[1].preference,
                storyPreferenceText:
                  this.fingerprintData.preferences[1].oneLine,
                dataPreferenceImgURL: this.fingerprintData.preferences[2].logo,
                dataPreference: this.fingerprintData.preferences[2].preference,
                dataPreferenceText: this.fingerprintData.preferences[2].oneLine,
                fingerPrintAvatarURL: this.fingerprintData.logo,
              },
            },
            heading: 'Fingerprint share email preview',
          },
          {
            name: 'EmailPreviewModal',
            classes: 'v-application',
            width: '850px',
            height: '600px',
            styles: {
              borderRadius: '20px',
              padding: '20px',
            },
          },
          {
            'before-close': this.subModalClosed,
          },
        );
        trackGeneralEvents.shareFingerprintPreviewemail(this.currentUser, {
          [TD_PLACEMENT]: shareFingerPrintVsPlacement[this.origin],
        });
      }
    },
    subModalClosed() {
      this.$emit('subModalOpen', true);
    },
    handleUpdate() {
      this.updateLoading = true;
      let shareSettings = '';
      const body = {
        entityType: this.isAudience ? 'audience' : 'user',
        entityID: this.isAudience
          ? this.currentTestAudience.uuid
          : this.currentUser.user.id,
        sharedEntities: [],
      };
      if (this.publicFull) {
        body.sharedEntities.push({ type: 'public', id: 'public' });
        shareSettings += `${TD_SHARE_PUBLIC_FULL}_`;
      }
      if (this.publicLimited) {
        body.sharedEntities.push({
          type: 'public_limited',
          id: 'public_limited',
        });
        shareSettings += `${TD_SHARE_PUBLIC_LIMITED}_`;
      }
      if (this.selectedMembers) {
        this.selectedUsers.forEach((user) => {
          body.sharedEntities.push({ type: 'user', id: user.id });
        });
        shareSettings += `${TD_SHARE_SELECT_MEMBERS}_`;
      }
      if (this.type === 'form') {
        this.$emit('updated', body);
      } else {
        updateFingerprintShareDetails(body).then(() => {
          this.updateLoading = false;
          if (this.type === 'modal') {
            this.isUpdated = true;
            setTimeout(() => {
              this.$modal.hide('ShareFingerPrint');
            }, 800);
          } else {
            this.isUpdated = true;
          }
        });
      }
      trackProfileEvents.profileFingerprintShareUpdate(
        this.currentUser,
        this.origin,
        {
          [TD_FINGERPRINT_SHARING_SETTINGS]: shareSettings,
          [TD_PLACEMENT]: shareFingerPrintVsPlacement[this.origin],
        },
      );
    },
    async initData() {
      const data = await getFingerPrintShareACL(
        this.isAudience ? this.currentTestAudience.uuid : null,
      );
      if (data) {
        if (data.type === 'data') {
          const selectedUsers = [];
          data.data.forEach((entity) => {
            if (
              entity.sharedEntityID === 'public' &&
              entity.sharedEntityType === 'public'
            ) {
              this.publicFull = true;
            }
            if (
              entity.sharedEntityID === 'public_limited' &&
              entity.sharedEntityType === 'public_limited'
            ) {
              this.publicLimited = true;
            }
            if (entity.sharedEntityType === 'user') {
              selectedUsers.push(entity);
            }
          });
          if (selectedUsers.length) {
            this.selectedMembers = true;
            this.selectedUsers = selectedUsers.map((user) => ({
              ...user,
              isActive: true, // making users enabled by default
              isDifferentCompany:
                user.companyID !== this.currentUser?.user?.companyID,
              id: user.sharedEntityID,
            }));
          }
        } else if (data.data === 'public') {
          this.publicFull = true;
        } else {
          this.publicLimited = true;
        }
        this.dataLoading = false;
      } else {
        this.dataLoading = false;
      }
      if (this.requester) {
        this.selectedMembers = true;
        if (isEmailValid(this.requester)) {
          this.selectedUsers.push({ id: this.requester });
        }
      }
    },
  },
  async beforeMount() {
    this.dataLoading = true;
    if (this.origin === 'fingerPrintTest')
      this.company = this.currentTestAudience?.fpVisibleToCompany;
    else this.company = this.currentUser?.user?.fpVisibleToCompany;
    this.initData([]);
  },
};
</script>
<style lang="scss" scoped>
@import '@/scss/variables.scss', '@/scss/app.scss';

.main-wrapper {
  padding: 20px;
}

.close-btn {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 1;
}

::v-deep .v-input--selection-controls__input {
  margin-right: 8px;
}

::v-deep .v-input--selection-controls {
  margin-top: 0px;
}

::v-deep .v-input__slot {
  align-items: start;
}

.select-users-autocomplete {
  width: calc(100% - 40px);
  margin-left: 35px;
}

::v-deep .v-text-field {
  padding-top: 0;
  margin-top: -5px;
}
.share-fingerprint__title {
  font-family: 'Lato' !important;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.01em;
  padding: 1.25rem auto 1rem;
  margin-bottom: 20px;
}

.send-request__img {
  margin-bottom: 20px;
}

.header {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 2.5px;
  color: #000000;
}
.sub-header {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #7b7b7b;
}
.preview-email {
  color: #21a7e0;
  cursor: pointer;
  margin: 10px 0px 10px 15px;
  font-size: 1rem;
}

.share-footer {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;

  &.align-middle {
    flex-direction: column;
  }
}
.success-message {
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-transform: none !important;
}
</style>
