var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"generateActionModalContainer"},[_c('div',{staticClass:"closeIconContainer"},[_c('v-icon',{attrs:{"medium":""},on:{"click":function($event){return _vm.handleCloseModal('cross')}}},[_vm._v(_vm._s('mdi-close'))])],1),_c('div',{staticClass:"headingContainer"},[_vm._v(" "+_vm._s(_vm.heading)+" ")]),_c('div',{staticClass:"content"},[(_vm.modalType === 'unsaved')?_c('div',{staticClass:"subContent"},[_vm._v(" "+_vm._s(_vm.type === 'slide' ? _vm.$t('premiumFeature.slideMessage') : _vm.$t('premiumFeature.prezentationMessage'))+" ")]):_vm._e(),(
        _vm.modalType === 'download' ||
        _vm.modalType === 'slidesLibrary' ||
        (_vm.modalType === 'favorites' && !_vm.isAddedToLibrary) ||
        _vm.modalType === 'unsaved'
      )?_c('div',{staticClass:"nameEditorAndLabel"},[_c('div',{class:_vm.modalType === 'unsaved' ? 'label-unsaved' : 'label'},[_vm._v(" "+_vm._s(_vm.$t('generate.typeLabel', { type: _vm.capitalize(_vm.type) }))+" ")]),_c('div',{staticClass:"textField"},[_c('v-text-field',{attrs:{"rules":_vm.maxLimit,"readonly":_vm.actionFinished},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.slideName.length > 0 && !_vm.actionFinished)?_c('v-icon',{attrs:{"medium":"","color":"#21a7e0","placeholder":_vm.$t('generate.enterSlideName')},on:{"click":_vm.clearSlideName}},[_vm._v(" "+_vm._s('mdi-close-circle')+" ")]):_vm._e()]},proxy:true}],null,false,908038319),model:{value:(_vm.slideName),callback:function ($$v) {_vm.slideName=$$v},expression:"slideName"}})],1)]):_vm._e(),(_vm.modalType === 'favorites' && _vm.isAddedToLibrary)?_c('div',{staticClass:"favoritesText"},[_vm._v(" "+_vm._s(_vm.$t('generate.actionLabel', { typeLabel: _vm.type }))+" ")]):_vm._e(),(!_vm.isAddedToLibrary && _vm.modalType === 'download')?_c('div',{staticClass:"add-prez-lib"},[_c('v-checkbox',{attrs:{"label":_vm.type === 'slide'
            ? _vm.$t('generate.addSlideToLibrary')
            : _vm.$t('generate.addPrezentationToLibrary'),"color":"#20a7e0","hide-details":""},model:{value:(_vm.addToLibrary),callback:function ($$v) {_vm.addToLibrary=$$v},expression:"addToLibrary"}})],1):_vm._e()]),_c('div',{staticClass:"actions",style:(_vm.modalType === 'download' &&
      ['redesign', 'generated'].indexOf(_vm.originPage) > -1
        ? { margin: '0px 0px 12px 0px' }
        : {})},[(!_vm.actionFinished || _vm.operation.loading)?_c('div',{staticClass:"buttons",style:(_vm.modalType === 'download' && _vm.isOriginPageFromPremiumFeatures
          ? { width: '38%' }
          : {})},[_c('v-btn',{staticClass:"cancelBtn",on:{"click":function($event){return _vm.handleCloseModal('button')}}},[_vm._v(" "+_vm._s(_vm.secondaryBtnText || _vm.$t('common.cancel'))+" ")]),(_vm.modalType === 'download' && _vm.isOriginPageFromPremiumFeatures)?_c('div',[(_vm.type === 'slide')?_c('Download',{attrs:{"file-name":_vm.getFilename,"item":Object.assign({}, _vm.downloadItem, {name: _vm.slideName}),"downloading":_vm.isDownloadingFileFromSnackbar,"log-download":_vm.logDownload,"count-first-time":1,"on-download":function () { return _vm.handleSlideDownload(); },"origin":"slide","limitsKey":"slide","isDisabled":_vm.isDownloadingFileFromSnackbar ||
            _vm.slideName.length === 0 ||
            _vm.slideName.length > 100,"speedDialDirection":'top',"speedDialPosTop":'-80',"speedDialPosRight":'0',"closePreviousPopups":function () { return _vm.closePreviousPopups(_vm.slideName, _vm.addToLibrary); },"allowDownloadSettingsIcon":false}}):_vm._e(),(_vm.type === 'prezentation')?_c('Download',{attrs:{"downloading":_vm.isDownloadingFileFromSnackbar,"file-name":_vm.getFilename,"download-file-name":_vm.getDownloadFilename,"merged-url":_vm.getMergedUrlForSaveToGdrive,"item":Object.assign({}, _vm.downloadItem, {name: _vm.slideName}),"log-download":_vm.logDownload,"on-download":function () { return _vm.handleSlideDownload(); },"origin":"prezentation","limitsKey":"prezentation","isDisabled":_vm.isDownloadingFileFromSnackbar ||
            _vm.slideName.length === 0 ||
            _vm.slideName.length > 100,"speedDialDirection":'top',"speedDialPosTop":'-80',"speedDialPosRight":'0',"closePreviousPopups":function () {
              _vm.closePreviousPopups(_vm.slideName, _vm.addToLibrary);
            },"allowDownloadSettingsIcon":false}}):_vm._e()],1):_c('v-btn',{staticClass:"primaryBtn",attrs:{"disabled":(!_vm.isAddedToLibrary && _vm.slideName === '') || _vm.slideName.length > 100,"loading":_vm.operation.loading},on:{"click":_vm.handlePrimaryButtonAction}},[_vm._v(" "+_vm._s(_vm.primaryBtnText)+" ")])],1):_vm._e(),(_vm.actionFinished && _vm.operation.success && !_vm.operation.loading)?_c('div',{staticClass:"success"},[_c('v-icon',{attrs:{"color":"#20a7e0"}},[_vm._v("mdi-check")]),_c('span',[_vm._v(_vm._s(_vm.successMessage))])],1):_vm._e(),(_vm.actionFinished && !_vm.operation.success && !_vm.operation.loading)?_c('div',{staticClass:"success"},[_c('v-icon',{attrs:{"color":"#20a7e0","size":"18"}},[_vm._v("mdi-alert-outline")]),_c('span',[_vm._v(_vm._s(_vm.modalType === 'favorites' ? _vm.$t('premiumFeature.addToFavoritesFailed') : _vm.$t('premiumFeature.addToLibraryfailed')))])],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }