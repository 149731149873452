<template>
  <div class="acc-wrapper">
    <div class="acc-cntr">
      <div class="header-section">
        <div class="title-section">
          <h2>{{ $t('sideBarFeatures.accelerators') }}</h2>
          <div class="title-section-subheading">
            <span v-html="$t('acceleratorSteps.titleDesc')"></span>
          </div>
        </div>
      </div>

      <v-img
        class="banner-img"
        src="/assets/img/accelerators/header-banner.svg"
      />

      <AcceleratorContactUs :placement="'Accelerators'" />
    </div>

    <div class="acc-cntr">
      <div class="header-section">
        <div class="title-section">
          <h2>{{ $t('accelerators.weOffer') }}</h2>
        </div>
      </div>

      <Loading v-if="loading" />

      <div class="offer-cntr" v-else>
        <div v-for="(offer, index) in offerings" :key="index + '_offer'">
          <v-card>
            <v-img class="offer-img" :src="offer.imageUrlS3" contain />
            <v-card-title class="offer-title">
              {{ $t(offer.value) }}
            </v-card-title>
          </v-card>
        </div>
      </div>

      <AcceleratorContactUs :placement="'Accelerators we offer'" />
    </div>

    <div class="acc-cntr">
      <div class="header-section">
        <div class="title-section">
          <h2>{{ $t('overnightPresentations.howItWorks') }}</h2>
        </div>
      </div>

      <div ref="timeline" class="timeline-container">
        <div
          v-for="(content, index) in timelineData"
          :key="index + '_timeline'"
          class="timeline-item"
          :class="{ opposite: index % 2 === 0 }"
        >
          <v-card>
            <v-img
              height="200"
              width="300"
              :src="content.image"
              contain
              class="text-white"
            />
            <v-card-title class="timeline-title">
              {{ $t(content.heading) }}
            </v-card-title>
            <v-card-text class="timeline-desc">
              {{ $t(content.description) }}
            </v-card-text>
          </v-card>
          <div class="dot"></div>
        </div>
        <div
          ref="progress"
          class="progress-bar"
          :style="{
            top: timelineHeight / 6 + 'px',
            height: (timelineHeight / 3) * 2 + 'px',
          }"
        >
          <div
            class="progress-indicator"
            :style="{ height: progress + '%' }"
          ></div>
        </div>
      </div>

      <AcceleratorContactUs :placement="'How it works'" />
    </div>

    <div class="acc-cntr">
      <div class="header-section">
        <div class="title-section">
          <h2>{{ $t('accelerators.sampleAccelerators') }}</h2>
        </div>
      </div>

      <div class="container" data-pendo-id="beforeAndAfter">
        <v-carousel
          hide-delimiter-background
          height="auto"
          prev-icon="mdi-chevron-left"
          v-model="activeCarouselItem"
          next-icon="mdi-chevron-right"
        >
          <v-carousel-item
            :class="activeCarouselItem === index && 'v-window-item--active'"
            v-for="(content, index) in sliderImages"
            :key="index"
            eager
          >
            <BeforeAfter
              :value="50"
              :step="0.01"
              :beforeImage="content.beforeImage"
              :afterImage="content.afterImage"
            />
          </v-carousel-item>
        </v-carousel>
      </div>

      <AcceleratorContactUs :placement="'Sample of Accelerators provided'" />
    </div>

    <div class="acc-cntr">
      <div class="header-section">
        <div class="title-section">
          <h2>{{ $t('accelerators.ourExperts') }}</h2>
        </div>
      </div>

      <div class="experts-cntr">
        <div v-for="(expert, index) in experts" :key="index + '_expert'">
          <v-card>
            <div class="expert-header">
              <div class="expert-img-cntr">
                <v-img :src="expert.profileImgURL" contain />
              </div>

              <div class="expert-title">
                <v-card-title class="expert-name">{{
                  $t(expert.fullName)
                }}</v-card-title>
                <v-card-subtitle
                  >{{ $t(expert.designation) }},
                  {{ $t(expert.company) }}</v-card-subtitle
                >
              </div>
            </div>
            <v-card-text class="expert-desc">{{
              $t(expert.description)
            }}</v-card-text>
          </v-card>
        </div>
      </div>

      <AcceleratorContactUs :placement="'Our experts'" />
    </div>

    <div class="acc-cntr">
      <div class="header-section">
        <div class="title-section">
          <h2>{{ $t('overnightPresentations.customerTestimonials') }}</h2>
        </div>
      </div>

      <div class="customer-stats">
        <div v-for="(item, i) in customerStories" :key="i + '_testimonial'">
          <v-card>
            <p class="customer-story-msg">{{ $t(item.testimony) }}</p>
            <p class="customer-story-by">
              - {{ $t(item.designation) }}, {{ item.company }}
            </p>
          </v-card>
        </div>
      </div>

      <AcceleratorContactUs :placement="'Customer testimonials'" />
    </div>

    <div class="acc-cntr">
      <div class="header-section">
        <div class="title-section">
          <h2>{{ $t('overnightPresentations.faq') }}</h2>
        </div>
      </div>

      <div class="faq-questions">
        <v-expansion-panels multiple>
          <v-expansion-panel
            v-for="(faq, index) in faqData"
            :key="index"
            @click="handleFAQHandle(faq.question)"
          >
            <v-expansion-panel-header class="faq-header">
              {{ $t(faq.question) }}
            </v-expansion-panel-header>
            <v-expansion-panel-content
              class="faq-content"
              v-html="returnMarked($t(faq.answer))"
            >
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>

      <AcceleratorContactUs :placement="'FAQ'" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import DOMPurify from 'dompurify';
import { marked } from 'marked';
import acceleratorsServices from './AcceleratorsServices';
import AcceleratorContactUs from './AcceleratorContactUs.vue';
import BeforeAfter from '../OvernightPrezentations/BeforeAfter.vue';
import Loading from '../../common/Loading.vue';
import { getFileURL } from '@/utils/calyrex';
import { trackAcceleratorsEvents } from '../../common/Analytics/AcceleratorsEvents';
import { TD_FAQTITLE } from '../../common/Analytics/MatomoTrackingDataHelper';
import EventBus from '../../common/event-bus';

export default {
  name: 'AcceleratorsContainer',
  created() {
    window.addEventListener('scroll', this.onScroll);
  },
  data() {
    return {
      loading: true,
      timelineHeight: 0,
      offerings: [],
      experts: [],
      customerStories: [],
      timelineData: [
        {
          image: '/assets/img/accelerators/timelines/timeline_1.svg',
          heading: 'acceleratorSteps.stepOneTitle',
          description: 'acceleratorSteps.stepOneDescp',
        },
        {
          image: '/assets/img/accelerators/timelines/timeline_2.svg',
          heading: 'acceleratorSteps.stepTwoTitle',
          description: 'acceleratorSteps.stepTwoDescp',
        },
        {
          image: '/assets/img/accelerators/timelines/timeline_3.svg',
          heading: 'acceleratorSteps.stepThreeTitle',
          description: 'acceleratorSteps.stepThreeDescp',
        },
      ],
      sampleData: [
        {
          image: '/assets/img/accelerators/sample-slide.svg',
        },
      ],
      activeCarouselItem: 0,
      sliderImages: [
        {
          beforeImage:
            '/assets/img/accelerators/sample-images/1-example-before.png',
          afterImage:
            '/assets/img/accelerators/sample-images/1-example-after.png',
        },
        {
          beforeImage:
            '/assets/img/accelerators/sample-images/2-example-before.png',
          afterImage:
            '/assets/img/accelerators/sample-images/2-example-after.png',
        },
        {
          beforeImage:
            '/assets/img/accelerators/sample-images/3-example-before.png',
          afterImage:
            '/assets/img/accelerators/sample-images/3-example-after.png',
        },
        {
          beforeImage:
            '/assets/img/accelerators/sample-images/4-example-before.png',
          afterImage:
            '/assets/img/accelerators/sample-images/4-example-after.png',
        },
        {
          beforeImage:
            '/assets/img/accelerators/sample-images/5-example-before.png',
          afterImage:
            '/assets/img/accelerators/sample-images/5-example-after.png',
        },
      ],
      progress: 0,
      faqData: [
        {
          question: 'acceleratorFAQ.question1',
          answer: 'acceleratorFAQ.answer1',
        },
        {
          question: 'acceleratorFAQ.question2',
          answer: 'acceleratorFAQ.answer2',
        },
        {
          question: 'acceleratorFAQ.question3',
          answer: 'acceleratorFAQ.answer3',
        },
        {
          question: 'acceleratorFAQ.question4',
          answer: 'acceleratorFAQ.answer4',
        },
        {
          question: 'acceleratorFAQ.question5',
          answer: 'acceleratorFAQ.answer5',
        },
      ],
      leftNavExpanded: false,
    };
  },
  async mounted() {
    setTimeout(() => {
      EventBus.$emit('MINIMIZE_LEFTNAV');
    }, 50);
    EventBus.$on('LEFTNAV_EXPANDED', () => {
      this.leftNavExpanded = true;
    });
    EventBus.$on('LEFTNAV_MINIMIZED', () => {
      this.leftNavExpanded = false;
    });

    if (this.$refs.timeline) {
      const rect = this.$refs.timeline.getBoundingClientRect();
      this.timelineHeight = rect.height;
    }
    this.getTestimonialData();
  },
  computed: {
    ...mapState('users', ['currentUser']),
  },
  methods: {
    async getProfileImage(imageUrl) {
      let s3Url = '';
      if (imageUrl) {
        await getFileURL(null, `public/${imageUrl}`, null, 'storage')
          .then((s3obj) => {
            s3Url = s3obj;
          })
          .catch((err) => {
            console.log(err);
          });
      }

      return s3Url;
    },
    handleFAQHandle(question) {
      trackAcceleratorsEvents.acceleratoeFAQClick(this.currentUser, {
        [TD_FAQTITLE]: this.$t(question),
      });
    },
    async getTestimonialData() {
      try {
        const resp = await acceleratorsServices.methods.getTestimonialData();
        // Process Types Data
        this.offerings = await resp?.data?.types.map((ele) => {
          this.getProfileImage(ele.imageUrl).then((url) => {
            ele.imageUrlS3 = url.replace(/"/g, "'");
          });

          return ele;
        });

        // Process Experts Data
        this.experts = await resp?.data?.experts.map((ele) => {
          this.getProfileImage(ele.profileImg).then((url) => {
            ele.profileImgURL = url.replace(/"/g, "'");
          });

          return ele;
        });
        // Process Customer Stories
        this.customerStories = resp?.data?.customerTestimonials || [];

        setTimeout(() => {
          this.loading = false;
        }, 2000);
      } catch (err) {
        this.loading = false;
        console.error(err);
      }
    },

    onScroll() {
      window.requestAnimationFrame(() => {
        const target = this.$refs.progress.getBoundingClientRect();
        const top = target.top + this.timelineHeight;
        const itemLength =
          // eslint-disable-next-line no-nested-ternary
          window.scrollY + window.innerHeight <= top
            ? 0
            : window.scrollY + window.innerHeight - top <=
              window.innerHeight + target.height
            ? window.scrollY + window.innerHeight - top
            : window.innerHeight + target.height;
        const percentage = Math.round(
          (itemLength * 100) / (window.innerHeight + target.height),
        );
        this.progress = percentage;
      });
    },
    returnMarked(content) {
      return DOMPurify.sanitize(
        `<div class="marked-container">${marked(content)}</div>`,
      );
    },
  },
  beforeDestroy() {
    EventBus.$off('LEFTNAV_EXPANDED');
    EventBus.$off('LEFTNAV_MINIMIZED');
    window.removeEventListener('scroll', this.onScroll);
  },
  components: { AcceleratorContactUs, BeforeAfter, Loading },
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss', '@/scss/app.scss';
.acc-wrapper {
  width: 100%;
  height: 100%;
  padding: 0 1rem;
}
.acc-cntr {
  margin: auto;
  margin-top: 15px;
  width: 98%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .spinning-loading-wrapper {
    position: unset;
    height: fit-content;
  }

  .header-section {
    margin: 20px 0px 20px 0px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    .title-section-subheading {
      font-size: 16px;
    }
    .red-color {
      font-weight: 700;
    }

    h2 {
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      letter-spacing: -0.01em;
      color: #000000;
      margin-bottom: 8px;
    }
  }

  .banner-img {
    width: 90%;
    margin-bottom: 1rem;
  }

  .submit-req-btn {
    display: grid;
    place-items: center;
    margin: 1rem 0;
  }
}

.offer-cntr {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  grid-gap: 1.5rem;
  width: 100%;
  margin-bottom: 1rem;

  .v-card {
    width: 100%;
    max-width: 340px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1), -1px -1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 12px;

    .offer-img {
      width: 50%;
      margin-top: 1rem;
    }

    .offer-title {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      text-align: center;
      color: #000000;
      font-size: 1rem;
    }
  }
}

.timeline-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .timeline-item {
    position: relative;
    width: 50%;

    .dot {
      height: 36px;
      width: 36px;
      position: absolute;
      top: 50%;
      left: 50%;
      border: 2px solid #21a7e0;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      background-color: white;
      z-index: 3;
    }
  }

  .v-card {
    transform: translateX(calc(50% + 40px));
  }

  .opposite {
    .v-card {
      transform: translateX(calc(-50% - 40px));
    }
  }

  .progress-bar {
    width: 7px;
    background: #d8d8d8;
    position: absolute;

    .progress-indicator {
      width: 100%;
      background: #21a7e0;
    }
  }
  .v-card {
    max-width: 535px;
    width: calc(100% - 40px);
    margin: auto;
    height: 350px;
    padding: 10px;
    margin-bottom: 20px;
    background: #ffffff;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1), -1px -1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .timeline-title {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      text-align: center;
      color: #000000;
    }
    .timeline-desc {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      text-align: center;
      color: #697077;
    }
  }
}

.sample-cntr {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 1rem;

  .v-img {
    width: 100%;
  }
}

.sample-slide-count {
  display: flex;
  gap: 0.5rem;
}

.sample-slide-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.experts-cntr {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  padding: 0.5rem;
  overflow-x: scroll;
  width: 100%;
  max-width: fit-content;

  .v-card {
    width: 300px;
    height: 100%;
    overflow: hidden;
    border-radius: 12px;

    .expert-header {
      position: relative;

      .expert-img-cntr {
        height: 300px;
        overflow: hidden;
      }

      .v-img {
        width: 100%;
      }

      .expert-title {
        position: absolute;
        bottom: 0;
        background-color: #00000076;
        color: white;
        width: 100%;
        text-align: center;
        font-family: Lato;
        line-height: 24px;

        .expert-name {
          display: block;
          text-align: center;
          font-size: 1.2rem;
          font-weight: 700;
        }
      }
    }

    .expert-desc {
      font-family: Lato;
      font-size: 1rem;
      font-weight: 400;
      line-height: 21px;
      text-align: left;
      color: #000000;
    }
  }
}

.customer-stats {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  padding: 0.5rem;
  overflow-x: scroll;
  width: 100%;
  max-width: fit-content;

  .v-card {
    padding: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px 16px;
    gap: 8px;
    min-height: 175px;
    width: 300px;
    height: 100%;
    overflow: hidden;
    border-radius: 12px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1), -1px -1px 4px rgba(0, 0, 0, 0.1);
    h3 {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;

      text-align: center;
    }
    .customer-story-by {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      text-align: end;
      color: black;
    }
    .customer-story-msg {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      align-items: flex-end;
      text-align: start;
      color: #697077;
    }
  }
}

.faq-questions {
  margin: 0px 0px 20px 0px;
  .faq-content {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    /* or 21px */

    /* New Palette/Primary/Primary 50 */

    color: #697077;
  }
  .faq-header {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    /* identical to box height, or 26px */

    color: #000000;
  }
  ::v-deep .v-expansion-panel {
    box-shadow: $item-box-shadow;
    border-radius: 8px;
    margin-top: 16px;
    padding: 24px 16px;

    &::before {
      box-shadow: none !important;
    }
    &::after {
      border: 0;
    }

    .v-expansion-panel-header {
      font-weight: 400;
      font-size: 20px;
      line-height: 130%;
    }

    .v-expansion-panel-content {
      display: block;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      margin: 1em 24px;
      .marked-container {
        ol {
          margin-bottom: 1em;
        }
        ul {
          margin-bottom: 1em;
        }
        p {
          margin-bottom: 0px;
        }
      }
    }
  }
}
</style>
