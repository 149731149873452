<template>
  <div class="listing-wrapper" :data-pendo-id="getContainerPendoText">
    <div class="heading">Select Slides</div>
    <div class="sub-heading-section">
      <p class="sub-heading-section__select-text">
        Your file contains {{ slidesList.length }}
        {{ slidesList.length === 1 ? 'slide' : 'slides' }}. Please select the
        {{ slidesList.length === 1 ? 'slide' : 'slides' }} that you wish to
        {{ getTitleText }}
      </p>
      <v-checkbox
        v-model="selectAllSlides"
        color="primary"
        class="sub-heading-section__checkbox"
        @change="handleSelectAllSlides"
        hide-details
        dense
      >
        <template v-slot:label>
          <span
            style="color: #21a7e0"
            data-pendo-id="comply-select-all-slides-checkbox"
            >Select all</span
          >
        </template>
      </v-checkbox>
    </div>
    <v-row class="slides-selection-section">
      <v-col cols="3" v-for="(slide, i) in slidesList" :key="i">
        <slide-item
          :slidesSelected="selectedSlides"
          :slide="slide"
          :handleSelectSlide="handleSelectSlide"
          :slideIndex="i"
        />
      </v-col>
    </v-row>
    <div class="cta-section">
      <label class="cta-section__select-count"
        >{{ selectedSlides.length ? selectedSlides.length : 'No' }}
        {{ selectedSlides.length === 1 ? 'slide' : 'slides' }} selected</label
      >
      <v-btn
        fab
        class="primary-button cta-section__next-btn"
        color="#21a7e0"
        rounded
        width="135"
        height="48"
        @click="onNext"
        :loading="loading"
        :disabled="!selectedSlides.length"
        :data-pendo-id="getButtonPendoText"
        style="text-transform: none !important"
      >
        Next
      </v-btn>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import SlideItem from './slideItem.vue';
import { trackComplyEvents } from '../../../common/Analytics/ComplyEvents';
import {
  TD_NUMBEROFSLIDES,
  TD_CHECKED,
  TD_UNCHECKED,
  TD_PREZNAME,
  TD_SELECT_ALL_CHECKBOX,
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
} from '../../../common/Analytics/MatomoTrackingDataHelper';

export default {
  name: 'SlidesListing',
  components: {
    SlideItem,
  },
  props: {
    slidesList: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    origin: {
      type: String,
      default: 'comply',
    },
  },
  data() {
    return {
      selectedSlides: [],
      selectAllSlides: true,
    };
  },
  computed: {
    ...mapState('users', ['currentUser']),
    ...mapState('comply', ['fileDetails']),
    getContainerPendoText() {
      if (this.origin === 'synthesis') {
        return 'syntesis-slide-selection';
      }
      if (this.origin === 'redesign') {
        return 'redesign-slide-selection';
      }
      return null;
    },
    getButtonPendoText() {
      if (this.origin === 'synthesis') {
        return 'synthesis-next-cta-button';
      }
      if (this.origin === 'redesign') {
        return 'redesign-next-cta-button';
      }
      return 'comply-next-cta-button';
    },
    getTitleText() {
      let title = '';
      if (this.origin === 'comply') {
        title = 'convert and make brand compliant.';
      } else if (this.origin === 'synthesis') {
        title = 'synthesize.';
      } else {
        title = 'redesign.';
      }
      return title;
    },
  },
  watch: {
    selectedSlides(newVal, oldVal) {
      if (
        newVal.length !== oldVal.length &&
        newVal.length === this.slidesList.length
      ) {
        this.handleSelectAllSlides(true);
      }
    },
    slidesList(newVal, oldVal) {
      if (newVal.length !== oldVal.length) {
        this.handleSelectAllSlides(true);
      }
    },
  },
  methods: {
    handleSelectSlide(e, slide) {
      if (e) {
        this.selectedSlides.push(slide);
        if (this.selectedSlides.length === this.slidesList.length) {
          this.selectAllSlides = true;
        }
      } else {
        this.selectAllSlides = false;
        this.selectedSlides = this.selectedSlides.filter(
          (item) => item.key !== slide.key,
        );
        if (!this.selectedSlides.length) this.selectAllSlides = false;
      }
    },
    handleSelectAllSlides(e) {
      if (e) {
        this.selectedSlides = [...this.slidesList];
      } else {
        this.selectedSlides = [];
        this.selectAllSlides = false;
      }
    },
    onNext() {
      this.$emit('handleOnNext', this.selectedSlides);
      if (this.origin === 'comply') {
        const otherData = {
          [TD_NUMBEROFSLIDES]: this.selectedSlides.length,
          [TD_COMMON_COLUMN_NAME]: TD_SELECT_ALL_CHECKBOX,
          [TD_COMMON_COLUMN_VALUE]: this.selectAllSlides
            ? TD_CHECKED
            : TD_UNCHECKED,
          [TD_PREZNAME]: this.fileDetails.name,
        };
        trackComplyEvents.complianceCheckerUploadCheckComplianceNext(
          this.currentUser,
          otherData,
        );
      }
    },
  },
  mounted() {
    this.handleSelectAllSlides(true);
    console.log(this.slidesList, this.selectedSlides, '-----list------');
  },
};
</script>
<style scoped lang="scss"></style>
<style scoped lang="scss">
.listing-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;
}

.heading {
  align-self: stretch;
  color: #000;

  /* Strong */
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.sub-heading-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  &__select-text {
    color: #000;
    /* Body */
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    /* 20.8px */
    margin-bottom: 0;
  }

  > .v-input--checkbox {
    margin-top: 0;
    padding: 0;
  }
}

.slides-selection-section {
  // height: calc(100vh - 520px);
  // overflow-y: scroll;
}

.cta-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;
  position: sticky;
  bottom: 0px;
  padding: 20px;
  background-color: #fff;

  &__select-count {
    color: var(--color-styles-old-dark, #212121);
    text-align: center;

    /* Large Label */
    font-family: Lato;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.14px;
  }

  &__next-btn {
    color: var(--color-styles-old-white, #fff);

    /* Field Name */
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: none;
    letter-spacing: normal;
    padding: 0 25px !important;
  }
}

::v-deep .v-input--checkbox {
  .v-input--selection-controls__input {
    .v-icon {
      color: #21a7e0;
    }
  }
}
</style>
