var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"templateItemContainer"},[(!_vm.isDisabled)?_c('div',{staticClass:"template-card-wrapper",class:{
      'template-border-highlight': _vm.isSelected,
      'disabled-template': _vm.isDisabled,
    },on:{"click":_vm.onTemplateSelect}},[_c('div',{staticClass:"template-name-block"},[_c('p',{staticClass:"template-name-block__name"},[_vm._v(" "+_vm._s(_vm.template.name)+" ")])]),_c('v-row',[_c('v-col',[_c('v-img',{attrs:{"loading":"lazy","lazy-src":'/assets/img/comply/template1.png',"aspect-ratio":16 / 9,"src":_vm.template.thumbnailURL,"contain":"","alt":""}})],1),_c('v-col',[_c('v-img',{attrs:{"loading":"lazy","lazy-src":'/assets/img/comply/template1.png',"aspect-ratio":16 / 9,"src":_vm.getSampleSlideUrl,"alt":"","contain":""}})],1)],1)],1):_c('v-tooltip',{attrs:{"attach":"","absolute":"","max-width":"200","content-class":'center-tooltip'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('div',_vm._g({staticClass:"template-card-wrapper",class:{
          'template-border-highlight': _vm.isSelected,
          'disabled-template': _vm.isDisabled,
        }},on),[_c('div',{staticClass:"template-name-block"},[_c('p',{staticClass:"template-name-block__name"},[_vm._v(" "+_vm._s(_vm.template.name)+" ")])]),_c('v-row',[_c('v-col',[_c('v-img',{attrs:{"loading":"lazy","lazy-src":'/assets/img/comply/template1.png',"aspect-ratio":16 / 9,"src":_vm.template.thumbnailURL,"contain":"","alt":""}})],1),_c('v-col',[_c('v-img',{attrs:{"loading":"lazy","lazy-src":'/assets/img/comply/template1.png',"aspect-ratio":16 / 9,"src":_vm.getSampleSlideUrl,"alt":"","contain":""}})],1)],1)],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('common.comingSoon')))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }