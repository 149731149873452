<template>
  <div v-if="!suggestion.isGroupSuggestion">
    <div
      :class="{
        'color-fill-suggestion adjust-left-with-color-picker': !hideColorPicker,
        'color-fill-suggestion adjust-left-without-color-picker':
          hideColorPicker,
      }"
    >
      <div class="block">
        <div class="block__wrapper">
          <span class="block__text">{{ suggestionTextLeft }}</span>
          <div class="color-and-details preview-shape-container1">
            <PreviewShape
              class="preview-shape1"
              :color="'#' + currentColor"
              :suggestionName="suggestionName"
              :colorDisplayName="suggestion.display_name"
            />
            <div
              class="d-flex"
              v-if="suggestion.display_name"
              style="width: 180px"
            >
              <div class="color-details-with-display-name">
                <span>{{ getThemeColorDisplayName }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="arrow">
        <v-icon>mdi-arrow-right</v-icon>
      </div>
      <div class="block">
        <div class="block__wrapper">
          <span class="block__text">{{ suggestionTextRight }}</span>
          <div class="color-and-details preview-shape-container1">
            <PreviewShape
              class="preview-shape1"
              :color="'#' + suggestedColor"
              :suggestionName="suggestionName"
            />
          </div>
        </div>
      </div>
      <div class="icon-box" v-if="!hideColorPicker">
        <v-tooltip
          bottom
          max-width="250"
          content-class="tooltip-content"
          fixed
          close-delay="300"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              v-if="!!!suggestion.status"
              icon
              :data-pendo-id="
                !isActionLoading ? 'comply-edit-color-pencil' : ''
              "
              @click="toggleColorPicker(suggestion)"
            >
              <v-icon color="primary" size="24">mdi-eyedropper-variant</v-icon>
            </v-btn>
          </template>
          <span>{{ 'Choose new color' }}</span>
        </v-tooltip>
      </div>
    </div>
  </div>
  <div class="group-suggestion-description" v-else>
    {{ suggestion.description }}
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';
import PreviewShape from './PreviewShape.vue';
import { getThemeColorSuggestionDisplayName } from '../../utils/comply-helper.utils';

export default {
  props: {
    suggestion: {
      type: Object,
      required: true,
    },
    hideColorPicker: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PreviewShape,
  },
  computed: {
    ...mapGetters('comply', ['colorPickerState', 'isActionLoading']),
    currentColor() {
      if (
        this.suggestion &&
        this.suggestion.current &&
        this.suggestion.current.length === 8
      ) {
        return this.suggestion.current.substring(2);
      }
      return this.suggestion.current;
    },

    suggestedColor() {
      const color = this.suggestion?.userSelectedColor
        ? this.suggestion?.userSelectedColor
        : this.suggestion?.suggested;
      if (color && color.length === 8) {
        return color.substring(2);
      }
      return color;
    },
    suggestionName() {
      if (this.suggestion && this.suggestion.name) {
        return this.suggestion.name;
      }
      return '';
    },
    suggestionTextLeft() {
      if (
        this.suggestion?.status &&
        this.suggestion?.status.toLowerCase() === 'accepted'
      )
        return 'Previous';
      return 'Current';
    },
    suggestionTextRight() {
      if (this.suggestion?.userSelectedColor) return 'Selected';
      if (
        this.suggestion?.status &&
        this.suggestion?.status.toLowerCase() === 'accepted'
      )
        return 'Current';
      return 'Suggested';
    },
    getThemeColorDisplayName() {
      return getThemeColorSuggestionDisplayName(this.suggestion?.display_name);
    },
  },
  methods: {
    ...mapMutations('comply', ['setToggleColorPicker']),
    ...mapActions('comply', ['updateSuggestedColor', 'setColorPickerState']),
    toggleColorPicker(suggestion) {
      this.setColorPickerState({
        suggestionNumber: this.suggestion.index,
        panelOpen: true,
        current: suggestion?.current,
        suggested: suggestion?.suggested,
        category: this.category,
        suggestion,
        itemIndex: this.itemIndex,
        selectedColor: suggestion?.userSelectedColor,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.color-fill-suggestion {
  display: flex;
  // align-items: center;
  justify-content: space-around;
  padding: 8px 38px 16px 31px;
}
.adjust-left-with-color-picker {
  padding-left: 53px !important;
}
.adjust-left-without-color-picker {
  padding-left: 38px !important;
}
.block {
  display: flex;

  &__text {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
  }
  &__wrapper {
    gap: 12px;
    display: flex;
    flex-direction: column;
  }
}
.preview-shape {
  border-radius: 25px;
  width: 100%;
  height: 100%;
}
.preview-shape-container {
  width: 100px;
  height: 100px;
}

.arrow {
  color: #191c1e;
  width: 24px;
  height: 100%;
  margin: 45px 24px 0px 24px;
}

.arrow__with-theme-color {
  color: #191c1e;
  width: 24px;
  height: 24px;
  margin: 24px;
  margin-left: -19px !important;
}

.icon-box {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 37px;
}

.group-suggestion-description {
  font-weight: 500;
  letter-spacing: 0.2px;
  margin: 0 54px 10px 65px;
  font-size: 14px;
  line-height: 1.3;
  color: #000000;
}

.color-details-with-display-name {
  color: #000;
  padding: 6px 5px;
}
</style>
