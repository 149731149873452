<template>
  <div class="imageSuggestionContainer">
    <div class="labelAndImg">
      <div class="label">{{ suggestionTextLeft }}</div>
      <div class="currentWrapper">
        <v-img
          contain
          :src="currentThumbnail"
          :lazy-src="'/assets/img/slides/placeholder-slide.svg'"
          loading="lazy"
          alt
          :aspect-ratio="16 / 9"
        />
      </div>
    </div>
    <div class="iconContainer">
      <v-icon>{{ 'mdi-arrow-right' }}</v-icon>
    </div>
    <div class="labelAndImg">
      <div class="label">{{ suggestionTextRight }}</div>
      <div class="suggestedWrapper">
        <v-img
          contain
          :src="suggestedThumbnail"
          :lazy-src="'/assets/img/slides/placeholder-slide.svg'"
          loading="lazy"
          alt
          :aspect-ratio="16 / 9"
        />
      </div>
    </div>
    <div class="imageLibraryLaunch">
      <v-tooltip
        bottom
        max-width="250"
        content-class="tooltip-content"
        fixed
        close-delay="300"
      >
        <template v-slot:activator="{ on, attrs }">
          <!-- <v-icon
            v-bind="attrs"
            v-on="on"
            v-if="!suggestion.status"
            color="primary"
            @click="handleReplaceIconPanelOpen"
          >
            {{ 'mdi-image-outline' }}
          </v-icon> -->
          <v-img
            v-bind="attrs"
            v-on="on"
            contain
            src="/assets/icons/comply/note_stack_add.svg"
            :lazy-src="'/assets/img/slides/placeholder-slide.svg'"
            loading="lazy"
            alt
            :aspect-ratio="16 / 9"
            @click="handleReplaceIconPanelOpen"
            v-if="!suggestion.status"
          >
          </v-img>
        </template>
        <span>{{ 'Choose new icon' }}</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
// import { v4 as uuidv4 } from 'uuid';
import { mapActions } from 'vuex';
import { getLoggedInUserToken } from '../../../../../utils/api';

export default {
  name: 'IconSuggestion',
  props: {
    suggestion: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: '',
    },
    category: {
      type: Object,
      default: () => ({}),
    },
    itemIndex: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    suggestionTextLeft() {
      if (
        this.suggestion?.status &&
        this.suggestion?.status.toLowerCase() === 'accepted'
      )
        return 'Previous';
      return 'Current';
    },
    suggestionTextRight() {
      if (
        this.suggestion?.status &&
        this.suggestion?.status.toLowerCase() === 'accepted'
      )
        return 'Current';
      if (
        this.suggestion.suggested.length === 1 &&
        this.suggestion.suggested[0].origin &&
        this.suggestion.suggested[0].origin === 'Brand'
      )
        return 'Selected';
      return 'Suggested';
    },
  },
  data() {
    return {
      currentThumbnail: '',
      suggestedThumbnail: '',
      suggestedThumbnailOnLoad: '',
    };
  },
  methods: {
    ...mapActions('comply', ['setReplaceIconSuggestionState']),

    handleReplaceIconPanelOpen() {
      this.setReplaceIconSuggestionState({
        suggestionNumber: this.suggestion.index,
        current: this.currentThumbnail,
        suggested: this.suggestedThumbnail,
        panelOpen: true,
        color: this.suggestion.avatarColor,
        suggestedOnLoad: this.suggestedThumbnail,
        category: this.category,
        suggestion: this.suggestion,
        itemIndex: this.itemIndex,
      });
    },

    async handlePopulateThumbnails() {
      const token = await getLoggedInUserToken();
      const awsAssetsDistribution = process.env.VUE_APP_AWS_ASSETS_DISTRIBUTION;
      this.currentThumbnail = `${awsAssetsDistribution}${this.suggestion?.current?.s3_path}?accesskey=${token}`;
      this.suggestedThumbnail = `${awsAssetsDistribution}/${this.suggestion?.suggested[0]?.s3_path}?accesskey=${token}`;
      this.suggestedThumbnailOnLoad = this.suggestedThumbnail;
    },
  },
  watch: {
    suggestion: {
      async handler(val) {
        if (val) {
          await this.handlePopulateThumbnails();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  // async mounted() {},
};
</script>

<style lang="scss" scoped>
.imageSuggestionContainer {
  margin: 20px 75px 40px 65px;
  display: flex;
  justify-content: space-between;
  .labelAndImg {
    .currentWrapper,
    .suggestedWrapper {
      width: 48px;
      height: 48px;
      // box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
      //   rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
      border-radius: 4px;
      .v-image {
        width: 100%;
        height: 100%;
        aspect-ratio: 16 / 9;
        border-radius: 4px;
      }
    }
    .label {
      font-size: 18px;
      color: #000000;
      margin: 0px 0px 8px 0px;
    }
  }
  .iconContainer,
  .imageLibraryLaunch {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    > .v-image {
      cursor: pointer;
    }
  }
}
</style>
