<template>
  <div class="access-requests">
    <div class="access-requests-title">
      {{ $t('fingerprintvue.accessRequests.fingerprintAccessRequests') }}
    </div>
    <div>
      <v-data-table
        v-if="!loadingAccessRequests"
        :headers="tableHeaders"
        :items="accessRequests"
        :footer-props="{
          itemsPerPageOptions: [10, 25, 50, 100],
        }"
        :search="search"
        class="access-requests-table"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <div class="access-requests-searchbar">
              <Search
                :searchKey="searchKey"
                :placeholder="$t('fingerprintvue.accessRequests.searchBy')"
                :onSearch="onSearch"
              />
            </div>
            <div class="spacer"></div>
            <div class="access-requests-actions">
              <div
                @click="handleApproveOrDeclineAll(true)"
                :class="!pendingRequests.length ? 'action-disabled' : ''"
              >
                <v-icon
                  small
                  class="approve-all--auto"
                  color="primary"
                  :disabled="!pendingRequests.length"
                >
                  mdi-check-circle-outline
                </v-icon>
                <span>{{
                  $t('fingerprintvue.accessRequests.approveAll')
                }}</span>
              </div>
              <div
                @click="handleApproveOrDeclineAll(false)"
                :class="!pendingRequests.length ? 'action-disabled' : ''"
              >
                <v-icon
                  small
                  class="decline-all--auto"
                  color="primary"
                  :disabled="!pendingRequests.length"
                >
                  mdi-close-circle-outline
                </v-icon>
                <span>{{
                  $t('fingerprintvue.accessRequests.declineAll')
                }}</span>
              </div>
            </div>
          </v-toolbar>
        </template>
        <template v-slot:[`item.requestorFullName`]="{ item }">
          <div>
            <AudienceItem
              :itemClass="'no-padding clickable'"
              :firstName="item.requester_firstname"
              :lastName="item.requester_lastname"
              :email="item.requesterID"
              :isDifferentCompany="item.isRequesterDifferentCompany"
              :companyName="item.requester_company_name"
            />
          </div>
        </template>
        <template v-slot:[`item.audienceFullName`]="{ item }">
          <div>
            <AudienceItem
              v-if="item.isDifferentCompany"
              :itemClass="'no-padding clickable'"
              :firstName="item.audience_firstname"
              :lastName="item.audience_lastname"
              :companyName="item.companyName"
              :isDifferentCompany="item.isDifferentCompany"
              :fingerprintType="item.fingerprintType"
            />
            <AudienceItem
              v-else
              :itemClass="'no-padding clickable'"
              :firstName="item.audience_firstname"
              :lastName="item.audience_lastname"
              :email="item.audience_email"
              :fingerprintType="item.fingerprintType"
            />
          </div>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <clip-loader
            v-if="item.actionInProgress"
            :color="`#21a7e0`"
            :width="'16'"
            :height="'16'"
            :size="'16px'"
            class="cliploader"
          />
          <div v-else-if="item.status !== 'Pending'">{{ item.status }}</div>
          <clip-loader
            v-else-if="isBulkActionInitiated"
            :color="`#21a7e0`"
            :width="'16'"
            :height="'16'"
            :size="'16px'"
            class="cliploader"
          />
          <div v-else>
            <v-tooltip
              top
              max-width="200"
              content-class="tooltip-content"
              fixed
              open-delay="500"
              close-delay="300"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  class="approve-access-request--auto"
                  color="primary"
                  @click="handleApproveOrDecline(item, true)"
                >
                  mdi-check-circle-outline
                </v-icon>
              </template>
              <span>{{
                $t('fingerprintvue.accessRequests.approveRequests')
              }}</span>
            </v-tooltip>
            <v-tooltip
              top
              max-width="200"
              content-class="tooltip-content"
              fixed
              open-delay="500"
              close-delay="300"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  class="decline-access-request--auto"
                  color="primary"
                  @click="handleApproveOrDecline(item, false)"
                >
                  mdi-close-circle-outline
                </v-icon>
              </template>
              <span>{{
                $t('fingerprintvue.accessRequests.declineRequest')
              }}</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
      <clip-loader
        v-else
        :color="`#21a7e0`"
        :width="'60'"
        :height="'60'"
        :size="'50px'"
        class="cliploader"
      />
    </div>
    <ConfirmationDialog
      :show="showConfirmation"
      :on-primary-click="onDialogCancel"
      :on-secondary-click="onDialogConfirm"
      :on-close="onDialogCancel"
      :primary-btn-text="$t('common.cancel')"
      :secondary-btn-text="$t('common.confirm')"
      :title="
        isApproveAll
          ? $t('fingerprintvue.accessRequests.approveAllTitle')
          : $t('fingerprintvue.accessRequests.declineAllTitle')
      "
      :text="
        isApproveAll
          ? $t('fingerprintvue.accessRequests.confirmApproveAll')
          : $t('fingerprintvue.accessRequests.confirmDeclineAll')
      "
    />
  </div>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import AudienceApi from '../../../API/audience-api';
import Search from '../../common/Search.vue';
import AudienceItem from '../../common/AudienceItem.vue';
import { getIsDifferentCompany } from '../../../utils/common';
import BulkRequestNotification from './BulkRequestNotification.vue';
import { trackProfileEvents } from '../../common/Analytics/ProfileEvents';
import ConfirmationDialog from '@/components/common/ConfirmationDialog';

const APPROVED = 'Approved';
const DECLINED = 'Declined';
const PENDING = 'Pending';

export default {
  name: 'AccessRequests',
  props: {
    requestId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      tableHeaders: [
        {
          text: 'Requester Name',
          value: 'requestorFullName',
        },
        { text: 'Audience Name', value: 'audienceFullName' },
        { text: 'Requested On', value: 'createdTime' },
        { text: 'Actions', value: 'status', sortable: false },
      ],
      loadingAccessRequests: false,
      items: [],
      search: '',
      isBulkActionInitiated: false,
      isSearchReset: false,
      showConfirmation: false,
      isApproveAll: false,
    };
  },
  components: {
    AudienceItem,
    ClipLoader,
    Search,
    ConfirmationDialog,
  },
  computed: {
    ...mapState('users', ['currentUser']),
    accessRequests() {
      let itemsToMap = [];
      if (this.requestId && !this.isSearchReset) {
        itemsToMap = this.items.filter(
          (item) => item.id.toString() === this.requestId,
        );
      } else {
        itemsToMap = this.items;
      }
      return itemsToMap.map((item) => {
        const isDifferentCompany = getIsDifferentCompany(
          item,
          this.currentUser,
        );
        const isRequesterDifferentCompany = getIsDifferentCompany(
          {
            companyName: item.requester_company_name,
          },
          this.currentUser,
        );
        const {
          createdAt,
          audience_firstname: audFirstName,
          audience_lastname: audLastName,
          requester_firstname: reqFirstName,
          requester_lastname: reqLastName,
          fingerprintType,
        } = item;
        const createdTime = moment(createdAt).format('MM/DD/YYYY');
        return {
          ...item,
          createdTime,
          actionInProgress: false,
          isDifferentCompany,
          audience_lastname:
            item.audienceID === this.currentUser?.user?.id
              ? `${audLastName} (Me)`
              : audLastName,
          fingerprintType: fingerprintType || 'default',
          requestorFullName: `${reqFirstName} ${reqLastName}`,
          audienceFullName: `${audFirstName} ${audLastName}`,
          isRequesterDifferentCompany,
        };
      });
    },
    pendingRequests() {
      return this.accessRequests.filter(
        (request) => request.status === PENDING,
      );
    },
    searchKey() {
      if (this.requestId && !this.isSearchReset) {
        return `Displaying ${this.accessRequests.length} results`;
      }
      return this.search;
    },
  },
  mounted() {
    this.loadingAccessRequests = true;
    AudienceApi.methods
      .getAccessRequests()
      .then((data) => {
        this.loadingAccessRequests = false;
        if (data) {
          this.items = data;
        }
      })
      .catch((e) => {
        console.log(e);
        this.loadingAccessRequests = false;
      });
  },
  methods: {
    onSearch(val) {
      if (!val) {
        this.isSearchReset = true;
      }
      this.search = val;
    },
    onDialogCancel() {
      this.showConfirmation = false;
    },
    onDialogConfirm() {
      this.isBulkActionInitiated = true;
      this.showConfirmation = false;
      const queryParam = `?action=${
        this.isApproveAll ? 'approve_all' : 'decline_all'
      }`;
      AudienceApi.methods
        .approveOrDeclineAccessRequests(queryParam)
        .then(() => {
          const pendingItems = this.accessRequests.filter(
            (item) => item.status === PENDING,
          );
          pendingItems.forEach((item) => {
            item.status = this.isApproveAll ? APPROVED : DECLINED;
          });
          this.isBulkActionInitiated = false;

          this.$modal.show(
            BulkRequestNotification,
            {},
            {
              name: 'BulkRequestNotification',
              width: '400',
              height: '350px',
            },
          );
          if (this.isApproveAll) {
            trackProfileEvents.profileFingerprintAccessrequestsApproveAll(
              this.currentUser,
            );
          } else {
            trackProfileEvents.profileFingerprintAccessrequestsDeclineAll(
              this.currentUser,
            );
          }
        })
        .catch((e) => {
          console.log(e);
          this.isBulkActionInitiated = false;
          this.showConfirmation = false;
        });
    },
    handleApproveOrDecline(item, isApprove) {
      item.actionInProgress = true;
      const { id, audienceType } = item;
      const queryParam = `?action=${
        isApprove ? 'approve' : 'decline'
      }&id=${id}&type=${audienceType}`;
      AudienceApi.methods
        .approveOrDeclineAccessRequests(queryParam)
        .then(() => {
          item.actionInProgress = false;
          item.status = isApprove ? APPROVED : DECLINED;
          if (isApprove) {
            trackProfileEvents.profileFingerprintAccessrequestsActionApprove(
              this.currentUser,
            );
          } else {
            trackProfileEvents.profileFingerprintAccessrequestsActionDecline(
              this.currentUser,
            );
          }
        })
        .catch((e) => {
          console.log(e);
          item.actionInProgress = false;
        });
    },
    handleApproveOrDeclineAll(isApproveAll) {
      if (!this.pendingRequests.length) {
        return;
      }
      this.isApproveAll = isApproveAll;
      this.showConfirmation = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.access-requests {
  padding: 32px;
  padding-top: 10px;
  margin: 2px;

  ::v-deep .audience-item {
    cursor: pointer;
    padding-left: 0px;
  }

  ::v-deep table {
    thead {
      tr {
        th {
          &:first-child {
            padding-left: 0px;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          &:first-child {
            padding-left: 0px;
          }
        }
      }
    }
  }

  ::v-deep header {
    .v-toolbar__content {
      padding-left: 0px;
    }
  }

  .access-requests-actions {
    display: flex;
    div {
      color: #21a7e0 !important;
      display: flex;
      margin-right: 10px;

      &:hover {
        cursor: pointer;
      }

      .approve-all--auto,
      .decline-all--auto {
        margin-right: 5px;
      }
    }
    .action-disabled {
      color: $darker-gray !important;

      &:hover {
        cursor: not-allowed;
      }
    }
  }

  .access-requests-searchbar {
    width: 440px;

    ::v-deep .item-search-input {
      margin-left: 0px;
    }
  }

  .access-requests-table {
    .cliploader {
      display: inline-block;
    }
  }
  .access-requests-title {
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 700;
    letter-spacing: normal;
    line-height: 24px;
    margin-bottom: 14px;
    text-align: start;
  }

  .approve-access-request--auto,
  .decline-access-request--auto {
    margin-right: 10px;
  }

  .v-data-table::v-deep th {
    color: $zen-blue-default !important;
    font-size: 16px !important;
    font-weight: normal;
    min-width: 152px;

    .v-data-table-header__icon {
      margin-top: -2px;
    }
  }
}
</style>
