<template>
  <div class="walkthroughs-wrapper">
    <div v-if="previousRoute === 'search' && isSearchPage">
      <v-btn class="btn-back" @click="goToSearchLandingPage">
        <v-icon class="icon" color="primary">mdi-chevron-left</v-icon>
        <span>{{ $t('slides.backToSearch') }}</span>
      </v-btn>
    </div>
    <div class="walkthroughs-title">{{ $t('help.walkthrough') }}</div>
    <div class="toolbar">
      <v-text-field
        solo
        v-model="walkthroughSearch"
        single-line
        hide-details
        :placeholder="
          !!routeQuery
            ? $t('help.displayingResult')
            : $t('help.searchWalkthroughs')
        "
        class="item-search-input"
        autocomplete="off"
      >
        <template v-slot:append>
          <v-icon color="primary" @click="resetSearch">
            {{
              !!routeQuery || walkthroughSearch ? 'mdi-close' : 'mdi-magnify'
            }}
          </v-icon>
        </template></v-text-field
      >
      <div class="status-text">
        {{
          $t('help.statusText', {
            completed: completed,
            totalWalkthroughs: totalWalkthroughs,
          })
        }}
      </div>
    </div>
    <div
      class="walkthrough-items-wrapper"
      v-if="
        !isLoading &&
        walkthroughs &&
        walkthroughs.length > 0 &&
        filteredWalkthroughs.length
      "
    >
      <v-row style="width: 100%">
        <template v-for="(item, index) in filteredWalkthroughs">
          <v-col
            :key="index"
            :id="item.target_id"
            class="d-flex child-flex"
            xs="12"
            sm="12"
            md="6"
            lg="4"
            xl="3"
            v-if="isWalkthroughFeatureEnabled(item.target_id)"
          >
            <div class="walkthrough-item" @click="onWalkthroughClick(item)">
              <v-icon
                v-if="item.status === completedStatusText"
                class="check-mark"
              >
                mdi-check-circle</v-icon
              >
              <div class="walkthrough-item-img">
                <img :src="item.img" alt width="366px" height="265px" />
              </div>
              <div class="item-info">
                <div class="item-info-title">
                  {{ item.title }}
                </div>
                <v-tooltip
                  top
                  max-width="320"
                  content-class="tooltip-content"
                  fixed
                  open-delay="500"
                  close-delay="300"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-line-clamp:20="3"
                      v-bind="attrs"
                      v-on="on"
                      class="item-info-desc"
                    >
                      {{ item.description }}
                    </div>
                  </template>
                  <span>{{ item.description }}</span>
                </v-tooltip>
              </div>
            </div>
          </v-col>
        </template>
      </v-row>
    </div>
    <div v-if="isLoading" class="text-center">
      <v-progress-circular
        :size="68"
        :width="4"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-row v-if="!isLoading && !filteredWalkthroughs.length">
      <EmptyState
        img-url="/assets/img/help/no-results-found-send-support-request.svg"
        :is-btn-visible="true"
        :buttonName="'help.supportRequest'"
        :handleCTA="handleOpenSendRequest"
      >
        <template v-slot:description>
          <div class="no-results__wrapper">
            <p class="no-results-found">
              {{ $t('build.step1.storylineFieldNoResult') }}
            </p>
            <p class="no-results__description">
              {{ $t('help.noResultDescription') }}
            </p>
          </div>
        </template>
      </EmptyState>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import debounce from 'lodash/debounce';
import EmptyState from '../../../common/EmptyState.vue';
import HelpApi from '../../../../API/help-api';
import { getFileURL } from '@/utils/calyrex';
import { transformId } from '@/utils/common';
import LocalSearch from '@/services/localSearch';
import EventBus from '../../../common/event-bus';
import { logSearchQuery } from '../../../../utils/api-helper';
import {
  MatomoAnalyticsHandler,
  WALKTHROUGHS,
} from '../../../common/Analytics/MatomoAnalyticsHandler';
import { trackHelpEvents } from '../../../common/Analytics/HelpEvents';
import { TD_WALKTHROUGHTITLE } from '../../../common/Analytics/MatomoTrackingDataHelper';

export default {
  name: 'Walkthroughs',
  components: {
    EmptyState,
  },
  data() {
    return {
      previousRoute: '',
      isLoading: false,
      walkthroughSearch: '',
      walkthroughs: [],
      totalWalkthroughs: 0,
      completedStatusText: 'completed',
      featureEnablementConfig: {
        w11_know: 'isKnowEnabled',
        w14_overnight_prezentations: 'hasOPAccess',
        w15_generate: 'isGenerateFullAccess',
        w16_upload: 'isUploadFullAccess',
        w17_comply: 'isComplyFullAccess',
        w18_synthesis: 'isSynthesisFullAccess',
        w19_redesign: 'isRedesignFullAccess',
      },
      notEnabledFeature: 0,
    };
  },
  computed: {
    ...mapState('users', ['currentUser']),
    ...mapState('search', ['isSearchPage']),
    ...mapGetters('users', [
      'isKnowEnabled',
      'getOPAccessLevel',
      'isUploadFullAccess',
      'isGenerateFullAccess',
      'isComplyFullAccess',
      'isSynthesisFullAccess',
      'isRedesignFullAccess',
    ]),
    ...mapGetters('pendoStore', ['getGuides']),
    hasOPAccess() {
      return this.getOPAccessLevel && this.getOPAccessLevel !== 'no_access';
    },
    routeQuery() {
      return this.$route.query.id;
    },
    filteredWalkthroughs() {
      if (this.$route.query.id && this.walkthroughs.length) {
        // we transform the ID because Calyrex returns Ids with _ rather than -
        const foundItem = this.walkthroughs.find(
          (e) => e.uuid.toLowerCase() === transformId(this.$route.query.id),
        );
        if (foundItem) {
          const walkthroughGuide = this.pendoGuides.find(
            (guide) => guide.id.trim() === foundItem.guide_id.trim(),
          );
          if (walkthroughGuide && walkthroughGuide.show) {
            walkthroughGuide.show();
          }
          return [foundItem];
        }
        return [];
      }
      const searchKey = this.walkthroughSearch?.trim();
      if (searchKey) {
        return this.searchEngine.search(searchKey);
      }

      return this.walkthroughs.map((wt) => {
        wt.status = this.getWalkthroughStatus(wt);
        return wt;
      });
    },
    completed() {
      return this.walkthroughs.filter(
        (wt) => wt.status === this.completedStatusText,
      ).length;
    },
    pendoGuides() {
      return this.getGuides;
    },
  },
  async mounted() {
    this.previousRoute = this.$router.app.previousRoute.name;
    this.isLoading = true;
    this.walkthroughs = await this.getWalkthroughs();
    if (this.$route.query.log_search) {
      this.logSearch();
    }
    this.searchEngine = new LocalSearch(this.walkthroughs, [
      'title',
      'description',
    ]);
    this.totalWalkthroughs = this.getEnabledWalkthroughLength(
      this.walkthroughs,
    );
    this.isLoading = false;
    MatomoAnalyticsHandler.trackHelp(
      {
        type: WALKTHROUGHS.toLowerCase(),
      },
      this.currentUser,
    );
    EventBus.$on('walkthroughs_click', () => {
      this.resetSearch();
    });
  },
  beforeDestroy() {
    EventBus.$off('walkthroughs_click');
  },

  watch: {
    '$route.query': {
      async handler(newVal) {
        if (newVal.log_search) {
          this.logSearch();
        }
      },
      deep: true,
    },
    walkthroughSearch() {
      this.handleSearch();
    },
  },
  methods: {
    // eslint-disable-next-line func-names
    handleSearch: debounce(function () {
      if (this.walkthroughSearch) {
        trackHelpEvents.hybridSearch(
          this.currentUser,
          this.walkthroughSearch,
          'walkthroughs',
          this.filteredWalkthroughs?.length,
        );
      }
    }, 500),
    handleOpenSendRequest() {
      this.$root.$emit('openHelpMenuRequest');
    },
    goToSearchLandingPage() {
      this.$router.back();
    },
    logSearch() {
      const logPayload = {
        type: 'Help',
        searchTerm: '',
        searchTermResult: false,
      };
      logPayload.searchTerm =
        this.$route.query.display_name?.toLowerCase() || '';
      const foundItem = this.walkthroughs.find(
        (e) => e.uuid.toLowerCase() === transformId(this.$route.query.id),
      );
      logPayload.searchTermResult = !!foundItem;
      logSearchQuery(logPayload)
        .then()
        .catch((err) => console.log(err));
    },
    resetSearch() {
      this.walkthroughSearch = '';
      const query = { ...this.$route.query };
      // removing log_search and display_name from query
      if (query.log_search) {
        delete query.log_search;
      }
      if (query.display_name) {
        delete query.display_name;
      }
      if (this.$route.query.id) {
        this.$router.replace({ query: null });
      }
    },
    getWalkthroughStatus(item) {
      const findAndGetStatus = (pendoGuides) => {
        const walkthroughGuide = pendoGuides.find(
          (guide) => guide.id.trim() === item.guide_id.trim(),
        );

        if (!walkthroughGuide) {
          return '';
        }
        return walkthroughGuide.hasBeenSeen ? this.completedStatusText : null;
      };

      if (!this.pendoGuides) {
        return '';
      }

      if (localStorage.getItem(item.guide_id.trim()) === 'true') {
        return this.completedStatusText;
      }

      return findAndGetStatus(this.pendoGuides);
    },
    async getWalkthroughs() {
      return await HelpApi.methods.getHelpData('walkthroughs').then(
        async (resp) => {
          const decoratedWalkthroughs = resp.data.map(
            async (walkthrough) => await this.decorateWalkthrough(walkthrough),
          );

          const walkthroughs = await Promise.all(decoratedWalkthroughs);
          const eligibleWalkthroughs = walkthroughs.filter(
            (wt) =>
              wt.role === 'all' ||
              wt.role.toLocaleLowerCase() ===
                this.currentUser.user.role.toLocaleLowerCase(),
          );
          return eligibleWalkthroughs;
        },
        (error) => {
          console.log(error.response);
        },
      );
    },
    async decorateWalkthrough(walkthrough) {
      walkthrough.status = this.getWalkthroughStatus(walkthrough);
      walkthrough.img = await getFileURL(
        this.currentUser.user.cognitoID,
        walkthrough.image_link,
        this.currentUser.userIp,
      );
      return walkthrough;
    },
    onWalkthroughClick(item) {
      trackHelpEvents.helpWalkthroughsClick(this.currentUser, {
        [TD_WALKTHROUGHTITLE]: item.title,
      });
    },
    isWalkthroughFeatureEnabled(targetId) {
      if (this.featureEnablementConfig[targetId]) {
        const prop = this.featureEnablementConfig[targetId];
        return this[prop];
      }
      return true;
    },
    getEnabledWalkthroughLength(walkthorughs) {
      let count = 0;
      for (let index = 0; index < walkthorughs.length; index++) {
        const wt = walkthorughs[index];
        if (this.isWalkthroughFeatureEnabled(wt.target_id)) {
          count++;
        }
      }
      return count;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/app.scss', '@/scss/variables.scss';
.walkthroughs-wrapper {
  padding: 1.5rem 2rem 2rem;

  .btn-back {
    border: none;
    text-transform: none;
    background-color: #ffffff !important;
    box-shadow: none;
    letter-spacing: normal;
    color: #21a7e0;
    padding: 0;
    margin: 0px 0px 0px -8px;
  }

  ::v-deep {
    .item-search-input {
      align-items: center;
      background-color: #fff;
      border: 1px solid transparent;
      border-radius: 24px;
      box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
      display: flex;
      justify-content: flex-start;
      margin-left: 2px;
      padding: 0 !important;
      position: relative;
      max-width: 425px;
      z-index: 3;

      > .v-input__control {
        min-height: 40px;
        > .v-input__slot {
          box-shadow: none;
        }
      }

      .v-input__control > .v-input__slot:before {
        border-style: none;
      }

      input {
        background-clip: padding-box;
        border: none;
        color: $gray-search-input;
        display: block;
        font-size: 14px;
        line-height: 1.25;
        margin: 0 auto;
        outline: none;
        padding: 0.5rem 0.7rem;
        transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
        width: 100%;
      }

      input::placeholder {
        color: $gray-dark-login-text !important;
        font-size: 14px;
        opacity: 1;
      }

      .v-input__append-inner {
        padding-right: 5px;
      }
    }
  }

  .no-results__wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    .no-results-found {
      font-size: 24px;
      font-weight: 600;
      letter-spacing: -0.01em;
      line-height: 29px;
      text-align: center;
    }
    .no-results__description {
      height: 52px;
      line-height: 130%;
      margin-bottom: -10px !important;
      text-align: center;
      width: 344px;
    }
  }

  .toolbar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    .status-text {
      color: $gray-dark-login-text;
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      font-weight: normal;
      height: 19px;
      letter-spacing: normal;
      line-height: normal;
      position: relative;
      right: 24px;
      text-align: right;
      top: 17px;
    }
  }

  .walkthrough-item {
    border-radius: 8px;
    box-shadow: $element-box-shadow-default;
    height: 422px;
    position: relative;
    &:hover {
      box-shadow: $element-box-shadow-hover;
      cursor: pointer;
    }

    .check-mark {
      color: $zen-blue-default !important;
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 3;
    }
    .completed {
      background-color: $zen-blue-default !important;
    }

    .item-info {
      padding: 16px;

      .item-info-desc {
        align-items: center;
        font-size: 16px;
        line-height: 19px;
        word-break: break-word !important;
      }

      .item-info-title {
        align-items: center;
        color: $zen-blue-default;
        display: flex;
        font-size: 20px;
        font-weight: normal;
        height: 26px;
        line-height: 1.3;
        margin-bottom: 12px;
        text-align: left;
        width: 328px;
      }
    }

    .status-bar {
      height: 4px;
      background-color: $gray-background-hover;
    }

    .walkthrough-item-img {
      border-bottom: solid 1px #efefef;
      cursor: pointer;
      display: flex;
      justify-content: center;
      position: relative;
    }
  }

  .walkthroughs-title {
    color: #000;
    font-size: 24px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 600;
    letter-spacing: normal;
    line-height: normal;
    margin-bottom: 25px;
    text-align: left;
  }

  .walkthrough-items-wrapper {
    height: 70vh;
    overflow-y: auto;
    padding: 5px;
    width: 100%;
  }
}
</style>
