<template>
  <div class="main-wrapper">
    <div class="slides-ph">
      <div class="slides-ph__counter">
        <div class="slides-ph__counter--text">Slides</div>
        <v-badge
          v-model="slideCount"
          color="#FFE1B7"
          class=""
          inline
          overlap
          bordered
        >
          <template v-slot:badge>
            <span class="slides-ph__counter--count">{{ slideCount }}</span>
          </template>
        </v-badge>
      </div>
    </div>
    <div class="slides-main-info">
      <div class="slides-main-info__slide">
        <div class="slides-main-info__mini-slide">
          <span>1</span>
          <v-img
            :aspect-ratio="16 / 9"
            :src="thumbnailSmall"
            contain
            class="slides-main-info__mini-slide__image"
          />
        </div>
      </div>
      <div class="slides-main-info__large-slide">
        <div class="slides-main-info__large-slide__landscape">
          <v-img
            :aspect-ratio="16 / 9"
            alt="image"
            contain
            :src="thumbnailLarge"
          />
        </div>
        <div class="slides-main-info__disclaimer">
          <div class="slides-main-info__disclaimer__sub">
            Prezent is powered by ASTRIDᵀᴹ AI, so mistakes are possible. Please
            double-check the results.
          </div>
          <div>
            We take confidentiality very seriously and we’ll keep your
            information secure.
            <a href="https://www.prezent.ai/security" target="_blank"
              >Learn more</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import { ERROR_CODES } from './utils/comply-helper.utils';

export default {
  name: 'SlidesPlaceholder',
  computed: {
    ...mapState('comply', ['errorCode']),
    ...mapGetters('users', ['getUserVisualPreference']),
    thumbnailSmall() {
      if (this.errorCode) {
        switch (this.errorCode) {
          case ERROR_CODES.ComplianceCheckFailure:
            return '/assets/img/comply/compliance-check-failed.svg';
          case ERROR_CODES.FileSizeFailure:
            return '/assets/img/comply/file-upload-failed-thumbnail.png';
          case ERROR_CODES.FileUploadFailure:
            return '/assets/img/comply/file-upload-failed-thumbnail-no-index.png';
          default:
            return '/assets/img/comply/SlideThumbnail.png';
        }
      }
      return this.getUserVisualPreference.toLowerCase() === 'vibrant'
        ? '/assets/img/comply/vibrant-comply.svg'
        : '/assets/img/comply/minimal-comply-text-flattened.svg';
    },
    thumbnailLarge() {
      if (this.errorCode) {
        switch (this.errorCode) {
          case ERROR_CODES.ComplianceCheckFailure:
            return '/assets/img/comply/compliance-check-failed.svg';
          case ERROR_CODES.FileSizeFailure:
            return '/assets/img/comply/file-upload-failed-size-error-humbnail-large.svg';
          case ERROR_CODES.FileUploadFailure:
            return '/assets/img/comply/file-upload-30-slides.svg';
          default:
            return '/assets/img/comply/SlideExtraLarge.png';
        }
      }
      return this.getUserVisualPreference.toLowerCase() === 'vibrant'
        ? '/assets/img/comply/vibrant-comply.svg'
        : '/assets/img/comply/minimal-comply-text-flattened.svg';
    },
  },
  props: {
    retryAction: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      slideCount: 1,
    };
  },
  methods: {
    onRetryCtaClick() {
      this.$emit('handleRetry', true);
    },
  },
};
</script>
<style scoped lang="scss">
.main-wrapper {
  // margin-top: 12px;
}
.slides-ph {
  display: flex;
  align-items: center;
  gap: 174px;
  align-self: stretch;
  &__counter {
    display: flex;
    align-items: center;
    gap: 4px;
    &--text {
      color: #212121;
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.14px;
    }
    &--count {
      color: black !important;
    }
  }
}

.slides-main-info {
  margin-top: 12px;
  width: 100%;
  position: relative;
  display: flex;
  gap: 20px;
  height: calc(100% - 190px);
  &__slide {
    width: 364px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    height: 100%;
    overflow: auto;
    padding: 5px 10px 10px 0;
    transition: width 0.5s ease-in;
    position: relative;
    top: -5px;
  }
  &__mini-slide {
    width: 100%;
    display: flex;
    gap: 10px;
    &__image {
      position: relative;
      aspect-ratio: 16/9;
      width: calc(100% - 20px);
      box-shadow: 0px 2px 5px 1px rgba(64, 60, 67, 0.16);
      border-radius: 12px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &__large-slide {
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // flex: 1;
    // gap: 5px;
    width: calc(100% - 384px);
    transition: width 0.5s ease-in;
    &__landscape {
      box-shadow: 0px 2px 5px 1px rgba(64, 60, 67, 0.16);
      border-radius: 12px;
      aspect-ratio: 16/9;
      margin-bottom: 10px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &__disclaimer {
    margin-top: 8px;
    padding-bottom: 20px;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #697077;
    &__sub {
      margin-bottom: 2px;
    }
    a {
      color: #21a7e0;
      cursor: pointer;
    }
  }
}

.retry-block {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 80px;
  .retry-btn {
    color: var(--color-styles-old-white, #fff);

    /* Field Name */
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: none;
    letter-spacing: normal;
  }
}
</style>
