<template>
  <div
    class="brandImagesLib"
    v-if="
      getReplaceImageSuggestionState && getReplaceImageSuggestionState.panelOpen
    "
  >
    <ReplaceComplyImages
      :handleAcceptFromBrandImageLib="handleAcceptFromBrandImageLib"
      :handleRejectFromBrandImageLib="handleRejectFromBrandImageLib"
      :origin="'comply'"
    />
  </div>
  <div
    class="brandImagesLib"
    v-else-if="
      getReplaceIconSuggestionState && getReplaceIconSuggestionState.panelOpen
    "
  >
    <ReplaceComplyIcons
      :handleAcceptFromBrandImageLib="handleAcceptFromBrandIconLib"
      :handleRejectFromBrandImageLib="handleRejectFromBrandIconLib"
      :origin="'comply'"
    />
  </div>
  <div v-else-if="colorPickerState && colorPickerState.panelOpen">
    <ComplyColorPicker
      @close="closeColorPicker"
      @accept="acceptColorPicker"
      @reject="rejectColorPicker"
      :origin="'slide'"
      :suggestion="colorPickerState.suggestion"
    ></ComplyColorPicker>
  </div>
  <div v-else>
    <v-row v-if="getCurrentSlideCompleted && !reviewClicked">
      <v-col>
        <div class="complied-message">
          <v-img
            max-height="45%"
            src="/assets/img/comply/brand-compliance-achieved.svg"
          ></v-img>
          <div class="complied-message__wrapper">
            <div>
              Congratulations! Your slide is
              <span class="score-percentage">100%</span>
              compliant with brand guidelines for your selected template.
            </div>
          </div>
          <div class="complied-message__actions">
            <v-btn
              v-if="slideId < totalSlides - 1"
              rounded
              color="primary"
              class="app-button mr-2"
              @click="onNextSlideClick"
            >
              Next Slide
            </v-btn>
            <v-btn
              text
              rounded
              color="primary"
              class="app-button mr-2"
              name--auto="review_details_current_slide_tab"
              data-pendo-id="tc-review-current-slide-details"
              @click="onReviewDetailsClick"
            >
              Review details
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <template v-else>
      <div class="compliance-score-box">
        <v-row class="slide-compliance-score-box">
          <v-col cols="8" class="score-text-col">
            <span class="score-text">Brand Compliance Score</span>
            <span class="score-percentage">
              {{ getCurrentSlideCompleted ? '100' : slideComplianceScore }}%
            </span>
          </v-col>
          <v-col cols="4" class="progress-col">
            <v-progress-linear
              :value="getCurrentSlideCompleted ? 100 : slideComplianceScore"
              :color="complianceColor"
              height="7"
            ></v-progress-linear>
          </v-col>
        </v-row>
      </div>
      <!--
      <div style="height: 18px"></div>
      !-->

      <div class="expansionPanelsSuggestions">
        <v-row>
          <v-col class="pr-0">
            <v-expansion-panels v-model="panel">
              <v-expansion-panel
                v-for="(category, i) in getSuggestionsList"
                :key="`${category.key}_${i}`"
                :disabled="
                  (category.totalSuggestions === 0 &&
                    category.key !== 'template') ||
                  (category.key === 'images' &&
                    !(isReplaceBrandImageEnabled && allowReplaceImageOverall))
                "
                class="suggestions__panel"
                @click="selectCategory(category)"
              >
                <v-expansion-panel-header
                  :data-pendo-id="
                    suggestionsIndex === i ? 'comply-suggestion' : null
                  "
                >
                  <div class="d-flex flex-column">
                    <div class="suggestion-details">
                      <v-avatar
                        :color="category.avatarColor"
                        :max-width="16"
                        :max-height="16"
                        :min-height="16"
                        :min-width="16"
                      ></v-avatar>
                      <span class="suggestion-details__item-text">
                        {{ category.text }}
                      </span>
                      <v-icon :color="'#D9D9D9'">mdi-circle-small</v-icon>
                      <div class="suggestion-details__total">
                        <span v-if="category.key === 'template'">
                          All suggestions resolved
                        </span>
                        <span v-else>
                          {{ getSuggestionsCountText(category) }}
                        </span>
                      </div>
                      <div
                        style="margin-left: auto"
                        class="suggestion-details__actions"
                        data-pendo-id="comply-accept-or-reject"
                        v-if="
                          category.key !== 'template' &&
                          !isAllSuggestionsResolved(category)
                        "
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              v-bind="attrs"
                              v-on="on"
                              @click.stop="onAcceptCategoryClick(category)"
                              v-if="category.totalSuggestions > 0"
                            >
                              <v-icon color="#00925a">mdi-check</v-icon>
                            </v-btn>
                          </template>
                          <span>Accept</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              v-bind="attrs"
                              v-on="on"
                              @click.stop="onRejectCategoryClick(category)"
                              v-if="category.totalSuggestions > 0"
                            >
                              <v-icon color="#d32f2f">mdi-close</v-icon>
                            </v-btn>
                          </template>
                          <span>Reject</span>
                        </v-tooltip>
                      </div>
                    </div>
                    <div
                      class="suggestion-details__description"
                      v-if="
                        !(
                          isReplaceBrandImageEnabled && allowReplaceImageOverall
                        ) && category.key === 'images'
                      "
                    >
                      No brand images available to check compliance.
                      <a class="text-link" @click="onGetInTouchclick">
                        Get in touch
                      </a>
                      to add brand images.
                    </div>
                    <div
                      class="suggestion-details__description"
                      v-else-if="
                        isReplaceBrandIconsEnabled &&
                        brandIconsListInLibrary.length === 0 &&
                        category.key === 'icons' &&
                        !deckFullComplianceInfo
                      "
                    >
                      No brand icons available to check compliance.
                      <a class="text-link" @click="onGetInTouchclickIcons">
                        Get in touch
                      </a>
                      to add brand icons.
                    </div>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content
                  :key="category.text"
                  :disabled="
                    !(isReplaceBrandImageEnabled && allowReplaceImageOverall) &&
                    category.key === 'images'
                  "
                  class="expansion-panel-content"
                >
                  <v-list>
                    <v-list-group
                      v-for="(suggestion, index) in category.suggestions"
                      :key="suggestion.name + index"
                      no-action
                      :value="subPanel[category.key] == suggestion.index"
                    >
                      <template v-slot:activator>
                        <v-list-item
                          @click.stop="selectSuggestion(suggestion)"
                          class="suggestions-box"
                        >
                          <v-list-item-content
                            class="suggestions__item-header"
                            append-icon="mdi-check"
                          >
                            <div class="suggestions__header-wrapper">
                              <v-avatar
                                :max-width="24"
                                :max-height="24"
                                :min-height="24"
                                :min-width="24"
                                :color="category.avatarColor"
                                class="mr-2"
                                :data-pendo-id="
                                  index === 0
                                    ? 'comply-suggestion-item-bubble'
                                    : null
                                "
                              >
                                <span v-if="suggestion.category === 'template'">
                                  <v-icon :color="'white'">mdi-check</v-icon>
                                </span>
                                <span class="suggestions__item-id" v-else>
                                  {{ index + 1 }}
                                </span>
                              </v-avatar>
                              <span class="suggestions__item-name">
                                {{ getSuggestionDisplayName(suggestion) }}
                              </span>
                              <div v-if="suggestion.status">
                                <v-icon :color="'#D9D9D9'">
                                  mdi-circle-small
                                </v-icon>
                                <span class="suggestion-details__total">
                                  {{
                                    suggestion.status === 'accepted'
                                      ? 'Accepted'
                                      : 'Rejected'
                                  }}
                                </span>
                              </div>
                              <div v-if="suggestion.category === 'template'">
                                <v-icon :color="'#D9D9D9'">
                                  mdi-circle-small
                                </v-icon>
                                <span class="suggestion-details__total">
                                  {{ 'Accepted' }}
                                </span>
                              </div>
                            </div>
                          </v-list-item-content>
                          <v-list-item-icon v-if="!suggestion.status">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-if="suggestion.category !== 'template'"
                                  icon
                                  @click.stop="onAcceptAction(suggestion)"
                                  v-bind="attrs"
                                  v-on="on"
                                  data-pendo-id="comply-suggestion-accept"
                                >
                                  <v-icon color="#00925a">mdi-check</v-icon>
                                </v-btn>
                              </template>
                              <span>Accept</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-if="suggestion.category !== 'template'"
                                  icon
                                  @click.stop="onRejectAction(suggestion)"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon color="#d32f2f">mdi-close</v-icon>
                                </v-btn>
                              </template>
                              <span>Reject</span>
                            </v-tooltip>
                          </v-list-item-icon>
                        </v-list-item>
                      </template>
                      <component
                        :is="
                          getComponentName(
                            suggestion.type || suggestion.category,
                          )
                        "
                        :type="suggestion.type"
                        :suggestion="suggestion"
                        :category="category"
                        :itemIndex="index"
                        :hideColorPicker="true"
                      >
                      </component>
                    </v-list-group>
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </div>
    </template>
    <v-row v-if="!getCurrentSlideCompleted">
      <v-col class="d-flex align-baseline">
        <div class="buttons-content mb-5">
          <v-btn
            rounded
            color="primary"
            class="app-button"
            data-pendo-id="accept-slide-suggestions"
            @click="onAcceptAllClick"
          >
            Accept slide suggestions
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import {
  sendEmail,
  getSearchBrandIconsSolr,
} from '../../../../utils/api-helper';
import ShapeColorSuggestion from './format-suggestions/ShapeColorSuggestion.vue';
import FontSizeSuggestion from './format-suggestions/FontSizeSuggestion.vue';
import HeaderFooterSuggestion from './format-suggestions/HeaderFooterSuggestion.vue';
import FontStyleSuggestion from './format-suggestions/FontStyleSuggestion.vue';
import ImageSuggestion from './format-suggestions/ImageSuggestion';
import ReplaceComplyImages from '../replace-comply-images/ReplaceComplyImages';
import ComplyColorPicker from './format-suggestions/ComplyColorPicker';
import GetInTouchDetail from '../../../Main/Profile/GetInTouchDetail';
import TemplateSuggestion from './format-suggestions/TemplateSuggestion.vue';
import { trackComplyEvents } from '../../../common/Analytics/ComplyEvents';
import IconSuggestion from './format-suggestions/IconSuggestion.vue';
import {
  TD_PREZNAME,
  TD_TEMPLATE,
  TD_PLACEMENT,
  TD_COMMON_COLUMN_VALUE,
  TD_COMMON_COLUMN_NAME,
  TD_SLIDE,
  TD_CATEGORY_REJECTED,
  TD_CATEGORY_ACCEPTED,
  TD_SUGGESTION_ACCEPTED,
  TD_SUGGESTION_REJECTED,
  TD_TEMPLATE_CONVERTER,
  TD_CROSS_FEATURE,
  TD_NUMBEROFSLIDES,
  TD_TIME_SPENT_IN_SECS,
} from '../../../common/Analytics/MatomoTrackingDataHelper';
import { ERROR_CODES, getSuggestionName } from '../utils/comply-helper.utils';
import ReplaceComplyIcons from '../replace-comply-icons/ReplaceComplyIcons.vue';

export default {
  components: {
    ShapeColorSuggestion,
    FontSizeSuggestion,
    HeaderFooterSuggestion,
    FontStyleSuggestion,
    ImageSuggestion,
    ReplaceComplyImages,
    ComplyColorPicker,
    TemplateSuggestion,
    ReplaceComplyIcons,
    IconSuggestion,
  },
  data() {
    return {
      selectedColorMapping: null,
      panel: null,
      subPanel: {
        color: null,
        font: null,
        images: null,
        template: null,
        icons: null,
        specialColors: null,
      },
      reviewClicked: false,
      resolvedCountByCategory: {},
      brandIconsListInLibrary: [],
    };
  },
  props: {
    clearSlideLibraryFlags: {
      type: Function,
    },
  },
  watch: {
    selectedSuggestion(val) {
      if (val) {
        this.panel = val.categoryIndex;
        this.subPanel[val.category] = val.index;
      } else {
        this.subPanel = {
          color: null,
          font: null,
          images: null,
          template: null,
          icons: null,
          specialColors: null,
        };
      }
    },
    slideIndex() {
      this.reviewClicked = false;
      this.panel = null;
    },
  },
  computed: {
    ...mapState('comply', [
      'slidesInfo',
      'fileDetails',
      'selectedSuggestion',
      // 'hasBrandImages',
      'slideIndex',
      'deckFullComplianceInfo',
      'selectedTemplate',
    ]),
    ...mapState('users', ['currentUser']),
    ...mapGetters('comply', [
      'slideId',
      'slideData',
      'getSlidesInfo',
      'selectedSlide',
      'complySlidesDetails',
      'getFileDetails',
      'totalSlides',
      'getComplianceScore',
      'slideComplianceScore',
      'getCurrentSlideSuggestions',
      'getReplaceImageSuggestionState',
      'colorPickerState',
      'getCurrentSlideCompleted',
      'getReplaceIconSuggestionState',
      'isDeckFullCompliantFlow',
    ]),
    ...mapGetters('users', [
      'isReplaceBrandImageEnabled',
      'allowReplaceImageOverall',
      'isReplaceBrandIconsEnabled',
    ]),
    suggestionsIndex() {
      return this.getCurrentSlideSuggestions.findIndex(
        (s) => s.totalSuggestions > 0,
      );
    },
    formatSuggestions() {
      const selectedSlideData = this.selectedSlide;
      console.log('selectedslide-current slide', selectedSlideData);
      return selectedSlideData.suggestions;
    },
    filteredMapping() {
      return this.category.suggestions.map((suggestion) => ({
        ...suggestion,
        filteredMapping: suggestion.mapping.filter(
          (item) =>
            !item.status ||
            (item.status !== 'accepted' && item.status !== 'rejected'),
        ),
      }));
    },
    complianceColor() {
      if (this.slideComplianceScore < 50) {
        return '#D32F2F'; // Red for score < 50%
      }
      if (this.slideComplianceScore >= 50 && this.slideComplianceScore <= 75) {
        return '#FDD835'; // Yellow for score 50% - 75%
      }
      return '#00925A'; // Green for score > 75%
    },
    // isSlideFullCompliant() {
    //   const slideSuggestions = this.getCurrentSlideSuggestions.flatMap(
    //     (item) => item.suggestions,
    //   );
    //   let compliant = true;
    //   slideSuggestions.every((sugg) => {
    //     if (!sugg.status) {
    //       compliant = false;
    //       return false;
    //     }
    //     return true;
    //   });
    //   return compliant;
    // },
    // hasBrandImages() {
    //   const imageCat = this.getCurrentSlideSuggestions.find(
    //     (item) => item.key === 'images',
    //   );
    //   return (
    //     imageCat && imageCat.suggestions && imageCat.suggestions.length > 0
    //   );
    // },
    getSuggestionsList() {
      if (!this.isReplaceBrandIconsEnabled) {
        return this.getCurrentSlideSuggestions.filter(
          (sug) => sug.key.toLowerCase() !== 'icons',
        );
      }
      return this.getCurrentSlideSuggestions;
    },
  },
  methods: {
    ...mapActions('comply', [
      'updateSlideSuggestions',
      'setSelectedSuggestion',
      'setReplaceImageSuggestionState',
      'setColorPickerState',
      'updateUserSelectedColor',
      'acceptUserSelectedColor',
      'acceptAllSelectedColorInCategory',
      'setReplaceIconSuggestionState',
    ]),
    updateSelectedColor(suggestion, newColor) {
      const color =
        newColor && newColor.length === 6 ? `ff${newColor}` : newColor;
      this.updateUserSelectedColor({
        suggestion,
        userSelectedColor: color || undefined,
      });
    },
    resetColorPicker() {
      this.setColorPickerState({
        suggestion: null,
        selectedColor: null,
        panelOpen: false,
      });
    },
    acceptColorPicker(colorPickerState) {
      if (this.clearSlideLibraryFlags) this.clearSlideLibraryFlags();
      this.updateSelectedColor(
        colorPickerState.suggestion,
        colorPickerState.selectedColor,
        true,
      );
      this.onAcceptClick(colorPickerState.suggestion);
      this.resetColorPicker();
    },
    closeColorPicker(colorPickerState) {
      this.updateSelectedColor(
        colorPickerState.suggestion,
        colorPickerState.selectedColor,
        false,
      );
      this.resetColorPicker();
    },
    rejectColorPicker(colorPickerState) {
      this.updateSelectedColor(colorPickerState.suggestion, null, true);
      this.resetColorPicker();
      this.onCancelClick(colorPickerState.suggestion);
    },
    getGlobalIndex(categoryIndex, localIndex) {
      return this.categories[categoryIndex].suggestions
        .slice(0, localIndex)
        .reduce(
          (acc, suggestion) =>
            acc + (suggestion.mapping ? suggestion.mapping.length : 0),
          0,
        );
    },
    getSuggestionsByKey(key) {
      console.log('key', key, 'formatsuggestionsbykey', this.formatSuggestions);
      return (
        this.formatSuggestions.data.slide_data.format_properties
          .format_suggestions[key] || []
      );
    },

    getSuggestionsTotalCount(suggestions) {
      if (!Array.isArray(suggestions)) {
        return 0;
      }
      let subtract = 0;
      return (
        suggestions.reduce((acc, suggestion) => {
          if (suggestion.mapping) {
            suggestion.mapping.forEach((item) => {
              if (item.status === 'accepted' || item.status === 'rejected') {
                subtract += 1;
              }
            });
            return acc + suggestion.mapping.length;
          }
          return acc;
        }, 0) - subtract
      );
    },

    getComponentName(type) {
      const componentNames = {
        color_fill: 'ShapeColorSuggestion',
        color_outline: 'ShapeColorSuggestion',
        font_size: 'FontSizeSuggestion',
        font_color: 'ShapeColorSuggestion',
        header_footer: 'HeaderFooterSuggestion',
        font_style: 'FontStyleSuggestion',
        image: 'ImageSuggestion',
        template: 'TemplateSuggestion',
        group: 'ShapeColorSuggestion',
        icon: 'IconSuggestion',
      };
      return componentNames[type] || null;
    },

    selectSuggestion(suggestion) {
      if (
        this.selectedSuggestion &&
        this.selectedSuggestion.index === suggestion.index &&
        this.selectedSuggestion.category === suggestion.category
      ) {
        this.setSelectedSuggestion(null);
      } else {
        this.setSelectedSuggestion({
          index: suggestion.index,
          categoryIndex: suggestion.categoryIndex,
          category: suggestion.category,
        });
      }
      // this.selectedSuggestion = suggestion;
    },
    selectCategory(category) {
      this.$nextTick(() => {
        if (this.panel !== category.index) {
          this.setSelectedSuggestion({
            index: 1,
            categoryIndex: category.index,
            category: category.key,
          });
        } else {
          this.setSelectedSuggestion(null);
        }
      });
    },

    async handleAcceptFromBrandImageLib(suggestion) {
      if (this.clearSlideLibraryFlags) this.clearSlideLibraryFlags();
      await this.onAcceptClick(suggestion);
      this.setReplaceImageSuggestionState({
        suggestionNumber: 0,
        current: '',
        suggested: '',
        panelOpen: false,
        color: '',
        suggestedOnLoad: '',
        category: null,
        suggestion: null,
        itemIndex: 0,
        imageFromLib: null,
      });
    },

    handleRejectFromBrandImageLib(suggestion) {
      this.onCancelClick(suggestion);
      this.setReplaceImageSuggestionState({
        suggestionNumber: 0,
        current: '',
        suggested: '',
        panelOpen: false,
        color: '',
        suggestedOnLoad: '',
        category: null,
        suggestion: null,
        itemIndex: 0,
        imageFromLib: null,
      });
    },

    handleRejectFromBrandIconLib(suggestion) {
      this.onCancelClick(suggestion);
      this.setReplaceIconSuggestionState({
        suggestionNumber: 0,
        current: '',
        suggested: '',
        panelOpen: false,
        color: '',
        suggestedOnLoad: '',
        category: null,
        suggestion: null,
        itemIndex: 0,
        imageFromLib: null,
      });
    },

    async handleAcceptFromBrandIconLib(suggestion) {
      if (this.clearSlideLibraryFlags) this.clearSlideLibraryFlags();
      await this.onAcceptClick(suggestion);
      this.setReplaceIconSuggestionState({
        suggestionNumber: 0,
        current: '',
        suggested: '',
        panelOpen: false,
        color: '',
        suggestedOnLoad: '',
        category: null,
        suggestion: null,
        itemIndex: 0,
        imageFromLib: null,
      });
    },

    convertSuggestedForImage(formatSuggestions, mapIndex, sugg) {
      return [
        {
          ...formatSuggestions[0],
          mapping: [
            {
              ...formatSuggestions[0].mapping[mapIndex],
              suggested:
                sugg.origin === 'Brand'
                  ? sugg
                  : formatSuggestions[0].mapping[mapIndex].suggested[0],
            },
          ],
        },
      ];
    },

    convertSuggestedForIcon(formatSuggestions, mapIndex, sugg) {
      return [
        {
          ...formatSuggestions[0],
          mapping: [
            {
              ...formatSuggestions[0].mapping[mapIndex],
              suggested: sugg,
            },
          ],
        },
      ];
    },

    onAcceptAction(suggestion) {
      if (suggestion.isGroupSuggestion) {
        this.onGroupAcceptClick(suggestion);
      } else {
        this.onAcceptClick(suggestion);
      }
    },

    onRejectAction(suggestion) {
      if (suggestion.isGroupSuggestion) {
        this.onGroupRejectClick(suggestion);
      } else {
        this.onCancelClick(suggestion);
      }
    },

    async onGroupAcceptClick(suggestion) {
      let slidePatchData = this.createSlidePatchData(
        suggestion.suggestionsList,
      );
      slidePatchData.action = 'accept';
      const colorsCategorySuggestions = this.getCurrentSlideSuggestions.find(
        (s) => s.key === 'color',
      );
      // apply bg to font color if there is only one suggestion left out inside the color suggestions.
      if (
        colorsCategorySuggestions !== undefined &&
        colorsCategorySuggestions.suggestions.filter((s) => s.status === null)
          .length === 1 &&
        suggestion.category === 'color'
      ) {
        slidePatchData = {
          ...slidePatchData,
          ...{
            applyBGtoFont: [
              'bg_to_font_color',
              'bg_to_bullet_color',
              'bg_to_svg_color',
            ],
          },
        };
      }
      try {
        await this.$store.dispatch('comply/applyComplianceChanges', {
          slideData: slidePatchData,
        });
        this.updateSlideSuggestions({
          category: suggestion.category,
          categorySubIndex: suggestion.categorySubIndex,
          mappingIndex: suggestion.mappingIndex,
          isAccepted: true,
          suggestedToUpdate: suggestion.suggested[0],
        });
      } catch (error) {
        console.log(error);
        // this.$toast.error('Error applying compliance changes');
      } finally {
        const suggestionType = this.replaceUnderscoreWithSpace(suggestion.type);
        this.snackbarMessage = `Accepted ${suggestionType} suggestion`;
        this.isSnackbarVisible = true;
      }
      const otherData = {
        [TD_PREZNAME]: this.fileDetails.name,
        [TD_TEMPLATE]: this.selectedTemplate,
        [TD_COMMON_COLUMN_NAME]: TD_SUGGESTION_ACCEPTED,
        [TD_COMMON_COLUMN_VALUE]: suggestion.name,
        [TD_NUMBEROFSLIDES]: 1,
      };
      trackComplyEvents.complianceCheckerReviewDetailsSuggestionAccept(
        this.currentUser,
        otherData,
      );
    },

    async onGroupRejectClick(suggestion) {
      const slidePatchData = this.createSlidePatchData(
        suggestion.suggestionsList,
      );
      slidePatchData.action = 'reject';
      try {
        await this.$store.dispatch('comply/applyComplianceChanges', {
          slideData: slidePatchData,
        });
        this.updateSlideSuggestions({
          category: suggestion.category,
          categorySubIndex: suggestion.categorySubIndex,
          mappingIndex: suggestion.mappingIndex,
          isAccepted: false,
        });
      } catch (err) {
        console.error('Error while processing all suggestions reject:', err);
        // this.$toast.error('Error applying all compliance changes');
      } finally {
        const suggestionType = this.replaceUnderscoreWithSpace(suggestion.type);
        this.snackbarMessage = `Dismissed ${suggestionType} suggestion`;
        this.isSnackbarVisible = true;
      }
      const otherData = {
        [TD_PREZNAME]: this.fileDetails.name,
        [TD_TEMPLATE]: this.selectedTemplate,
        [TD_COMMON_COLUMN_NAME]: TD_SUGGESTION_REJECTED,
        [TD_COMMON_COLUMN_VALUE]: suggestion.name,
      };
      trackComplyEvents.complianceCheckerReviewDetailsSuggestionReject(
        this.currentUser,
        otherData,
      );
    },

    /**
     * Accept/Reject Actions
     */
    async onAcceptClick(suggestion) {
      if (this.clearSlideLibraryFlags) this.clearSlideLibraryFlags();
      // this.logData(categoryType, categoryName, suggestion, index);
      let formatSuggestions = [
        {
          ...this.selectedSlide.suggestions.data.slide_data.format_properties
            .format_suggestions[suggestion.category][
            suggestion.categorySubIndex
          ],
        },
      ];
      if (formatSuggestions[0].type === 'image') {
        formatSuggestions = this.convertSuggestedForImage(
          formatSuggestions,
          suggestion.mappingIndex,
          suggestion.suggested[0],
        );
      } else if (formatSuggestions[0].type === 'icon') {
        formatSuggestions = this.convertSuggestedForIcon(
          formatSuggestions,
          suggestion.mappingIndex,
          suggestion.suggested[0],
        );
      } else {
        formatSuggestions[0].mapping = [
          formatSuggestions[0].mapping[suggestion.mappingIndex],
        ];
      }
      let slidePatchData = this.createSlidePatchData(formatSuggestions);
      slidePatchData.action = 'accept';
      const colorsCategorySuggestions = this.getCurrentSlideSuggestions.find(
        (s) => s.key === 'color',
      );
      // apply bg to font color if there is only one suggestion left out inside the color suggestions.
      if (
        colorsCategorySuggestions !== undefined &&
        colorsCategorySuggestions.suggestions.filter((s) => s.status === null)
          .length === 1 &&
        suggestion.category === 'color'
      ) {
        slidePatchData = {
          ...slidePatchData,
          ...{
            applyBGtoFont: [
              'bg_to_font_color',
              'bg_to_bullet_color',
              'bg_to_svg_color',
            ],
          },
        };
      }
      try {
        await this.$store.dispatch('comply/applyComplianceChanges', {
          slideData: slidePatchData,
        });
        this.updateSlideSuggestions({
          category: suggestion.category,
          categorySubIndex: suggestion.categorySubIndex,
          mappingIndex: suggestion.mappingIndex,
          isAccepted: true,
          suggestedToUpdate: suggestion.suggested[0],
        });
      } catch (error) {
        console.log(error);
        // this.$toast.error('Error applying compliance changes');
      } finally {
        const suggestionType = this.replaceUnderscoreWithSpace(suggestion.type);
        this.snackbarMessage = `Accepted ${suggestionType} suggestion`;
        this.isSnackbarVisible = true;
      }
      const otherData = {
        [TD_PREZNAME]: this.fileDetails.name,
        [TD_TEMPLATE]: this.selectedTemplate,
        [TD_COMMON_COLUMN_NAME]: TD_SUGGESTION_ACCEPTED,
        [TD_COMMON_COLUMN_VALUE]: suggestion.name,
        [TD_NUMBEROFSLIDES]: 1,
      };
      trackComplyEvents.complianceCheckerReviewDetailsSuggestionAccept(
        this.currentUser,
        otherData,
      );
    },
    createSlidePatchData(data) {
      const currentSlide = this.selectedSlide;
      return {
        upload_id: this.fileDetails.uploadId,
        source: {
          s3: currentSlide.pptx.s3_bucket,
          filepath: currentSlide.pptx.s3_path,
        },
        slide_id: this.slideId,
        filename:
          this.formatSuggestions.data.slide_data.slide_properties.filename,
        format_properties: {
          format_suggestions: data,
        },
        legal_properties: {
          legal_suggestions: [],
        },
        content_properties: {
          content_suggestions: [],
        },
        action: '',
      };
    },
    handleFormatCategoryType(suggestion, index, slidePatchData) {
      const suggestionCopy = JSON.parse(JSON.stringify(suggestion));
      suggestionCopy.mapping = [suggestionCopy.mapping[index]];

      if (suggestion.type === 'image') {
        suggestionCopy.mapping = [
          {
            ...suggestionCopy.mapping[0],
            suggested:
              suggestion.origin && suggestion.origin === 'brandLibPanel'
                ? suggestionCopy.mapping[0].suggested
                : suggestionCopy.mapping[0].suggested[0],
          },
        ];
      }

      slidePatchData.format_properties.format_suggestions = [suggestionCopy];
    },

    handleFormatTemplate(suggestion, slidePatchData) {
      const suggestionCopy = JSON.parse(JSON.stringify(suggestion));

      slidePatchData.format_properties.format_suggestions = [suggestionCopy];
    },

    replaceUnderscoreWithSpace(str) {
      return str.replace(/_/g, ' ');
    },

    async onCancelClick(suggestion) {
      // this.$set(item, 'status', 'rejected');
      let formatSuggestions = [
        {
          ...this.selectedSlide.suggestions.data.slide_data.format_properties
            .format_suggestions[suggestion.category][
            suggestion.categorySubIndex
          ],
        },
      ];
      if (formatSuggestions[0].type === 'image') {
        formatSuggestions = this.convertSuggestedForImage(
          formatSuggestions,
          suggestion.mappingIndex,
          suggestion.suggested[0],
        );
      } else if (formatSuggestions[0].type === 'icon') {
        formatSuggestions = this.convertSuggestedForIcon(
          formatSuggestions,
          suggestion.mappingIndex,
          suggestion.suggested[0],
        );
      } else {
        formatSuggestions[0].mapping = [
          formatSuggestions[0].mapping[suggestion.mappingIndex],
        ];
      }
      const slidePatchData = this.createSlidePatchData(formatSuggestions);
      slidePatchData.action = 'reject';
      try {
        await this.$store.dispatch('comply/applyComplianceChanges', {
          slideData: slidePatchData,
        });
        this.updateSlideSuggestions({
          category: suggestion.category,
          categorySubIndex: suggestion.categorySubIndex,
          mappingIndex: suggestion.mappingIndex,
          isAccepted: false,
        });
      } catch (err) {
        console.error('Error while processing all suggestions reject:', err);
        // this.$toast.error('Error applying all compliance changes');
      } finally {
        const suggestionType = this.replaceUnderscoreWithSpace(suggestion.type);
        this.snackbarMessage = `Dismissed ${suggestionType} suggestion`;
        this.isSnackbarVisible = true;
      }
      const otherData = {
        [TD_PREZNAME]: this.fileDetails.name,
        [TD_TEMPLATE]: this.selectedTemplate,
        [TD_COMMON_COLUMN_NAME]: TD_SUGGESTION_REJECTED,
        [TD_COMMON_COLUMN_VALUE]: suggestion.name,
      };
      trackComplyEvents.complianceCheckerReviewDetailsSuggestionReject(
        this.currentUser,
        otherData,
      );
    },
    async onAcceptAllClick() {
      try {
        if (this.clearSlideLibraryFlags) this.clearSlideLibraryFlags();
        const slidePatchData = this.createAllSuggestionsPatchData();
        const allCategories = this.getCurrentSlideSuggestions;
        slidePatchData.action = 'accept';
        slidePatchData.applyBGtoFont = [
          'bg_to_font_color',
          'bg_to_bullet_color',
          'bg_to_svg_color',
        ];
        const imageCategory = allCategories.find(
          (category) => category.key === 'images',
        );
        slidePatchData.include_image_conversions =
          this.getSuggestionCount(imageCategory) === 0
            ? false
            : this.isReplaceBrandImageEnabled;
        const iconsCategory = allCategories.find(
          (category) => category.key === 'icons',
        );
        slidePatchData.include_icon_conversions =
          this.getSuggestionCount(iconsCategory) === 0
            ? false
            : this.isReplaceBrandIconsEnabled;
        await this.$store.dispatch('comply/applyComplianceChanges', {
          slideData: slidePatchData,
        });
        for (const category of allCategories) {
          this.updateSlideSuggestions({
            category: category.key,
            isAccepted: true,
            isBulkUpdate: true,
          });
        }
        this.snackbarMessage = 'All suggestions have been processed';
        this.isSnackbarVisible = true;
      } catch (error) {
        console.error('Error while processing all suggestions:', error);
        this.$store.dispatch('comply/resetState');
        this.$store.dispatch(
          'comply/setTcErrorCode',
          ERROR_CODES.ComplianceCheckFailure,
        );
        await this.$store.dispatch('comply/resetFileData');
        // this.$toast.error('Error applying all compliance changes');
      }
      const otherData = {
        [TD_PREZNAME]: this.fileDetails.name,
        [TD_TEMPLATE]: this.selectedTemplate,
        [TD_NUMBEROFSLIDES]: 1,
      };
      trackComplyEvents.complianceCheckerReviewDetailsAcceptSlideSuggestions(
        this.currentUser,
        otherData,
      );
    },

    createAllSuggestionsPatchData() {
      const currentSlide = this.selectedSlide;
      const formatProperties =
        this.formatSuggestions.data.slide_data.format_properties
          .format_suggestions;
      const allCategoriesSuggestions = Object.keys(formatProperties).reduce(
        (acc, key) => acc.concat(formatProperties[key]),
        [],
      );

      const allCatsFormatted = allCategoriesSuggestions.map((item) => {
        if (item.type === 'image') {
          return {
            ...item,
            mapping: item.mapping.map((it) => ({
              ...it,
              suggested: it.suggested[0],
            })),
          };
        }
        return {
          ...item,
        };
      });

      return {
        upload_id: this.fileDetails.uploadId,
        source: {
          s3: currentSlide.pptx.s3_bucket,
          filepath: currentSlide.pptx.s3_path,
        },
        slide_id: this.slideId,
        filename:
          this.formatSuggestions.data.slide_data.slide_properties.filename,
        format_properties: {
          format_suggestions: allCatsFormatted,
        },
        legal_properties: {
          legal_suggestions: [],
        },
        content_properties: {
          content_suggestions: [],
        },
        action: '',
      };
    },
    onNextSlideClick() {
      const currentIndex = this.slideId;
      console.log('slideID', currentIndex);
      console.log('totalSlides', this.totalSlides);

      if (currentIndex < this.totalSlides) {
        const nextIndex = currentIndex + 1;
        console.log('nextIndex', nextIndex);
        this.$store.dispatch('comply/selectSlide', {
          slideData: this.complySlidesDetails[nextIndex],
          index: nextIndex,
        });
      }
    },
    onReviewDetailsClick() {
      this.reviewClicked = true;
    },

    async onAcceptCategoryClick(category) {
      if (this.clearSlideLibraryFlags) this.clearSlideLibraryFlags();
      const eventStartTime = new Date();
      try {
        this.acceptAllSelectedColorInCategory({ category: category.key });
        let suggestions =
          this.selectedSlide.suggestions.data.slide_data.format_properties
            .format_suggestions[category.key];
        let suggCat = suggestions;
        if (suggCat[0].type && suggCat[0].type === 'image') {
          suggCat = [
            {
              ...suggCat[0],
              mapping: suggCat[0].mapping.map((it) => ({
                ...it,
                suggested: it.suggested[0],
              })),
            },
          ];
        }
        suggestions = suggCat;
        const slidePatchData = this.createSlidePatchData(suggestions);
        slidePatchData.action = 'accept';
        console.log('sugg accept', suggCat);
        if (category.key === 'color') {
          slidePatchData.applyBGtoFont = [
            'bg_to_font_color',
            'bg_to_bullet_color',
            'bg_to_svg_color',
          ];
        }
        await this.$store.dispatch('comply/applyComplianceChanges', {
          slideData: slidePatchData,
        });
        this.updateSlideSuggestions({
          category: category.key,
          isAccepted: true,
        });
      } catch (error) {
        console.error('Error applying compliance changes:', error);
        // this.$toast.error('Error applying compliance changes');
      }
      const otherData = {
        [TD_PREZNAME]: this.fileDetails.name,
        [TD_TEMPLATE]: this.selectedTemplate,
        [TD_COMMON_COLUMN_NAME]: TD_CATEGORY_ACCEPTED,
        [TD_COMMON_COLUMN_VALUE]: category.text,
        [TD_PLACEMENT]: TD_SLIDE,
        [TD_CROSS_FEATURE]: TD_TEMPLATE_CONVERTER,
        [TD_NUMBEROFSLIDES]: 1,
        [TD_TIME_SPENT_IN_SECS]: (new Date() - eventStartTime) / 1000,
      };
      trackComplyEvents.complianceCheckerReviewDetailsCategoryAccept(
        this.currentUser,
        otherData,
      );
    },

    async onRejectCategoryClick(category) {
      const eventStartTime = new Date();
      try {
        let suggestions =
          this.selectedSlide.suggestions.data.slide_data.format_properties
            .format_suggestions[category.key];
        let suggCat = suggestions;
        if (suggCat[0].type && suggCat[0].type === 'image') {
          suggCat = [
            {
              ...suggCat[0],
              mapping: suggCat[0].mapping.map((it) => ({
                ...it,
                suggested: it.suggested[0],
              })),
            },
          ];
        }
        suggestions = suggCat;
        const slidePatchData = this.createSlidePatchData(suggestions);
        slidePatchData.action = 'reject';
        await this.$store.dispatch('comply/applyComplianceChanges', {
          slideData: slidePatchData,
        });
        this.updateSlideSuggestions({
          category: category.key,
          isAccepted: false,
        });

        const suggestionType = this.replaceUnderscoreWithSpace(category.key);
        this.snackbarMessage = `Rejected all ${suggestionType} suggestions`;
        this.isSnackbarVisible = true;
      } catch (error) {
        console.log(error);
        this.$toast.error('Error applying compliance changes');
      }
      const otherData = {
        [TD_PREZNAME]: this.fileDetails.name,
        [TD_TEMPLATE]: this.selectedTemplate,
        [TD_COMMON_COLUMN_NAME]: TD_CATEGORY_REJECTED,
        [TD_COMMON_COLUMN_VALUE]: category.text,
        [TD_PLACEMENT]: TD_SLIDE,
        [TD_NUMBEROFSLIDES]: 1,
        [TD_CROSS_FEATURE]: TD_TEMPLATE_CONVERTER,
        [TD_TIME_SPENT_IN_SECS]: (new Date() - eventStartTime) / 1000,
      };
      trackComplyEvents.complianceCheckerReviewDetailsCategoryReject(
        this.currentUser,
        otherData,
      );
    },
    onGetInTouchclick() {
      try {
        const userName = `${this.currentUser.user.firstName} ${this.currentUser.user.lastName}`;

        const params = {
          type: 'brand-image-request',
          meta: {
            // message: mBody,
            requester: this.currentUser.user.firstName,
            // subject: `${userName} has expressed interest in adding brand images`,
            requesterFullName: userName,
            requesterID: this.currentUser.user.id,
          },
        };
        sendEmail(params)
          .then((response) => console.log(response))
          .catch((err) => console.log(err));
      } catch (err) {
        console.error(err);
      }
      this.$modal.show(
        GetInTouchDetail,
        {
          title: 'prezentation.thankForYourInterestInBrandImages',
        },
        {
          name: 'GetInTouchDetail',
          width: '564',
          height: '507px',
        },
      );
    },

    onGetInTouchclickIcons() {
      try {
        const userName = `${this.currentUser.user.firstName} ${this.currentUser.user.lastName}`;

        const params = {
          type: 'brand-icon-request',
          meta: {
            // message: mBody,
            requester: this.currentUser.user.firstName,
            // subject: `${userName} has expressed interest in adding brand images`,
            requesterFullName: userName,
            requesterID: this.currentUser.user.id,
          },
        };
        sendEmail(params)
          .then((response) => console.log(response))
          .catch((err) => console.log(err));
      } catch (err) {
        console.error(err);
      }

      this.$modal.show(
        GetInTouchDetail,
        {
          title: 'prezentation.thankForYourInterestInBrandIcons',
        },
        {
          name: 'GetInTouchDetail',
          width: '564',
          height: '507px',
        },
      );
    },

    getSuggestionsCountText(category) {
      const suggestionsCount = this.getSuggestionCount(category);
      if (category.resolvedSuggestions > 0) {
        if (category.totalSuggestions === category.resolvedSuggestions) {
          return 'All suggestions resolved';
        }
        if (
          category.totalSuggestions === 0 &&
          category.resolvedSuggestions > 0
        ) {
          return 'All suggestions resolved';
        }
        return `${suggestionsCount} suggestions unresolved`;
      }
      if (suggestionsCount === 0) return 'No suggestions available';
      return suggestionsCount === 1
        ? `${suggestionsCount} suggestion unresolved`
        : `${suggestionsCount} suggestions unresolved`;
    },

    isAllSuggestionsResolved(category) {
      /* if user resolves suggestions by clicking on accept/rejecct or if the suggestion category is template,
      we should show the expand icon too see the accepted/rejected suggestions. else disable it completely as there are no total suggestions */
      if (category.resolvedSuggestions < category.totalSuggestions) {
        return false;
      }
      // if (category.key === 'images' && !this.isReplaceBrandImageEnabled) {
      //   return false;
      // }
      return (
        category.totalSuggestions === category.resolvedSuggestions ||
        category.totalSuggestions === 0
      );
    },
    getSuggestionCount(category) {
      return category.suggestions.filter((sugg) => sugg.status === null).length;
    },
    async searchBrandIconsFromSolr({ query, skip }) {
      const templates = this.selectedTemplate || '';
      const resp = await getSearchBrandIconsSolr({
        query,
        skip,
        templates,
      });
      if (resp?.data?.docs?.length) {
        return resp?.data?.docs.map((image) => ({
          filepath: image?.filepath,
        }));
      }
      return [];
    },
    getSuggestionDisplayName(suggestion) {
      return getSuggestionName(suggestion);
    },
  },
  async mounted() {
    console.log('suggestions on mounted', this.getCurrentSlideSuggestions);
    if (this.isReplaceBrandIconsEnabled) {
      this.brandIconsListInLibrary = await this.searchBrandIconsFromSolr({
        query: '*',
        skip: 0,
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.no-images-description {
  color: #212121;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.24px;
  margin: 18px auto 16px 16px;
  width: 412px;
}

.text-link {
  background: none;
  border: none;
  padding: 1px !important;
  font-size: inherit;
  cursor: pointer;
  text-transform: none;
  letter-spacing: normal;
  pointer-events: auto;
}
.compliance-score-box {
  padding: 16px 0;
  margin: 16px;
}
.expansionPanelsSuggestions {
  max-height: calc(100vh - 400px);
  overflow: auto;
  margin: 0px 0px 20px 0px;
  .row {
    width: 100% !important;
    margin: 0 !important;
  }

  ::v-deep .v-expansion-panel--disabled {
    color: rgba(0, 0, 0, 0.87) !important;
  }
}

.slide-compliance-score-box {
  border-radius: 8px;
  background: #f2f4f8;
  align-items: center;
}

.score-text-col {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.score-text {
  font-size: 16px;
  font-weight: 600;
}

.score-percentage {
  font-size: 20px;
  font-weight: 700;
}

::v-deep .v-expansion-panel::before {
  border: 1px solid #f2f4f8;
  box-shadow: none;
}
::v-deep .v-expansion-panel::after {
  border-top: none;
}

::v-deep
  .v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)
  > .v-expansion-panel--active {
  border-radius: 4px;
  border-radius: 8px;
  border: 1px solid var(--new-palette-primary-primary-99, #f2f4f8);
}

::v-deep .v-expansion-panel--active .v-expansion-panel-header {
  border: none !important;
}
::v-deep .v-expansion-panel-content {
  .v-expansion-panel-content__wrap {
    .v-list {
      .v-list-group {
        .v-list-group__header {
          .suggestions-box {
            .v-list-item__content {
              .suggestions__header-wrapper {
                display: flex;
                align-items: center;
                div:last-child {
                  display: flex;
                  align-items: center;
                  // span {
                  //   line-height: 30px;
                  // }
                }
              }
            }
            .v-icon {
              display: block !important;
            }
          }
        }
      }
    }
  }
  border: none !important;
}
.suggestions {
  &__total-wrapper {
    display: flex;
    width: 212px;
    height: 35px;
    padding: 8px;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 4px;
    background: #f2f4f8;
    margin-left: auto;
    justify-content: center;
  }
  &__total-number {
    color: #1075ab;
  }
  &__panel {
    margin-bottom: 16px;

    ::v-deep .v-expansion-panel-header {
      border-radius: 8px;
      border: 1px solid#F2F4F8;
    }
  }
  &__item-header {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
  }
  &__header-wrapper {
    display: flex;
    align-items: baseline;
  }
  &__item-id {
    color: #fff;
  }
  &__item-name {
    color: #000;
  }
}

.suggestions-box {
  border-top: 1px solid #f2f4f8;
  min-width: 100%;
  height: 68px;
}

.suggestion-details {
  display: flex;
  align-items: center;
  font-size: 16px;
  gap: 8px;
  &__total {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.24px;
    color: #697077;
  }
  &__item-text {
    margin-left: 10px;
  }
  &__actions {
    display: flex;
    justify-content: flex-end;
    margin-right: 2px;
  }
  &__description {
    color: #212121;
    font-weight: 400;
    letter-spacing: 0.24px;
    margin: 18px auto 0 0;
    font-size: 14px;
  }
}
.score {
  margin-left: 8px;
}
.app-button {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: none;
  letter-spacing: normal;
}
.complied-message {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  gap: 40px;
  justify-content: center;
  &__wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 14px;
    max-width: 70%;
    text-align: center;
  }
  &__description {
    font-weight: 600;
  }
  &__actions {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .score-percentage {
    color: #21a7e0;
    font-size: 20px;
    font-weight: 700;
  }
}
.loader-wrapper {
  color: #000;
  display: flex;
  width: 540px;
  height: 120px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;

  border-radius: 12px;
  background: var(--color-styles-old-white, #fff);

  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.16),
    0px 0px 6px 0px rgba(0, 0, 0, 0.16);
}

::v-deep
  .v-list-group--active
  > .v-list-group__header
  > .v-list-group__header__append-icon
  .v-icon {
  color: #757575 !important;
}

::v-deep
  .v-list-group
  .v-list-group__header
  .v-list-item__icon.v-list-group__header__append-icon {
  min-width: 0px !important;
  margin-top: 8px !important;
}
</style>
