<template>
  <div
    class="slide-container"
    :class="{ 'right-panel-open': rightPanelComponent }"
  >
    <SlidesListComponent
      :is-right-panel-open="rightPanelComponent"
      :panelHeight="mainSlideHeight"
    />
    <MainSlideComponent @main-slide-height="setMainSlideHeight" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import SlidesListComponent from './SlidesListComponent.vue';
import MainSlideComponent from './MainSlideComponent.vue';

export default {
  components: {
    SlidesListComponent,
    MainSlideComponent,
  },
  data() {
    return {
      mainSlideHeight: 0,
    };
  },
  computed: {
    ...mapState('comply', ['rightPanelComponent']),
    ...mapGetters('comply', ['complySlidesDetails']),
  },
  methods: {
    setMainSlideHeight(height) {
      this.mainSlideHeight = height;
      this.$emit('update-main-slide-height', this.mainSlideHeight);
    },
  },
};
</script>

<style lang="scss" scoped>
.slide-container {
  width: 100%;
  position: relative;
  display: flex;
  gap: 20px;
  height: calc(100% - 190px);

  &.panel-open {
    gap: 10px;
  }
}
</style>
