<template>
  <div class="color-fill-suggestion">
    <div class="block">
      <div class="block__wrapper">
        <span class="block__text">{{ suggestionTextLeft }}</span>
        <div class="color-and-details">
          <div class="color-box" :style="{ 'font-family': current }">Aa</div>
          <div class="color-details">
            <span :style="{ 'font-family': current }">{{ current }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="arrow">
      <v-icon>mdi-arrow-right</v-icon>
    </div>
    <div class="block">
      <div class="block__wrapper">
        <span class="block__text">{{ suggestionTextRight }}</span>
        <div class="color-and-details">
          <div class="color-box" :style="{ 'font-family': suggested }">Aa</div>
          <div class="color-details">
            <span :style="{ 'font-family': suggested }">{{ suggested }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    suggestion: {
      type: Object,
      required: true,
    },
  },
  computed: {
    current() {
      return this.suggestion.current;
    },
    suggested() {
      return this.suggestion.suggested;
    },
    suggestionTextLeft() {
      if (
        this.suggestion?.status &&
        this.suggestion?.status.toLowerCase() === 'accepted'
      )
        return 'Previous';
      return 'Current';
    },
    suggestionTextRight() {
      if (
        this.suggestion?.status &&
        this.suggestion?.status.toLowerCase() === 'accepted'
      )
        return 'Current';
      return 'Suggested';
    },
  },
};
</script>

<style scoped lang="scss">
.color-fill-suggestion {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 38px 16px 66px;
}
.block {
  display: flex;
  align-items: center;

  &__text {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
  }
  &__wrapper {
    gap: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
.color-box {
  color: #000;
  display: flex;
  font-size: 24px;
  width: 50px;
  height: 50px;
}
.color-details {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
  margin-top: 6px;
  text-transform: capitalize;
}
.arrow {
  color: #191c1e;
  width: 24px;
  height: 24px;
  margin: 24px;
}
</style>
