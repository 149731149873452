<template>
  <div class="replaceComplyImagesContainer">
    <div class="backBtn">
      <v-btn @click="handleBack">
        <v-icon size="22">{{ 'mdi-chevron-left' }}</v-icon>
        {{ 'Back' }}
      </v-btn>
    </div>
    <div class="heading">{{ 'Icon Suggestion' }}</div>
    <div class="suggestionNumberAndAction">
      <div class="suggestionsHeaderWrapper">
        <v-avatar
          :max-width="24"
          :max-height="24"
          :min-height="24"
          :min-width="24"
          :color="getReplaceIconSuggestionState.color"
          class="mr-2"
        >
          <span class="suggestions__item-id">
            {{ getReplaceIconSuggestionState.suggestionNumber }}
          </span>
        </v-avatar>
        <div class="suggText">{{ 'Icon - Brand Icon' }}</div>
        <!--
          <div v-if="suggestion.status">
            <v-icon :color="'#D9D9D9'"
              >mdi-circle-small</v-icon
            >
            <span class="suggestion-details__total">
              {{
                suggestion.status === 'accepted'
                  ? 'Accepted'
                  : 'Rejected'
              }}</span
            >
          </div>
          !-->
      </div>
      <div class="actions">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="handleAccept" v-bind="attrs" v-on="on">
              <v-icon color="#00925a">mdi-check</v-icon>
            </v-btn>
          </template>
          <span>Accept</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="handleReject" v-bind="attrs" v-on="on">
              <v-icon color="#d32f2f">mdi-close</v-icon>
            </v-btn>
          </template>
          <span>Reject</span>
        </v-tooltip>
      </div>
    </div>
    <div class="imageSuggestion">
      <div class="labelAndImg">
        <div class="label">{{ 'Current' }}</div>
        <div class="currentWrapper">
          <v-img
            contain
            :src="getReplaceIconSuggestionState.current"
            :lazy-src="'/assets/img/slides/placeholder-slide.svg'"
            loading="lazy"
            alt
            :aspect-ratio="16 / 9"
          />
        </div>
      </div>
      <div class="iconContainer">
        <v-icon>{{ 'mdi-arrow-right' }}</v-icon>
      </div>
      <div class="labelAndImg">
        <div class="label">
          {{ `${isRevertEnabled ? 'Selected' : 'Suggested'}` }}
        </div>
        <div class="suggestedWrapper">
          <v-img
            contain
            :src="getReplaceIconSuggestionState.suggested"
            :lazy-src="'/assets/img/slides/placeholder-slide.svg'"
            loading="lazy"
            alt
            :aspect-ratio="16 / 9"
          />
        </div>
      </div>
      <div class="revertWrappper">
        <v-tooltip bottom v-if="isRevertEnabled">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              @click="handleRevert"
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
              {{ 'mdi-restore' }}
            </v-icon>
          </template>
          <span>{{ 'Revert to suggested' }}</span>
        </v-tooltip>
      </div>
    </div>
    <div class="companyLibHeader">
      {{ `${currentUser.company.displayName} Library` }}
    </div>
    <div class="brandImageList">
      <BrandIcons
        class="image-library-item ml-2"
        :selectedImageData="{ test: 'test-selected-img' }"
        @imageSelected="imageSelected($event)"
        :isAdobeEnabled="false"
        :slideData="{ test: 'test-slide-data' }"
        :isGenerate="false"
        :defaultImages="brandLibDefaultSuggestions"
        :origin="'comply'"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import BrandIcons from './BrandIcons.vue';
import { trackComplyEvents } from '../../../common/Analytics/ComplyEvents';
import {
  TD_PREZNAME,
  TD_TEMPLATE,
  TD_COMMON_COLUMN_VALUE,
  TD_COMMON_COLUMN_NAME,
} from '../../../common/Analytics/MatomoTrackingDataHelper';

export default {
  name: '',
  data() {
    return {};
  },
  props: {
    handleAcceptFromBrandImageLib: {
      type: Function,
      default: () => {},
    },
    handleRejectFromBrandImageLib: {
      type: Function,
      default: () => {},
    },
    origin: {
      type: String,
      default: null,
    },
  },
  components: {
    BrandIcons,
  },
  methods: {
    ...mapActions('comply', ['setReplaceIconSuggestionState']),

    handleBack() {
      this.setReplaceIconSuggestionState({
        suggestionNumber: 0,
        current: '',
        suggested: '',
        panelOpen: false,
        color: '',
        suggestedOnLoad: '',
        category: null,
        suggestion: null,
        itemIndex: 0,
        imageFromLib: null,
      });
    },

    imageSelected(event) {
      if (event && event.url) {
        this.setReplaceIconSuggestionState({
          ...this.getReplaceIconSuggestionState,
          suggested: event.url,
          imageFromLib: {
            ...event,
            s3_bucket:
              event.type && event.type === 'default'
                ? event.s3_bucket
                : process.env.VUE_APP_MVP_ASSETS_BUCKET,
            s3_path:
              event.type && event.type === 'default'
                ? event.s3_path
                : `private${event.s3_path}`,
          },
        });
      }
    },

    handleRevert() {
      this.setReplaceIconSuggestionState({
        ...this.getReplaceIconSuggestionState,
        suggested: this.getReplaceIconSuggestionState.suggestedOnLoad,
        imageFromLib: null,
      });
      if (this.origin === 'comply') {
        const otherData = {
          [TD_PREZNAME]: this.fileDetails.name,
          [TD_TEMPLATE]: this.selectedTemplate,
          [TD_COMMON_COLUMN_NAME]: 'Suggestion',
          [TD_COMMON_COLUMN_VALUE]:
            this.getReplaceIconSuggestionState.suggestion.name,
        };
        trackComplyEvents.complianceCheckerSuggestionChangeReset(
          this.currentUser,
          otherData,
        );
      }
    },

    handleAccept() {
      this.handleAcceptFromBrandImageLib({
        ...this.getReplaceIconSuggestionState.suggestion,
        suggested: this.getReplaceIconSuggestionState.imageFromLib
          ? [this.getReplaceIconSuggestionState.imageFromLib]
          : this.getReplaceIconSuggestionState.suggestion.mapping[
              this.getReplaceIconSuggestionState.itemIndex
            ].suggested,
        origin: 'brandLibPanel',
      });
    },

    handleReject() {
      this.handleRejectFromBrandImageLib(
        this.getReplaceIconSuggestionState.suggestion,
      );
    },
  },
  computed: {
    ...mapGetters('comply', ['getReplaceIconSuggestionState']),
    ...mapState('users', ['currentUser']),
    ...mapState('comply', ['fileDetails', 'selectedTemplate']),

    isRevertEnabled() {
      return (
        this.getReplaceIconSuggestionState &&
        this.getReplaceIconSuggestionState.suggestedOnLoad &&
        this.getReplaceIconSuggestionState.suggestedOnLoad !== '' &&
        this.getReplaceIconSuggestionState.suggested &&
        this.getReplaceIconSuggestionState.suggested !== '' &&
        this.getReplaceIconSuggestionState.suggested !==
          this.getReplaceIconSuggestionState.suggestedOnLoad
      );
    },

    brandLibDefaultSuggestions() {
      return this.getReplaceIconSuggestionState.suggestion.suggested.map(
        (item) => ({ ...item, type: 'default' }),
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.replaceComplyImagesContainer {
  .backBtn {
    margin: 0px 0px 18px 0px;
    .v-btn {
      box-shadow: none;
      background-color: #ffffff;
      color: #21a7e0;
      letter-spacing: normal;
      text-transform: none;
      padding: 0;
    }
  }
  .heading,
  .companyLibHeader {
    font-size: 20px;
    font-weight: 600;
  }
  .heading {
    margin: 0px 0px 16px 0px;
  }
  .suggestionNumberAndAction {
    display: flex;
    justify-content: space-between;
    margin: 0px 0px 20px 0px;
    .suggestionsHeaderWrapper {
      display: flex;
      .v-avatar {
        color: #ffffff;
      }
      .suggText {
        font-size: 16px;
      }
    }
    .actions {
    }
  }
  .imageSuggestion {
    margin: 20px 40px 40px 58px;
    display: flex;
    justify-content: space-between;
    padding: 0px 20px;
    .labelAndImg {
      .currentWrapper,
      .suggestedWrapper {
        width: 48px;
        height: 48px;
        // box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
        //   rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
        border-radius: 4px;
        .v-image {
          width: 100%;
          height: 100%;
          aspect-ratio: 16 / 9;
          border-radius: 4px;
        }
      }
      .label {
        font-size: 18px;
        color: #000000;
        margin: 0px 0px 8px 0px;
      }
    }
    .iconContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .revertWrappper {
      width: 10%;
    }
  }
  .brandImageList {
    ::v-deep .image-library-item {
      margin: 0 !important;
      .container {
        padding: 12px 2px 0px 2px !important;
      }
    }
  }
}
</style>
