<template>
  <div class="wait-list-audience">
    <v-img
      class="not-registered"
      :src="'/assets/img/audience/audience-not-registered.svg'"
      max-height="400"
      max-width="401"
      alt
    ></v-img>
    <div class="not-registered-info">
      <div class="not-registered-info-detail">
        {{ $t('navbarActions.addColleagueDetail.thanksForIntroduction') }}
      </div>
      <div class="not-registered-info-detail">
        {{ $t('navbarActions.addColleagueDetail.addedToWaitlist') }}
      </div>
    </div>
    <div class="create-fingerprint">
      <div class="create-fingerprint-title">
        {{ $t('navbarActions.addColleagueDetail.onTheirBehalf') }}
      </div>
      <v-container class="px-0 py-0 pl-2" fluid>
        <v-radio-group class="pt-0" v-model="fingerprintType">
          <v-radio value="private" color="#20a7e0">
            <div slot="label">
              <span class="black--text option-description"
                >{{
                  $t(
                    'navbarActions.addColleagueDetail.completeaFingerprintAssessment',
                  )
                }}
                <v-tooltip
                  top
                  max-width="260"
                  content-class="tooltip-content"
                  fixed
                  open-delay="500"
                  close-delay="300"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="icon-title info-icon"
                      v-bind="attrs"
                      v-on="on"
                      v-text="`mdi-information-outline`"
                    />
                  </template>
                  <span
                    >{{
                      $t(
                        'navbarActions.addColleagueDetail.completeaFingerprintAssessment',
                      )
                    }}
                  </span>
                </v-tooltip></span
              >
            </div>
          </v-radio>
          <v-radio value="zenSense" color="#20a7e0">
            <span slot="label" class="black--text option-description"
              >{{
                $t(
                  'navbarActions.addColleagueDetail.generateFingerprintUsingZensense',
                )
              }}
              <v-tooltip
                top
                max-width="220"
                content-class="tooltip-content"
                fixed
                open-delay="500"
                close-delay="300"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="icon-title info-icon"
                    v-bind="attrs"
                    v-on="on"
                    v-text="`mdi-information-outline`"
                  />
                </template>
                <span>
                  {{
                    $t(
                      'navbarActions.addColleagueDetail.generateFingerprintUsingZensense',
                    )
                  }}
                </span>
              </v-tooltip></span
            >
          </v-radio>
        </v-radio-group>
      </v-container>
      <v-btn
        class="primary-button"
        rounded
        color="#21a7e0"
        height="48"
        min-width="134"
        :loading="handleTakeAssessmentClicked"
        @click="
          () => {
            handleTakeAssessmentClicked = true;
            handleTakeAssessment(fingerprintType === 'zenSense');
          }
        "
      >
        {{ $t(takeAssessmentButtonText) }}
      </v-btn>
    </div>
    <div class="add-new-audience">
      <div class="add-new-audience-title">
        {{ $t('navbarActions.addColleagueDetail.wouldYouLikeToAddAnother') }}
      </div>
      <v-btn
        class="secondary-button"
        rounded
        outlined
        color="primary"
        height="48"
        min-width="134"
        :loading="handleAddNewAudienceClicked"
        @click="
          () => {
            handleAddNewAudienceClicked = true;
            handleAddNewAudience();
          }
        "
      >
        {{ $t('fingerprint.searchPage.addNewAudience') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WaitListAudienceCompany',
  props: {
    handleTakeAssessment: {
      type: Function,
      default: () => {},
    },
    handleAddNewAudience: {
      type: Function,
      default: () => {},
    },
    takeAssessmentButtonText: {
      type: String,
      default: 'fingerprint.takeAssessment',
    },
  },
  data() {
    return {
      fingerprintType: 'private',
      handleTakeAssessmentClicked: false,
      handleAddNewAudienceClicked: false,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss', '@/scss/app.scss';

.wait-list-audience {
  align-content: 'center';

  .add-new-audience {
    text-align: center;
    .add-new-audience-title {
      margin-bottom: 24px;
    }
  }

  .add-new-audience-title,
  .create-fingerprint-title {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
  }

  .create-fingerprint {
    margin-bottom: 24px;
    text-align: center;
    .container {
      display: flex;
      justify-content: center;
    }
  }

  .not-registered {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .not-registered-info {
    color: #000000;
    margin-bottom: 24px;
    text-align: center;
    .not-registered-info-title {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      margin-bottom: 16px;
    }
    .not-registered-info-detail {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 19px;
    }
  }
}
</style>
