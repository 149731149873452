<template>
  <div v-if="isCustomUrl">
    <div v-if="iconUrl" class="know-item-img">
      <img :src="iconUrl" alt :width="40" :height="40" />
    </div>
  </div>
  <v-icon v-else color="black" size="40" v-text="icon" />
</template>

<script>
import { mapState } from 'vuex';
// import KnowIcon from '@/components/common/Icons/KnowIcon.vue';
// import { getFileURL } from '@/utils/calyrex';

export default {
  name: 'FAQCategoryIcon',
  data: () => ({
    // iconUrl: '',
  }),
  props: {
    category: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    iconUrl: {
      type: String,
      default: () => '',
    },
  },
  computed: {
    ...mapState('users', ['currentUser']),
    isCustomUrl() {
      return this.icon.endsWith('.svg');
    },
  },
  methods: {
    // async getIconUrl() {
    //   return await getFileURL(
    //     this.currentUser.user.cognitoID,
    //     this.icon,
    //     this.currentUser.userIp,
    //   );
    // },
  },
  async beforeMount() {
    // if (this.isCustomUrl) {
    //   this.iconUrl = await this.getIconUrl();
    // }
  },
  components: {
    // KnowIcon,
  },
};
</script>
