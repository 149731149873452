var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:("comply-upload-section fl-upldr-bx " + (_vm.isActionLoading ? 'blur-effect' : ''))},[_c('div',{staticClass:"comply-upload-section__left",on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.onComplyUploadDivClick()}}},[(_vm.fileError)?_c('p',{staticClass:"comply-upload-section__example-label"},[_vm._v(" "+_vm._s(_vm.$t(_vm.fileError))+" ")]):(!_vm.getFileName())?_c('p',{staticClass:"comply-upload-section__example-label",on:{"click":function($event){return _vm.onComplyUploadDivClick()}}},[_vm._v(" Upload your PowerPoint slides (.pptx) ")]):_c('v-tooltip',{attrs:{"bottom":"","fixed":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({staticClass:"comply-upload-section__file-name"},'p',attrs,false),on),[_vm._v(" "+_vm._s(_vm.getFileName())+" ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.getFileName()))])]),(!_vm.getComplianceScore)?_c('label',{staticClass:"comply-upload-section__size-limit-text"},[_c('span',[_vm._v(" Template Converter works best with decks having up to 20 slides and file size under 200MB. ")])]):_c('div',{staticClass:"quick-actions"},[_c('a',{attrs:{"data-pendo-id":"comply-change-template-btn-top-panel"},on:{"click":function($event){return _vm.onChangeTemplate()}}},[_c('v-icon',{attrs:{"color":"#21A7E0"}},[_vm._v("mdi-compare")]),_vm._v(" Change template")],1)])],1),(!_vm.getComplianceScore)?_c('div',[(!_vm.fileDetails)?_c('div',{staticClass:"comply-upload-section__right"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({attrs:{"data-pendo-id":"comply-upload-file-icon"}},'div',attrs,false),on),[_c('v-file-input',{ref:"complyFileUploadInput",staticClass:"comply-upload-section__upload-icon",attrs:{"accept":("" + _vm.allowedUploadTypes),"prepend-icon":"mdi-tray-arrow-up","multiple":"","label":"File input","hide-input":""},on:{"change":_vm.previewFiles}})],1)]}}],null,false,3832326342)},[_c('span',[_vm._v("Upload")])]),_c('input',{staticStyle:{"display":"none"},attrs:{"type":"file","id":"hiddenInput","accept":("" + (_vm.allowedUploadTypes.join(', ')))},on:{"change":_vm.handleOnDivClickFileSelection}}),_c('v-btn',{staticClass:"comply-upload-section__check-compliance-btn",attrs:{"color":"#21A7E0","rounded":"","disabled":(!_vm.newFilesToUpload.length ||
            !!_vm.fileError.length ||
            _vm.slidesThresholdExceeds) &&
          !_vm.checkingUsage,"loading":_vm.showLoader,"data-pendo-id":"comply-upload-check-compliance-btn"},on:{"click":function($event){return _vm.onComplianceBtnClick()}}},[_vm._v("Upload")])],1):_c('v-btn',{staticClass:"comply-upload-section__cancel-btn",attrs:{"rounded":"","outlined":""},on:{"click":_vm.onCancel}},[_vm._v("Cancel")])],1):_c('div',{staticClass:"actions-container"},[_c('div',{staticClass:"re-check"},[_c('v-icon',{on:{"click":_vm.onCancel}},[_vm._v("mdi-close")]),_c('v-btn',{staticClass:"re-check-cta",attrs:{"loading":_vm.loading,"rounded":"","outlined":""},on:{"click":function($event){return _vm.handleOnReCheckComply('recheck-compliance')}}},[_vm._v("Recheck compliance")])],1),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":300,"offset-y":"","left":"","content-class":"generate-rating-popover"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var attrs = ref.attrs;
return [_c('div',_vm._b({staticClass:"generate-rating-btn-container",attrs:{"data-pendo-id":"comply-rating-btn"}},'div',attrs,false),[_c('div',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var hover = ref.hover;
return [_c('v-btn',{class:{
                  bounce: _vm.animateLike,
                  'rating-disabled': _vm.form.isLiked !== null,
                },attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.form.isLiked === null ? _vm.handleRatingClick('like') : function () {}}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":(hover && _vm.form.isLiked === null) ||
                        _vm.form.isLiked === true ||
                        _vm.animateLike
                          ? '#21a7e0'
                          : '#000',"size":"24"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.form.isLiked === true || _vm.animateLike ? 'mdi-thumb-up' : 'mdi-thumb-up-outline')+" ")])]}}],null,true),model:{value:(_vm.likeTooltip),callback:function ($$v) {_vm.likeTooltip=$$v},expression:"likeTooltip"}},[_c('span',[_vm._v(_vm._s(_vm.animateLike ? 'Rate brand compliance suggestions' : 'I like the suggestions!'))])])],1)]}}],null,true)})],1),_c('div',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var hover = ref.hover;
return [_c('v-btn',{class:{ 'rating-disabled': _vm.form.isLiked !== null },attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.form.isLiked === null
                    ? _vm.handleRatingClick('dislike')
                    : function () {}}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":(hover && _vm.form.isLiked === null) ||
                        _vm.form.isLiked === false
                          ? '#21a7e0'
                          : '#000',"size":"24"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.form.isLiked === false ? 'mdi-thumb-down' : 'mdi-thumb-down-outline')+" ")])]}}],null,true)},[_c('span',[_vm._v(" I don’t like the suggestions!")])])],1)]}}],null,true)})],1)])]}}]),model:{value:(_vm.feedbackPopup),callback:function ($$v) {_vm.feedbackPopup=$$v},expression:"feedbackPopup"}},[_c('v-card',[_c('v-btn',{staticClass:"generate-rating-popover__close",attrs:{"elevation":"0","icon":""},on:{"click":_vm.closeFeedback}},[_c('v-icon',[_vm._v("mdi-close")])],1),(_vm.feedbackSuccess)?_c('div',{staticClass:"feedback-success"},[_c('img',{attrs:{"src":"/assets/img/generate/feedback_thanks.png"}}),_c('span',[_vm._v("Thanks for your feedback!")])]):[_c('div',{staticClass:"generate-rating-popover__content"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('div',[_c('label',{staticClass:"form__label"},[_vm._v(" "+_vm._s(_vm.$t('generate.feedbackIsInvaluable'))+" ")]),_c('v-textarea',{staticClass:"form__textarea",attrs:{"label":"Type your feedback","solo":"","rows":4,"required":"","hide-details":true},model:{value:(_vm.form.feedback),callback:function ($$v) {_vm.$set(_vm.form, "feedback", $$v)},expression:"form.feedback"}})],1),_c('div',{staticClass:"form__share"},[_c('v-checkbox',{attrs:{"hide-details":true},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"share-label"},[_vm._v(" Share the deck details with our team to help improve future results "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"250","content-class":"tooltip-content","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":18}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}])},[_c('span',[_vm._v(" We don't use confidential customer data for training ML models ")])])],1)]},proxy:true}]),model:{value:(_vm.form.shareDetails),callback:function ($$v) {_vm.$set(_vm.form, "shareDetails", $$v)},expression:"form.shareDetails"}})],1)])],1),_c('v-card-actions',{staticClass:"form__action"},[_c('v-spacer'),_c('v-btn',{staticClass:"primary-button",staticStyle:{"text-transform":"none !important"},attrs:{"color":"primary","rounded":"","width":"100","height":"48","disabled":_vm.form.feedback.length === 0},on:{"click":_vm.submitRatings}},[_vm._v(" Submit ")])],1)]],2)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }