var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"external-fingerprints"},[_c('div',{staticClass:"external-fingerprints-title"},[_vm._v(" "+_vm._s(_vm.$t('fingerprintvue.externalFingerprints.audienceInvites'))+" ")]),_c('div',[(!_vm.loadingExternalFingerPrints)?_c('v-data-table',{staticClass:"external-fingerprints-table",attrs:{"headers":_vm.tableHeaders,"items":_vm.externalFingerPrints,"footer-props":{
        itemsPerPageOptions: [10, 25, 50, 100],
      },"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('div',{staticClass:"external-fingerprints-searchbar"},[_c('Search',{attrs:{"searchKey":_vm.search,"placeholder":_vm.$t('fingerprintvue.externalFingerprints.searchBy'),"onSearch":_vm.onSearch}})],1),_c('div',{staticClass:"spacer"}),_c('v-btn',{staticClass:"primary-button add-audience mt-2 py-2",staticStyle:{"'font-size'":"'18px'"},attrs:{"rounded":"","color":"#21a7e0","height":"48","min-width":"134"},on:{"click":_vm.addNewAudience}},[_c('NewFeatureBadge',{attrs:{"module-name":"addAudience","feature-id":'addAudience_id',"offsetX":'30',"offsetY":'-15'}},[_vm._v(" "+_vm._s(_vm.$t('fingerprint.searchPage.addNewAudience'))+" ")])],1)],1)]},proxy:true},{key:"item.fullName",fn:function(ref){
      var item = ref.item;
return [_c('div',{on:{"click":function($event){return _vm.onAudienceClick(item)}}},[_c('AudienceItem',{attrs:{"itemClass":'no-padding clickable',"firstName":item.firstName,"lastName":item.lastName}})],1)]}},{key:"item.sharedEntityType",fn:function(ref){
      var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","max-width":"200","content-class":"tooltip-content","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"copy-fingerprint--auto",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.handleCopyLink(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-content-copy ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.isCopied && _vm.copiedFPId === item.id ? _vm.$t('navbarActions.shareFreeTrial.copied') : _vm.$t('navbarActions.shareFreeTrial.copyLink')))])])]}}],null,true)}):_c('clip-loader',{staticClass:"cliploader",attrs:{"color":"#21a7e0","width":'60',"height":'60',"size":'50px'}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }