<template>
  <div class="main-wrapper">
    <div class="search-container">
      <div class="heading">Select template</div>
      <div class="sub-heading">
        Please select the brand template for the output slide(s)
      </div>
      <div class="search-block">
        <v-text-field
          solo
          rounded
          v-model="templateSearch"
          label="Search for templates"
          hide-details
        >
          <template v-slot:append>
            <v-icon
              color="primary"
              @click="templateSearch = ''"
              v-text="templateSearch ? `mdi-close` : `mdi-magnify`"
            />
          </template>
        </v-text-field>
      </div>
    </div>
    <div
      class="template-listing-wrapper"
      :data-pendo-id="getContainerPendoText"
    >
      <div class="recomended-templates" v-if="recommendedTemplatesList.length">
        <p class="recomended-templates__heading">
          Recommended company templates
        </p>
        <v-row class="templates-grid">
          <v-col
            cols="4"
            v-for="(template, index) in recommendedTemplatesList"
            :key="index"
          >
            <template-item
              :handleSelectTemplate="handleSelectTemplate"
              :isSelected="isTemplateSelected(template)"
              :template="template"
            ></template-item>
          </v-col>
        </v-row>
      </div>
      <div class="other-templates" v-if="otherTemplatesList.length">
        <p class="other-templates__heading">Other company templates</p>
        <v-row class="templates-grid">
          <v-col
            cols="4"
            v-for="template in otherTemplatesList"
            :key="template.num_id"
          >
            <template-item
              :handleSelectTemplate="handleSelectTemplate"
              :isSelected="isTemplateSelected(template)"
              :template="template"
              :isDisabled="template.disabled"
            ></template-item>
          </v-col>
          <!-- <v-col
            style="position: relative"
            cols="4"
            v-for="template in templatesData.disabled_templates"
            :key="template.num_id"
          >
            <template-item
              :isSelected="false"
              :template="template"
              isDisabled
            ></template-item>
          </v-col> -->
        </v-row>
      </div>
    </div>
    <div
      class="cta-section"
      v-if="recommendedTemplatesList.length || otherTemplatesList.length"
    >
      <v-btn
        fab
        class="primary-button cta-section__next-btn"
        color="#21a7e0"
        width="180"
        height="48"
        rounded
        :loading="loading"
        :disabled="!selectedTemplate"
        @click="onPrimaryBtnClick"
        :data-pendo-id="getButtonPendoText"
        style="text-transform: none !important"
        :name--auto="handleAutomationNames()"
      >
        {{ primaryBtnText }}
      </v-btn>
    </div>
    <div v-else class="not-found-container">
      <img
        src="/assets/img/no-results-found-templates.svg"
        height="250"
        width="250"
        alt
      />
      <div class="heading mb-1 mt-6">
        {{ $t('build.step3.noTemplatesFound') }}
      </div>
      <v-btn
        center
        class="primary-button"
        height="35"
        width="160"
        rounded
        filled
        color="primary"
        @click="handleAddMore"
      >
        {{ $t('build.step3.addTemplates') }}
      </v-btn>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import TemplateItem from './TemplateItem.vue';
import { trackComplyEvents } from '../../../common/Analytics/ComplyEvents';
import {
  TD_NUMBEROFSLIDES,
  TD_RECOMMENDED,
  TD_OTHER,
  TD_PREZNAME,
  TD_TEMPLATE,
  TD_TEMPLATE_SELECTED,
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
} from '../../../common/Analytics/MatomoTrackingDataHelper';
import AddingMoreTemplateDetail from '../../Profile/AddingMoreTemplateDetail.vue';

export default {
  components: { TemplateItem },
  props: {
    templatesData: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
    primaryBtnText: {
      type: String,
      default: 'Check Compliance',
    },
    origin: {
      type: String,
      default: 'comply',
    },
    templateRequestFrom: {
      type: String,
      default: '',
    },
  },
  name: 'TemplatesListing',
  data() {
    return {
      selectedTemplate: null,
      templateSearch: '',
    };
  },
  computed: {
    ...mapState('users', ['currentUser']),
    ...mapState('comply', ['fileDetails', 'selectedSlides']),
    getContainerPendoText() {
      if (this.origin === 'synthesis') {
        return 'synthesis-select-template-heading';
      }
      if (this.origin === 'redesign') {
        return 'redesign-select-template-heading';
      }
      return 'comply-select-template-heading';
    },
    recommendedTemplatesList() {
      if (!this.templateSearch) {
        return this.templatesData.recommended_templates;
      }
      return this.templatesData.recommended_templates.filter((x) =>
        x.name.toLowerCase().includes(this.templateSearch.toLowerCase()),
      );
    },
    otherTemplates() {
      return [
        ...this.templatesData.other_templates,
        ...this.templatesData.disabled_templates.map((x) => ({
          ...x,
          disabled: true,
        })),
      ];
    },
    otherTemplatesList() {
      if (!this.templateSearch) {
        return this.otherTemplates;
      }
      return this.otherTemplates.filter((x) =>
        x.name.toLowerCase().includes(this.templateSearch.toLowerCase()),
      );
    },
    getButtonPendoText() {
      if (this.origin === 'synthesis') {
        return 'synthesize-btn';
      }
      if (this.origin === 'redesign') {
        return 'redesign-proceed-btn';
      }
      return 'comply-check-compliance-button';
    },
  },
  methods: {
    handleAutomationNames() {
      return this.primaryBtnText.toLowerCase().split(' ').join('_');
    },
    handleSelectTemplate(template) {
      this.selectedTemplate = template;
    },
    onPrimaryBtnClick() {
      this.$emit('onPrimaryBtnClick', this.selectedTemplate);
      if (this.origin === 'comply') {
        const otherData = {
          [TD_NUMBEROFSLIDES]: this.selectedSlides.length,
          [TD_COMMON_COLUMN_NAME]: TD_TEMPLATE_SELECTED,
          [TD_COMMON_COLUMN_VALUE]:
            this.templatesData.recommended_templates.find(
              (templates) => templates === this.selectedTemplate,
            )
              ? TD_RECOMMENDED
              : TD_OTHER,
          [TD_PREZNAME]: this.fileDetails.name,
          [TD_TEMPLATE]: this.selectedTemplate.template_internal_name,
        };
        trackComplyEvents.complianceCheckerUploadCheckComplianceNextCheckCompliance(
          this.currentUser,
          otherData,
        );
      }
    },
    isTemplateSelected(template) {
      if (!this.selectedTemplate) return false;
      return (
        template.template_internal_name ===
        this.selectedTemplate.template_internal_name
      );
    },
    handleAddMore() {
      this.$modal.show(
        AddingMoreTemplateDetail,
        {
          templateRequestFrom: this.templateRequestFrom,
        },
        {
          name: 'AddingMoreTemplateDetail',
          width: '564px',
          height: '507px',
          styles: { borderRadius: '12px' },
        },
      );
    },
  },
  mounted() {
    if (Object.keys(this.templatesData).length > 0) {
      if (this.templatesData.recommended_templates.length) {
        [this.selectedTemplate] = this.templatesData.recommended_templates;
      } else {
        [this.selectedTemplate] = this.templatesData.other_templates;
      }
    }
  },
};
</script>
<style scoped lang="scss">
.main-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;
}
.search-container {
  .heading {
    //styleName: Strong;
    font-family: Lato;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 8px;
  }
  .sub-heading {
    //styleName: Body;
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.8px;
    margin-bottom: 16px;
  }

  .search-block {
    flex-shrink: 0;
    align-self: stretch;
    max-width: 370px;
    &__search-box {
      border-radius: 25px;
      background: var(--color-styles-old-white, #fff);
    }
  }
}

.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 15px;
  margin-top: 50px;
  .heading {
    color: #000000;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    margin: 10px 0px;
    text-align: center;
  }
  button {
    background: #21a7e0;
    border-radius: 25px;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin: 15px 0;
    color: white;
    text-transform: none;
  }
}

.template-listing-wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  // overflow-y: auto;
  // flex: 1;
  // height: calc(100vh - 510px);
  // overflow-y: auto;
}
.recomended-templates {
  display: flex;
  flex-direction: column;
  gap: 10px;
  &__heading {
    padding: 24px 0px 12px 0px;
    color: var(--new-palette-primary-primary-0, #000);

    /* Strong */
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0;
  }
}
.other-templates {
  display: flex;
  flex-direction: column;
  gap: 10px;
  &__heading {
    color: var(--new-palette-primary-primary-0, #000);
    padding: 24px 0px 12px 0px;
    /* Strong */
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0;
  }
}
.templates-grid {
  // height: 410px;
  margin: 0;
}

.cta-section {
  display: flex;
  align-items: center;
  justify-content: center;
  // margin-top: 50px;
  position: sticky;
  bottom: 0px;
  padding: 20px;
  background-color: #fff;

  &__next-btn {
    color: var(--color-styles-old-white, #fff);

    /* Field Name */
    font-family: Lato;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: none;
    letter-spacing: normal;
    padding: 0 25px !important;
  }
}
</style>
