<template>
  <div
    class="right-panel"
    :class="{ fade: isActionLoading, hide: !rightPanelComponent }"
  >
    <div class="main-wrapper">
      <ComplyScoreDetails
        :isAddedToLibrary="isAddedToLibrary"
        :addToLibrary="addToLibrary"
        v-if="rightPanelComponent === 'complianceScore'"
        @reviewDetails="handleReviewDetails"
        :clearSlideLibraryFlags="clearSlideLibraryFlags"
        :handleClosePopAndSaveToLib="handleClosePopAndSaveToLib"
      />
      <SlideDetailsTabs
        v-if="rightPanelComponent === 'complySlideDetails'"
        :selectedSlide="selectedSlide"
        @reviewDetails="handleReviewDetails"
        :clearSlideLibraryFlags="clearSlideLibraryFlags"
        :isAddedToLibrary="isAddedToLibrary"
        :addToLibrary="addToLibrary"
        :handleClosePopAndSaveToLib="handleClosePopAndSaveToLib"
      />
      <template-selection
        v-if="rightPanelComponent === 'compare'"
        :templatesInfo="templatesInfo"
        :selectedTemplate="selectedTemplate"
        @selectedTemplate="handleTemplateChange"
      ></template-selection>
    </div>
    <ConfirmationDialog
      :show="showConfirmation"
      :on-primary-click="onDialogCancel"
      :on-secondary-click="onDialogConfirm"
      :on-close="onDialogCancel"
      :primary-btn-text="'Cancel'"
      :secondary-btn-text="dialogPrimary"
      :title="dialogTitle"
      :text="dialogText"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import ComplyScoreDetails from './ComplyScoreDetails';
import SlideDetailsTabs from './SlideDetailsTabs.vue';
import TemplateSelection from './TemplateSelection.vue';
import ConfirmationDialog from '@/components/common/ConfirmationDialog';
import { trackComplyEvents } from '../../../common/Analytics/ComplyEvents';
import {
  TD_CROSS_FEATURE,
  TD_NUMBEROFSLIDES,
  TD_PLACEMENT,
  TD_PREZNAME,
  TD_TEMPLATE,
  TD_TEMPLATE_CONVERTER,
  TD_TIME_SPENT_IN_SECS,
} from '../../../common/Analytics/MatomoTrackingDataHelper';
import { ERROR_CODES } from '../utils/comply-helper.utils';

export default {
  data() {
    return {
      showConfirmation: false,
      dialogTitle: 'Change template',
      dialogText:
        'Your prezentation will be rechecked for compliance per brand guidelines for the selected template, and any current changes will not be saved.',
      dialogPrimary: 'Change template',
      changedTemplate: null,
    };
  },
  props: {
    slideHeight: {
      type: Number,
      required: true,
    },
    clearSlideLibraryFlags: {
      type: Function,
    },
    isAddedToLibrary: {
      type: Boolean,
      default: false,
    },
    addToLibrary: {
      type: Function,
      default: () => ({}),
    },
    handleClosePopAndSaveToLib: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState('comply', [
      'rightPanelComponent',
      'fileDetails',
      'isSmartToolsOpen',
      'selectedSlides',
      'templatesInfo',
      'selectedTemplate',
      'placement',
    ]),
    ...mapState('users', ['currentUser']),
    ...mapGetters('comply', ['getFileDetails', 'isActionLoading']),
    selectedSlide() {
      return this.$store.getters['comply/selectedSlide'];
    },
  },
  watch: {},
  components: {
    ComplyScoreDetails,
    SlideDetailsTabs,
    TemplateSelection,
    ConfirmationDialog,
  },
  methods: {
    ...mapActions('comply', ['resetState']),
    async handleReviewDetails() {
      try {
        const eventStartTime = new Date();
        await this.$store.dispatch('comply/processReviewDetails');
        const otherData = {
          [TD_PREZNAME]: this.fileDetails.name,
          [TD_TEMPLATE]: this.selectedTemplate,
          [TD_CROSS_FEATURE]: TD_TEMPLATE_CONVERTER,
          [TD_NUMBEROFSLIDES]: this.selectedSlides.length,
          [TD_TIME_SPENT_IN_SECS]: (new Date() - eventStartTime) / 1000,
        };
        trackComplyEvents.complianceCheckerReviewDetails(
          this.currentUser,
          otherData,
        );
      } catch (error) {
        this.resetState();
        this.$store.dispatch(
          'comply/setTcErrorCode',
          ERROR_CODES.ComplianceCheckFailure,
        );
        await this.$store.dispatch('comply/resetFileData');
      }
    },
    async handleTemplateChange(template) {
      this.changedTemplate = template;
      this.showConfirmation = true;
    },
    getRightPanelStyle() {
      // const heightValue = 100;
      const styles = {
        // height: `${heightValue}%`,
      };

      if (this.isSlideSuggestionsOpen) {
        styles.minWidth = '620px';
        styles.maxWidth = '620px';
      }
      return styles;
    },
    closeSmartTools() {
      this.$store.commit('comply/openSmartTools', false);
    },
    async onDialogConfirm() {
      this.showConfirmation = false;
      this.$store.dispatch('comply/openSmartTools', false);
      this.$store.dispatch('comply/setRightPanelComponent', null);
      await this.$store.dispatch(
        'comply/updateSelectedTemplate',
        this.changedTemplate.template_internal_name,
      );
      this.$emit('handleOnCheckCompliance', 'template-change');
      const otherData = {
        [TD_PLACEMENT]: this.placement,
        [TD_PREZNAME]: this.fileDetails.name,
        [TD_TEMPLATE]: this.selectedTemplate,
        [TD_NUMBEROFSLIDES]: this.selectedSlides.length,
      };
      trackComplyEvents.complianceCheckerChangeTemplate(
        this.currentUser,
        otherData,
      );
    },
    onDialogCancel() {
      this.showConfirmation = false;
      this.changedTemplate = null;
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
// .right-panel-wrapper {
//   display: flex;
//   gap: 24px;
//   height: 100vh;
//   box-shadow: -2px 0px 2px 0px rgba(182, 182, 182, 0.3);
// }
.main-wrapper {
  display: flex;
  padding: 24px 16px;
  flex-direction: column;
  gap: 13px;
  flex-shrink: 0;
  height: calc(100vh - 68px);
}
.comply-deck-details {
  box-shadow: -2px 0px 2px 0px rgba(182, 182, 182, 0.3);
}

.right-panel {
  width: 620px;
  box-shadow: -2px 0px 2px rgba(182, 182, 182, 0.3);
  padding-right: 55px;
  transition: width 0.5s ease-in, opacity 0.2s ease-in 0.3s,
    transform 0.5s ease-in;
  &.fade {
    opacity: 0.4;
    pointer-events: none;
  }
  &.hide {
    width: 0;
    transform: translateX(620px);
    opacity: 0;
    padding: 0;
    transition: width 0.5s ease-in, opacity 0.2s ease-in 0.3s,
      transform 0.5s ease-in;
  }
}

.blur-effect {
  opacity: 0.4;
  pointer-events: none;
}
</style>
