<template>
  <div class="slide-content" :class="{ 'panel-open': rightPanelComponent }">
    <div class="marker-switch" v-if="getCurrentSuggestionView === 'deck'">
      <v-tooltip :disabled="isActionLoading" left>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <v-switch
              v-model="showMarkers"
              hide-details
              dense
              @change="onToggle"
              :disabled="
                isActionLoading ||
                getCurrentSlideSuggestions.length === 0 ||
                getCurrentSuggestionView === 'deck'
              "
            >
              <template v-slot:label>
                <label :class="{ 'is-disabled': isActionLoading }"
                  >Show markers</label
                >
              </template>
            </v-switch>
          </div>
        </template>
        <span
          >Switch to the Current Slide tab in Suggestions pane to display
          markers</span
        >
      </v-tooltip>
    </div>
    <div class="marker-switch" v-else>
      <v-switch
        v-model="showMarkers"
        label="Show markers"
        hide-details
        dense
        @change="onToggle"
        :disabled="isActionLoading || getCurrentSlideSuggestions.length === 0"
      >
        <template v-slot:label>
          <label :class="{ 'is-disabled': isActionLoading }"
            >Show markers</label
          >
        </template>
      </v-switch>
    </div>
    <div class="landscape-slide">
      <VideoLoader v-model="tcLoader" v-if="!tcLoader.isIdle()" />
      <v-img
        v-else
        ref="selectedImageRef"
        :aspect-ratio="16 / 9"
        :src="landscapeImage"
        max-width="100%"
        alt="image"
        contain
        loading="lazy"
        :lazy-src="'/assets/img/slides/placeholder-slide.svg'"
        class="landscape-image"
      >
        <div v-if="getMarkers.length && showMarkers">
          <div
            v-for="marker in getMarkers"
            class="marker"
            :class="{
              selected:
                selectedSuggestion &&
                selectedSuggestion.index === marker.index &&
                selectedSuggestion.categoryIndex === marker.categoryIndex,
            }"
            :style="`left: ${marker.x}%; top:${marker.y}%; position: absolute; background-color:${marker.avatarColor}`"
            :key="`${marker.index}-${marker.avatarColor}`"
            @click.stop="handleMarkerClick(marker)"
          >
            <v-icon color="white" v-if="marker.status === 'accepted'">
              mdi-check
            </v-icon>
            <v-icon color="white" v-else-if="marker.status === 'rejected'">
              mdi-close
            </v-icon>
            <template v-else>{{ marker.index }}</template>
          </div>
        </div>
      </v-img>
    </div>
    <div class="comply-privacy">
      <div class="comply-privacy__sub">
        Prezent is powered by ASTRIDᵀᴹ AI, so mistakes are possible. Please
        double-check the results.
      </div>
      <div>
        We take confidentiality very seriously and we’ll keep your information
        secure.
        <a href="https://www.prezent.ai/security" target="_blank">Learn more</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { trackComplyEvents } from '../../../common/Analytics/ComplyEvents';
import {
  TD_PREZNAME,
  TD_TEMPLATE,
  TD_COMMON_COLUMN_VALUE,
  TD_COMMON_COLUMN_NAME,
  TD_TOGGLED_TO,
  TD_ON,
  TD_OFF,
} from '../../../common/Analytics/MatomoTrackingDataHelper';
import VideoLoader from '../../../common/VideoLoader.vue';
import { Loader } from '../../../../utils/loader-helper';

export default {
  components: {
    VideoLoader,
  },
  data() {
    return {
      showMarkers: true,
      tcLoader: new Loader('tcAllSteps'),
    };
  },
  computed: {
    ...mapState('users', ['currentUser']),
    ...mapState('comply', [
      'rightPanelComponent',
      'selectedSuggestion',
      'selectedTemplate',
      'fileDetails',
      'loaderType',
    ]),
    ...mapGetters('comply', [
      'selectedSlide',
      // 'getMarkers',
      'getCurrentSlideSuggestions',
      'getCurrentSuggestionView',
      'isActionLoading',
    ]),
    getMarkers() {
      if (this.getCurrentSuggestionView === 'deck') return [];
      return this.getCurrentSlideSuggestions
        .map((category) => category.suggestions)
        .flat()
        .filter((i) => i.x && i.y);
    },
    imageHeight() {
      const image = this.$refs.selectedImageRef;
      if (image) {
        return image.clientHeight;
      }
      return 0;
    },

    landscapeImage() {
      if (this.selectedSlide?.png?.thumbnail) {
        return this.selectedSlide.png?.thumbnail;
      }
      return '/assets/img/slides/undraw_work_in_progress_uhmv.svg';
    },
  },
  watch: {
    selectedSlide: {
      handler(val) {
        if (
          val &&
          Object.keys(val).length > 0 &&
          this.loaderType === 'tcAllSteps' &&
          this.isActionLoading
        ) {
          console.log('heree');
          this.$store.dispatch('comply/setComplyCheckLoading', false);
        }
      },
    },
    isActionLoading(val) {
      if (val) {
        this.tcLoader = new Loader(this.loaderType, 1);
      } else {
        // if (this.loaderType === 'tcAllSteps') {
        //   this.$store.dispatch('comply/setComplyCheckLoading', false);
        // }
        this.tcLoader.nextState(2);
      }
    },
    getCurrentSuggestionView: {
      handler(val) {
        if (val === 'currSlide') this.showMarkers = true;
        else this.showMarkers = false;
      },
    },
  },
  mounted() {
    if (this.getCurrentSuggestionView === 'deck') this.showMarkers = false;
    this.reportHeight();
    window.addEventListener('resize', this.reportHeight);
    if (!this.selectedSlide && !this.isReviewDetailsLoading) {
      this.$store.dispatch('comply/setComplyCheckLoading', true);
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.reportHeight);
  },
  methods: {
    ...mapActions('comply', ['setSelectedSuggestion']),
    handleMarkerClick(marker) {
      this.setSelectedSuggestion({
        index: marker.index,
        categoryIndex: marker.categoryIndex,
        category: marker.category,
      });
      const otherData = {
        [TD_PREZNAME]: this.fileDetails.name,
        [TD_TEMPLATE]: this.selectedTemplate,
      };
      trackComplyEvents.complianceCheckerMarkerClick(
        this.currentUser,
        otherData,
      );
    },
    reportHeight() {
      if (this.$refs?.selectedImageRef?.$el?.clientHeight) {
        this.$emit(
          'main-slide-height',
          this.$refs.selectedImageRef.$el.clientHeight,
        );
      }
    },
    onToggle() {
      const otherData = {
        [TD_PREZNAME]: this.fileDetails.name,
        [TD_TEMPLATE]: this.selectedTemplate,
        [TD_COMMON_COLUMN_NAME]: TD_TOGGLED_TO,
        [TD_COMMON_COLUMN_VALUE]: this.showMarkers ? TD_ON : TD_OFF,
      };
      trackComplyEvents.complianceCheckerShowMarkersToggle(
        this.currentUser,
        otherData,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-spinner {
  background-color: white;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slide-content {
  width: calc(100% - 384px);
  transition: width 0.5s ease-in;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .marker-switch {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    ::v-deep .v-input--selection-controls {
      margin-top: 0;
      padding-top: 0;
    }
    ::v-deep .v-input--selection-controls__input + .v-label {
      color: #000000;
      font-size: 14px;
      top: -2px;
    }
  }

  &.panel-open {
    width: calc(100% - 150px);
    transition: width 0.5s ease-in;
  }
}

.landscape-slide {
  box-shadow: 0px 2px 5px 1px rgba(64, 60, 67, 0.16);
  border-radius: 12px;
  aspect-ratio: 16/9;
  max-width: 100%;
  margin-bottom: 10px;
  // overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  ::v-deep
    .v-image.v-responsive.landscape-image
    theme--light
    > .v-responsive__content {
    width: auto;
  }
  .landscape-image {
    position: relative;

    .marker {
      display: flex;
      align-items: center;
      justify-content: center;
      // transform: translate(-50%, -50%);
      transform-origin: center center;
      border-radius: 50%;
      height: 24px;
      width: 24px;
      color: white;
      cursor: pointer;
      transition: all 0.2s ease-in;
      box-sizing: content-box;

      &.selected {
        z-index: 2;
        height: 26px;
        width: 26px;
        border: 3px solid currentColor;
        border-color: color-mix(in srgb, currentColor 30%, transparent);
        transition: all 0.2s ease-in;
        box-shadow: -1px -1px 8px 0px rgba(0, 0, 0, 0.5),
          1px 1px 4px 0px rgba(0, 0, 0, 0.1);
      }

      &:hover {
        border: 3px solid currentColor;
        border-color: color-mix(in srgb, currentColor 30%, transparent);
        transition: all 0.2s ease-in;
        box-shadow: -1px -1px 8px 0px rgba(0, 0, 0, 0.5),
          1px 1px 4px 0px rgba(0, 0, 0, 0.1);
      }
    }
  }
}
.comply-privacy {
  margin-top: 8px;
  padding-bottom: 20px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #697077;

  &__sub {
    margin-bottom: 2px;
  }

  a {
    color: #21a7e0;
    cursor: pointer;
  }
}

.is-disabled {
  color: rgba(0, 0, 0, 0.38) !important;
}
</style>
