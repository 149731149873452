<template>
  <div class="color-fill-suggestion">
    <div class="block">
      <div class="block__wrapper">
        <span class="block__text">Current</span>
        <div class="color-and-details">
          <div class="color-box" :style="{ 'font-size': currentFontSize }">
            Aa
          </div>
          <div class="color-details">
            <span>Font size:</span>
            <span>{{ suggestion.font_mapping.current_font }} pts</span>
          </div>
        </div>
      </div>
    </div>
    <div class="arrow">
      <v-icon>mdi-arrow-right</v-icon>
    </div>
    <div class="block">
      <div class="block__wrapper">
        <span class="block__text">Suggested</span>
        <div class="color-and-details">
          <div class="color-box" :style="{ 'font-size': suggestedFontSize }">
            Aa
          </div>
          <div class="color-details">
            <span>Font size:</span>
            <span>{{ suggestion.font_mapping.suggested_font }} pts</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    suggestion: {
      type: Object,
      required: true,
    },
  },
  computed: {
    currentFontSize() {
      return `${this.suggestion.mapping.current}px`;
    },
    suggestedFontSize() {
      return `${this.suggestion.mapping.suggested}px`;
    },
  },
};
</script>

<style scoped lang="scss">
.color-fill-suggestion {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0 16px;
}
.block {
  display: flex;
  align-items: center;

  &__text {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
  }
  &__wrapper {
    gap: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
.color-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  color: #000;
}
.color-details {
  display: flex;
  flex-direction: column;
  color: #000;
  font-size: 16px;
  line-height: 130%;
}
.arrow {
  color: #191c1e;
  width: 24px;
  height: 24px;
  margin: 24px;
}

.color-and-details {
  display: flex;
  align-items: flex-end;
  gap: 12px;
}
</style>
