<template>
  <div class="color-fill-suggestion">
    <div class="block">
      <div class="block__wrapper">
        <span class="block__text">Previous</span>
        <div class="color-and-details">
          <div class="color-box">
            <ClipLoader
              v-if="isTemplateUrlLoading"
              class="loading-spinner"
              :color="'#21a7e0'"
              :width="20"
              :height="20"
            />
            <v-img
              v-else
              class="image-style"
              loading="lazy"
              :lazy-src="'/assets/img/comply/template1.png'"
              :aspect-ratio="16 / 9"
              :src="
                currentTemplateThumbnailUrl &&
                suggestion.currentTemplate.thumbnail
                  ? currentTemplateThumbnailUrl
                  : '/assets/img/comply/unidentified_template.svg'
              "
              contain
              alt="Current Template Thumbnail"
            />
          </div>
          <div class="color-details">
            <span>{{
              suggestion.currentTemplate.name
                ? suggestion.currentTemplate.name
                : 'Unidentified'
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="arrow">
      <v-icon aria-label="Arrow pointing right">mdi-arrow-right</v-icon>
    </div>
    <div class="block">
      <div class="block__wrapper">
        <span class="block__text">Current</span>
        <div class="color-and-details">
          <div class="color-box">
            <ClipLoader
              v-if="isTemplateUrlLoading"
              class="loading-spinner"
              :color="'#21a7e0'"
              :width="20"
              :height="20"
            />
            <v-img
              v-else
              class="image-style"
              loading="lazy"
              :lazy-src="'/assets/img/comply/template1.png'"
              :aspect-ratio="16 / 9"
              :src="
                selectedTemplateThumbnailUrl
                  ? selectedTemplateThumbnailUrl
                  : '/assets/img/comply/unidentified_template.svg'
              "
              contain
              alt="Suggested Template Thumbnail"
            />
          </div>
          <div class="color-details">
            <span>{{
              suggestion.selectedTemplate.name
                ? suggestion.selectedTemplate.name
                : 'Unidentified'
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import { mapState } from 'vuex';

export default {
  props: {
    suggestion: {
      type: Object,
      required: true,
    },
  },
  components: {
    ClipLoader,
  },
  watch: {
    suggestion: {
      handler(newValue) {
        this.$store.dispatch('comply/fetchTemplateThumbnailUrls', newValue);
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      template: this.suggestion,
    };
  },
  computed: {
    ...mapState('comply', [
      'isTemplateUrlLoading',
      'selectedTemplateThumbnailUrl',
      'currentTemplateThumbnailUrl',
    ]),
  },
};
</script>

<style scoped lang="scss">
.color-fill-suggestion {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0 16px;
}
.block {
  width: 134px;
  height: 122px;

  &__text {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
  }
  &__wrapper {
    gap: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
.color-box {
  color: #000;
  display: flex;
  font-size: 24px;
  width: 80px;
  height: 40px;

  .image-style {
    border: solid 1px #e9e9e9;
    border-radius: 2px;
  }
}
.color-details {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.14px;
  margin-top: 6px;
  text-transform: capitalize;
}
.arrow {
  color: #191c1e;
  width: 24px;
  height: 24px;
  margin: 24px;
}
</style>
