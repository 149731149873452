<template>
  <div class="supportbox-wrapper">
    <v-btn class="back-btn" icon @click="handleBack">
      <v-icon dark v-text="`mdi-chevron-left`" />
    </v-btn>
    <v-card-title class="help-menu-title">{{
      $t('help.supportRequest')
    }}</v-card-title>
    <v-card-text v-if="isSent"
      ><ConfirmationSVG />
      <div class="support-confirm-message">
        {{ $t('help.supportConfirmMesage') }}
      </div></v-card-text
    >
    <v-card-text class="support-card-text" v-else
      >{{ $t('help.supportCardText') }}
      <div class="supportbox-inner">
        <v-textarea
          v-model="message"
          rounded
          class="supportbox-message"
          solo
          :label="$t('help.enterNoteHere')"
        ></v-textarea>
        <v-btn
          :loading="isSending"
          :disabled="!message.trim().length"
          @click="handleSubmit"
          rounded
          filled
          color="#21a7e0"
          class="primary-button"
        >
          <span style="padding-left: 16px; padding-right: 16px">{{
            $t('common.submit')
          }}</span>
        </v-btn>
      </div>
    </v-card-text>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { sendEmail } from '@/utils/api-helper';
import ConfirmationSVG from './ConfirmationSVG';
import { trackHelpEvents } from '../../../common/Analytics/HelpEvents';

export default {
  name: 'Supportbox',
  components: { ConfirmationSVG },
  data: () => ({
    message: '',
    isSent: false,
    isSending: false,
  }),
  props: {
    handleBack: {
      type: Function,
    },
  },
  mounted() {},
  computed: {
    ...mapState('users', ['currentUser']),
  },
  methods: {
    async handleSubmit() {
      this.isSending = true;
      const params = {
        type: 'support-ticket',
        meta: {
          message: this.message,
          email: this.currentUser.user.id,
          name: this.currentUser.user.firstName,
        },
      };
      await sendEmail(params).catch((e) => {
        // Todo: handle errors
        console.log(e);
        this.isSending = false;
      });
      this.isSent = true;
      this.isSending = false;
      trackHelpEvents.helpSupportSubmitClick(this.currentUser);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/scss/app.scss', '@/scss/variables.scss';
.supportbox-wrapper {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 2rem;
  .help-menu-title {
    font-family: 'Lato';
    font-weight: 600;
  }
  .support-card-text {
    font-family: 'Lato';
  }
  .back-btn {
    left: 5px;
    position: absolute;
    top: 5px;
    z-index: 1;
  }
  .supportbox-inner {
    margin-top: 1rem;
    ::v-deep textarea {
      max-height: 400px;
    }
    ::v-deep .v-input__slot {
      padding: 0 0 0 1rem;
    }
    .supportbox-message {
      border-radius: 16px;
      font-family: 'Lato';
    }
  }
  .support-confirm-message {
    align-items: center;
    display: flex;
    font-family: 'Lato';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    margin-top: 1rem;
    text-align: center;
  }
}
</style>
