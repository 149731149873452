<template>
  <div class="request-sent-wrapper">
    <v-btn class="close-btn" icon @click="handleClose">
      <v-icon dark v-text="`mdi-close`" />
    </v-btn>
    <div class="request-sent-img">
      <img src="/assets/img/request-sent-illustration.svg" alt />
    </div>
    <div class="request-sent-title">
      {{ $t('profile.editPaymentDetail.success') }}
    </div>
    <div class="request-sent-body">
      {{ $t('prezentation.requestToShareFingerprint', { name: fullname }) }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'FingerprintShareRequestSent',
  props: {
    fullname: {
      props: String,
      default: '',
    },
  },
  methods: {
    handleClose() {
      this.$modal.hide('FingerprintShareRequestSent');
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.request-sent-wrapper {
  height: 100%;
  text-align: center;
  width: 100%;
  .close-btn {
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 1;
  }

  .request-sent-title {
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 500;
    letter-spacing: normal;
    line-height: normal;
    margin: auto auto 10px auto;
    text-align: center;
    width: 80%;
  }

  .request-sent-body {
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    line-height: normal;
    margin: auto;
    text-align: center;
    width: 80%;
  }

  .request-sent-img {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 66px;
    width: 100%;
  }

  img {
    margin: auto;
    margin-bottom: 36px;
  }
}
</style>
