<template>
  <div class="external-fingerprints">
    <div class="external-fingerprints-title">
      {{ $t('fingerprintvue.externalFingerprints.audienceInvites') }}
    </div>
    <div>
      <v-data-table
        v-if="!loadingExternalFingerPrints"
        :headers="tableHeaders"
        :items="externalFingerPrints"
        :footer-props="{
          itemsPerPageOptions: [10, 25, 50, 100],
        }"
        :search="search"
        class="external-fingerprints-table"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <div class="external-fingerprints-searchbar">
              <Search
                :searchKey="search"
                :placeholder="
                  $t('fingerprintvue.externalFingerprints.searchBy')
                "
                :onSearch="onSearch"
              />
            </div>
            <div class="spacer"></div>
            <v-btn
              class="primary-button add-audience mt-2 py-2"
              rounded
              color="#21a7e0"
              height="48"
              min-width="134"
              style="'font-size': '18px'"
              @click="addNewAudience"
            >
              <NewFeatureBadge
                module-name="addAudience"
                :feature-id="'addAudience_id'"
                :offsetX="'30'"
                :offsetY="'-15'"
              >
                {{ $t('fingerprint.searchPage.addNewAudience') }}
              </NewFeatureBadge>
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:[`item.fullName`]="{ item }">
          <div @click="onAudienceClick(item)">
            <AudienceItem
              :itemClass="'no-padding clickable'"
              :firstName="item.firstName"
              :lastName="item.lastName"
            />
          </div>
        </template>
        <template v-slot:[`item.sharedEntityType`]="{ item }">
          <v-tooltip
            top
            max-width="200"
            content-class="tooltip-content"
            fixed
            open-delay="500"
            close-delay="300"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                v-bind="attrs"
                v-on="on"
                class="copy-fingerprint--auto"
                color="primary"
                @click="handleCopyLink(item)"
              >
                mdi-content-copy
              </v-icon>
            </template>
            <span>{{
              isCopied && copiedFPId === item.id
                ? $t('navbarActions.shareFreeTrial.copied')
                : $t('navbarActions.shareFreeTrial.copyLink')
            }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <clip-loader
        v-else
        :color="`#21a7e0`"
        :width="'60'"
        :height="'60'"
        :size="'50px'"
        class="cliploader"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import AudienceApi from '../../../API/audience-api';
import Search from '../../common/Search.vue';
import AudienceItem from '../../common/AudienceItem.vue';
import AddCollegueDetail from '../NewPrezentation/AddCollegueDetail.vue';
import NewFeatureBadge from '../../common/NewFeatureBadge.vue';
import { capitalizeFirstLetter } from '@/utils/common';
import { AddNewAudienceModalProps } from '../AddNewAudience/AddNewAudienceModalProps';
import {
  fpCopyVsPlacementMap,
  trackProfileEvents,
} from '../../common/Analytics/ProfileEvents';
import {
  TD_PLACEMENT,
  TD_PROFILE_EXTERNAL_AUDIENCES,
} from '../../common/Analytics/MatomoTrackingDataHelper';

export default {
  name: 'ExternalFingerPrints',
  data() {
    return {
      tableHeaders: [
        {
          text: 'Name',
          value: 'fullName',
        },
        { text: 'Fingerprint', value: 'fingerPrint' },
        { text: 'Company', value: 'companyName' },
        { text: 'Actions', value: 'sharedEntityType', sortable: false },
      ],
      loadingExternalFingerPrints: false,
      items: [],
      search: '',
      copiedFPId: '',
      isCopied: false,
    };
  },
  components: {
    AudienceItem,
    ClipLoader,
    Search,
    NewFeatureBadge,
  },
  computed: {
    ...mapState('users', ['currentUser']),
    externalFingerPrints() {
      if (this.items?.length) {
        return this.items.map((item) => {
          const { fingerPrint, firstName, lastName } = item;
          return {
            ...item,
            fingerPrint: fingerPrint
              ? capitalizeFirstLetter(fingerPrint)
              : 'Pending',
            fullName: `${firstName} ${lastName}`,
          };
        });
      }
      return [];
    },
  },
  mounted() {
    this.loadingExternalFingerPrints = true;
    AudienceApi.methods
      .getExternalFingerPrints()
      .then((data) => {
        this.loadingExternalFingerPrints = false;
        if (data) {
          this.items = data;
        }
      })
      .catch((e) => {
        console.log(e);
        this.loadingExternalFingerPrints = false;
      });
  },
  methods: {
    onSearch(val) {
      this.search = val;
    },
    handleCopyLink(item) {
      this.isCopied = true;
      const { uuid, entityType, id } = item;
      navigator.clipboard.writeText(
        `${window.location.origin}/home/fingerprints?type=${entityType}&uuid=${uuid}`,
      );
      this.copiedFPId = id;
      setTimeout(() => {
        this.isCopied = false;
      }, 1000);
      trackProfileEvents.profileFingerprintCopylink(this.currentUser, {
        [TD_PLACEMENT]: fpCopyVsPlacementMap[TD_PROFILE_EXTERNAL_AUDIENCES],
      });
    },
    onAudienceClick(item) {
      this.$router.push({
        name: 'fingerprints',
        query: {
          type: item.entityType,
          uuid: item.uuid,
        },
      });
    },
    addNewAudience() {
      this.$modal.show(
        AddCollegueDetail,
        {
          origin: TD_PROFILE_EXTERNAL_AUDIENCES,
        },
        AddNewAudienceModalProps,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.external-fingerprints {
  padding: 32px;
  padding-top: 10px;
  margin: 2px;

  ::v-deep .audience-item {
    cursor: pointer;
    padding-left: 0px;

    .v-list-item__content {
      .v-list-item__title.text-name {
        color: $zen-blue-default;
      }
    }
  }

  ::v-deep table {
    thead {
      tr {
        th {
          &:first-child {
            padding-left: 0px;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          &:first-child {
            padding-left: 0px;
          }
        }
      }
    }
  }

  ::v-deep header {
    .v-toolbar__content {
      padding-left: 0px;
    }
  }

  .add-audience {
    color: white;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: normal;
  }

  .external-fingerprints-searchbar {
    width: 420px;

    ::v-deep .item-search-input {
      margin-left: 0px;
    }
  }

  .external-fingerprints-table {
    .cliploader {
      display: inline-block;
    }
  }
  .external-fingerprints-title {
    font-size: 20px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 700;
    letter-spacing: normal;
    line-height: 24px;
    margin-bottom: 14px;
    text-align: start;
  }

  .copy-fingerprint--auto {
    margin-left: 10px;
  }

  .v-data-table::v-deep th {
    color: $zen-blue-default !important;
    font-size: 16px !important;
    font-weight: normal;
    min-width: 150px;

    .v-data-table-header__icon {
      margin-top: -2px;
    }
  }
}
</style>
