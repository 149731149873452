<template>
  <div class="fingerprint-available">
    <v-img
      class="already-exists"
      :src="'/assets/img/audience/account-already-exists.svg'"
      max-height="400"
      max-width="401"
      alt
    ></v-img>
    <div class="already-exists-info">
      <div class="already-exists-info-title">
        {{ $t('navbarActions.addColleagueDetail.greatNews') }}
      </div>
      <div class="already-exists-info-detail">
        {{ $t('build.step3.fingerprintOf') }}
        <a
          :class="!audienceData.isHidden ? 'clickable' : 'not-clickable'"
          @click="!audienceData.isHidden ? onFingerprintClick() : ''"
          >{{ audienceFullName }}</a
        >
        {{ $t('build.step3.alreadyAvailable') }}
      </div>
    </div>
    <div class="add-new-audience">
      <div class="add-new-audience-title">
        {{ $t('navbarActions.addColleagueDetail.wouldYouLikeToAddAnother') }}
      </div>
      <v-btn
        class="primary-button"
        rounded
        color="#21a7e0"
        height="48"
        min-width="134"
        @click="handleAddNewAudience"
      >
        {{ $t('fingerprint.searchPage.addNewAudience') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'FingerprintAvailable',
  props: {
    handleAddNewAudience: {
      type: Function,
      default: () => {},
    },
    handleClose: {
      type: Function,
      default: () => {},
    },
    audienceData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState('users', ['currentUser']),
    audienceFullName() {
      if (this.audienceData?.audience) {
        const { firstName, lastName } = this.audienceData.audience;
        return `${firstName} ${lastName}`;
      }
      return '';
    },
    audienceCompanyId() {
      if (this.audienceData?.audience) {
        return this.audienceData.audience?.companyID;
      }
      return '';
    },
    currentUserCompanyId() {
      return this.currentUser?.user?.companyID;
    },
    isAudienceSameCompany() {
      return this.audienceCompanyId === this.currentUserCompanyId;
    },
    fingerPrintUrl() {
      if (this.audienceData?.audience) {
        const { uuid, cognitoID } = this.audienceData.audience;
        const queryParams = `uuid=${uuid || cognitoID}&type=${
          this.audienceData.fpType
        }`;
        return `/home/fingerprints?${queryParams}`;
      }
      return '';
    },
  },
  methods: {
    onFingerprintClick() {
      this.handleClose();
      this.$router.push(
        this.fingerPrintUrl,
        () => {},
        () => {},
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss', '@/scss/app.scss';

.fingerprint-available {
  align-content: 'center';

  .add-new-audience {
    text-align: center;
    .add-new-audience-title {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 19px;
      margin-bottom: 24px;
    }
  }

  .already-exists {
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .already-exists-info {
    color: #000000;
    margin-bottom: 24px;
    text-align: center;
    .already-exists-info-title {
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
      margin-bottom: 16px;
    }
    .already-exists-info-detail {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 19px;
      .not-clickable {
        text-decoration: none;
        cursor: auto;
      }
    }
  }
}
</style>
