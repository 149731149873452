<template>
  <div class="quick-access-toolbar">
    <slot></slot>
    <v-tooltip
      bottom
      max-width="250"
      content-class="tooltip-content"
      fixed
      open-delay="500"
      close-delay="300"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-hover v-slot="{ hover }">
          <v-btn
            depressed
            small
            icon
            :ripple="false"
            :color="
              (hover && '#21A7E0') || (!getComplianceScore ? '#757575' : '#000')
            "
            :style="
              !getComplianceScore
                ? {
                    pointerEvents: 'none',
                    cursor: 'not-allowed',
                  }
                : {}
            "
            :disabled="isActionLoading"
            v-bind="attrs"
            v-on="on"
            @click="handleDownload"
            data-pendo-id="compliance-checker-download"
          >
            <v-icon>mdi-download-outline</v-icon>
          </v-btn>
        </v-hover>
      </template>
      <span>{{ mapUserDownloadPreferenceToTooltip }}</span>
    </v-tooltip>
    <div>
      <v-tooltip
        bottom
        max-width="250"
        content-class="tooltip-content"
        fixed
        open-delay="500"
        close-delay="300"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-hover v-slot="{ hover }">
            <v-btn
              depressed
              small
              icon
              :ripple="false"
              :color="showTutorialMenu || hover ? '#21A7E0' : '#000'"
              :disabled="isActionLoading"
              v-bind="attrs"
              v-on="on"
              @click="onClickTutorialIcon"
              data-pendo-id="compliance-checker-tutorials"
            >
              <v-icon name="tutorials-icon">mdi-television-play</v-icon>
            </v-btn>
          </v-hover>
        </template>
        <span>Tutorials</span>
      </v-tooltip>
      <v-menu
        v-model="showTutorialMenu"
        :attach="true"
        absolute
        offset-y
        :position-x="-425"
        :position-y="40"
        :nudge-width="320"
        :close-on-click="true"
        :close-on-content-click="false"
        content-class="tutorial-menu"
      >
        <tutorial-menu :category="'Template Converter'"></tutorial-menu>
      </v-menu>
    </div>
    <!-- <v-icon color="primary" disabled>mdi-text-box-check-outline</v-icon> -->
    <v-tooltip
      bottom
      max-width="250"
      content-class="tooltip-content"
      fixed
      open-delay="500"
      close-delay="300"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-hover v-slot="{ hover }">
          <v-btn
            depressed
            small
            icon
            :ripple="false"
            :color="
              ((hover ||
                ['complianceScore', 'complySlideDetails'].includes(
                  rightPanelComponent,
                )) &&
                '#21A7E0') ||
              (!getComplianceScore ? '#757575' : '#000')
            "
            :style="
              !getComplianceScore
                ? {
                    pointerEvents: 'none',
                    cursor: 'not-allowed',
                  }
                : {}
            "
            :disabled="isActionLoading"
            v-bind="attrs"
            v-on="on"
            @click="onComplianceSuggestionsClick"
          >
            <v-icon>mdi-text-box-check-outline</v-icon>
          </v-btn>
        </v-hover>
      </template>
      <span>Brand compliance suggestions</span>
    </v-tooltip>
    <v-tooltip
      bottom
      max-width="250"
      content-class="tooltip-content"
      fixed
      open-delay="500"
      close-delay="300"
      v-if="!rightPanelComponent"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-hover v-slot="{ hover }">
          <v-btn
            depressed
            small
            icon
            :ripple="false"
            :style="
              !getComplianceScore
                ? {
                    pointerEvents: 'none',
                    cursor: 'not-allowed',
                  }
                : {}
            "
            :color="
              (hover && '#21A7E0') || (!getComplianceScore ? '#757575' : '#000')
            "
            :disabled="isActionLoading"
            v-bind="attrs"
            v-on="on"
            @click="onClickSmartTools"
          >
            <v-icon>mdi-square-edit-outline</v-icon>
          </v-btn>
        </v-hover>
      </template>
      <span>Smart Tools</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import { downloadComplyDeck } from '../../../utils/api-helper';
// import { downloadFileWithCustomName } from '../../../utils/common';
// import { getFileURL } from '@/utils/calyrex';
import GenerateActionModal from '../GenerateSlides/GenerateActionModal.vue';
import { trackComplyEvents } from '../../common/Analytics/ComplyEvents';
import {
  TD_PREZNAME,
  TD_TEMPLATE,
  TD_DOWNLOAD_ICON,
  TD_PLACEMENT,
  TD_NUMBEROFSLIDES,
} from '../../common/Analytics/MatomoTrackingDataHelper';
import TutorialMenu from '../GenerateSlides/TutorialMenu.vue';
import { callMergeSlidesApi } from '../../../utils/merge-slide-helper';
import { miscConfig } from '../../../runtimeConfig';

export default {
  components: { TutorialMenu },
  props: {
    isAddedToLibrary: {
      type: Boolean,
      default: false,
    },
    addToLibrary: {
      type: Function,
      default: () => ({}),
    },
    handleClosePopAndSaveToLib: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      downloadOperation: {
        loading: false,
      },
      showTutorialMenu: false,
    };
  },
  computed: {
    ...mapState('comply', [
      'rightPanelComponent',
      'isSmartToolsOpen',
      'selectedTemplate',
      'fileDetails',
      'selectedSlides',
      'slidesInfo',
    ]),
    ...mapState('users', ['currentUser']),
    ...mapGetters('comply', [
      'getComplianceScore',
      'getFileDetails',
      'totalSlides',
      'getCurrentSlideSuggestions',
      'isActionLoading',
    ]),

    mapUserDownloadPreferenceToTooltip() {
      const userDownloadPreference =
        this.currentUser?.user?.userDownloadPreference?.toLowerCase();
      if (userDownloadPreference === 'local_download') {
        return this.$t('common.downloadPPTX');
      }
      if (userDownloadPreference === 'save_to_gdrive') {
        return this.$t('common.googleDownload');
      }
      return this.$t('common.download');
    },
  },
  methods: {
    ...mapActions('comply', ['setRightPanelComponent', 'openSmartTools']),
    ...mapActions('commonDownloads', [
      'setDownloadItems',
      'setShowDownloadSnackbar',
      'setDownloadLoaders',
      // 'resetAddedToLibrarySnackbarState',
      // 'setPremiumFtLibConfPopupData',
      // 'setIsAddedToLibraryDownload',
    ]),
    handleDownload() {
      const filename = `${this.getFileDetails.name.replace(
        /.pptx/g,
        '',
      )}-template-converter`;
      const slides = Object.values(this.slidesInfo?.slide_images || {});
      this.$modal.show(
        GenerateActionModal,
        {
          heading:
            this.totalSlides > 1 ? 'Download prezentation' : 'Download slide',
          primaryBtnText: 'Download',
          modalType: 'download',
          primaryBtnAction: this.download,
          type: this.totalSlides > 1 ? 'prezentation' : 'slide',
          operation: this.downloadOperation,
          isAddedToLibrary: this.isAddedToLibrary,
          filename,
          originPage: 'comply',
          downloadItem:
            slides.length > 1
              ? {
                  name: filename,
                  categories: ['title'],
                  prefs: {
                    // ...this.selectedSlide.slideDetails.prefs,
                    source: 'comply',
                  },
                  filename,
                  noofslides: slides.length,
                  downloadLoaderId: slides[0]?.pptx?.s3_path,
                }
              : {
                  name: `${this.getFileDetails?.name.replace(/.pptx/g, '')}`,
                  prefs: {
                    source: 'comply',
                  },
                  filename: slides[0]?.pptx?.s3_path,
                  noofslides: slides.length,
                  downloadLoaderId: slides[0]?.pptx?.s3_path,
                },
          getMergedUrlForSaveToGdrive: this.getMergedUrlForSaveToGdrive,
          closePreviousPopups: this.handleClosePopAndSaveToLib,
          logDownload: () => this.logMetricsOnSaveToGoogleDrive(),
        },
        {
          name: 'GenerateActionModal',
          width: '600px',
          height: 'auto',
          styles: {
            'max-height': '95%',
            'max-width': '800px',
            overflow: 'auto',
            'border-radius': '20px',
          },
          clickToClose: true,
        },
      );
      const otherData = {
        [TD_PREZNAME]: this.fileDetails.name,
        [TD_TEMPLATE]: this.selectedTemplate,
        [TD_PLACEMENT]: TD_DOWNLOAD_ICON,
        [TD_NUMBEROFSLIDES]: this.selectedSlides.length,
      };
      trackComplyEvents.complianceCheckerDownload(this.currentUser, otherData);
    },
    async download(name, isFavorite, addToLibraryClick) {
      this.downloadOperation.loading = true;
      //
      const slides = Object.values(this.slidesInfo?.slide_images || {});
      this.setDownloadLoaders({
        loaderId: slides[0]?.pptx?.s3_path,
        downloading: true,
      });
      this.setShowDownloadSnackbar(true);
      this.setDownloadItems({
        status: 'assembling',
        itemIndex: uuidv4(),
        originType: 'tcDownload',
        logDownloadCallback: async () => {
          if (addToLibraryClick === true && !this.isAddedToLibrary) {
            await this.addToLibrary(name, false, 'download');
          }
        },
        downloadStatusCallback: () => {
          this.downloadOperation.loading = false;
          this.setDownloadLoaders({
            loaderId: slides[0]?.pptx?.s3_path,
            downloading: false,
          });
          this.$modal.hide('GenerateActionModal');
        },
        metaData: {
          ...this.getFileDetails,
          ...this.slidesInfo,
          fileName: name,
          limitsKey: this.totalSlides > 1 ? 'prezentation' : 'slide',
          origin: this.totalSlides > 1 ? 'prezentation' : 'slide',
          noofslides: this.totalSlides,
        },
      });
      //

      // this.downloadOperation.loading = true;
      // if (addToLibraryClick === true && !this.isAddedToLibrary) {
      //   await this.addToLibrary(name, false, 'download');
      // }
      // const mergedUrl = await this.getMergedUrlForDownload();
      // downloadComplyDeck(this.getFileDetails.uploadId, mergedUrl).then(
      //   async () => {
      //     const fileUrl = await getFileURL(
      //       this.currentUser.user.id,
      //       mergedUrl,
      //       this.currentUser.userIp,
      //       'IOCUpload',
      //     );
      //     downloadFileWithCustomName(fileUrl, name);
      //     this.downloadOperation.loading = false;
      //     this.$modal.hide('GenerateActionModal');
      //   },
      // );
    },
    // async handleClosePopAndSaveToLib(name, addToLib) {
    //   if (addToLib === true && !this.isAddedToLibrary) {
    //     await this.addToLibrary(name, false, 'download');
    //     this.isAddedToLibraryDownload = true;
    //     setTimeout(() => {
    //       this.isAddedToLibraryDownload = false;
    //     }, 10000);
    //   }
    //   this.$modal.hide('GenerateActionModal');
    // },
    async logMetricsOnSaveToGoogleDrive() {
      // await downloadComplyDeck(this.getFileDetails.uploadId);
      const s = {
        slides: [],
        outputBucket: process.env.VUE_APP_MVP_ASSETS_BUCKET,
        outputPath: `private/template-converter/comply/${this.getFileDetails.uploadId}/mergeslides/`,
      };

      const slides = Object.values(this.slidesInfo?.slide_images || {});
      slides.forEach((slide) => {
        s.slides.push({
          slideId:
            slide?.pptx?.s3_path?.charAt(0) === '/'
              ? slide?.pptx?.s3_path?.substring(1)
              : slide?.pptx?.s3_path,
          isReplaced: false,
          isUploaded: false,
          isGenerated: false,
          isSynthesis: false,
          isRedesign: false,
          isComply: true,
        });
      });
      let s3url = '';
      await callMergeSlidesApi(s)
        .then((res) => {
          s3url = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
      const url = `private/template-converter/comply/${this.getFileDetails.uploadId}/mergeslides/${s3url}`;
      console.log('url ===>', url);
      await downloadComplyDeck(this.getFileDetails.uploadId, url);
    },
    onClickTutorialIcon() {
      this.showTutorialMenu = !this.showTutorialMenu;
      trackComplyEvents.complianceCheckerTutorialIconClick(this.currentUser);
    },
    onComplianceSuggestionsClick() {
      this.setRightPanelComponent(
        this.getCurrentSlideSuggestions.length
          ? 'complySlideDetails'
          : 'complianceScore',
      );
      const otherData = {
        [TD_PREZNAME]: this.fileDetails.name,
        [TD_TEMPLATE]: this.selectedTemplate,
      };
      trackComplyEvents.complianceCheckerComplianceSuggestionsClick(
        this.currentUser,
        otherData,
      );
    },
    onClickSmartTools() {
      this.openSmartTools(true);
      const otherData = {
        [TD_PREZNAME]: this.fileDetails.name,
        [TD_TEMPLATE]: this.selectedTemplate,
      };
      trackComplyEvents.complianceCheckerSmartToolsClick(
        this.currentUser,
        otherData,
      );
    },
    // async getMergedUrlForDownload() {
    //   const s = {
    //     slides: [],
    //     outputBucket: process.env.VUE_APP_MVP_ASSETS_BUCKET,
    //     outputPath: `private/template-converter/comply/${this.fileDetails.uploadId}/mergeslides/`,
    //   };
    //   const slides = Object.values(this.slidesInfo?.slide_images || {});
    //   slides.forEach((slide) => {
    //     s.slides.push({
    //       slideId:
    //         slide?.pptx?.s3_path?.charAt(0) === '/'
    //           ? slide?.pptx?.s3_path?.substring(1)
    //           : slide?.pptx?.s3_path,
    //       isReplaced: false,
    //       isUploaded: false,
    //       isGenerated: false,
    //       isSynthesis: false,
    //       isRedesign: false,
    //       isComply: true,
    //     });
    //   });
    //   let mergedUrl = '';
    //   await callMergeSlidesApi(s)
    //     .then((res) => {
    //       const mergedslide = res.data;
    //       if (mergedslide.includes('.pptx')) {
    //         mergedUrl = mergedslide;
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    //   return `private/template-converter/comply/${this.fileDetails.uploadId}/mergeslides/${mergedUrl}`;
    // },
    async getMergedUrlForSaveToGdrive() {
      const s = {
        slides: [],
      };
      const slides = Object.values(this.slidesInfo?.slide_images || {});
      slides.forEach((slide) => {
        s.slides.push({
          slideId:
            slide?.pptx?.s3_path?.charAt(0) === '/'
              ? slide?.pptx?.s3_path?.substring(1)
              : slide?.pptx?.s3_path,
          isReplaced: false,
          isUploaded: false,
          isGenerated: false,
          isSynthesis: false,
          isRedesign: false,
          isComply: true,
        });
      });
      let mergedUrl = '';
      await callMergeSlidesApi(s)
        .then((res) => {
          const mergedslide = res.data;
          if (mergedslide.includes('.pptx')) {
            mergedUrl = mergedslide;
          }
        })
        .catch((err) => {
          console.log(err);
        });
      return `${miscConfig.aws_prezentation_distribution}/${mergedUrl}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.quick-access-toolbar {
  gap: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 20px;
}

.blur-effect {
  opacity: 0.4;
  pointer-events: none;
}

.tutorial-menu {
  border-radius: 12px;
  width: 520px;
  background: white;
}
</style>
