var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"access-requests"},[_c('div',{staticClass:"access-requests-title"},[_vm._v(" "+_vm._s(_vm.$t('fingerprintvue.accessRequests.fingerprintAccessRequests'))+" ")]),_c('div',[(!_vm.loadingAccessRequests)?_c('v-data-table',{staticClass:"access-requests-table",attrs:{"headers":_vm.tableHeaders,"items":_vm.accessRequests,"footer-props":{
        itemsPerPageOptions: [10, 25, 50, 100],
      },"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('div',{staticClass:"access-requests-searchbar"},[_c('Search',{attrs:{"searchKey":_vm.searchKey,"placeholder":_vm.$t('fingerprintvue.accessRequests.searchBy'),"onSearch":_vm.onSearch}})],1),_c('div',{staticClass:"spacer"}),_c('div',{staticClass:"access-requests-actions"},[_c('div',{class:!_vm.pendingRequests.length ? 'action-disabled' : '',on:{"click":function($event){return _vm.handleApproveOrDeclineAll(true)}}},[_c('v-icon',{staticClass:"approve-all--auto",attrs:{"small":"","color":"primary","disabled":!_vm.pendingRequests.length}},[_vm._v(" mdi-check-circle-outline ")]),_c('span',[_vm._v(_vm._s(_vm.$t('fingerprintvue.accessRequests.approveAll')))])],1),_c('div',{class:!_vm.pendingRequests.length ? 'action-disabled' : '',on:{"click":function($event){return _vm.handleApproveOrDeclineAll(false)}}},[_c('v-icon',{staticClass:"decline-all--auto",attrs:{"small":"","color":"primary","disabled":!_vm.pendingRequests.length}},[_vm._v(" mdi-close-circle-outline ")]),_c('span',[_vm._v(_vm._s(_vm.$t('fingerprintvue.accessRequests.declineAll')))])],1)])])]},proxy:true},{key:"item.requestorFullName",fn:function(ref){
      var item = ref.item;
return [_c('div',[_c('AudienceItem',{attrs:{"itemClass":'no-padding clickable',"firstName":item.requester_firstname,"lastName":item.requester_lastname,"email":item.requesterID,"isDifferentCompany":item.isRequesterDifferentCompany,"companyName":item.requester_company_name}})],1)]}},{key:"item.audienceFullName",fn:function(ref){
      var item = ref.item;
return [_c('div',[(item.isDifferentCompany)?_c('AudienceItem',{attrs:{"itemClass":'no-padding clickable',"firstName":item.audience_firstname,"lastName":item.audience_lastname,"companyName":item.companyName,"isDifferentCompany":item.isDifferentCompany,"fingerprintType":item.fingerprintType}}):_c('AudienceItem',{attrs:{"itemClass":'no-padding clickable',"firstName":item.audience_firstname,"lastName":item.audience_lastname,"email":item.audience_email,"fingerprintType":item.fingerprintType}})],1)]}},{key:"item.status",fn:function(ref){
      var item = ref.item;
return [(item.actionInProgress)?_c('clip-loader',{staticClass:"cliploader",attrs:{"color":"#21a7e0","width":'16',"height":'16',"size":'16px'}}):(item.status !== 'Pending')?_c('div',[_vm._v(_vm._s(item.status))]):(_vm.isBulkActionInitiated)?_c('clip-loader',{staticClass:"cliploader",attrs:{"color":"#21a7e0","width":'16',"height":'16',"size":'16px'}}):_c('div',[_c('v-tooltip',{attrs:{"top":"","max-width":"200","content-class":"tooltip-content","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"approve-access-request--auto",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.handleApproveOrDecline(item, true)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-check-circle-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('fingerprintvue.accessRequests.approveRequests')))])]),_c('v-tooltip',{attrs:{"top":"","max-width":"200","content-class":"tooltip-content","fixed":"","open-delay":"500","close-delay":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"decline-access-request--auto",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.handleApproveOrDecline(item, false)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-close-circle-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('fingerprintvue.accessRequests.declineRequest')))])])],1)]}}],null,true)}):_c('clip-loader',{staticClass:"cliploader",attrs:{"color":"#21a7e0","width":'60',"height":'60',"size":'50px'}})],1),_c('ConfirmationDialog',{attrs:{"show":_vm.showConfirmation,"on-primary-click":_vm.onDialogCancel,"on-secondary-click":_vm.onDialogConfirm,"on-close":_vm.onDialogCancel,"primary-btn-text":_vm.$t('common.cancel'),"secondary-btn-text":_vm.$t('common.confirm'),"title":_vm.isApproveAll
        ? _vm.$t('fingerprintvue.accessRequests.approveAllTitle')
        : _vm.$t('fingerprintvue.accessRequests.declineAllTitle'),"text":_vm.isApproveAll
        ? _vm.$t('fingerprintvue.accessRequests.confirmApproveAll')
        : _vm.$t('fingerprintvue.accessRequests.confirmDeclineAll')}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }