<template>
  <v-expansion-panel
    @click="handleSelect"
    v-if="
      (categoryTips.length && $route.query.id) ||
      (categoryTips && !$route.query.id)
    "
    :class="`category-wrapper ${!categoryTips.length ? `hidden` : ``}`"
  >
    <v-expansion-panel-header class="category-name">
      <v-icon
        class="category-icon"
        v-if="itemData.isMdi"
        color="black"
        v-text="itemData.icon"
      ></v-icon>
      <component
        v-else
        :is="iconMap[itemData.icon]"
        class="category-icon"
        :iconWidth="24"
        :iconHeight="24"
        :iconColor="'black'"
        :iconStrokeWidth="0.5"
      />
      {{ itemData.name }}</v-expansion-panel-header
    >
    <v-expansion-panel-content>
      <v-expansion-panels
        :mandatory="!!$route.query.id"
        :value="categoryTips.length === 1 && $route.query.id ? 0 : null"
        class="quicktips-wrapper"
      >
        <Quicktip
          :categoryIndex="catIndex"
          :key="quicktip.id"
          v-for="quicktip in categoryTips"
          :itemData="quicktip"
        />
      </v-expansion-panels>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapState } from 'vuex';
import { trackHelpEvents } from '../../../common/Analytics/HelpEvents';
import SlideLibIcon from '../../../common/Icons/SlideLibIcon.vue';
import PrezentationsExIcon from '../../../common/Icons/PrezentationsExIcon.vue';
import FingerprintIcon from '../../../common/Icons/FingerprintIcon.vue';
import {
  MatomoAnalyticsHandler,
  QUICKTIPS,
} from '../../../common/Analytics/MatomoAnalyticsHandler';
import {
  TD_PLACEMENT,
  TD_QUICKTIPSCATEGORY,
} from '../../../common/Analytics/MatomoTrackingDataHelper';
import Quicktip from './Quicktip';

export default {
  name: 'Category',
  components: { Quicktip },
  data() {
    return {
      iconMap: {
        'slide-lib-icon': SlideLibIcon,
        'prez-ex-icon': PrezentationsExIcon,
        'fp-icon': FingerprintIcon,
      },
    };
  },
  props: {
    itemData: {
      type: Object,
    },
    quicktips: {
      type: Array,
    },
    catIndex: {
      type: Number,
    },
  },
  computed: {
    ...mapState('users', ['currentUser']),
    categoryTips() {
      return this.quicktips.filter((e) => e.category === this.itemData.name);
    },
  },
  methods: {
    handleSelect() {
      MatomoAnalyticsHandler.trackHelp(
        {
          type: QUICKTIPS.toLowerCase(),
          actionValue: this.itemData.name,
        },
        this.currentUser,
      );
      trackHelpEvents.helpSectionAssetClick(this.currentUser, {
        [TD_QUICKTIPSCATEGORY]: this.itemData.name,
        [TD_PLACEMENT]: 'Quicktips',
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/scss/app.scss', '@/scss/variables.scss';

.category-wrapper {
  border-radius: 8px;
  margin-bottom: 1rem;
  &::before {
    box-shadow: $elevation-01;
  }
  &:after {
    border: 0;
  }
  &.hidden {
    display: none;
  }
}

.category-name {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  /* identical to box height */

  display: flex;
  letter-spacing: -0.01em;
  padding: 1rem 0.5rem;
  .category-icon {
    flex: none;
    width: 50px;
  }
}

.quicktips-wrapper {
  padding: 0 1rem;
}
</style>
