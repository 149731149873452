import API from '../../../utils/api';

export default {
  name: 'acceleratorsServices',
  methods: {
    async submitAccRequest(body) {
      try {
        const resp = await API.post('/api3/accelerators/add', body);
        return resp;
      } catch (err) {
        return err;
      }
    },
    async getTestimonialData() {
      try {
        const resp = await API.get('/api3/accelerators/getdata');
        return resp;
      } catch (err) {
        return err;
      }
    },
  },
};
