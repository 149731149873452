import { render, staticRenderFns } from "./MagicFill.vue?vue&type=template&id=bf7804fe&scoped=true&"
import script from "./MagicFill.vue?vue&type=script&lang=js&"
export * from "./MagicFill.vue?vue&type=script&lang=js&"
import style0 from "./MagicFill.vue?vue&type=style&index=0&id=bf7804fe&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf7804fe",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VTab,VTabItem,VTabs,VTabsItems,VTextarea})
