<template>
  <div class="tutorial-video">
    <v-icon
      class="close-icon"
      color="#000"
      style="cursor: pointer"
      @click="handleOutSideClickFn"
    >
      mdi-close
    </v-icon>
    <video
      class="video-js vjs-default-skin video-content-section__video-player"
      data-setup="{'fluid': true}"
      ref="video"
      height="100%"
      controls
      @volumechange="updateVolume"
    />
  </div>
</template>

<script>
import 'video.js/dist/video-js.css';
import Hls from 'hls.js';
import { mapState, mapActions } from 'vuex';
import usersApi from '../../../../API/users-api.js';

export default {
  name: 'TutorialVideoDetail',
  props: {
    streamVideoURL: {
      type: String,
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hls: new Hls(),
    };
  },
  computed: {
    ...mapState('users', ['currentUser']),
  },
  methods: {
    ...mapActions('users', ['setCurrentUser']),
    updateVolume() {
      usersApi.methods
        .updateUserProfile({
          volume: this.$refs.video.volume,
          volumeMuted: this.$refs.video.muted,
        })
        .then(() => {
          this.setCurrentUser({
            ...this.currentUser,
            user: {
              ...this.currentUser.user,
              volume: this.$refs.video.volume,
              volumeMuted: this.$refs.video.muted,
            },
          });
        });
    },
    handleOutSideClickFn() {
      this.$modal.hide('TutorialVideoDetail');
      this.stopStreamingVideo();
      this.$emit('modalclosed');
    },
    stopStreamingVideo() {
      this.hls.destroy();
    },
  },
  mounted() {
    const stream = this.streamVideoURL;
    const { video } = this.$refs;
    this.hls.loadSource(stream);
    this.hls.attachMedia(video);
    this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
      video.play();
    });
    this.hls.startLoad();
    this.$refs.video.volume = this.currentUser.user.volume || 1;
    this.$refs.video.muted = this.currentUser.user.volumeMuted;
    video.addEventListener('ended', () => {
      this.handleOutSideClickFn();
    });
  },

  beforeDestroy() {
    this.hls.destroy();
  },
};
</script>

<style lang="scss" scoped>
.tutorial-video {
  position: relative;
  video {
    width: 100%;
    aspect-ratio: 16 / 9;
  }
  .close-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 100;
  }
}
</style>
