<template>
  <v-card class="mx-auto help-wrapper">
    <v-btn class="close-btn" icon @click="handleClose">
      <v-icon dark v-text="`mdi-close`" />
    </v-btn>
    <Support
      v-if="isSendingMessage || openSupport"
      :handle-back="cancelSupportMessage"
    />
    <div v-else class="support-menu-content">
      <v-card-title class="help-menu-title">{{ $t('help.help') }}</v-card-title>
      <v-list>
        <v-list-item-group
          class="help-menu-list"
          v-model="selectedItem"
          color="#21a7e0"
        >
          <v-list-item
            class="help-menu-item"
            v-for="(item, i) in items"
            :key="i"
            @click="handleNavigate(item)"
          >
            <v-list-item-icon>
              <v-icon class="help-menu-item__icon" v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                class="help-menu-item__title"
                v-text="$t(item.text)"
              ></v-list-item-title>
              <v-list-item-subtitle
                class="help-menu-item__subtitle"
                v-text="$t(item.subtitle)"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-card-text class="help-menu-info">
        <div>{{ $t('help.cantFind') }}</div>
      </v-card-text>
      <v-card-actions class="help-menu-actions">
        <v-btn
          @click="initSupportMessage"
          rounded
          filled
          color="#21a7e0"
          class="primary-button"
        >
          <span style="padding-left: 8px; padding-right: 8px">{{
            $t('help.supportRequest')
          }}</span>
        </v-btn>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import Support from './Support';
import EventBus from '../../common/event-bus';
import { trackHelpEvents } from '../../common/Analytics/HelpEvents';
import { TD_PLACEMENT } from '../../common/Analytics/MatomoTrackingDataHelper';

export default {
  request: 'HelpMenu',
  components: {
    Support,
  },
  props: {
    openSupport: {
      type: Boolean,
      default: false,
    },
    origin: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    isSendingMessage: false,
    selectedItem: null,
    items: [
      {
        eventEmitText: 'tutorials_click',
        linkToNavigate: 'help/tutorials',
        analyticsText: 'Tutorials',
        text: 'generate.tutorials',
        icon: 'mdi-human-male-board',
        subtitle: 'help.tutorialsSubtitle',
      },
      {
        eventEmitText: 'faqs_click',
        linkToNavigate: 'help/faqs',
        analyticsText: 'FAQ',
        text: 'overnightPresentations.faq',
        icon: 'mdi-comment-question-outline',
        subtitle: 'help.faqSubtitle',
      },
      {
        eventEmitText: 'walkthroughs_click',
        linkToNavigate: 'help/walkthroughs',
        analyticsText: 'Walkthrough',
        text: 'help.walkthrough',
        icon: 'mdi-walk',
        subtitle: 'help.walkthroughSubtitle',
      },
      {
        eventEmitText: 'quicktips_click',
        linkToNavigate: 'help/quicktips',
        analyticsText: 'Quicktips',
        text: 'help.quickTips',
        icon: 'mdi-alarm',
        subtitle: 'help.quickTipsSubtitle',
      },
    ],
  }),
  computed: {
    ...mapState('users', ['currentUser', 'startLoadingSlidesFlag', 'themes']),
    ...mapGetters('replaceImageStore', ['getIsDirty']),
    ...mapGetters('replaceIconStore', ['getIsDirtyIcon']),
    ...mapGetters('slidesStore', [
      'getIsSlideDetailsDirty',
      'getIsSlideACLDirty',
      'getIsSlideBestPractisesDirty',
    ]),

    isBPLSlideView() {
      return this.$route.path.includes('best-practice-library');
    },
  },
  methods: {
    ...mapActions('slidesStore', [
      'setRouteToNavigate',
      'saveSlidesStateSnapshot',
      'setSlideViewConfirmationModalState',
    ]),
    ...mapActions('search', ['setIsSearchPage']),
    handleNavigate(item) {
      if (this.$route.name === 'search' && this.origin === 'search') {
        this.setIsSearchPage(true);
      } else {
        this.setIsSearchPage(false);
      }
      if (
        (this.getIsDirty === true ||
          this.getIsDirtyIcon === true ||
          this.getIsSlideDetailsDirty === true ||
          this.getIsSlideACLDirty === true ||
          this.getIsSlideBestPractisesDirty === true) &&
        (this.$route.name === 'slide-detail' || this.isBPLSlideView) &&
        this.startLoadingSlidesFlag &&
        this.themes &&
        this.themes.length > 0
      ) {
        this.setSlideViewConfirmationModalState(true);
        this.setRouteToNavigate({
          content: `/${item.linkToNavigate}`,
          type: 'to_help_link',
          action: 'push',
          pushAction: false,
        });
      } else {
        EventBus.$emit(`${item.eventEmitText}`);
        this.handleClose();
        trackHelpEvents.helpSectionClick(this.currentUser, {
          [TD_PLACEMENT]: `${item.analyticsText}`,
        });
        this.saveSlidesStateSnapshot({});
        this.setRouteToNavigate({
          content: '',
          type: '',
          action: '',
          pushAction: false,
        });
        this.$router.push(`/${item.linkToNavigate}`).catch(() => {});
      }

      // clearing global search value when this component is clicked after making a global search
      EventBus.$emit('CLEAR_SEARCH');
    },
    cancelSupportMessage() {
      this.isSendingMessage = false;
      this.openSupport = false;
    },
    initSupportMessage() {
      this.isSendingMessage = true;
      trackHelpEvents.helpSupportClick(this.currentUser);
    },
    handleClose() {
      this.downloading = false;
      this.$modal.hide('HelpMenu');
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import '@/scss/variables.scss', '@/scss/app.scss';
.help-wrapper {
  background: $white-default;
  border-radius: 16px;
  box-shadow: 2px 2px 16px rgba(0, 0, 0, 0.1), -2px -2px 16px rgba(0, 0, 0, 0.1);
  height: 680px;
  max-height: 100%;
  overflow: auto;
  position: absolute;
  overflow: auto;
  width: 468px;

  .close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
  }
  .help-menu-title {
    color: $dark-default;
    font-family: 'Lato';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.02em;
    line-height: 38px;
    padding-left: 20px;
    padding-top: 20px;
  }

  .help-menu-list {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 6px 20px;
  }

  .help-menu-item {
    align-items: flex-start;
    background: $white-default;
    border-radius: 16px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.05), -2px -2px 4px #f2f2f2;
    display: flex;
    height: 104px;
    padding: 6px 16px;
    width: 428px;

    ::v-deep .v-list-item__content {
      overflow: visible;
    }
    .help-menu-item__icon {
      color: $zen-blue-default !important;
      font-size: 20px;
    }
  }

  ::v-deep .theme--light.v-list-item:hover:before {
    border-radius: 16px;
  }

  .help-menu-item__title {
    color: $zen-blue-default !important;
    font-family: 'Lato';
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 16px;
    padding-left: 10px;
  }

  .help-menu-item__subtitle {
    color: $dark-default !important;
    font-family: 'Lato';
    font-size: 16px;
    line-height: 19px;
    margin-left: -22px;
    white-space: pre-line;
  }

  .help-menu-info {
    align-items: center;
    color: $dark-default !important;
    display: flex;
    flex-direction: column;
    font-family: 'Lato';
    font-size: 16px;
    line-height: 19px;
    text-align: center;
  }

  .help-menu-actions {
    display: flex;
    justify-content: center;
  }
}
</style>
