<template>
  <div class="upload-container">
    <v-btn :disabled="uploadInprogress" class="close-btn" icon @click="close">
      <v-icon dark v-text="`mdi-close`" />
    </v-btn>
    <h1 class="manage-heading">
      {{ $t('overnightPresentations.uploadLocalFiles') }}
    </h1>
    <div class="rating-cont">
      <v-data-table
        :headers="listHeaders"
        :items="files"
        class="user-panel"
        hide-default-footer
        :no-results-text="$t('build.step1.storylineFieldNoResult')"
      >
        <template v-slot:[`item.fileName`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                {{ item.fileName }}
              </div>
            </template>
            <span>{{ item.fileName }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <div class="status">
            <div>
              <div class="error" v-if="item.error">
                <v-icon :color="'rgb(211 48 47)'">{{
                  'mdi-close-circle'
                }}</v-icon>
              </div>
              <div class="success" v-else-if="item.processingCompleted">
                <v-icon :color="'#21a7e0'">{{ 'mdi-check-circle' }}</v-icon>
              </div>
              <v-progress-circular
                :value="item.progress"
                :color="'#21a7e0'"
                background-color="#f2f2f2"
                :width="3"
                :size="20"
                v-else
              />
            </div>
            <div>
              {{ item.status }}
            </div>
          </div>
        </template>
      </v-data-table>
    </div>
    <!-- <div
      class="errorTextContainer"
      v-if="
        files &&
        files.length > 0 &&
        files.filter(
          (fl) =>
            fl.error === true || fl.combinedSizeError === true || fl.error,
        ).length > 0 &&
        !uploadInprogress
      "
    >
      {{ getErrorMessage(files) }}
    </div> -->
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
// import { removeSpecialCharacters } from '../../../utils';
import Vue from 'vue';
import { uploadChunks, uploadOPFile } from '../../../../utils/api-helper.js';
import { trackGenerateEvents } from '../../../common/Analytics/GenerateEvents';
import {
  TD_FILE_SIZE,
  TD_NUMBEROFSLIDES,
  TD_PREZNAME,
} from '../../../common/Analytics/MatomoTrackingDataHelper';

export default {
  name: 'UploadMultiContent',
  data() {
    return {
      listHeaders: [
        {
          text: 'File name',
          value: 'fileName',
          sortable: false,
        },
        {
          text: 'Status',
          value: 'status',
          sortable: false,
        },
      ],
      files: [],
      uploadInprogress: false,
    };
  },
  props: {
    filess: {
      type: Array,
      default: () => [],
    },
    updateLocalFiles: {
      type: Function,
      default: () => {},
    },
    size: {
      type: Number,
      default: 0,
    },
    source: {
      default: 'synthesize',
    },
  },
  computed: {
    ...mapState('users', ['currentUser']),
    ...mapGetters('generateSlides', ['getDeckContext']),

    totalFilesSize() {
      let totalSize = 0;
      let allFiles = [];
      if (
        this.getDeckContext &&
        this.getDeckContext.files &&
        this.getDeckContext.files.length > 0 &&
        this.filess.length > 0
      ) {
        allFiles = [...this.getDeckContext.files, ...this.filess];
      } else if (this.filess.length > 0) {
        allFiles = [...this.filess];
      }
      allFiles.forEach((file) => {
        totalSize += Number(file.size);
      });
      return totalSize;
    },
  },
  watch: {
    files: {
      handler(val) {
        if (val && val.length > 0) {
          let filesUploaded = 0;
          val.every((file) => {
            if (file.processingCompleted) {
              filesUploaded++;
            }
            return true;
          });
          if (filesUploaded === this.filess.length) {
            this.updateLocalFiles([...this.files].filter((fl) => !fl.error));
            setTimeout(() => {
              this.close();
            }, 1500);
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    // getErrorMessage(files) {
    //   const genericErrors = [...files].filter((fl) => fl.error === true).length;
    //   const sizeErrors = [...files].filter(
    //     (fl) => fl.combinedSizeError === true,
    //   ).length;
    //   let result = '';
    //   if (genericErrors === 0 && sizeErrors === 0) {
    //     result = '';
    //   } else if (genericErrors > 0 && sizeErrors === 0) {
    //     result = 'Failed to upload file.';
    //   } else if (genericErrors === 0 && sizeErrors > 0) {
    //     result = 'One or more file size exceeds the maximum limit.';
    //   } else if (genericErrors > 0 && sizeErrors > 0) {
    //     result =
    //       'Oops! One or more of your files are above the file size limit or failed to upload.';
    //   }
    //   return result;
    // },
    getOutputPath() {
      const dt = new Date();
      const outputPath = `${this.currentUser.company.id}/generate/${
        dt.getFullYear() +
        String(dt.getMonth() + 1).padStart(2, '0') +
        String(dt.getDate()).padStart(2, '0')
      }/${dt.getHours()}${dt.getMinutes()}${dt.getSeconds()}`;
      return outputPath;
    },
    close() {
      this.$modal.hide('UploadMultiContent');
    },
  },
  async mounted() {
    let totalSize = this.size;
    this.filess.forEach(async (i, index) => {
      if ((totalSize + i.size) / 1024 ** 2 > 200) {
        this.files.push({
          fileName: i.name,
          type: i.type,
          fileID: uuidv4(),
          errorType: 'size',
          status: 'File exceeds size limitations',
          processingCompleted: true,
          size: i.size,
          numOfPages: i.numOfPages,
          error: true,
        });
        const otherData = {
          [TD_NUMBEROFSLIDES]: i.numOfPages,
          [TD_PREZNAME]: i.name,
          [TD_FILE_SIZE]: `${i.size ? i.size / 1024 / 1024 : 0} MB`,
        };
        trackGenerateEvents.generateAddContextUploadFailed(
          this.currentUser,
          otherData,
        );
      } else if (
        !['ppt', 'pptx', 'doc', 'docx', 'txt', 'pdf'].includes(
          i.name.split('.').pop(),
        )
      ) {
        this.files.push({
          fileName: i.name,
          type: i.type,
          fileID: uuidv4(),
          errorType: 'type',
          status: 'Unsupported file type',
          processingCompleted: true,
          size: i.size,
          error: true,
          numOfPages: i.numOfPages,
        });
        const otherData = {
          [TD_NUMBEROFSLIDES]: i.numOfPages,
          [TD_PREZNAME]: i.name,
          [TD_FILE_SIZE]: `${i.size ? i.size / 1024 / 1024 : 0} MB`,
        };
        trackGenerateEvents.generateAddContextUploadFailed(
          this.currentUser,
          otherData,
        );
      } else {
        totalSize += i.size;
        const file = {
          progress: 0,
          file: i,
          name: i.name,
          fileName: i.name,
          type: i.type,
          fileID: uuidv4(),
          requestIdentifier: uuidv4(),
          status: 'File is being processed',
          processingCompleted: false,
          size: i.size,
          error: false,
          numOfPages: i.numOfPages,
        };
        this.files.push(file);
        try {
          await uploadChunks(file, 'generate');
          await uploadOPFile(file.requestIdentifier, file.name, 'generate')
            .then((res) => {
              // file = {
              //   ...file,
              //   ...res.data,
              //   processingCompleted: true,
              //   size: file.file.size,
              // };
              Vue.set(this.files, index, {
                fileName: file.name,
                type: file.type,
                fileID: uuidv4(),
                progress: res.data.progress,
                s3Bucket: res.data.s3Bucket,
                s3Prefix: res.data.s3Prefix,
                sizeError: false,
                status: 'File uploaded successfully',
                processingCompleted: true,
                numOfPages: res.data.numOfPages,
                size: file.size,
                error: false,
              });
            })
            .catch((err) => {
              Vue.set(this.files, index, {
                fileName: file.name,
                type: file.type,
                fileID: uuidv4(),
                errorType: 'upload',
                status: 'File upload failed',
                processingCompleted: true,
                size: file.size,
                error: true,
                numOfPages: 0,
              });
              let numOfSlides = 0;
              if (err?.response?.data?.data) {
                numOfSlides = err.response.data.data.numOfPages;
              }
              const otherData = {
                [TD_NUMBEROFSLIDES]: numOfSlides,
                [TD_PREZNAME]: file.name,
                [TD_FILE_SIZE]: `${file.size ? file.size / 1024 / 1024 : 0} MB`,
              };
              trackGenerateEvents.generateAddContextUploadFailed(
                this.currentUser,
                otherData,
              );
              console.log(err);
            });
        } catch (err) {
          Vue.set(this.files, index, {
            fileName: file.name,
            type: file.type,
            fileID: uuidv4(),
            errorType: 'upload',
            status: 'File upload failed',
            processingCompleted: true,
            size: file.size,
            error: true,
            numOfPages: 0,
          });
          const otherData = {
            [TD_NUMBEROFSLIDES]: 0,
            [TD_PREZNAME]: file.name,
            [TD_FILE_SIZE]: `${file.size ? file.size / 1024 / 1024 : 0} MB`,
          };
          trackGenerateEvents.generateAddContextUploadFailed(
            this.currentUser,
            otherData,
          );
          console.log(err);
        }
      }
    });
  },
};
</script>

<style lang="scss" scoped>
// @import '@/scss/variables.scss', '@/scss/app.scss';

.upload-container {
  padding: 30px 20px;
  // background-color: white;
  .manage-heading {
    letter-spacing: normal;
    font-family: 'Lato';
    font-size: 24px;
  }
  .close-btn {
    position: absolute;
    top: 0px;
    right: 0px;
    margin: 10px 5px;
  }
  .rating-cont {
    margin-top: 30px;
    width: 100%;
    ::v-deep .v-data-table {
      width: 100%;
      .v-data-table__wrapper {
        width: 100%;
        table {
          width: 100%;
          thead {
            width: 100%;
            display: block;
            tr {
              display: table;
              width: 100%;
              th:first-child {
                text-align: left !important;
                min-width: 280px;
                max-width: 280px;
                padding: 0px 16px 0px 0px;
                span {
                  font-size: 18px;
                  letter-spacing: normal;
                  color: black;
                }
              }
              th {
                width: 40%;
                text-align: left;
                span {
                  font-size: 18px;
                  letter-spacing: normal;
                  color: black;
                }
              }
            }
          }
          tbody {
            width: 100%;
            display: block;
            max-height: 240px;
            overflow: auto;
            tr {
              width: 100%;
              display: table;
              .text-start:first-child {
                min-width: 280px;
                max-width: 280px;
                border: none !important;
                padding: 0px 16px 0px 0px;
                div {
                  width: 100%;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
              .text-start {
                width: 40%;
                border: none !important;
              }
              .text-start:last-child {
                text-align: left;
                border: none !important;
              }
            }
          }
        }
      }
    }
    .success {
      color: green;
    }
    .error {
      color: red;
    }
  }
  .status {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}
</style>
