<template>
  <div class="node-count-container">
    <div class="heading">
      Smart Charts
      <div class="sub">{{ $t('build.step3.poweredByZenSence') }}</div>
    </div>
    <div class="sub-heading">Select the chart type you would like to use</div>
    <div class="node-content">
      <div v-for="chart in charts" class="slide" :key="chart.key">
        <div
          @click="handleChartChange(chart)"
          class="chart-card"
          :class="{
            current: chart.current,
          }"
        >
          <v-img
            contain
            :aspect-ratio="16 / 9"
            :src="
              `/assets/icons/generate/smartCharts/${chart.key}.svg` ||
              '/assets/img/slides/placeholder-slide.svg'
            "
            loading="lazy"
            class="node-image"
          />
        </div>
        <div>{{ chart.title }}</div>
      </div>
    </div>
  </div>
</template>
<script>
// import { mapState } from 'vuex';

const chartTypeMap = {
  line_graph: 'multi_line',
  no_graph: 'list',
};

export default {
  name: 'SmartCharts',
  components: {},
  props: {
    slideType: {
      type: String,
      default: 'normal',
    },
  },
  methods: {
    handleChartChange(chart) {
      if (chart.current) {
        return;
      }
      //   if (this.nodeCount === slide.asset.prefs.node) {
      //     return;
      //   }
      this.$emit('change', {
        type: 'smart_chart_change',
        slide_type: chartTypeMap[chart.key] || chart.key,
      });
    },
  },
  data() {
    return {
      chartTypes: [
        {
          key: 'column_graph',
          title: 'Column graph',
        },
        {
          key: 'bar_graph',
          title: 'Bar graph',
        },
        {
          key: 'multi_line',
          title: 'Line graph',
        },
        {
          key: 'stacked_column',
          title: 'Stacked column graph',
        },
        {
          key: 'stacked_bar',
          title: 'Stacked bar graph',
        },
        {
          key: 'clustered_column',
          title: 'Clustered column graph',
        },
        {
          key: 'clustered_bar',
          title: 'Clustered bar graph',
        },
        {
          key: 'pie_graph',
          title: 'Pie chart',
        },
        {
          key: 'donut_graph',
          title: 'Donut chart',
        },
        {
          key: 'no_graph',
          title: 'No chart',
        },
      ],
    };
  },
  mounted() {},
  computed: {
    chartSlideType() {
      if (this.chartTypes.map((i) => i.key).includes(this.slideType)) {
        return this.slideType;
      }
      return 'no_graph';
    },
    charts() {
      if (
        ['thank_you', 'discussion_topic', 'cover_slide', 'title'].includes(
          this.slideType,
        )
      ) {
        return [
          {
            key: 'no_graph',
            title: 'No graph',
            current: true,
          },
        ];
      }
      return this.chartTypes.map((chart) => ({
        ...chart,
        current: chart.key === this.chartSlideType,
      }));
    },
  },
};
</script>
<style lang="scss" scoped>
.node-count-container {
  padding: 20px 20px;
  padding-right: 5px;
  height: 100%;

  .heading {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 24px;
    .sub {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.02em;
      color: #697077;
      margin-top: 4px;
    }
  }

  .sub-heading {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: #000000;
    margin-bottom: 16px;
  }
  .node-content {
    height: calc(100% - 100px);
    overflow: auto;
    padding: 5px;
    .slide {
      width: calc(50% - 20px);
      height: 160px;
      display: inline-block;
      margin-right: 20px;
      margin-bottom: 20px;

      .chart-card {
        aspect-ratio: 16 / 9;
        border-radius: 6px;
        overflow: hidden;
        margin-bottom: 15px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);

        &.current {
          border: 1.5px solid #21a7e0;
          cursor: default;
          pointer-events: none;
        }

        &:hover {
          box-shadow: -4px 4px 25px 1px rgba(0, 0, 0, 0.3);
        }
        .node-image {
          width: 92px;
          height: 70px;
        }
      }
    }
  }
}
</style>
