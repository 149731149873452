<template>
  <v-expansion-panel @click="handleSelect(itemData)" class="quicktip-wrapper">
    <div :ref="`quicktip-anchor-${itemData.unique_id}`" />
    <v-expansion-panel-header class="quicktip-name">{{
      itemData.question
    }}</v-expansion-panel-header>
    <v-expansion-panel-content class="quicktip-steps-wrapper">
      <v-timeline align-top dense>
        <template v-for="(step, i) in steps">
          <Step
            :in-viewport-once="true"
            :in-viewport-root-margin="'-50% 0%'"
            :id="i + 1"
            :step="step"
            :key="i"
            v-if="step.text || step.image"
          />
        </template>
      </v-timeline>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapState } from 'vuex';
import { mdiCheck } from '@mdi/js';
import Step from './Step';
import EventBus from '@/components/common/event-bus';
import {
  MatomoAnalyticsHandler,
  _S_,
  QUICKTIPS,
} from '../../../common/Analytics/MatomoAnalyticsHandler';
import { trackHelpEvents } from '../../../common/Analytics/HelpEvents';
import {
  TD_QUICKTIPSCATEGORY,
  TD_QUICKTIPSTITLE,
} from '../../../common/Analytics/MatomoTrackingDataHelper';

export default {
  name: 'Quicktip',
  data: () => ({
    steps: [],
  }),
  components: {
    Step,
  },
  computed: {
    ...mapState('users', ['currentUser']),
    icon() {
      return mdiCheck;
    },
  },
  props: {
    itemData: {
      type: Object,
    },
    categoryIndex: {
      type: Number,
    },
  },
  mounted() {
    this.parseSteps();
  },
  methods: {
    handleSelect(item) {
      EventBus.$emit('quicktip_select', { id: this.categoryIndex });
      const { category, question } = item;
      MatomoAnalyticsHandler.trackHelp(
        {
          type: QUICKTIPS.toLowerCase(),
          actionValue: `${category}${_S_}${question}`,
        },
        this.currentUser,
      );
      trackHelpEvents.helpQuickTipsClick(this.currentUser, {
        [TD_QUICKTIPSCATEGORY]: category,
        [TD_QUICKTIPSTITLE]: question,
      });
    },
    parseSteps() {
      const steps = [];
      Object.keys(this.itemData).forEach((key) => {
        const step = {};
        if (key.startsWith('step_') && !key.includes('image')) {
          step.image = this.itemData[`${key}_image`] || '';
          step.text = this.itemData[key] || '';
          steps.push(step);
        }
      });
      this.steps = steps;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/scss/app.scss', '@/scss/variables.scss';

.quicktip-wrapper {
  border-bottom: 1px solid #b6b6b660;
  border-radius: 0;
  &::before {
    box-shadow: none;
  }
}

.quicktip-wrapper {
  &:last-child {
    border: 0;
  }
  &::before,
  &::after {
    border: none;
  }
}

.v-timeline--dense:not(.v-timeline--reverse):before {
  left: 1rem;
}

.v-timeline::before {
  height: calc(100% - 45px);
  top: 45px;
}

::v-deep .v-timeline-item__body {
  left: -2.5rem;
}
</style>
