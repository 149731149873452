<template>
  <div class="loader-section">
    <div class="loader-section__main-block">
      <div class="loader-section__main-block__svg-section">
        <div class="progress-spinner">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
        <video autoplay loop muted playsinline ref="video">
          <source
            :src="'/assets/videos/loaders/TCUpload.mp4'"
            type="video/mp4"
          />
        </video>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ProgressAnimationLoader',
  props: {
    message: {
      type: String,
      default: 'Processing your file...',
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped lang="scss">
.loader-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  &__main-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    &__svg-section {
      max-width: 910px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      .progress-spinner {
        margin-top: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 24px;

        .processing-text {
          color: #000;
          text-align: center;

          /* Heading 4 */
          font-family: Lato;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%; /* 26px */
        }
      }
    }
  }
}
</style>
