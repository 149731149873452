<template>
  <div class="tutorial-menu-container">
    <div class="heading">{{ $t('generate.tutorials') }}</div>
    <div class="body">
      <Loading :style="'position: relative !important'" v-if="loading" />
      <div
        v-for="tutorial in tutList"
        :key="tutorial.id"
        @click="handleVideoClick(tutorial)"
        class="item-container"
      >
        <!-- <div class="thumbnail-container">
          <img :src="tutorial.imageUrl" class="thumbnail" />
        </div> -->
        <v-hover>
          <template v-slot:default="{ hover }">
            <div class="item">
              <div class="thumbnail-container">
                <v-img
                  contain
                  loading="lazy"
                  :src="tutorial.imageUrl"
                  alt
                  class="thumbnail"
                />
                <v-fade-transition>
                  <v-overlay v-if="hover" absolute color="#616161">
                    <v-icon class="play-btn" color="white">
                      mdi-play-circle-outline</v-icon
                    >
                  </v-overlay>
                </v-fade-transition>
              </div>
              <div class="content">
                <div class="heading">{{ tutorial.title }}</div>
                <div class="desc">{{ tutorial.description }}</div>
              </div>
            </div>
          </template>
        </v-hover>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import helpApi from '../../../API/help-api';
import { getFileURL } from '@/utils/calyrex';
import TutorialVideoDetail from '../Help/Tutorials/TutorialVideoDetail.vue';
import {
  TD_TUTORIALTITLE,
  TD_AUD,
  TD_PREZNAME,
  TD_PREZTYPE,
  TD_TEMPLATE,
} from '../../common/Analytics/MatomoTrackingDataHelper';
import Loading from '../../common/Loading.vue';
import { trackCommonEvents } from '../../common/Analytics/CommonEvents';

export default {
  name: 'TutorialMenu',
  components: { Loading },
  data() {
    return {
      tutList: [],
      loading: true,
    };
  },
  props: {
    category: {
      type: String,
    },
  },
  watch: {},
  computed: {
    ...mapState('users', ['currentUser']),
    ...mapState('prezentationDetails', ['prezentationData']),
  },
  async mounted() {
    this.tutList = await this.getTutorialDetails();
    this.loading = false;
  },
  methods: {
    handleVideoClick(item) {
      this.$modal.show(
        TutorialVideoDetail,
        {
          streamVideoURL: item.videoUrl,
          modalclosed: this.handleVideoClosedFn,
        },
        {
          name: 'TutorialVideoDetail',
          width: '1280px',
          height: '720px',
        },
      );
      let otherData = {};
      if (
        this.category === 'Best Practice Library' ||
        this.category === 'Prezentation Library' ||
        this.category === 'Story Builder'
      ) {
        otherData = {
          [TD_AUD]: this.prezentationData.audienceID,
          [TD_PREZNAME]: this.prezentationData.name,
          [TD_PREZTYPE]: this.prezentationData.type,
          [TD_TEMPLATE]: this.prezentationData.theme,
          [TD_TUTORIALTITLE]: item.title,
        };
      } else {
        otherData = {
          [TD_TUTORIALTITLE]: item.title,
        };
      }
      trackCommonEvents.trackTutorialClicked(
        this.currentUser,
        otherData,
        this.category,
      );
    },
    async getTutorialDetails() {
      return await helpApi.methods
        .getHelpData('tutorial', this.currentUser.user.language)
        .then(
          async (resp) => {
            const decoratedTutorials = resp.data
              .filter((i) => i.category[0] === this.category)
              .map(async (tutorial) => this.decorateTutorial(tutorial));
            return await Promise.all(decoratedTutorials);
          },
          (error) => {
            console.log(error.response);
          },
        );
    },
    async decorateTutorial(tutorial) {
      tutorial.imageUrl = await this.getSignedFileURL(tutorial.image_link);
      tutorial.videoUrl = await this.getSignedFileURL(tutorial.video_link);
      return tutorial;
    },
    async getSignedFileURL(uri) {
      return await getFileURL(
        this.currentUser.username,
        uri,
        this.currentUser.userIp,
      );
    },
  },
};
</script>
<style scoped lang="scss">
.tutorial-menu-container {
  width: 520px;
  border-radius: 15px;
  padding: 20px;

  .heading {
    font-family: 'Lato';
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 10px;
  }

  .body {
    height: calc(100% - 34px);
    min-height: 150px;
    max-height: 450px;
    overflow: auto;
    padding-right: 10px;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;

    .item-container {
      border-bottom: 0.5px solid #c1c7cd;
      padding: 5px 0;

      &:last-child {
        border-bottom: none;
      }
    }

    .item {
      height: 140px;
      display: flex;
      gap: 24px;
      padding: 12px 5px;
      cursor: pointer;

      &:hover {
        background-color: #e1f5fb;
      }

      .thumbnail-container {
        width: 150px;
        background-color: white;
        border-radius: 4px;
        box-shadow: -0.6px -0.6px 2.4px 0px rgba(0, 0, 0, 0.1),
          0.6px 0.6px 2.4px 0px rgba(0, 0, 0, 0.1);
        position: relative;

        .play-btn {
          font-size: 54px;
          left: 50%;
          position: absolute;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
        .thumbnail {
          width: 100%;
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: calc(100% - 174px);

        .heading {
          color: #000;
          font-family: 'Lato';
          font-size: 16px;
          font-weight: 600;
          line-height: 19px;
          letter-spacing: 0em;
          text-align: left;
        }

        .desc {
          color: #4d5358;
          font-family: 'Lato';
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.14px;
        }
      }
    }
  }
}
</style>
