<template>
  <div class="finger-prints-wrapper">
    <div class="second-wrapper">
      <div class="back-btn-cntr" v-if="currentRoute !== '/profile'">
        <v-btn class="btn-back" @click="goToSearchLandingPage">
          <v-icon class="icon" color="primary">mdi-chevron-left</v-icon>
          <span>{{
            $t(
              previousRoute === 'search'
                ? 'fingerprint.backToSearch'
                : previousRoute === 'fingerprint'
                ? 'fingerprint.backToFingerprints'
                : 'common.back',
            )
          }}</span>
        </v-btn>
      </div>
      <div class="shared-wrapper" data-pendo-id="fingerprint-share">
        <template v-if="showShareLinkedIn">
          <ShareFPOnLinkedIn
            :fpData="fingerprintData"
            :answers="answers"
            :audience="audience"
            :isAudience="isAudience"
            :iconSize="'30px'"
            :origin="origin"
          />
          <div
            class="btn"
            @click="viewOrHideInsights()"
            v-if="audience.fingerprintType === 'Group_Insight'"
          >
            <v-icon>{{
              hideInsights ? 'mdi-chevron-down' : 'mdi-chevron-up'
            }}</v-icon>
            <span>{{
              hideInsights
                ? $t('fingerprintvue.viewInsights')
                : $t('fingerprintvue.hideInsights')
            }}</span>
          </div>
          <template>
            <v-menu
              bottom
              left
              v-if="!hideActions"
              :close-on-content-click="closeEllipsisMenu"
              :key="menuKey"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn dark icon v-bind="attrs" v-on="on">
                  <v-icon size="24" color="primary">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list nav>
                <v-list-item-group>
                  <v-list-item
                    v-for="(item, i) in moreItems"
                    :key="i"
                    @click="fingerprintAction(item.title)"
                  >
                    <v-list-item-icon>
                      <v-icon v-text="item.icon" color="#000"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-if="item.title === 'Copy Link'">
                        <span
                          >{{
                            isCopied
                              ? $t('navbarActions.shareFreeTrial.copied')
                              : $t('navbarActions.shareFreeTrial.copyLink')
                          }}
                        </span>
                      </v-list-item-title>
                      <v-list-item-title
                        v-else-if="item.title === 'Share' && shareable"
                        v-text="$t(item.display_title)"
                        color="#000"
                      >
                      </v-list-item-title>
                      <v-list-item-title
                        v-else
                        v-text="$t(item.display_title)"
                        color="#000"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
            <v-menu
              bottom
              left
              v-if="hideActions"
              :close-on-content-click="closeEllipsisMenu"
              :key="menuKey2"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn dark icon v-bind="attrs" v-on="on">
                  <v-icon color="primary">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list nav>
                <v-list-item-group>
                  <v-list-item
                    v-for="(item, i) in menuItems"
                    :key="i"
                    @click="fingerprintAction(item.title)"
                  >
                    <v-list-item-icon>
                      <v-icon v-text="item.icon" color="#000"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-if="item.title === 'Copy Link'">
                        <span
                          >{{
                            isCopied
                              ? $t('navbarActions.shareFreeTrial.copied')
                              : $t('navbarActions.shareFreeTrial.copyLink')
                          }}
                        </span>
                      </v-list-item-title>
                      <v-list-item-title
                        v-else-if="item.title === 'Share' && shareable"
                        v-text="$t(item.display_title)"
                        color="#000"
                      >
                      </v-list-item-title>
                      <v-list-item-title
                        v-else
                        v-text="$t(item.display_title)"
                        color="#000"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </template>
        </template>
        <template v-else>
          <div v-if="shareable" class="btn btn-share" @click="handleClickShare">
            <v-icon>mdi-account-multiple-check-outline</v-icon>
            <NewFeatureBadge
              module-name="sharetrialform"
              :feature-id="'sharetrialformfpb_id'"
              :offsetX="'62'"
              :offsetY="'44'"
              :size="'medium'"
            >
              <span>{{ $t('common.share') }}</span>
            </NewFeatureBadge>
          </div>
          <div class="btn" @click="handleCopyLink" style="margin-left: 13px">
            <v-icon>mdi-link-variant</v-icon>
            <span>{{
              isCopied
                ? $t('navbarActions.shareFreeTrial.copied')
                : $t('navbarActions.shareFreeTrial.copyLink')
            }}</span>
          </div>
          <div
            class="btn"
            @click="viewOrHideInsights()"
            v-if="audience.fingerprintType === 'Group_Insight'"
          >
            <v-icon>{{
              hideInsights ? 'mdi-chevron-down' : 'mdi-chevron-up'
            }}</v-icon>
            <span>{{
              hideInsights
                ? $t('fingerprintvue.viewInsights')
                : $t('fingerprintvue.hideInsights')
            }}</span>
          </div>
          <v-menu bottom left v-if="!hideActions">
            <template v-slot:activator="{ on, attrs }">
              <v-btn dark icon v-bind="attrs" v-on="on">
                <v-icon color="primary">mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list nav>
              <v-list-item-group>
                <v-list-item
                  v-for="(item, i) in moreItems"
                  :key="i"
                  @click="fingerprintAction(item.title)"
                >
                  <v-list-item-icon>
                    <v-icon v-text="item.icon" color="#000"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="$t(item.display_title)"
                      color="#000"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </template>
      </div>
      <div class="info-wrapper">
        <div class="logos">
          <img
            v-if="audience.profileImage"
            :src="profileImage"
            class="logo-profile"
          />
          <span v-else class="logo-initial">
            {{ audienceInitials }}
          </span>
          <img
            v-if="locked"
            src="/assets/img/lock-icon@100px.svg"
            class="logo-fingerprint"
            alt
          />
          <img
            v-else
            :src="fingerprintData.logo"
            alt="Fingerprint-Logo"
            class="logo-fingerprint"
          />
        </div>
        <div class="info-content" v-if="!locked">
          <div class="info-fullname">
            {{ audienceFullname }}
            <img
              v-if="fingerprintType !== 'default'"
              src="/assets/img/prezent_avatar.svg"
              alt
              width="30px"
            />
            <span class="highlight">{{ fingerprint }}</span>
          </div>
          <div>
            <span
              v-if="currentUserJobTitle && fingerprintType !== 'standard'"
              class="info-company job-title"
            >
              {{ currentUserJobTitle }}
            </span>
            <span
              v-if="
                audienceCompanyName ||
                (currentUserCompany && fingerprintType !== 'standard')
              "
              class="info-company company-name"
              :class="currentUserJobTitle ? 'highlight-fingerprint' : ''"
            >
              {{ audienceCompanyName || currentUserCompany }}
            </span>
          </div>
          <div>
            <span
              v-if="currentUserIndustry && fingerprintType !== 'standard'"
              class="info-company info-industry"
            >
              {{ currentUserIndustry }}
            </span>
            <span
              v-if="currentUserJobFunction && fingerprintType !== 'standard'"
              class="info-company job-function"
              :class="currentUserIndustry ? 'highlight-fingerprint' : ''"
            >
              {{ currentUserJobFunction }}
            </span>
            <span
              v-if="currentUserGroup && fingerprintType !== 'standard'"
              class="info-company info-group"
              :class="
                currentUserJobFunction || currentUserIndustry
                  ? 'highlight-fingerprint'
                  : ''
              "
            >
              {{ currentUserGroup }}
            </span>
          </div>
          <span
            v-if="currentUserRegion && fingerprintType !== 'standard'"
            class="info-company info-region"
          >
            {{ currentUserRegion }}
          </span>
          <p class="info-email" v-if="fingerprintType !== 'standard'">
            <span v-if="audienceEmail" class="info-company aud-email">
              {{ audienceEmail }}
            </span>
            <span
              v-if="isGroup"
              class="info-company group-user"
              :class="isGroup && audienceEmail ? 'highlight-fingerprint' : ''"
            >
              {{ $t('prezentationShareStatus.group') }}
            </span>
            <span
              v-if="creator"
              class="info-company info-creator"
              :class="
                (isGroup || audienceEmail) && creator
                  ? 'highlight-fingerprint'
                  : ''
              "
            >
              {{ $t('build.step1.createdBy') }} {{ creator }}
            </span>
            <span
              v-if="isPrivateAudience"
              class="info-company info-shared"
              :class="isPrivateAudience ? 'highlight-fingerprint' : ''"
            >
              <span v-if="isAlreadyShared">{{ $t('build.step1.shared') }}</span>
              <span v-else>{{ $t('build.step1.private') }}</span>
            </span>
          </p>
          <p class="info-email" v-else>
            <span>{{ $t('fingerprintvue.createdByPrezent') }}</span>
          </p>
        </div>
        <div class="info-content" v-else>
          <div class="info-fullname">
            {{ audienceFullname }}
          </div>
          <p
            v-if="
              audienceCompanyName ||
              (currentUserCompany && fingerprintType !== 'standard')
            "
            class="info-company company-name"
          >
            {{ audienceCompanyName || currentUserCompany }}
          </p>
        </div>
      </div>
      <div class="result-wrapper">
        <div class="result-title">
          <div>
            <span>
              {{ userFirstName }}
            </span>
            {{ $t('fingerprintvue.fingerprintIs') }}
            {{ locked ? '' : $t('fingerprintvue.the') }}
            <span class="highlight" v-if="!locked">{{ fingerprint }}</span>
            <img
              v-else
              alt
              class="locked-inline"
              src="/assets/img/lock-icon@24px.svg"
            />
          </div>
        </div>
        <div class="result-description">
          {{
            locked
              ? $t('fingerprintvue.lockedPresentationPreferences', {
                  userFirstName,
                })
              : fingerprintData.description
          }}
        </div>
        <div class="result-items">
          <div
            class="item"
            :class="{ pref: !hideInsights }"
            v-for="(item, index) in fingerprintData.preferences"
            :key="index"
          >
            <template v-if="!hideInsights">
              <v-progress-circular
                :rotate="-90"
                :size="110"
                :width="16"
                :value="groupInsightPercentage[item.title]"
                :color="'#78C9EB'"
              >
                <img class="pref-img" :src="item.logo" />
              </v-progress-circular>
              <div class="pref-content">
                <div>
                  {{ groupInsightPercentage[item.title] }}%
                  {{ item.preference }}
                </div>
                <div>{{ item.oneLine }}</div>
              </div>
            </template>
            <template v-else>
              <div class="title">
                {{ $t(item.title) }}
              </div>
              <img :src="item.logo" alt width="86px" height="60px" />
              <template v-if="!locked">
                <div class="description">
                  {{ item.preference }}
                </div>
                <div class="sub-description">
                  {{ item.oneLine }}
                </div>
              </template>
            </template>
          </div>
        </div>
        <div class="personal-preferences">
          <div class="header" :class="{ locked: locked }">
            <span class="title">
              {{ personalPrefTitle }}
            </span>
            <img
              src="/assets/img/lock-icon@24px.svg"
              class="locked-inline"
              alt
              v-if="locked"
            />
            <span
              v-if="
                prefEditable &&
                !isPrefEditMode &&
                fingerprintType !== 'standard'
              "
              class="btn btn-edit"
              @click="handleEditPref"
            >
              <v-icon>mdi-pencil-outline</v-icon>
              {{ $t('fingerprint.editPreferences.editPersonalPreferences') }}
            </span>
          </div>
          <div v-if="isPrefEditMode">
            <div class="selections">
              <div
                :class="`selection edit-mode ${getClassName(selection)}`"
                v-for="(selection, index) in allPrefs"
                :key="index"
                @click="handleQuestion(selection)"
              >
                <span>
                  <v-img
                    class="mx-2"
                    :src="
                      selection.image
                        ? selection.image
                        : 'assets/img/preferences-added_by_user.svg'
                    "
                    height="30px"
                    width="30px"
                  />
                </span>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <span class="selection-title" v-on="on">
                      {{ selection.title }}
                    </span>
                  </template>
                  <span>{{ selection.title }}</span>
                </v-tooltip>
              </div>
            </div>
            <div class="add-preference-wrapper">
              <div id="addCustomPref" class="write-own-btn">
                <div class="write-own-header">
                  <span class="pe-2">
                    <v-img
                      v-if="!inputFocused"
                      src="assets/img/preferences-Other.svg"
                      height="30px"
                      width="35px"
                    />
                    <v-img
                      v-else
                      src="assets/img/preferences-other-filled.svg"
                      height="30px"
                      width="35px"
                    />
                  </span>
                  <span>{{ $t('fingerprint.addYourOwn') }}</span>
                </div>
                <div class="new-pref-text">
                  <v-textarea
                    v-model="ownPreference"
                    :placeholder="$t('fingerprint.haveAClearAgenda')"
                    full-width
                    solo
                    two-line
                    @focus="setBackgroundColour"
                    @blur="removeBackgroundColour"
                  />
                </div>
                <div
                  v-if="ownPreference.length && isValidString"
                  class="add-pref"
                >
                  <button @click.stop="onEnter()">
                    <span>
                      <v-icon class="icon" v-text="`mdi-plus`" />
                    </span>
                    <span>{{ $t('fingerprint.add') }}</span>
                  </button>
                </div>
              </div>

              <div class="edit-preference-cta">
                <v-btn
                  class="cancelBtn"
                  min-width="120"
                  @click="handleEditPref"
                >
                  {{ $t('fingerprintvue.cancelPreferences') }}
                </v-btn>

                <v-btn
                  class="primary-button primaryBtn"
                  rounded
                  color="#21a7e0"
                  min-width="120"
                  @click="handleSavePreference"
                  :disabled="personalPrefs.length < 1"
                >
                  {{ $t('fingerprintvue.savePreferences') }}
                </v-btn>
              </div>
            </div>
          </div>
          <div v-else-if="locked" class="description">
            {{ userFirstName }} {{ $t('fingerprintvue.preferencesLocked') }}
          </div>
          <div class="selections" v-else>
            <div
              class="selection"
              v-for="(item, index) in personalPrefs"
              :key="index"
            >
              <span>
                <v-img
                  class="mx-2"
                  :src="getPrefImage(item)"
                  height="30px"
                  width="30px"
                />
              </span>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <span class="selection-title" v-on="on">
                    {{ item }}
                  </span>
                </template>
                <span>{{ item }}</span>
              </v-tooltip>
            </div>
          </div>
        </div>
        <div v-if="!hideInsights">
          <div class="insights-dos-dont-heading">
            {{ $t('fingerprintvue.prezentationsForGroupAudience') }}
          </div>
          <div class="insights-dos-dont-container">
            <div v-for="ind in 3" :key="ind" class="dos-donts-row">
              <div class="do-dos-item">
                <img :src="'/assets/img/dos-icon.svg'" />
                <div>{{ fingerprintData.dos[ind - 1] }}</div>
              </div>
              <div class="do-dos-item">
                <img :src="'/assets/img/dont-icon.svg'" />
                <div>{{ fingerprintData.donts[ind - 1] }}</div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="mt-6 other-fps"
          v-if="!hideActions && otherUsersCount !== 0"
        >
          <div class="title">
            {{ $t('common.other') }}
            {{
              `${
                fingerprintData.title.charAt(0).toUpperCase() +
                fingerprintData.title.slice(1)
              }`
            }}s
          </div>
          <OtherColleagues
            class="mt-2"
            :scope="'company'"
            :fingerprint="fingerprintData.title"
            large
            :origin="origin"
            @userCount="getOtherUsersCount"
          ></OtherColleagues>
        </div>
        <div v-if="fingerprintType !== 'default'" class="mt-6">
          <div class="title">
            <img src="/assets/img/prezent_avatar.svg" alt width="30px" />
            <span v-html="$t('fingerprintvue.generatedBy')"></span>
          </div>
          <div class="result-description">
            {{ $t('fingerprintvue.generatedUsingZensense') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getFileURL } from '@/utils/calyrex';
import { fpTypes, fpSelections } from '@/mock/fingerprint';
import { getFullName, getInitials } from '@/utils/common';
import { isGeneratedEmail } from '../../../utils/common';
import {
  FP_PROFILE,
  FP_PROFILE_OTHER,
  FP_RESULT_HOME,
  MatomoAnalyticsHandler,
} from '../../common/Analytics/MatomoAnalyticsHandler';
import ShareFingerPrintVue from './ShareFingerPrint.vue';
import NewFeatureBadge from '../../common/NewFeatureBadge.vue';
import OtherColleagues from '../Zentrends/OtherColleagues.vue';
import {
  shareFingerPrintVsPlacement,
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  TD_FLOW,
  TD_ADD_AUDIENCE_REQUESTED,
  TD_PLACEMENT,
  TD_PROFILE_MY_FINGERPRINT,
  TD_SHARE_FINGERPRINT_MFP,
  TD_FP_SEARCH,
  TD_MY_FP_IN_PROFILE,
  TD_FP_CREATED_FP,
} from '../../common/Analytics/MatomoTrackingDataHelper';
import { trackGeneralEvents } from '../../common/Analytics/GeneralEvents';
import {
  fpCopyVsPlacementMap,
  trackProfileEvents,
} from '../../common/Analytics/ProfileEvents';
import { getGroupInsights } from '../../../utils/api-helper';
import { trackFingerprintEvents } from '../../common/Analytics/FingerprintEvents';
import ShareFPOnLinkedIn from '../../common/ShareFPOnLinkedIn.vue';

export default {
  name: 'FingerPrint',
  components: {
    NewFeatureBadge,
    OtherColleagues,
    ShareFPOnLinkedIn,
  },
  props: {
    showfpTest: {
      type: Boolean,
      default: false,
    },
    isAudience: {
      type: Boolean,
      default: false,
    },
    fingerprint: {
      type: String,
      default: '',
    },
    audience: {
      type: Object,
      default: () => {},
    },
    answers: {
      type: Object,
      default: () => {},
    },
    creator: {
      type: String,
      default: '',
    },
    isDifferentCompany: {
      type: Boolean,
      default: false,
    },
    fingOrigin: {
      type: String,
      default: '',
    },
    isGroup: {
      type: Boolean,
      default: false,
    },
    isPrivateAudience: {
      type: Boolean,
      default: false,
    },
    shareable: {
      type: Boolean,
      default: false,
    },
    isAlreadyShared: {
      type: Boolean,
      default: false,
    },
    prefEditable: {
      type: Boolean,
      default: false,
    },
    personalPrefs: {
      type: Array,
      default: () => [],
    },
    allPrefs: {
      type: Array,
      default: () => [],
    },
    currentCompany: {
      type: String,
      default: '',
    },
    currentJobTitle: {
      type: String,
      default: '',
    },
    currentJobFunction: {
      type: String,
      default: '',
    },
    currentIndustry: {
      type: String,
      default: '',
    },
    currentGroup: {
      type: String,
      default: '',
    },
    isCurrentUser: {
      type: Boolean,
      default: false,
    },
    locked: {
      type: Boolean,
      default: false,
    },
    hideActions: {
      type: Boolean,
      default: false,
    },
    getCommonAudienceTrackingData: {
      type: Function,
      default: () => {},
    },
    getMatomoFingerprintData: {
      type: Function,
      default: () => {},
    },
    origin: {
      type: String,
      default: '',
    },
  },
  emits: ['actionClicked'],
  data() {
    return {
      previousRoute: '',
      profileImage: null,
      isCopied: false,
      showShareLinkedIn: false,
      closeEllipsisMenu: true,
      menuKey: 0,
      menuKey2: 0,
      isPrefEditMode: false,
      internalSelections: [],
      ownPreference: '',
      inputFocused: false,
      hideInsights: true,
      groupInsights: null,
      otherUsersCount: -1,
      moreItems: [
        {
          title: 'Build',
          display_title: 'fingerprintsResultPage.ellipsis_option_story_builder',
          icon: 'mdi-plus-circle-outline',
        },
        {
          title: 'Slides',
          display_title: 'fingerprintsResultPage.ellipsis_option_slide_library',
          icon: 'mdi-folder-table-outline',
        },
        {
          title: 'Compare',
          display_title: 'fingerprint.compareFingerprint.compare',
          icon: 'mdi-file-compare',
        },
        {
          title: 'Learn more',
          display_title: 'fingerprint.learnMore',
          icon: 'mdi-television-play',
        },
      ],
      menuItems: [],
      currentRoute: '',
    };
  },
  beforeMount() {
    this.currentRoute = this.$router.history.current.path;
  },
  computed: {
    ...mapState('users', ['currentUser']),
    isValidString() {
      return this.ownPreference.trim() !== '';
    },
    isCurrentUserFP() {
      return (
        this.audience?.id === this.currentUser?.user?.id ||
        (this.isAudience && this.origin === 'add_new_aud')
      );
    },
    isExistingPref() {
      return this.allPrefs.some(
        (pref) =>
          pref?.title === this.ownPreference?.replace(/\s+/g, ' ').trim(),
      );
    },
    isAlreadySelectedPref() {
      return this.personalPrefs.some(
        (pref) => pref === this.ownPreference?.replace(/\s+/g, ' ').trim(),
      );
    },
    fingerprintData() {
      const fpData = fpTypes.find(
        (fprint) => fprint.title === this.fingerprint?.toLowerCase(),
      );
      if (!fpData) {
        return fpTypes?.[0];
      }
      return fpData;
    },
    groupInsightPercentage() {
      if (this.groupInsights) {
        return {
          'fingerprint.visualPreference':
            this.groupInsights.fingerPrint.fpInsights.visualPreference
              .percentage,
          'fingerprint.storyChoice':
            this.groupInsights.fingerPrint.fpInsights.storyPreference
              .percentage,
          'fingerprint.dataStyle':
            this.groupInsights.fingerPrint.fpInsights.dataPreference.percentage,
        };
      }
      return {
        'fingerprint.visualPreference': 0,
        'fingerprint.storyChoice': 0,
        'fingerprint.dataStyle': 0,
      };
    },
    fingerprintType() {
      if (this.audience && this.audience.fingerprintType) {
        return this.audience.fingerprintType.toLowerCase();
      }
      return 'default';
    },
    audienceCompanyName() {
      if (this.audience) {
        // if (
        //   this.audience.fingerprintType === 'Group_Insight' &&
        //   this.groupInsights
        // ) {
        //   let sameCompany = true;
        //   const { companyName } = this.groupInsights.members[0];
        //   if (!companyName) {
        //     return 'Multiple companies';
        //   }
        //   this.groupInsights.members.forEach((member) => {
        //     if (member.companyName !== companyName) {
        //       sameCompany = false;
        //     }
        //   });
        //   return sameCompany ? companyName : 'Multiple companies';
        // }
        const { companyDisplayName, companyName, displayName } = this.audience;
        return companyDisplayName || companyName || displayName;
      }
      return '';
    },

    audienceEmail() {
      if (this.isDifferentCompany) {
        return null;
      }

      if (isGeneratedEmail(this.audience)) {
        return null;
      }

      return this.audience.id;
    },
    audienceInitials() {
      const groupName = this.audience?.firstName
        ?.replace(/\s+/g, ' ')
        .split(' ');
      if (this.isGroup && groupName.length === 1) {
        return this.audience.firstName[0].toUpperCase();
      }
      if (this.isGroup && groupName.length >= 2) {
        return `${groupName[0][0].toUpperCase()}${groupName[1][0].toUpperCase()}`;
      }
      return getInitials(this.audience.firstName, this.audience.lastName);
    },
    audienceFullname() {
      if (this.isGroup) {
        return getFullName(this.audience.firstName);
      }
      if (this.audience.fullName) return this.audience.fullName;
      return getFullName(this.audience.firstName, this.audience.lastName);
    },
    personalPrefTitle() {
      if (this.isPrefEditMode)
        return this.$t('fingerprint.editPreferences.editPersonalPreferences');
      return this.$t('fingerprint.editPreferences.personalPreferences');
    },
    currentUserJobTitle() {
      if (this.audience) {
        return this.audience?.jobTitle;
      }
      return '';
    },
    currentUserJobFunction() {
      if (this.audience) {
        return this.audience?.jobFunction;
      }
      return '';
    },
    currentUserIndustry() {
      if (this.audience) {
        return this.audience?.industry;
      }
      return '';
    },
    currentUserGroup() {
      if (this.audience) {
        const group = this.audience?.subDivision;
        if (group && (group !== null || group.length)) {
          return group.join(', ');
        }
        return '';
      }
      return '';
    },
    currentUserRegion() {
      if (this.audience) {
        const group = this.audience?.region;
        if (group && (group !== null || group.length)) {
          return group.join(', ');
        }
        return '';
      }
      return '';
    },
    audienceType() {
      if (this.fingerprintType === 'standard') {
        return `${this.audience.userID}-standard`;
      }
      if (this.isPrivateAudience) {
        return `${this.audience.id}-private_audience`;
      }
      if (this.audience.uuid) {
        return `${this.audience.id}-audience`;
      }
      return `${this.audience.id}-user`;
    },
    currentUserCompany() {
      if (!this.isPrivateAudience) {
        return this.currentCompany;
      }
      return '';
    },
    userFirstName() {
      if (this.isCurrentUser) {
        return this.$t('generate.fingerprintLabelText');
      }
      if (this.audience.firstName) {
        return `${
          this.audience.firstName.charAt(0).toUpperCase() +
          this.audience.firstName.substring(1)
        }'s`;
      }
      return `${
        this.audience.fullName.charAt(0).toUpperCase() +
        this.audience.fullName.substring(1)
      }'s`;
    },
    userCognitoID() {
      return this.currentUser?.user?.cognitoID;
    },
  },
  mounted() {
    this.previousRoute = this.$router.app.previousRoute.name;
    if (this.isCurrentUserFP) {
      this.showShareLinkedIn = true;
      if (this.hideActions) {
        this.menuItems = [
          {
            title: 'Share',
            display_title: 'common.share',
            icon: 'mdi-account-multiple-check-outline',
          },
          {
            title: 'Copy Link',
            display_title: 'navbarActions.shareFreeTrial.copyLink',
            icon: 'mdi-link-variant',
          },
        ];
      } else {
        this.moreItems = [
          {
            title: 'Share',
            display_title: 'common.share',
            icon: 'mdi-account-multiple-check-outline',
          },
          {
            title: 'Copy Link',
            display_title: 'navbarActions.shareFreeTrial.copyLink',
            icon: 'mdi-link-variant',
          },
          ...this.moreItems,
        ];
      }
    }

    if (this.audience && this.audience.profileImage) {
      getFileURL(
        null,
        `public/${this.audience.profileImage}`,
        null,
        'storage',
      ).then((s3Obj) => {
        this.profileImage = s3Obj;
      });
    } else {
      this.profileImage = null;
    }
    if (this.audience && this.audience.fingerprintType === 'Group_Insight') {
      getGroupInsights(this.audience.uuid).then((resp) => {
        this.groupInsights = resp.data;
      });
    }
    this.internalSelections = [...this.personalPrefs];
    const { initialIndex } = this.$route.query;
    try {
      if (this.isCurrentUser) {
        MatomoAnalyticsHandler.trackFingerprint({
          user: this.currentUser,
          actionName: FP_PROFILE,
        });
      } else if (
        this.$route.name === 'welcome' &&
        initialIndex &&
        this.audience.uuid
      ) {
        MatomoAnalyticsHandler.trackFingerprint({
          user: this.currentUser,
          actionName: FP_PROFILE_OTHER,
          audience: {
            id: this.audience.id,
          },
        });
      } else {
        MatomoAnalyticsHandler.trackFingerprint({
          user: this.currentUser,
          actionName: FP_RESULT_HOME,
          audience: {
            id: this.audience.id,
          },
        });
      }
    } catch (e) {
      console.log(e);
    }
  },
  methods: {
    goToSearchLandingPage() {
      this.$router.back();
    },
    fingerprintAction(action) {
      switch (action) {
        case 'Share':
          this.handleClickShare();
          break;
        case 'Copy Link':
          this.handleCopyLink();
          break;
        case 'Build':
          this.$router.push({
            path: `/home/build?audience=${this.audienceType}`,
          });
          trackFingerprintEvents.fingerprintsScreenElipsisBuild(
            this.currentUser,
            this.origin,
          );
          break;
        case 'Slides':
          this.$router.push({
            path: `/home/slides?audience=${this.audienceType}`,
          });
          trackFingerprintEvents.fingerprintsScreenElipsisSlides(
            this.currentUser,
            this.origin,
          );
          break;
        case 'Compare':
          if (`${this.currentUser.user.id}-user` !== this.audienceType) {
            this.$router.push({
              path: `/home/fingerprint?compare=${this.audienceType}`,
            });
          } else {
            this.$router.push({
              path: '/home/fingerprint',
            });
          }
          trackFingerprintEvents.fingerprintsScreenElipsisCompare(
            this.currentUser,
            this.origin,
          );
          break;
        case 'Learn more':
          this.$router.push({
            path: `/home/fingerprint?learn=${this.fingerprintData.title}`,
          });
          trackFingerprintEvents.fingerprintsScreenElipsisLearnMore(
            this.currentUser,
            this.origin,
          );
          break;
        default:
          break;
      }
      this.$emit('actionClicked', action);
    },
    getOtherUsersCount(len) {
      this.otherUsersCount = len;
    },
    setBackgroundColour() {
      this.inputFocused = true;
      document.getElementById('addCustomPref').style.background = '#f5fcff';
    },
    handleBackSearch() {
      this.$emit('handleFingClicked', false, {});
    },
    removeBackgroundColour() {
      this.inputFocused = false;
      document.getElementById('addCustomPref').style.background = '#FFFFFF';
    },
    getClassName(selection) {
      return this.personalPrefs.includes(selection.title) ? 'highlight' : '';
    },
    getPrefImage(item) {
      const selectedPref = fpSelections.find((prefs) => prefs.title === item);
      if (selectedPref?.image) return `/${selectedPref.image}`;
      return '/assets/img/preferences-added_by_user.svg';
    },
    onEnter() {
      if (this.ownPreference.length) {
        this.ownPreference = this.ownPreference?.replace(/\s+/g, ' ').trim();
        if (!this.isExistingPref)
          this.allPrefs.push({
            value: this.ownPreference.toLowerCase(),
            title: this.ownPreference,
          });
        const item = {};
        item.key = this.ownPreference;
        item.title = this.ownPreference;
        if (!this.isAlreadySelectedPref) {
          this.$emit('handleQuestion', item);
          this.personalPrefs.push(this.ownPreference);
        }
        this.ownPreference = '';
      }
    },
    handleCopyLink() {
      this.closeEllipsisMenu = false;
      // event handler of `Copy link` btn
      let queryParams = '';
      const { username, type, initialIndex, uuid, email } = this.$route.query;

      switch (this.$route.name) {
        case 'profile':
          queryParams = `uuid=${this.userCognitoID}&type=user`;
          trackProfileEvents.profileFingerprintCopylink(this.currentUser, {
            [TD_PLACEMENT]: fpCopyVsPlacementMap[TD_PROFILE_MY_FINGERPRINT],
          });
          break;
        case 'fingerprint-test':
          queryParams = `uuid=${this.audience.uuid}&type=audience`;
          trackGeneralEvents.addnewaudienceShowfingerprintCopyLink(
            this.currentUser,
            {
              ...this.getCommonAudienceTrackingData(),
              ...this.getMatomoFingerprintData(),
              [TD_COMMON_COLUMN_NAME]: TD_FLOW,
              [TD_COMMON_COLUMN_VALUE]: TD_ADD_AUDIENCE_REQUESTED,
            },
          );
          break;
        default:
          if (
            this.$route.name === 'welcome' &&
            (initialIndex || email) &&
            !this.audience.uuid
          ) {
            // re-take fingerprint from profile page or welcome page for new trial users
            queryParams = `username=${this.audience.id}&type=user`;
            const otherData = {
              ...this.getCommonAudienceTrackingData(),
              ...this.getMatomoFingerprintData(),
            };
            trackGeneralEvents.addnewaudienceShowfingerprintCopyLink(
              this.currentUser,
              otherData,
            );
          } else if (type && username) {
            // user or audience in search page
            queryParams = `username=${encodeURIComponent(
              username,
            )}&type=${type}`;
          } else if (type && uuid) {
            queryParams = `uuid=${uuid}&type=${type}`;
          } else if (this.audience.uuid) {
            // private audience
            if (this.audience.type) {
              queryParams = `uuid=${this.audience.uuid}&type=${this.audience.type}`;
            } else {
              queryParams = `uuid=${this.audience.uuid}`;
            }
          } else if (this.audience.shared && this.audience.type !== 'user') {
            queryParams = `uuid=${this.audience.uuid}&type=${this.audience.type}`;
          } else if (this.audience.type === 'user') {
            queryParams = `username=${encodeURIComponent(
              this.audience.id,
            )}&type=${this.audience.type}`;
          } else if (this.audience.fingerprintType === 'standard') {
            queryParams = `username=${encodeURIComponent(
              this.audience.userID,
            )}&type=standard`;
          } else {
            queryParams = `username=${encodeURIComponent(
              this.audience.id || this.audience.userID,
            )}&type=${type}`;
          }

          // if (this.showfpTest && this.showfpTest === true) {
          //   const otherData = {
          //     ...this.getCommonAudienceTrackingData(),
          //     ...this.getMatomoFingerprintData(),
          //   };
          //   trackGeneralEvents.addnewaudienceShowfingerprintCopyLink(
          //     this.currentUser,
          //     otherData,
          //   );
          // }
          break;
      }

      navigator.clipboard.writeText(
        `${window.location.origin}/home/fingerprints?${queryParams}`,
      );
      this.isCopied = true;
      setTimeout(() => {
        this.isCopied = false;
        this.closeEllipsisMenu = true;
        if (this.hideActions) {
          this.menuKey2++;
        } else {
          this.menuKey++;
        }
      }, 1000);
    },
    handleClickShare() {
      if (!this.isPrivateAudience) {
        this.$modal.show(
          ShareFingerPrintVue,
          {
            type: 'modal',
            isAudience: this.audience.id !== this.currentUser?.user?.id,
            origin: TD_SHARE_FINGERPRINT_MFP,
          },
          {
            name: 'ShareFingerPrint',
            width: 700,
            height: 'auto',
            minHeight: 300,
            styles: { borderRadius: '20px' },
          },
        );
        trackGeneralEvents.shareFingerprintIconclick(this.currentUser, {
          [TD_PLACEMENT]: shareFingerPrintVsPlacement[TD_SHARE_FINGERPRINT_MFP],
        });
      } else {
        // event handler of `Share` btn
        this.$emit('handleClickShare');
      }
    },
    handleEditPref() {
      // event handler of `Edit personal preferences` btn
      if (this.isPrefEditMode) {
        const set1 = new Set(this.personalPrefs);
        const set2 = new Set(this.audience.personalPreference);
        const uniquiePref1 = this.personalPrefs.filter((ele) => !set2.has(ele));
        const uniquiePref2 = this.audience.personalPreference.filter(
          (ele) => !set1.has(ele),
        );
        const uniquePref = [...uniquiePref1, ...uniquiePref2];

        const tempPrefs = this.allPrefs.filter((ele) =>
          uniquePref.includes(ele.title),
        );

        tempPrefs.forEach((item) => {
          this.handleQuestion(item);
        });
      }

      this.isPrefEditMode = !this.isPrefEditMode;
    },
    handleQuestion(item) {
      // event handler of `every question` in prefEditMode
      this.$emit('handleQuestion', item);
    },
    handleCreateOwn() {
      // event handler of `write your own` btn in prefEditMode
      this.$emit('handleCreateOwn');
    },
    handleSavePreference() {
      // event handler of `Save Preferences` btn in prefEditMode
      this.isPrefEditMode = false;
      if (
        this.origin === 'my_fp' ||
        this.origin === 'other_fp' ||
        this.$route.query?.log_search
      ) {
        let placement = '';
        if (this.$route.query?.log_search) {
          placement = TD_FP_SEARCH;
        } else if (this.origin === 'my_fp') {
          placement = TD_MY_FP_IN_PROFILE;
        } else if (this.origin === 'other_fp') {
          placement = TD_FP_CREATED_FP;
        }
        const otherData = {
          [TD_PLACEMENT]: placement,
        };
        trackFingerprintEvents.fingerprintsMyFingerprintEditPersonalPreferences(
          this.currentUser,
          otherData,
        );
      }

      this.$emit('handleSavePreference');
    },
    viewOrHideInsights() {
      if (this.hideInsights) {
        trackFingerprintEvents.fingerprintsScreenViewInsights(
          this.currentUser,
          this.origin,
        );
      }
      this.hideInsights = !this.hideInsights;
    },
  },
};
</script>

<style lang="scss" scoped>
sup {
  top: -0.75em;
  font-size: 50%;
}
.finger-prints-wrapper {
  .back-button {
    color: #21a7e0;
    font: 12px;
    cursor: pointer;
    padding-bottom: 50px;
    font-family: 'Lato';
    width: 100%;
    v-icon {
      padding-bottom: 5px;
    }
  }
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 80px 20px 32px 20px;
  width: 100%;

  .locked-inline {
    margin-left: 5px;
  }

  .second-wrapper {
    border-radius: 20px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1), -1px -1px 4px rgba(0, 0, 0, 0.1);
    max-width: 1344px;
    position: relative;
    padding: 0 40px 30px 40px;
    width: 100%;

    .highlight {
      color: #21a7e0;
    }

    .btn {
      align-items: center;
      cursor: pointer;
      display: flex;
      margin-left: 20px;

      ::v-deep .v-icon {
        color: #21a7e0;
        font-size: 16px;
        margin-right: 6px;
      }
    }

    .back-btn-cntr {
      display: flex;
      position: absolute;
      left: 22px;
      top: 20px;

      .btn-back {
        border: none;
        text-transform: none;
        background-color: #ffffff !important;
        box-shadow: none;
        letter-spacing: normal;
        color: #21a7e0;
        padding: 0;
        margin: 0px 0px 0px -8px;
      }
    }

    .shared-wrapper {
      color: #21a7e0;
      display: flex;
      position: absolute;
      right: 22px;
      top: 20px;

      .btn-share {
        ::v-deep .v-icon {
          font-size: 23px;
        }
      }
    }

    .info-wrapper {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: -48px;

      .logos {
        position: relative;
        display: flex;
        margin-bottom: 12px;

        .logo-profile {
          border-radius: 50%;
          width: 96px;
          height: 96px;
        }

        .logo-initial {
          align-items: center;
          background: #21a7e0;
          border-radius: 100%;
          color: white;
          display: flex;
          font-size: 45px;
          height: 96px;
          justify-content: center;
          line-height: 53px;
          width: 96px;
          padding-right: 10px;
        }
        .logo-fingerprint {
          background: white;
          border: 2px solid white;
          border-radius: 50%;
          height: 96px;
          margin-left: -20px;
        }
      }
      .info-content {
        align-items: center;
        color: #7b7b7b;
        display: flex;
        flex-direction: column;
        font-size: 16px;
        justify-content: center;

        .highlight::before {
          content: ' ';
          border-radius: 50%;
          background-color: gray;
          width: 6px;
          display: inline-block;
          height: 6px;
          margin: 0px 8px 0 5px;
          position: relative;
          top: -5px;
        }
        .highlight-fingerprint::before {
          content: ' ';
          border-radius: 50%;
          background-color: gray;
          width: 3px;
          display: inline-block;
          height: 3px;
          margin: 0px 8px -2px 8px;
          position: relative;
          top: -5px;
        }
        .info-top {
          display: flex;
          font-size: 24px;
          font-style: normal;
          font-weight: bold;
          flex-direction: row;
          line-height: 29px;
          margin-bottom: 4px;
          text-transform: capitalize;
        }
        .info-fingerprint {
          color: #21a7e0;
        }
        .info-fullname {
          color: #000;
          font-family: 'Lato';
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 29px;
          text-align: center;
          display: flex;
          align-items: center;

          img {
            margin-left: 5px;
            position: relative;
            top: 3px;
          }
          .highlight {
            text-transform: capitalize;
          }
        }
        .show-dot {
          margin: 0px 5px;
        }

        .info-email {
          align-items: center;
          display: flex;

          .display-flex {
            display: flex;
          }
          .dot {
            font-size: 8px;
            margin: 0 8px;
            padding-top: 8px;
          }
          .private-audience {
            display: flex;
          }
        }
      }
    }

    .result-wrapper {
      text-align: start;
      margin-top: 20px;

      .result-title {
        align-items: center;
        display: inline-block;
        font-size: 20px;
        font-weight: bold;
        font-style: normal;
        line-height: 24px;
        text-align: left;
        .highlight {
          text-transform: capitalize;
        }
      }

      .result-description {
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 19px;
        margin-top: 8px;
      }

      .result-items {
        align-items: center;
        display: flex;
        justify-content: space-between;
        margin-top: 32px;

        .item {
          align-items: center;
          border-radius: 12px;
          box-shadow: 1px 1px 8px 4px rgba(0, 0, 0, 0.02),
            -1px -1px 8px 4px rgba(0, 0, 0, 0.02);
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          text-align: center;
          padding: 20px;
          width: 30%;

          &.pref {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 15px;

            ::v-deep .v-progress-circular__underlay {
              stroke: #68788c;
              stroke-opacity: 0.6;
              stroke-width: 4px;
              z-index: 1;
            }

            .pref-img {
              width: 50px;
              height: 36px;
            }

            .pref-content {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              width: calc(100% - 110px);

              & > div:nth-child(1) {
                margin-top: 10px;
                font-family: 'Lato';
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 24px;
                text-align: start;
                color: #7b7b7b;
                margin-bottom: 4px;
              }

              & > div:nth-child(2) {
                font-family: 'Lato';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                text-align: start;
                color: #7b7b7b;
              }
            }
          }

          .title {
            font-family: 'Lato' !important;
            font-size: 20px;
            font-weight: bold;
            line-height: 24px;
            margin-bottom: 8px;
          }
          .description {
            color: #21a7e0;
            font-size: 20px;
            font-weight: normal;
            margin-top: 8px;
          }
          .sub-description {
            font-size: 16px;
            font-weight: normal;
            margin-top: 8px;
          }
        }
      }

      .personal-preferences {
        font-size: 20px;
        line-height: 24px;
        margin-top: 32px;

        .header {
          align-items: center;
          display: flex;
          justify-content: space-between;

          &.locked {
            justify-content: start;
          }

          .title {
            font-family: 'Lato' !important;
            font-size: 24px;
            font-weight: bold;
          }

          .btn-edit {
            color: #21a7e0;
            font-size: 14px;
            transform: translateX(3px);
          }
        }

        .title {
          font-family: 'Lato' !important;
          font-size: 24px;
          font-weight: bold;
        }
        .description {
          font-size: 16px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 19px;
          margin-top: 8px;
        }

        .selections {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          margin-top: 8px;

          .selection {
            align-items: center;
            display: flex;
            border-radius: 12px;
            box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1),
              -1px -1px 4px rgba(0, 0, 0, 0.1);
            justify-content: flex-start;
            color: rgba(33, 33, 33, 1);
            font-size: 18px;
            font-weight: 700;
            line-height: 24px;
            line-height: 19px;
            margin: 0 10px 10px 0;
            padding: 8px 15px;
            height: 70px;
            width: 31%;
            &.edit-mode {
              cursor: pointer;
            }
            &.highlight {
              border: 3px solid #29b6f6;
            }
            .selection-title {
              padding-right: 10px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }

        .add-preference-wrapper {
          align-items: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-top: 20px;

          .write-own-btn {
            background: #ffffff;
            border-radius: 12px;
            box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1),
              -1px -1px 4px rgba(0, 0, 0, 0.1);
            width: 40%;
            padding: 8px 25px;
            font-size: 20px;
            font-weight: 700;
            margin: auto;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            margin-bottom: 60px;
            .write-own-header {
              display: flex;
              margin-top: 10px;
            }
            .new-pref-text {
              background: #ffffff;
              border-radius: 4px;
              font-weight: 400;
              margin: 10px 0px;
              text-align: left;
              width: 100%;
              ::v-deep .v-input__slot {
                margin-bottom: 0px !important;
              }
              ::v-deep .v-text-field__details {
                display: none;
              }
            }
            .add-pref {
              color: #29b6f6;
              margin-bottom: 10px;
              text-align: center;
              width: 100%;
              .v-icon.v-icon {
                color: #29b6f6;
              }
            }
          }

          .primary-button {
            color: white;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0;
          }
        }
      }

      .other-fps {
        .title {
          font-family: 'Lato' !important;
          font-size: 20px;
          font-weight: bold;
          line-height: 24px;
          margin-bottom: 8px;
        }
      }

      .insights-dos-dont-heading {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #212121;
        margin-top: 32px;
        margin-bottom: 16px;
      }

      .insights-dos-dont-container {
        display: flex;
        flex-direction: column;
        gap: 16px;
        .dos-donts-row {
          display: flex;
          gap: 16px;

          .do-dos-item {
            display: flex;
            flex-basis: 50%;
            align-items: center;
            gap: 8px;
            font-family: 'Lato';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #212121;
            box-shadow: 1px 1px 4px rgb(0 0 0 / 10%),
              -1px -1px 4px rgb(0 0 0 / 10%);
            border-radius: 12px;
            padding: 5px 15px;

            img {
              width: 52px;
              height: 50px;
            }
          }
        }
      }
    }
  }
  .offscreen {
    position: absolute;
    left: -999em;
  }
}

::v-deep .v-list-item__icon:first-child {
  margin-right: 12px;
}

.edit-preference-cta {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;

  .cancelBtn,
  .primaryBtn {
    box-shadow: none;
    letter-spacing: normal;
    text-transform: none;
  }

  .cancelBtn {
    height: 40px;
    border-radius: 25px;
    background-color: #ffffff;
    border: 1px solid #21a7e0;
    font-weight: bold;
    color: #21a7e0;
  }
}
</style>
